import React from "react";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Email from "@material-ui/icons/Email";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ContactPictureUpload from "components/CustomUpload/ContactPictureUpload.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ReferralSources from "components/CoreWeb/ReferralSources";

import { getActiveOrg } from "variables/coreWeb.jsx";
import * as makeApiCall from "actions/makeApiCall";
import Button from "components/CustomButtons/Button.jsx";
import * as actions from "actions/projectActionTypes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { uploadFile } from "variables/coreRequests.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class ContactImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileSelected: null,
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem>
            <ContactPictureUpload
              imagePreviewUrl={this.props.imagePreviewUrl}
              handleImageChange={(file) => {
                this.setState({ fileSelected: file });
              }}
            />
          </GridItem>
        </GridContainer>
        {this.state.fileSelected ? (
          <GridContainer justify="center">
            <GridItem>
              <Button
                onClick={() => {
                  uploadFile(
                    [this.state.fileSelected],
                    [getActiveOrg(), this.props.contactId, 2],
                    "UploadContactImage",
                    this.props.dispatch
                  );
                }}
                color="rose"
              >
                Upload
              </Button>
            </GridItem>
          </GridContainer>
        ) : (
          ""
        )}
        <br />
      </div>
    );
  }
}

export default withStyles(style)(ContactImage);
