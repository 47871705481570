import React from "react";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.jsx";

import { connect } from "react-redux";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Button } from "@material-ui/core";

const style = {
  ...userProfileStyles,
  ...sweetAlertStyle,
};

const OrgStaffExport = function(props) {
  return (
    <div className="sl-pd-2">
      <GridContainer xs={12} sm={12} md={12} lg={12} className="fullwidth">
        <GridItem xs={12} sm={12} md={12} lg={12} className="pr-0">
          <Card className="nomar2 ">
            <CardHeader>
              <h3 className="myhead welcome-page">Data Export Request</h3>
            </CardHeader>
            <CardBody>
              <p className="set">
                Vetgeo.com respects your privacy and intends to remain
                transparent about any personal data we store about individuals.
                Under the General Data Protection Regulation (GDPR), EU citizens
                and residents are entitled to receive a copy of any personal
                data we might hold about you.
              </p>
              <p className="set">
                The following form will allow you to request an export of any
                data linked to your email address. You will be required to
                authenticate ownership of that address, and may be asked to
                provide additional identification or information necessary to
                verify the request and search our records.
              </p>
              <p className="set">
                This export will contain relevant personal or private data
                stored on WordPress.org, WordPress.net, WordCamp.org,
                BuddyPress.org, bbPress.org, and other related domains and
                sites.
              </p>
              <p className="set">
                By submitting this form, you are the individual owner of the
                specified email address and its associated accounts; and that
                all submitted information including any supplemental details
                necessary to verify your identity are true.
              </p>
              <br />
              <p className="set">
                <Button className="btn-theme btn-black m-b-t-50">
                  Accept Declaration and Request Export
                </Button>
              </p>
              <br />
              <p className="set m-b-t-50">
                Please Note: Before we can begin processing your request, we'll
                require that you verify ownership of the email address. If the
                email address is associated with an account, we'll also require
                you to log in to that account first.
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(OrgStaffExport));
