import React from "react";
import Button from "components/CustomButtons/Button.jsx";
import { paths } from "variables/projPaths";
import {
  roseColor,
  infoColor,
} from "../assets/jss/material-dashboard-pro-react";

const DEBUG = false;
const labelFromType = function(opoType) {
  if (opoType == 1) {
    return (
      <Button color="info" size="sm">
        Canine
      </Button>
    );
  }
  if (opoType == 2) {
    return (
      <Button color="info" size="sm">
        Feline
      </Button>
    );
  }

  if (opoType == 3) {
    return (
      <Button color="info" size="sm">
        Bovine
      </Button>
    );
  }
};

const calendarExceptionColor = "#ff0000";
const calendarAppointmentColor = infoColor;

const APIURL = DEBUG
  ? "https://api.vetgeo.com/api/"
  : "https://api.vetgeo.com/api/";
const CALLBACKURL = DEBUG
  ? "http://localhost:3000/app/dashboard"
  : "https://app.vetgeo.com/app/dashboard";

const UPLOADSPATH = "http://uploads.vetgeo.com/";

const POSTLOGINURL = paths.dashboard;

export {
  labelFromType,
  calendarExceptionColor,
  calendarAppointmentColor,
  APIURL,
  CALLBACKURL,
  UPLOADSPATH,
  POSTLOGINURL,
  DEBUG,
};
