import React from "react";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Pets from "@material-ui/icons/Pets";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Email from "@material-ui/icons/Email";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import ServiceSelect from "components/CoreWeb/ServiceSelect";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GenderSelect from "components/CoreWeb/GenderSelect";
import CustomerSelect from "components/CoreWeb/CustomerSelect";
import PricingSelect from "components/CoreWeb/PricingSelect";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class CreateAppointmentStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      firstnameState: "",
      lastname: "",
      lastnameState: "",
      email: "",
      emailState: "",
    };
  }
  sendState() {
    return this.state;
  }

  isValidated() {
    return true;
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <h4 className={classes.infoText}>
              Let's start with the appointment information
            </h4>
          </GridItem>
          {/*<GridItem xs={12} sm={4}>
          <PictureUpload />
    </GridItem>*/}
          <GridItem xs={12} sm={12} md={10}>
            <ServiceSelect />
            <PricingSelect />
            <CustomerSelect />
            <br />
            <br />
            <h4>
              <strong>Appointment Start</strong>
            </h4>
          </GridItem>

          <GridItem xs={12} sm={12} md={10}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    inputProps={{ placeholder: "Select Date" }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth>
                  <Datetime
                    dateFormat={false}
                    inputProps={{ placeholder: "Select Time" }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          </GridItem>

          <GridItem xs={12} sm={12} md={10}>
            <br />
            <br />
            <h4>
              <strong>Appointment End</strong>
            </h4>
          </GridItem>
          <GridItem xs={12} sm={12} md={10} lg={10}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    inputProps={{ placeholder: "Select Date" }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth>
                  <Datetime
                    dateFormat={false}
                    inputProps={{ placeholder: "Select Time" }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={10} lg={10}>
            <GridContainer justify="center">
              <GridItem xs={0} sm={0} md={5} lg={6} />
              <GridItem xs={0} sm={0} md={5} lg={6}>
                <h4>
                  <strong>Appointment Duration:</strong> 1 hour
                </h4>
              </GridItem>
              <GridItem xs={0} sm={0} md={5} lg={6} />
              <GridItem xs={0} sm={0} md={5} lg={6}>
                <h4>
                  <strong>Total Price:</strong> $300
                </h4>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(style)(CreateAppointmentStep1);
