import React, { useState, useEffect } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Pets from "@material-ui/icons/Pets";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Visibility from "@material-ui/icons/Visibility";
import InsertLink from "@material-ui/icons/InsertLink";

import CardAvatar from "components/Card/CardAvatar.jsx";

import { getActiveOrg } from "variables/coreWeb.jsx";
import { enumAnimalSpeciesToString } from "variables/coreWeb.jsx";
import * as makeApiCall from "actions/makeApiCall";
import image from "assets/img/default-dog.png";
import { paths } from "variables/projPaths.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import * as raisers from "actions/projectRaisers";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deletePatient } from "../../variables/coreRequests";
import { deleteAnimalWithDispatch } from "../../variables/thunks";
import { FormattedMessage } from "react-intl";
import AnimalList from "./AnimalList";

const style = {
  ...userProfileStyles,
  ...sweetAlertStyle,
};

function GenericMapList(props) {
  const { classes } = props;
  useEffect(() => {}, []);

  const fillButtons = function (theProps, animalId) {
    return (
      <div>
        <Button
          className={`${classes.actionButton} info-btn`}
          color="info"
          size="sm"
          onClick={() => {
            theProps.linkAction();
          }}
        >
          <InsertLink className={classes.icon} />
        </Button>
      </div>
    );
  };

  return (
    <GridContainer className="customer-link" justify={"center"}>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Card className="customer-link-card">
          {!props.hideHeader ? (
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Pets />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage id="yourPatients" />
                <small>
                  <FormattedMessage id="patientsToTakeCareOf" />
                </small>
              </h4>
            </CardHeader>
          ) : (
            ""
          )}
          <CardBody>
            <ReactTable
              data={(props.listData || []).map((prop, key) => {
                return {
                  header1: prop.header1,
                  actions: fillButtons(prop),
                };
              })}
              filterable={false}
              columns={[
                {
                  Header: props.header1,
                  accessor: "header1",
                  headerClassName: "table-header-center",
                },
                {
                  Header: <FormattedMessage id="actions" />,
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  headerClassName: "table-header-center",
                  Cell: (content) => (
                    <div className="text-left">{content.value}</div>
                  ),
                },
              ]}
              defaultPageSize={props.pageSize || 10}
              showPaginationTop={false}
              showPaginationBottom={true}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(GenericMapList));
