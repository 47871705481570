import { getActiveOrg } from "variables/coreWeb.jsx";
import * as actions from "actions/projectActionTypes";
import { APIURL } from "variables/project.jsx";
import { setNotificationMessage } from "variables/thunks.jsx";
import moment from "moment";

const updateServiceFulfilmentConfig = function(callApi, theProps) {
  const {
    advancedOrBasicServiceFulfilmentConfig,
    isActive,
    isQueueActive,
    name,
    numberOfRequiredResources,
    orgId,
    prePaymentRequired,
    serviceFulfilmentConfigId,
    serviceId,
  } = theProps;
  const json = {
    advancedOrBasicServiceFulfilmentConfig,
    isActive,
    isQueueActive,
    name,
    numberOfRequiredResources,
    orgId,
    prePaymentRequired,
    serviceFulfilmentConfigId,
    serviceId,
  };

  callApi.req(
    localStorage.getItem("access_token"),
    "Update_Org_ServiceFulfilmentConfig",
    json,
    actions.cw.API_RESP_UPDATE
  );
};

const updateServiceOrder = function(callApi, theProps) {
  const {
    advancedOrBasicServiceFulfilmentConfig,
    isActive,
    isQueueActive,
    name,
    numberOfRequiredResources,
    orgId,
    prePaymentRequired,
    serviceFulfilmentConfigId,
    serviceId,
  } = theProps;
  const json = {
    advancedOrBasicServiceFulfilmentConfig,
    isActive,
    isQueueActive,
    name,
    numberOfRequiredResources,
    orgId,
    prePaymentRequired,
    serviceFulfilmentConfigId,
    serviceId,
  };

  callApi.req(
    localStorage.getItem("access_token"),
    "Update_Org_ServiceOrder",
    json,
    theProps.actionToRaise || actions.cw.API_RESP_UPDATE
  );
};

const updateAnimal = function(callApi, theProps) {
  const {
    animalBreed,
    animalId,
    animalSpecies,
    bloodGroup,
    dateOfBirth,
    deceasedDate,
    deceasedStatus,
    desexedStatus,
    gender,
    insuranceReferenceNumber,
    isActive,
    mainColour,
    microchipId,
    name,
    orgId,
    passportNumber,
    secondaryColour,
  } = theProps;
  const json = {
    animalBreed,
    animalId,
    animalSpecies,
    bloodGroup,
    dateOfBirth,
    deceasedDate,
    deceasedStatus,
    desexedStatus,
    gender,
    insuranceReferenceNumber,
    isActive,
    mainColour,
    microchipId,
    name,
    orgId,
    passportNumber,
    secondaryColour,
  };

  callApi.req(
    localStorage.getItem("access_token"),
    "Update_Org_Animal",
    json,
    actions.cw.API_ANIMAL_UPDATE
  );
};

const updateContact = function(callApi, theProps) {
  const {
    contactAcquisition,
    contactFirstName,
    contactId,
    contactLastName,
    contactTitle,
    contactType,
    emailAddress,
    orgId,
    orgName,
    userId,
  } = theProps;
  const json = {
    contactAcquisition,
    contactFirstName,
    contactId,
    contactLastName,
    contactTitle,
    contactType,
    emailAddress,
    orgId,
    orgName,
    userId,
  };

  callApi.req(
    localStorage.getItem("access_token"),
    "Update_Contact",
    json,
    actions.cw.API_CUSTOMER_UPDATE
  );
};

const updateAddress = function(callApi, theProps) {
  const {
    address1,
    address2,
    city,
    contactId,
    town,
    userId,
    zipcode,
  } = theProps;
  const json = {
    address1,
    address2,
    city,
    contactId,
    town,
    userId,
    zipcode,
  };
  callApi.req(
    localStorage.getItem("access_token"),
    "Update_Address",
    json,
    actions.cw.API_ADDRESS_UPDATE
  );
};

const updateResource = function(callApi, theProps) {
  var payLoad = {};
  console.log(theProps);
  payLoad.allowsOverlaps = 2;
  payLoad.creatorId = 3;
  payLoad.depthUnitType = 2;
  payLoad.depthValue = 0;
  payLoad.heightUnitType = 2;
  payLoad.heightValue = 0;
  payLoad.latitude = 51.50722;
  payLoad.longitude = -0.1275;
  payLoad.maxAppointmentDuration = 7200000;
  payLoad.maxAppointmentFutureTimeInMs = 0;
  payLoad.maxDailyUserSlots = 2;
  payLoad.maxExceptionDuration = 604800000;
  payLoad.orgId = getActiveOrg();
  payLoad.resourceId = theProps.resourceId;
  payLoad.resourceName = theProps.resourceName;
  payLoad.slotDuration = 10800000;
  payLoad.timeAllocationType = 2;
  payLoad.timeZoneIANA = "";
  payLoad.userId = 0;
  payLoad.widthUnitType = 2;
  payLoad.widthValue = 0;

  callApi.req(
    localStorage.getItem("access_token"),
    "Update_Resource",
    payLoad,
    actions.cw.API_RESOURCE_UPDATE
  );
};

const updateService = function(callApi, theProps) {
  var payLoad = {
    functionName: "Update_Service",
    json: JSON.stringify(theProps),
  };
  callApi.req(
    localStorage.getItem("access_token"),
    "UpdateFunction",
    payLoad,
    actions.cw.API_SERVICE_UPDATE
  );
};

const updateProduct = function(callApi, theProps) {
  callApi.req(
    localStorage.getItem("access_token"),
    "Update_Product",
    theProps,
    actions.cw.API_PRODUCT_UPDATE
  );
};

const updateMedical = function(callApi, theProps, animalId, selectedMedicalId) {
  var payLoad = {};
  console.log(theProps);
  var dateTime = moment(theProps.date)
    .hour(moment(theProps.time).hour())
    .minute(moment(theProps.time).minute());

  var noteDesc;
  if (theProps.medicalNoteType == 9) {
    noteDesc = theProps.weightMeasurement + " " + theProps.weightUnits;
  } else if (theProps.medicalNoteType == 10) {
    noteDesc = theProps.heightMeasurement + " " + theProps.heightUnits;
  } else if (theProps.medicalNoteType == 12) {
    noteDesc =
      theProps.temperatureMeasurement + " " + theProps.temperatureUnits;
  } else if (theProps.medicalNoteType == 13) {
    noteDesc = theProps.pulseMeasurement + " beats per minute";
  } else if (theProps.medicalNoteType == 14) {
    noteDesc = theProps.respirationMeasurement + " breaths per minute";
  } else {
    noteDesc = theProps.noteDescription;
  }

  payLoad.medicalRecordId = parseInt(animalId);
  payLoad.medicalNoteType = theProps.medicalNoteType;
  payLoad.noteDescription = noteDesc;
  payLoad.medicalNoteId = parseInt(selectedMedicalId);
  payLoad.orgId = getActiveOrg();
  payLoad.createdOn_UTC = dateTime.toISOString();

  console.log(payLoad);
  callApi.req(
    localStorage.getItem("access_token"),
    "Update_MedicalNote",
    payLoad,
    actions.cw.API_MEDICAL_UPDATE
  );
};

const uploadFile = function(
  files,
  dataParamKeyValuePairs,
  endpointToSendTo,
  dispatch
) {
  var formData = new FormData();

  files.map((file) => {
    formData.append(`Files`, file);
    console.log(file);
  });

  dataParamKeyValuePairs.map((option) => {
    formData.append("Params", option);
  });

  fetch(APIURL + endpointToSendTo, {
    // content-type header should not be specified!
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ` + localStorage.getItem("access_token"),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.func_status == 1) {
        dispatch({
          type: actions.cw.FILE_UPLOADED_STATUS,
          payload: {
            msg: data.func_msg,
            color: "success",
          },
        });
        setTimeout(() => {
          dispatch({
            type: actions.cw.NOTI_HIDE,
            payload: {},
          });
        }, 2000);
      } else {
        dispatch({
          type: actions.cw.FILE_UPLOADED_STATUS,
          payload: {
            msg: data.func_msg,
            color: "danger",
          },
        });
        setTimeout(() => {
          dispatch({
            type: actions.cw.NOTI_HIDE,
            payload: {},
          });
        }, 2000);
      }
    })
    .catch((error) => {
      dispatch({
        type: actions.cw.FILE_UPLOADED_STATUS,
        payload: {
          msg: "Sothing Want Wrong",
          color: "danger",
        },
      });
    });
};

const createServiceFulfilmentConfigComplete = function(callApi, theProps) {
  var payLoad = {};
  payLoad.functionName = "CreateServiceFulfilmentConfigComplete";
  var json = {};
  json.orgId = getActiveOrg();
  json.isActive = 2;
  json.name = theProps.name || theProps.serviceFulfilmentConfig.name;
  json.serviceId = theProps.serviceId;
  json.numberOfRequiredResources = 0;
  json.prePaymentRequired = 2;
  json.advancedOrBasicServiceFulfilmentConfig = 3;
  json.isQueueActive = 2;
  json.serviceFulfilmentConfigId = 0;
  json.maxOrdersPerDay = 0;
  json.maxDailyLimit = 0;
  json.serviceFulfilmentConfigResourceOptionMapList = [];

  json.contactId = 0;
  if (theProps.mandatoryResources > 0) {
    for (var i = 0; i < theProps.mandatoryResources; i++) {
      var addMe = {};
      addMe.serviceFulfilmentResourceConfigRequiredOptional = 3;
      addMe.resourceId =
        theProps["mandatoryResConfigResourceSelect" + i.toString()];
      addMe.durationMilliseconds =
        theProps["mandatoryResConfigDuration" + i.toString()];
      addMe.monetaryAmount =
        theProps["mandatoryResConfigSalesPrice" + i.toString()];
      addMe.monetaryCurrency = 840;
      addMe.serviceId = theProps.serviceId;
      addMe.serviceFulfilmentResourceConfigRelationship = 3;
      addMe.serviceFulfilmentConfigId = 0;
      addMe.orgId = theProps.orgId;
      json.serviceFulfilmentConfigResourceOptionMapList.push(addMe);
    }
  }
  if (theProps.optionalResources > 0) {
    for (var l = 0; l < theProps.optionalResources; l++) {
      var addMeOptional = {};
      addMeOptional.serviceFulfilmentResourceConfigRequiredOptional = 2;
      addMeOptional.resourceId =
        theProps["optionalResConfigResourceSelect" + l.toString()];
      addMeOptional.durationMilliseconds =
        theProps["optionalResConfigDuration" + l.toString()];
      addMeOptional.monetaryAmount =
        theProps["optionalResConfigSalesPrice" + l.toString()];
      addMeOptional.monetaryCurrency = 840;
      addMeOptional.serviceId = theProps.serviceId;
      addMeOptional.serviceFulfilmentResourceConfigRelationship = 3;
      addMeOptional.serviceFulfilmentConfigId = 0;
      addMeOptional.orgId = theProps.orgId;
      json.serviceFulfilmentConfigResourceOptionMapList.push(addMeOptional);
    }
  }
  if (theProps.mandatoryStaff > 0) {
    for (var k = 0; k < theProps.mandatoryStaff; k++) {
      var addMeStaff = {};
      addMeStaff.serviceFulfilmentResourceConfigRequiredOptional = 3;
      addMeStaff.resourceId =
        theProps["mandatoryStaffConfigResourceSelect" + k.toString()];
      addMeStaff.durationMilliseconds =
        theProps["mandatoryStaffConfigDuration" + k.toString()];
      addMeStaff.monetaryAmount =
        theProps["mandatoryStaffConfigSalesPrice" + k.toString()];
      addMeStaff.monetaryCurrency = 840;
      addMeStaff.serviceId = theProps.serviceId;
      addMeStaff.serviceFulfilmentResourceConfigRelationship = 2;
      addMeStaff.serviceFulfilmentConfigId = 0;
      addMeStaff.orgId = theProps.orgId;
      json.serviceFulfilmentConfigResourceOptionMapList.push(addMeStaff);
    }
  }
  if (theProps.optionalStaffs > 0) {
    for (var j = 0; j < theProps.optionalStaffs; j++) {
      var addMeStaffOptional = {};
      addMeStaffOptional.serviceFulfilmentResourceConfigRequiredOptional = 2;
      addMeStaffOptional.resourceId =
        theProps["optionalStaffConfigResourceSelect" + j.toString()];
      addMeStaffOptional.durationMilliseconds =
        theProps["optionalStaffConfigDuration" + j.toString()];
      addMeStaffOptional.monetaryAmount =
        theProps["optionalStaffConfigSalesPrice" + j.toString()];
      addMeStaffOptional.monetaryCurrency = 840;
      addMeStaffOptional.serviceId = theProps.serviceId;
      addMeStaffOptional.serviceFulfilmentResourceConfigRelationship = 2;
      addMeStaffOptional.serviceFulfilmentConfigId = 0;
      addMeStaffOptional.orgId = theProps.orgId;
      json.serviceFulfilmentConfigResourceOptionMapList.push(
        addMeStaffOptional
      );
    }
  }

  payLoad.json = JSON.stringify(json);
  callApi.req(
    localStorage.getItem("access_token"),
    "CreateFunction",
    payLoad,
    actions.cw.API_SFC_CREATE
  );
};

const updateServiceFulfilmentConfigComplete = function(callApi, theProps) {
  var payLoad = {};
  var json = {};
  json.orgId = getActiveOrg();
  json.isActive = theProps.serviceFulfilmentConfig.isActive;
  json.name = theProps.name || theProps.serviceFulfilmentConfig.name;
  json.serviceId = theProps.serviceId;
  json.numberOfRequiredResources = 0;
  json.prePaymentRequired = 2;
  json.advancedOrBasicServiceFulfilmentConfig = 3;
  json.isQueueActive = 2;
  json.serviceFulfilmentConfigId =
    theProps.serviceFulfilmentConfig.serviceFulfilmentConfigId;
  json.maxOrdersPerDay = 0;
  json.maxDailyLimit = 0;
  json.serviceFulfilmentConfigResourceOptionMapList = [];

  json.contactId = 0;
  if (theProps.mandatoryResources > 0) {
    for (var i = 0; i < theProps.mandatoryResources; i++) {
      var addMe = {};
      addMe.serviceFulfilmentResourceConfigRequiredOptional = 3;
      addMe.resourceId =
        theProps["mandatoryResConfigResourceSelect" + i.toString()];
      addMe.durationMilliseconds =
        theProps["mandatoryResConfigDuration" + i.toString()];
      addMe.monetaryAmount =
        theProps["mandatoryResConfigSalesPrice" + i.toString()];
      addMe.monetaryCurrency = 840;
      addMe.serviceId = theProps.serviceId;
      addMe.serviceFulfilmentResourceConfigRelationship = 3;
      addMe.serviceFulfilmentConfigId = 0;
      addMe.orgId = theProps.orgId;
      json.serviceFulfilmentConfigResourceOptionMapList.push(addMe);
    }
  }
  if (theProps.optionalResources > 0) {
    for (var l = 0; l < theProps.optionalResources; l++) {
      var addMeOptional = {};
      addMeOptional.serviceFulfilmentResourceConfigRequiredOptional = 2;
      addMeOptional.resourceId =
        theProps["optionalResConfigResourceSelect" + l.toString()];
      addMeOptional.durationMilliseconds =
        theProps["optionalResConfigDuration" + l.toString()];
      addMeOptional.monetaryAmount =
        theProps["optionalResConfigSalesPrice" + l.toString()];
      addMeOptional.monetaryCurrency = 840;
      addMeOptional.serviceId = theProps.serviceId;
      addMeOptional.serviceFulfilmentResourceConfigRelationship = 3;
      addMeOptional.serviceFulfilmentConfigId = 0;
      addMeOptional.orgId = theProps.orgId;
      json.serviceFulfilmentConfigResourceOptionMapList.push(addMeOptional);
    }
  }
  if (theProps.mandatoryStaff > 0) {
    for (var k = 0; k < theProps.mandatoryStaff; k++) {
      var addMeStaff = {};
      addMeStaff.serviceFulfilmentResourceConfigRequiredOptional = 3;
      addMeStaff.resourceId =
        theProps["mandatoryStaffConfigResourceSelect" + k.toString()];
      addMeStaff.durationMilliseconds =
        theProps["mandatoryStaffConfigDuration" + k.toString()];
      addMeStaff.monetaryAmount =
        theProps["mandatoryStaffConfigSalesPrice" + k.toString()];
      addMeStaff.monetaryCurrency = 840;
      addMeStaff.serviceId = theProps.serviceId;
      addMeStaff.serviceFulfilmentResourceConfigRelationship = 2;
      addMeStaff.serviceFulfilmentConfigId = 0;
      addMeStaff.orgId = theProps.orgId;
      json.serviceFulfilmentConfigResourceOptionMapList.push(addMeStaff);
    }
  }
  if (theProps.optionalStaffs > 0) {
    for (var j = 0; j < theProps.optionalStaffs; j++) {
      var addMeStaffOptional = {};
      addMeStaffOptional.serviceFulfilmentResourceConfigRequiredOptional = 2;
      addMeStaffOptional.resourceId =
        theProps["optionalStaffConfigResourceSelect" + j.toString()];
      addMeStaffOptional.durationMilliseconds =
        theProps["optionalStaffConfigDuration" + j.toString()];
      addMeStaffOptional.monetaryAmount =
        theProps["optionalStaffConfigSalesPrice" + j.toString()];
      addMeStaffOptional.monetaryCurrency = 840;
      addMeStaffOptional.serviceId = theProps.serviceId;
      addMeStaffOptional.serviceFulfilmentResourceConfigRelationship = 2;
      addMeStaffOptional.serviceFulfilmentConfigId = 0;
      addMeStaffOptional.orgId = theProps.orgId;
      json.serviceFulfilmentConfigResourceOptionMapList.push(
        addMeStaffOptional
      );
    }
  }

  payLoad = json;
  callApi.req(
    localStorage.getItem("access_token"),
    "Update_Org_ServiceFulfilmentConfig",
    payLoad,
    actions.cw.API_SFC_UPDATE
  );
};

const deletePatient = function(callMe, animalId) {
  var payLoad = {};
  payLoad.orgId = getActiveOrg();
  payLoad.animalId = animalId;

  callMe.req(
    localStorage.getItem("access_token"),
    "Delete_Animal_By_Animal_ID",
    payLoad,
    actions.cw.API_ANIMAL_DELETE
  );
};

const deleteMetaData = function(callMe, orgId, metaDataId) {
  var payLoad = {};
  payLoad.functionName = "DeleteMetaData";
  var json = { orgId: orgId, metaDataId: metaDataId };
  payLoad.json = JSON.stringify(json);
  callMe.req(
    localStorage.getItem("access_token"),
    "DeleteFunction",
    payLoad,
    actions.cw.API_META_DELETED
  );
};

const deleteContact = function(callMe, contactId) {
  var payLoad = {};
  payLoad.contactId = contactId;
  payLoad.orgId = getActiveOrg();

  callMe.req(
    localStorage.getItem("access_token"),
    "Delete_Contact",
    payLoad,
    actions.cw.API_CONTACT_DELETE
  );
};

const deleteAddress = function(callMe, addressId) {
  var payLoad = {};
  payLoad.addressId = addressId;
  payLoad.orgId = getActiveOrg();

  callMe.req(
    localStorage.getItem("access_token"),
    "Delete_Address",
    payLoad,
    actions.cw.API_CONTACT_ADDRESS_DELETE
  );
};

const deleteService = function(callMe, serviceId) {
  var payLoad = {};
  payLoad.orgId = getActiveOrg();
  payLoad.serviceId = serviceId;

  callMe.req(
    localStorage.getItem("access_token"),
    "Delete_Service",
    payLoad,
    actions.cw.API_SERVICE_DELETE
  );
};

const deleteBooking = function(callMe, serviceFulfilmentConfigId) {
  var payLoad = {};
  payLoad.orgId = getActiveOrg();
  payLoad.serviceFulfilmentConfigId = serviceFulfilmentConfigId;

  callMe.req(
    localStorage.getItem("access_token"),
    "DeleteFunction",
    payLoad,
    actions.cw.API_SERVICE_DELETE
  );
};

const deleteProduct = function(callMe, productId) {
  console.log(productId);
  var payLoad = {};
  payLoad.orgId = getActiveOrg();
  payLoad.productId = productId;

  callMe.req(
    localStorage.getItem("access_token"),
    "Delete_Product",
    payLoad,
    actions.cw.API_PRODUCT_DELETE
  );
};

const deleteResource = function(callMe, resourceId) {
  var payLoad = {};
  payLoad.orgId = getActiveOrg();
  payLoad.resourceId = resourceId;

  callMe.req(
    localStorage.getItem("access_token"),
    "Delete_Resource",
    payLoad,
    actions.cw.API_RESOURCE_DELETE
  );
};

const deleteMedical = function(callMe, medicalId) {
  var payLoad = {};
  payLoad.medicalNoteId = medicalId;
  payLoad.orgId = getActiveOrg();

  callMe.req(
    localStorage.getItem("access_token"),
    "Delete_MedicalNote",
    payLoad,
    actions.cw.API_MEDICAL_DELETE
  );
};

export {
  updateServiceFulfilmentConfig,
  updateServiceOrder,
  updateAnimal,
  // deleteAnimal,
  updateContact,
  updateResource,
  updateService,
  updateProduct,
  uploadFile,
  createServiceFulfilmentConfigComplete,
  updateServiceFulfilmentConfigComplete,
  deletePatient,
  deleteContact,
  deleteAddress,
  deleteService,
  deleteProduct,
  deleteResource,
  deleteMedical,
  deleteMetaData,
  updateAddress,
  deleteBooking,
  updateMedical,
};
