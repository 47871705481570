import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { IntlProvider, FormattedMessage } from "react-intl";
import indexRoutes from "routes/index.jsx";
import { Provider } from "react-redux";
import configureStore from "store/configureStore";
import { AUTH_CONFIG } from "Auth/auth0-variables";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { CALLBACKURL } from "variables/project";
import LoginCallback from "components/CoreWeb/LoginCallback";
import Dashboard from "layouts/Dashboard";
import Logout from "components/CoreWeb/Containers/Logout";
import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";
import ReactGA from "react-ga";

const getLocale = function() {
  return "en-us";
};
const getMessages = function(language) {
  if (language == 2 || language == 3) {
    return {
      active: "Active",
      inActive: "InActive",
      activeOrInactive: "Active or Inactive",
      createNewOrg: "Create new clinic",
      yourOrganizations: "Your clinics",
      yourOrgPartOf: "The clins you control and are part of",
      patientDetails: "Patient Details",
      patientName: "Patient Name",
      createNew: "Create New",
      species: "Species",
      dateOfBirth: "Date Of Birth",
      dateOfTime: "Select Time Of Birth ",
      gender: "Sex",
      physicalExam: "Physical Exam",
      desexedStatus: "Desexed Status ",
      bloodGroup: "Blood Group ",
      passportNumber: "Passport Number",
      microchipNumber: "Microchip Number",
      insuranceRefNumber: "Insurance Ref Number",
      ageString: "Age",
      deceasedDate: "Deceased Date",
      alive: "Alive",
      deceased: "Deceased",
      // aliveOrDeceased: "Alive or Deceased",
      clinicServices: "Clinic Services",
      servicesClients: "The services you offer your clients",
      serviceName: "Service Name",
      serviceDescription: "Service Description",
      deletingServicePermanent: " Deleting a service is permanent",
      options: "Options",
      serviceDetails: "Service Details",
      createService: "Create Service",

      addressList: "Address List",
      addressesConfigured: "The addresses which are configured",
      address1: "Address 1",
      address2: "Address 1",
      town: "Town",
      city: "City",
      zipCode: "Zipcode",
      close: "Close",
      deletingAdddressPermanent: "Deleting a adddress is permanent",
      createAddress: "Create Address",
      yourCustomers: "Your Clients",

      Existingcustomers: "Clients",
      customerImage: "Client Image",
      customerName: "Client Name",
      deleteit: "Yes, delete it!",
      deletingCustomer: "Deleting a customer is permanent",
      details: "Details",
      addresses: "Addresses",
      patients: "Patients",
      appointments: "Appointments",
      createNewPhoneNumber: "Create New Phone Number",
      editNewPhoneNumber: "Edit New Phone Number",

      newMedicalNote: "New Medical Note",
      updateMedicalNote: "Update Medical Note",
      CreatePatientRecord: "Create a patient record",
      updatePatientRecord: "Update a patient record",
      time: "Time",
      medicalNote: "Medical Note Type",
      weightMeasurement: "Weight Measurement",
      weightUnits: "Weight Units",
      heightMeasurement: "Height Measurement",
      heightUnits: "Height Units",
      temperatureMeasurement: "Temperature Measurement",
      temperatureUnits: "Temperature Units",
      pulseMeasurement: "Pulse Measurement (beats per minute)",
      respirationMeasurement: "Respiration Measurement (breaths per minute)",
      respirationMeasurementNote: "Respiration Measurement",
      medicalNoteDetails: "Medical Note Details",
      medicalNoteDocument: "Medical Note Document",
      createNewEntry: "Create new entry",
      updateEntry: "Update Entry",
      yourPatients: "Your Patients",
      yourpatientsTakecare: "Your patients to take care of",
      patientType: "Species",
      deletingPatientPermanent: "Deleting a patient is permanent",
      createStaffMember: "Create Staff Member",
      staffImage: "Staff Image",
      staffDetails: "Staff Details",
      contactImage: "Contact Image",
      contactDetails: "Contact Details",
      emailAddress: "Email Address",
      businessName: "Business Name",
      comingSoon: "Coming Soon",
      createCustomer: "Create Client",
      phoneNumbers: "Phone Numbers",
      delete: "Delete",
      createNewNumber: "Create New Phone Number",
      createPhoneNumber: "Create Phone Number",
      medicalRecord: "Medical Record",
      patientImage: "Patient Image",
      changePicture: "Change Picture",
      upload: "Upload",
      medicalRecordEntries: "Medical Record Entries",
      medicalRecordPatient: "The medical record for the patient",
      addNewEntry: "Add new entry",
      date: "Date",
      noteType: "Note Type",
      noteDescriptionn: "Note Description",
      actions: "Actions",
      resourceName: "Resource Name",
      createResource: "Create Resource",
      updateResource: "Update Resource",
      addNewPricing: "Add Booking Configuration",
      pricingOptions: "Booking Options",
      staffMembers: "Staff Members",
      resources: "Resources",
      selectLocationBoundaries: "Select Location Boundaries",
      uploadServiceImages: "Upload service images",
      systemId: "System Id",
      userProductReference: "User Product Reference",
      productDetails: "Product Details",
      productCurrency: "Product Currency",
      purchasing: "Purchasing",
      purchaseCost: "Purchase Cost",
      purchaseTaxRate: "Purchase Tax Rate",
      Sales: "Sales",
      salesPrice: "salesPrice",
      salesTaxRate: "Sales Tax Rate",
      update: "Update",
      createProduct: "Create Product",
      yourDetails: "Your Details",
      presentYourself: "- Present yourself to others",
      emailaddress: "Email address",
      firstName: "First Name",
      lastName: "Last Name",
      languageSelect: "Language Select",
      organizationName: "Clinic Name",
      organizationIdNumber: "Clinic Id/Number",
      organizationType: "Organization Type",
      employeeCount: "Employee Count",
      yearEstablished: "Year Established",
      yearsTrading: "Years Trading",
      organisationCurrency: "Organisation Currency",
      companyVDescription: "Company Vision/Description",
      clinicsLocation: "This Clinics Location",
      updateDetails: "Update Details",
      createOrganization: "Create Clinic",
      existingPatients: "Patients",
      viewPatient: "View Patient",
      medicalRecordEntry: "Medical Record Entry",
      customers: "Clients",
      createNewCustomer: "Create New Client",
      existingCustomers: "Clients",
      viewCustomer: "View Client",
      viewAddress: "View Address",
      invoices: "Invoices",
      createNewInvoice: "Create New Invoice",
      existingInvoices: "Existing Invoices",
      services: "Services",
      createNewService: "Create New Service",
      existingServices: "Services",
      viewService: "View Service",
      addPricing: "Add Pricing",
      viewPricing: "View Pricing",
      inventory: "Inventory",
      addNewProduct: "Add New Product",
      products: "Products",
      viewProduct: "View Product",
      addInventory: "Add Inventory",
      viewStockLevels: "View Stock Levels",
      addResource: "Add Resource",
      manageResources: "Rooms",
      manageResourcesEquipment: "Equipment",
      viewResource: "View Resource",
      personell: "Staff",
      addStaffMember: "Add Staff Member",
      manageStaff: "Staff",
      viewStaff: "View Staff",
      settings: "Settings",
      clinicView: "Clinic View",
      createClinic: "Create Clinic",
      signIntoOrg: "Clinics",
      profile: "Profile",
      yourProfile: "Profile",
      logout: "Logout",
      pricingDescription: "Booking Description",
      isActive: "Is Active",
      pricing: "Booking",
      pricingDetails: "Booking Configuration",
      pricingName: "Configuration Name",
      pricingActiveInactive: "Configuration Active or Inactive",
      prepaymentRequired: "Prepayment Required",
      prepay: "In online booking, whether someone must pre-pay",
      updatePricing: "Update Pricing",
      createPricing: "Create Pricing",
      mandatoryStaff: "Mandatory staff",
      mandatoryStaffService:
        "Number of mandatory staff who must be present to complete the service",
      optionalstaff: "Optional staff",
      optionalStaffService:
        "Number of optional staff who must be present to complete the service",
      requirementType: "Requirement Type",
      staffMember: "Staff Member",
      durationMinutes: "Duration in Minutes",
      mandatoryResources: "Mandatory resources",
      mandatoryResourcesService:
        " Number of mandatory resources which must be available to complete the service",
      optionalResources: "Optional resources",
      optionalResourcesService:
        "Number of optional resources any of which could be available to complete the service",
      clinicProducts: "Clinic Products",
      servicesOfferClients: " The services you offer your clients",
      productId: "Product Id",
      productName: "Product Name",
      deletingProduct: "Deleting a product is permanent",
      staffList: "Staff List",
      manageYourStaff: "manage your staff",
      staffName: "Staff Name",
      role: "Role",
      areYouSure: "Are you sure?",
      deleteIt: "Yes, delete it!",
      cancel: "Cancel",
      deletingStaffPermanent: "Deleting a staff is permanent",
      deletingClinicsPermanent: "Deleting a clinic is permanent",
      resourceList: "Resource List",
      manageYourResources: "manage your resources",
      resourceType: "Resource Type",
      deletingResourcePermanent: "Deleting a resource is permanent",
      createPatient: "Create Patient",
      patientsToTakeCareOf: "Existing Patients",
      clinicCalendar: "Clinic Calendar",
      sales: "Sales",
      male: "Male",
      female: "Female",
      unspecified: "Unspecified",
      castrated: "Castrated",
      speyed: "Speyed",
      selectNumberType: "Select Number Type",
      home: "Home",
      work: "Work",
      mobile: "Mobile",
      mainline: "Mainline",
      homeFax: "Home Fax",
      workFax: "Work Fax",
      pager: "Pager",
      other: "Other",
      avian: "Avian",
      bovine: "Bovine",
      camelid: "Camelid",
      canine: "Canine",
      caprine: "Caprine",
      cavies: "Cavies",
      cervidae: "Cervidae",
      equine: "Equine",
      feline: "Feline",
      lapine: "Lapine",
      maurine: "Murine",
      murine: "Murine",
      ovine: "Ovine",
      note: "Note",
      procedure: "Procedure",
      behaviour: "Behaviour",
      allergy: "Allergy",
      observation: "Observation",
      dental: "Dental",
      laboratory: "Laboratory",
      medication: "Medication",
      pulseMeasurementNote: "Pulse Measurement",
      appointmentList: "Appointment List",
      existingAppointments: "- Existing appointments",
      type: "Type",
      startDate: "Start Date",
      endDate: "End Date",
      duration: "Duration",
      Status: "Status",
      intact: "Intact",
      deSexed: "De-Sexed",
      notApplicable: "Not Applicable",
      location: "Location",
      successful: "Successful",
      contactTitle: "Title",
      mr: "Mr",
      noActiveOrg: "You must sign into a clinic",
      search: "Search",
    };
  }
  if (language == 4) {
    return {
      createNewOrg: "Criar uma nova organização",
      yourOrganizations: "Sua Organização",
      yourOrgPartOf: "A organização que você controla e é parte de",
      patientDetails: "Detalhes do Paciente",
      patientName: "Nome do paciente",
      createNew: "Criar Novo",
      species: "Especies",
      dateOfBirth: "Data do Nascimento",
      dateOfTime: "Hora do Nascimento ",
      gender: "Genero ",
      physicalExam: "Physical Exam",
      desexedStatus: "Castração estado ",
      bloodGroup: "Grupo Sanguineo ",
      passportNumber: "Passaporte nr.",
      microchipNumber: "Microship nr.",
      insuranceRefNumber: "Seguro nr.",

      clinicServices: "Serviços da Clínica",
      servicesClients: "Serviços que você oferece para clientes",
      serviceName: "Nome do Serviço",
      serviceDescription: "Descrição do Serviço",
      deletingServicePermanent: " A exclusão de um serviço é permanente",
      options: "Opções",
      serviceDetails: "Detalhes do Serviço",
      createService: "Criar Serviço",

      addressList: "Lista de Endereços",
      addressesConfigured: "Os endereços configurados",
      address1: "Endereço 1",
      address2: "Endereço 2",
      town: "Bairro",
      city: "Cidade",
      zipCode: "CEP",
      close: "Fechar",
      deletingAdddressPermanent: "A exclusão de um endereço é permanente",
      createAddress: "Criar Endereço",
      yourCustomers: "Seus Clientes",

      Existingcustomers: "Clientes existentes",
      customerImage: "Imagem do Cliente",
      customerName: "Nome do Cliente",
      deleteit: "Sim, exclua-o!",
      deletingCustomer: "A exclusão de um cliente é permanente",
      details: "Detalhes",
      addresses: "Endereços",
      patients: "Pacientes",
      appointments: "Compromissos",
      createNewPhoneNumber: "Criar Novo Número de Telefone",
      editNewPhoneNumber: "Editar Novo Número de Telefone",

      newMedicalNote: "Nova Nota Médica",
      updateMedicalNote: "Atualizar Nota Médica",
      CreatePatientRecord: "Criar um registro de paciente",
      updatePatientRecord: "Atualizar o registro de um paciente",
      time: "Tempo",
      medicalNote: "Tipo de Nota Médica",
      weightMeasurement: "Medição de peso",
      weightUnits: "Weight Units",
      heightMeasurement: "Medição de altura",
      heightUnits: "Height Units",
      temperatureMeasurement: "Medição de temperatura",
      temperatureUnits: "Temperature Units",
      pulseMeasurement: "Medição de pulso (batimentos por minuto)",
      pulseMeasurementNote: "Pulse Measurement",
      respirationMeasurement: "Medição da respiração (respirações por minuto)",
      respirationMeasurementNote: "Respiration Measurement",
      medicalNoteDetails: "Detalhes da Nota Médica",
      medicalNoteDocument: "Documento de Nota Médica",
      createNewEntry: "Criar nova entrada",
      updateEntry: "atualização de entrada",
      yourPatients: "Seus Pacientes",
      yourpatientsTakecare: "Seus pacientes para cuidar",
      patientType: "Tipo de Paciente",
      deletingPatientPermanent: "A exclusão de um paciente é permanente",
      createStaffMember: "Criar Membro da Equipe",
      staffImage: "Imagem do Funcionário",
      staffDetails: "Detalhes da Funcionário",
      contactImage: "Imagem do Contato",
      contactDetails: "Detalhes do Contato",
      emailAddress: "Endereço de Email",
      businessName: "Nome da Empresa",
      comingSoon: "Em Breve",
      createCustomer: "Criar Cliente",
      phoneNumbers: "Telefone nr.",
      delete: "Excluir",
      createNewNumber: "Criar Novo Número de Telefone",
      createPhoneNumber: "Criar Número de Telefone",
      medicalRecord: "Registro Médico",
      patientImage: "Imagem do Paciente",
      changePicture: "Alterar Figura",
      upload: "Carregar",
      medicalRecordEntries: "Entradas do Registro Médico",
      medicalRecordPatient: "O registro médico do paciente",
      addNewEntry: "Adicionar nova entrada",
      date: "Data",
      noteType: "Tipo da Nota",
      noteDescriptionn: "Descrição da Nota",
      actions: "Ações",
      resourceName: "Nome do Recurso",
      createResource: "Criar Recurso",
      updateResource: "Atualizar Recurso",
      addNewPricing: "Adicionar Novo Preço",
      pricingOptions: "Opções de Preço",
      staffMembers: "Membros da Equipe",
      resources: "Recursos",
      selectLocationBoundaries: "Selecionar limites de localização",
      uploadServiceImages: "Carregar imagens de serviço",
      systemId: "ID do Sistema",
      userProductReference: "Referência do Produto do Usuário",
      productDetails: "Detalhes do produto",
      productCurrency: "Moeda do produto",
      purchasing: "Compras",
      purchaseCost: "Custo de compra",
      purchaseTaxRate: "Taxa de imposto de compra",
      Sales: "Vendas",
      salesPrice: "Preço de venda",
      salesTaxRate: "Taxa de imposto sobre vendas",
      update: "Atualizar",
      createProduct: "Criar Produto",
      yourDetails: "Seus Detalhes",
      presentYourself: "- Apresente-se aos demais",
      emailaddress: "Endereço de Email",
      firstName: "Primeiro Nome",
      lastName: "Último Nome",
      languageSelect: "Language Select",
      organizationName: "Nome da Organização",
      organizationIdNumber: "ID/Número da Organização",
      organizationType: "Tipo da Organização",
      employeeCount: "Conta de Funcionários",
      yearEstablished: "Anos de Estabelacimento",
      yearsTrading: "Anos de Negociação",
      organisationCurrency: "Moeda da Organização",
      companyVDescription: "Visão/Descrição da Empresa",
      clinicsLocation: "Localização das Clínicas",
      updateDetails: "Atualizar Detalhes",
      createOrganization: "Criar Organização",
      existingPatients: "Pacientes Existentes",
      viewPatient: "Exibir Paciente",
      medicalRecordEntry: "Entrada de Registro Médico",
      customers: "Clientes",
      createNewCustomer: "Criar Novo Cliente",
      existingCustomers: "Clientes Existentes",
      viewCustomer: "Exibir Cliente",
      viewAddress: "Exibir Endereço",
      invoices: "Faturas",
      createNewInvoice: "Criar Nova Fatura",
      existingInvoices: "Faturas Existentes",
      services: "Serviços",
      createNewService: "Criar Novo Seviço",
      existingServices: "Serviços Existentes",
      viewService: "Exibir Serviço",
      addPricing: "Adicionar Preços",
      viewPricing: "Exibir Preços",
      inventory: "Inventário",
      addNewProduct: "Adicionar Novo Produto",
      products: "Produtos",
      viewProduct: "Exibir Produto",
      addInventory: "Adicionar Inventário",
      viewStockLevels: "Exibir Níveis de Estoque",
      addResource: "Adicionar Recurso",
      manageResources: "Gerenciar Recursos",
      viewResource: "Exibir Recurso",
      personell: "Pessoal",
      addStaffMember: "Adicionar Membro da Equipe",
      manageStaff: "Gerenciar Equipe",
      viewStaff: "Exibir Equipe",
      settings: "Configurações",
      clinicView: "Perfil da Clínica",
      createClinic: "Criar Clínica",
      signIntoOrg: "Entrar na Organização",
      profile: "Perfil",
      yourProfile: "Seu Perfil",
      logout: "Sair",
      pricingDescription: "Descrição do Preço",
      isActive: "Está Ativo",
      pricing: "Preço",
      pricingDetails: "Detalhes do Preço",
      pricingName: "Nome do Preço",
      pricingActiveInactive: "Preço Ativo ou Inativo",
      prepaymentRequired: "Pagamento Obrigatório",
      prepay: "No agendamento online, se alguém deve pagar antecipadamente",
      updatePricing: "Atualizar preço",
      createPricing: "Criar preço",
      mandatoryStaff: "Funcionário obrigatório",
      mandatoryStaffService:
        "Número de funcionários obrigatórios que devem estar presentes para concluir o serviço",
      optionalstaff: "Funcionário opcional",
      optionalStaffService:
        "Número de funcionários opcionais que devem estar presentes para concluir o serviço",
      requirementType: "Tipo de Requerimento",
      staffMember: "Funcionário Membro",
      durationMinutes: "Duração em Minutos",
      mandatoryResources: "Recursos obrigatórios",
      mandatoryResourcesService:
        "Número de recursos obrigatórios que devem estar disponíveis para concluir o serviço",
      optionalResources: "Recursos opcionais",
      optionalResourcesService:
        "Número de recursos opcionais, um dos quais poderia estar disponível para concluir o serviço",
      clinicProducts: "Produtos da Clínica",
      servicesOfferClients: " Os serviços que você oferece a seus clientes",
      productId: "ID do Produto",
      productName: "Nome do Produto",
      deletingProduct: "A exclusão de um produto é permanente",
      staffList: "Lista de Funcionários",
      manageYourStaff: "Gerenciar seu funcionário",
      staffName: "Nome do funcionário",
      role: "Função",
      areYouSure: "Você tem certeza?",
      deleteIt: "Sim, exclua-o!",
      cancel: "Cancelar",
      deletingStaffPermanent: "A exclusão de um funcionário é permanente",
      resourceList: "Lista de Recursos",
      manageYourResources: "Gerenciar seus recursos",
      resourceType: "Tipo de Recurso",
      deletingResourcePermanent: "A exclusão de um recurso é permanente",
      createPatient: "Criar paciente",
      patientsToTakeCareOf: " - Seus pacientes para cuidar ",
      clinicCalendar: "Clinico Calendar",
      sales: "Venda",
      contactTitle: "contactTitle",
      search: "Procurar",
    };
  }
};
function getClientId() {
  /*console.log('environmet: ', process.env.NODE_ENV);
  switch (process.env.NODE_ENV) {
    case 'production':
      return constVars.AUTH_CLIENT_ID_PRODUCTION;

    case 'internal-prod':
      return constVars.AUTH_CLIENT_ID_INTERNAL_PROD;

    case 'preprod':
      return constVars.AUTH_CLIENT_ID_PREPROD;

    case 'verify':
      return constVars.AUTH_CLIENT_ID_VERIFY;

    case 'test':
      return constVars.AUTH_CLIENT_ID_TEST;

    case 'development':
      return constVars.AUTH_CLIENT_ID_TEST;

    default:
      throw new Error('Invalid environment variable is being used!');
  }*/
  return AUTH_CONFIG.clientId;
}
const hist = createBrowserHistory();
const store = configureStore();

//Google Analytics Analyze
ReactGA.initialize("UA-82196613-1"); // TRACKING_ID

hist.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
  console.log("Location:" + location.pathname);
});

const Auth0ProviderWithHistory = ({ children }) => {
  return (
    <Auth0Provider
      domain={AUTH_CONFIG.domain}
      clientId={getClientId()}
      redirectUri={CALLBACKURL}
      audience={AUTH_CONFIG.audience}
      domain={AUTH_CONFIG.domain}
    >
      {children}
    </Auth0Provider>
  );
};
ReactDOM.render(
  <Auth0ProviderWithHistory>
    <Provider store={store}>
      <IntlProvider
        locale={getLocale()}
        key={getLocale()}
        messages={getMessages(2)}
      >
        <Router history={hist}>
          <Switch>
            <Route path="/app/*" component={Dashboard} />
            <Redirect from="/*" to="/app/dashboard" />
          </Switch>
        </Router>
      </IntlProvider>
    </Provider>
  </Auth0ProviderWithHistory>,
  document.getElementById("root")
);
