import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import AddAlert from "@material-ui/icons/AddAlert";

import { connect } from "react-redux";
import * as actions from "actions/projectActionTypes";
import * as raisers from "actions/projectRaisers";

const style = {};

class ReduxNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Snackbar
          place="tc"
          color={this.props.color}
          icon={AddAlert}
          message={this.props.message}
          open={this.props.fieldIdentifier == true || this.props.open}
          closeNotification={this.props.closeNotification || function () {}}
          close
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default withStyles(style)(
  connect(mapStateToProps, mapDispatchToProps)(ReduxNotification)
);
