import React, { Fragment, useEffect, useRef, useState } from "react";
// react component used to create a calendar with events on it
// dependency plugin for react-big-calendar
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { connect, useSelector } from "react-redux";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { FormattedMessage } from "react-intl";
// core components
import Heading from "components/Heading/Heading.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import fullCalendarStyle from "@fullcalendar/core/main.css";
import fullCalTimelineStyle from "@fullcalendar/timeline/main.css";
import fullCalResourceTimelineStyle from "@fullcalendar/resource-timeline/main.css";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import modalStyle from "../../assets/jss/material-dashboard-pro-react/modalStyle";
import calStyles from "./Calendar.module.css";
import Table from "components/Table/Table";

import {
  getActiveOrg,
  getISODateTime,
  calcDurationDiff,
} from "variables/coreWeb.jsx";

import {
  createException,
  setCalendarClickData,
  deleteException,
  deleteAppointment,
  postApi,
  setCalendarEventClickData,
  createServiceOrder,
} from "variables/thunks";
import {
  getCalendarExceptionEvents,
  getCalendarAppointmentEvents,
  exToEvents,
  apToEvents,
} from "selectors/calendarSelector";

import AppointmentExceptionCreateOptions from "components/CoreWeb/AppointmentExceptionCreateOptions";
import { APPOINTMENTTYPE, EXCEPTIONTYPE } from "../../variables/coreWeb";
import { CardHeader } from "@material-ui/core";
const calIdentifier = "calendar1";

const CoreCalendar = function(props) {
  const calendarRef = useRef(null);
  const [events, setEvents] = useState([
    {
      start: new Date(),
      end: new Date(moment().add(1, "days")),
      title: "Some title",
    },
  ]);

  const [initialLoad, setInitialLoad] = useState(true);
  const [requestedStart, setRequestedStart] = useState(undefined);
  const [requestedEnd, setRequestedEnd] = useState(undefined);
  const [showCal, setShowCal] = useState(true);
  const [startDate, setStartDate] = useState(moment());
  const [startTime, setStartTime] = useState(moment());
  const [endDate, setEndDate] = useState(moment().toISOString());
  const [selected, setSelected] = useState(null);
  const [showCalModal, setShowCalModal] = useState(false);
  const [previewEvents, setPreviewEvents] = useState([]);
  const [retrievedEvents, setRetrievedEvents] = useState([]);
  const [displayEvents, setDisplayEvents] = useState([]);
  const getCalendar = useSelector(
    (state) => state.pwReducer.calendar[calIdentifier] || {}
  );
  const getExceptions = useSelector(
    (state) => state.pwReducer.calendar[calIdentifier].exceptions
  );
  const getAppointments = useSelector(
    (state) => state.pwReducer.calendar[calIdentifier].appointments
  );

  useEffect(
    () => {
      var conCatEvents = exToEvents(getExceptions).concat(
        apToEvents(getAppointments)
      );
      setRetrievedEvents(conCatEvents);
      console.log(JSON.stringify(conCatEvents) + "ÄÄÄÄÄÄÄÄÄÄÄÄÄÄÄ");
    },
    [getExceptions, getAppointments]
  );

  const handleModalOpen = () => {
    setShowCalModal(true);
  };
  const handleModalClose = () => {
    setShowCalModal(false);
  };

  const handleDateClick = (info) => {
    // bind with an arrow function
    alert("clicked " + info.dateStr + " on resource " + info.resource.id);
  };

  const handleEventClick = (info) => {
    console.log(JSON.stringify(info.event.extendedProps));
    console.log(JSON.stringify(info.event.id));
    props.dispatch(
      setCalendarEventClickData({ ...info, calendarId: calIdentifier })
    );
    setShowCal(true);
    setShowCalModal(true);
    setSelected(info);
  };

  const handleSelectClick = (info) => {
    /*alert(
      "selected " +
        info.startStr +
        " to " +
        info.endStr +
        " on resource " +
        info.resource.id
    );*/
    var composed = { ...info, fieldIdentifier: calIdentifier };
    props.dispatch(setCalendarClickData(composed));
    setShowCalModal(true);
    setSelected(info);
    console.log("-------------------------------------");
    //this.setState({ showCal: !this.state.showCal, selected: info });
  };

  const handleViewChange = (info) => {
    if (
      calendarRef &&
      calendarRef.current &&
      (calendarRef.current.calendar.view.currentStart.toISOString() !=
        requestedStart ||
        calendarRef.current.calendar.view.currentEnd.toISOString() !=
          requestedEnd)
    ) {
      pollCalEvents(calendarRef);
    }
  };

  const reloadCalendarView = function(reloadDelay) {
    const timer = setTimeout(() => {
      setShowCal(true);
      setShowCalModal(false);
      setPreviewEvents([]);
      setSelected(null);
      pollCalEvents(calendarRef);
    }, reloadDelay || 10);
  };

  const addNewEventAlert = function(slotInfo) {
    alert(JSON.stringify(slotInfo));
  };

  const selectedEvent = function(eventInfo) {
    alert(JSON.stringify(eventInfo));
  };

  /*const onEventResize = (type, { event, start, end, allDay }) => {
    this.setState(state => {
      state.events[0].start = start;
      state.events[0].end = end;
      return { events: state.events };
    });
  };*/

  const onEventDrop = ({ event, start, end, allDay }) => {
    console.log(start);
  };

  const pollCalEvents = (calRef) => {
    if (calRef.current) {
      var reqStart = calRef.current.calendar.view.currentStart.toISOString();
      var reqEnd = calRef.current.calendar.view.currentEnd.toISOString();
      setInitialLoad(false);
      setRequestedStart(reqStart);
      setRequestedEnd(reqEnd);
      //this.setState({initialLoad: false, requestedStart:reqStart, requestedEnd: reqEnd })
      var data = {
        resourceIdList: [],
        orgId: getActiveOrg(),
        start: calendarRef.current.calendar.view.currentStart.toISOString(),
        end: calendarRef.current.calendar.view.currentEnd.toISOString(),
      };

      props
        .dispatch(
          postApi(
            localStorage.getItem("access_token"),
            "ReadCalendarView",
            data
          )
        )
        .then((data) => {
          props.dispatch({
            type: actions.cw.GOT_CALENDAR_VIEW,
            payload: { ...data, fieldIdentifier: calIdentifier },
          });
        });
    }
  };

  const cancelClick = () => {
    //setShowCal(!showCal);
    setSelected(null);
    setShowCalModal(false);
    setPreviewEvents([]);
    //this.setState({ showCal: !this.state.showCal, selected: null });
    props.dispatch({
      type: actions.cw.SET_VAR,
      payload: {
        fieldIdentifier: "calendar.appointmentExceptionType",
        value: null,
      },
    });
  };

  const openSidePanel = function(type) {
    props.dispatch({
      type: actions.cw.SET_VAR,
      payload: {
        fieldIdentifier:
          "calendar." + calIdentifier + ".appointmentExceptionType",
        value: type,
      },
    });

    setShowCalModal(true);
  };

  const previewEventsChanged = function(events) {
    console.log("got preview events" + JSON.stringify(events));
    setPreviewEvents(events);
  };

  console.log("RESOURCES", props.resourceCompleteList);

  return (
    <div className="sl-pd-22">
      {/*<Heading
          textAlign="center"
          title="Your Clinic Calendar"
          category={
            <span>
              An overview of your clinic
            </span>
          }
        />*/}
      {calendarRef && calendarRef.current && initialLoad
        ? pollCalEvents(calendarRef)
        : ""}
      <GridContainer justify="center">
        <CoreApiReq
          endpointToCall={"Read_All_Organisation_Contacts"}
          afterActionHandler={actions.cw.GOT_ALL_ORG_CONTACTS_BY_ORG_ID}
          data={{
            orgId: getActiveOrg(),
          }}
        />
        <GridItem xs={12} sm={12} md={12} className={calStyles.actionBtnRow}>
          <Button
            className={`btn-theme ${calStyles.addActionBtn}`}
            onClick={() => {
              openSidePanel(APPOINTMENTTYPE);
            }}
          >
            + Add Appointment
          </Button>
          <Button
            className={`btn-theme ${calStyles.addActionBtn}`}
            onClick={() => {
              openSidePanel(EXCEPTIONTYPE);
            }}
          >
            + Add Exception
          </Button>
        </GridItem>
        {showCal ? (
          <Fragment>
            <GridItem xs={12} sm={12} md={3}>
              <Card className="calendar-resources">
                <Heading
                  textAlign="center"
                  title="Available Resources"
                  id="heading"
                />
                <CardBody className="card-body">
                  <table>
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Resource Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.resourceCompleteList.map((prop, key) => {
                        return (
                          <tr>
                            <td colSpan="2">
                              <div className="resource-detail">
                                <h4 className="resource-name">
                                  {prop.resourceName}
                                </h4>
                                <span className="resource-type">
                                  {prop.userOrResourceString}
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={9}>
              <Card className="calendar-card">
                <CardBody>
                  {/*<h4>{JSON.stringify(this.props.resourceCompleteList)}</h4>*/}
                  <FullCalendar
                    customButtons={{
                      dateBtn: {
                        theme: "true",
                        text: moment().format("dddd, MMMM Do, YYYY"),
                        //   click: function() {
                        //     alert("clicked the custom button!");
                        //   },
                      },
                    }}
                    header={{
                      left: "dateBtn",
                      center: "prev title next",
                      right: "today",
                    }}
                    ref={calendarRef}
                    schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
                    plugins={[
                      dayGridPlugin,
                      resourceTimelinePlugin,
                      interactionPlugin,
                    ]}
                    // defaultView={"resourceTimelineMonth"}
                    height={() => {
                      return Math.max(
                        (document.documentElement.clientHeight,
                        window.innerHeight * 0.7) || 0
                      );
                    }}
                    resourceLabelText={"Available Resources"}
                    resourceColumns={[
                      {
                        group: true,
                        labelText: "Type",
                        field: "userOrResourceString",
                      },
                      {
                        labelText: "Resource Name",
                        field: "resourceShortName",
                      },
                      /*{
                      labelText: 'Occupancy',
                      field: 'occupancy'
                    }*/
                    ]}
                    selectable={true}
                    resources={props.resourceCompleteList}
                    editable={true}
                    select={handleSelectClick}
                    events={(retrievedEvents || []).concat(previewEvents)}
                    datesRender={handleViewChange}
                    eventClick={handleEventClick}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </Fragment>
        ) : (
          <div />
        )}

        {showCalModal ? (
          <Dialog
            open={showCalModal}
            onClose={handleModalClose}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
            className={`cal-modal ${calStyles["cal-modal"]}`}
            maxWidth="xl"
          >
            <DialogTitle id="form-dialog-title">
              {props.pwReducer.calendar[calIdentifier]
                .appointmentExceptionType == APPOINTMENTTYPE
                ? "Create Appointment"
                : "Create Exception"}

              <IconButton
                aria-label="close"
                onClick={handleModalClose}
                style={{
                  position: "absolute",
                  right: -15,
                  top: -15,
                }}
              >
                x
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {/* <GridItem md={12}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={12}> */}
              <AppointmentExceptionCreateOptions
                calendarToUse={props.pwReducer.calendar[calIdentifier] || {}}
                events={props ? props.events : []}
                calendarIdentifier={calIdentifier}
                hideType={true}
                previewEventsUpdated={previewEventsChanged}
                contactList={props.pwReducer.contactList.filter(
                  (x) => x.contactType === 3
                )}
                startDate={startDate}
                startDateChanged={setStartDate}
              />
            </DialogContent>
            <DialogActions
              style={{ display: "flex", justifyContent: "left" }}
              className={calStyles.modalFooter}
            >
              {/* </GridItem>
                  <GridItem> */}
              {props && props.exceptionId ? (
                <Button
                  color="info"
                  className={`${calStyles.btn} ` + calStyles["btn-action"]}
                  onClick={() => {
                    props.dispatch(
                      deleteException(
                        {
                          orgId: getActiveOrg(),
                          exceptionId: props.exceptionId,
                        }
                        //reloadCalendarView
                      )
                    );
                    reloadCalendarView(2000);
                  }}
                >
                  <FormattedMessage id={"delete"} />{" "}
                </Button>
              ) : (
                ""
              )}

              {props &&
              props.pwReducer.calendar[calIdentifier].appointmentId ? (
                <Button
                  color="info"
                  className={`${calStyles.btn} ` + calStyles["btn-action"]}
                  onClick={() => {
                    props.dispatch(
                      deleteAppointment(
                        {
                          orgId: getActiveOrg(),
                          appointmentId:
                            props.pwReducer.calendar[calIdentifier]
                              .appointmentId,
                        }
                        //reloadCalendarView
                      )
                    );
                    reloadCalendarView(2000);
                  }}
                >
                  <FormattedMessage id={"delete"} />{" "}
                </Button>
              ) : (
                ""
              )}
              {/*props && props.exceptionId ? (
                  <Button color="success">
                    <FormattedMessage id={"update"} />{" "}
                  </Button>
                ) : (
                  ""
                )*/}
              {props &&
              !props.appointmentId &&
              !props.exceptionId &&
              props.pwReducer.calendar[calIdentifier]
                .appointmentExceptionType == EXCEPTIONTYPE ? (
                <Button
                  color={"info"}
                  className={`${calStyles.btn} ` + calStyles["btn-action"]}
                  onClick={() => {
                    props.dispatch(
                      createException(
                        {
                          orgId: getActiveOrg(),
                          start: getISODateTime(
                            props.pwReducer.calendar[calIdentifier].exStartDate,
                            props.pwReducer.calendar[calIdentifier].exStartTime
                          ),
                          end: getISODateTime(
                            props.pwReducer.calendar[calIdentifier].exEndDate,
                            props.pwReducer.calendar[calIdentifier].exEndTime
                          ),
                          exceptionTitle: "Exception",
                          repeatRuleOptions: [],
                          resourceIdList:
                            props.pwReducer.calendar[calIdentifier]
                              .selectedExResourceIds,
                          durationMilliseconds: 0,
                          calendarIdList: [],
                          timeZoneIANA: "Europe/Stockholm",
                          creatorId: props.pwReducer.selfProfile.contactId,
                          durationMilliseconds: calcDurationDiff(
                            moment(
                              getISODateTime(
                                props.pwReducer.calendar[calIdentifier]
                                  .exEndDate,
                                props.pwReducer.calendar[calIdentifier]
                                  .exEndTime
                              )
                            ),
                            moment(
                              getISODateTime(
                                props.pwReducer.calendar[calIdentifier]
                                  .exStartDate,
                                props.pwReducer.calendar[calIdentifier]
                                  .exStartTime
                              )
                            )
                          ),
                        }
                        //reloadCalendarView
                      )
                    );
                    reloadCalendarView(2000);
                  }}
                >
                  Create Exception
                </Button>
              ) : (
                ""
              )}

              {props &&
              !props.pwReducer.calendar[calIdentifier].appointmentId &&
              !props.pwReducer.calendar[calIdentifier].exceptionId &&
              props.pwReducer.calendar[calIdentifier]
                .appointmentExceptionType == APPOINTMENTTYPE ? (
                <Button
                  color={"info"}
                  className={`${calStyles.btn} ` + calStyles["btn-action"]}
                  onClick={() => {
                    const serviceOrderDetails = JSON.stringify({
                      orgId: getActiveOrg(),
                      contactId:
                        props.pwReducer.calendar[calIdentifier].contactId,
                      appointmentId: 0,
                      serviceId:
                        props.pwReducer.calendar[calIdentifier].serviceSelect,
                      orderState: 2,
                      start: moment(
                        props.pwReducer.calendar[calIdentifier].exStartTime
                      ).toISOString(),
                      end: moment(
                        props.pwReducer.calendar[calIdentifier].exEndTime
                      ).toISOString(),
                      monetaryAmount: -2,
                      monetaryCurrency: 1,
                      joinQueue: 2,
                      serviceFulfilmentConfigId:
                        props.pwReducer.calendar[calIdentifier]
                          .serviceFulfilmentConfigId,
                      serviceFulfilmentConfigResourceMapIdList: props.pwReducer.serviceDetails.serviceFulfilmentConfigCompleteList
                        .find(
                          (x) =>
                            x.serviceFulfilmentConfigId ==
                            props.pwReducer.calendar[calIdentifier]
                              .serviceFulfilmentConfigId
                        )
                        .serviceFulfilmentConfigResourceMapList.map(
                          (y) => y.serviceFulfilmentConfigResourceMapId
                        ),
                      createdOn_UTC: moment().utc(),
                      latitude: 0.0,
                      longitude: 0.0,
                    });
                    props.dispatch(
                      createServiceOrder(
                        {
                          json: serviceOrderDetails,
                          functionName: "CreateServiceOrder",
                        }
                        //reloadCalendarView
                      )
                    );
                    reloadCalendarView(2000);
                  }}
                >
                  Create Appointment
                </Button>
              ) : (
                ""
              )}

              <Button
                onClick={cancelClick}
                className={`${calStyles.btn} ` + calStyles["btn-cancel"]}
              >
                Cancel
              </Button>

              {/* </GridItem>
                </GridContainer>
              </GridItem> */}
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
      </GridContainer>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  console.log(
    "Resources",
    state.pwReducer.calendar[calIdentifier].resourceCompleteList
  );
  return {
    resourceCompleteList: state.pwReducer.calendar[calIdentifier]
      ? state.pwReducer.calendar[calIdentifier].resourceCompleteList
      : [],
    pwReducer: state.pwReducer,
    selectedExResourceIds: state.pwReducer.calendar[calIdentifier]
      ? state.pwReducer.calendar[calIdentifier].selectedExResourceIds
      : [],
    events: getCalendarExceptionEvents(state, calIdentifier).concat(
      getCalendarAppointmentEvents(state, calIdentifier)
    ),
    exceptionId: state.pwReducer.calendar[calIdentifier].exceptionId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: dispatch,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(buttonStyle)(CoreCalendar));
