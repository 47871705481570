import React from "react";

import { FormattedMessage } from "react-intl";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

import MenuItem from "@material-ui/core/MenuItem";
import ReduxCoreSelect from "components/CoreWeb/ReduxCoreSelect";
import * as actions from "actions/projectActionTypes";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import { connect } from "react-redux";

import { constVars } from "../../variables/constVars";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  ...userProfileStyles,
};

const selectOptions = [
  {
    name: constVars.kilograms,
    value: constVars.kilograms,
  },
  {
    name: constVars.grams,
    value: constVars.grams,
  },
  {
    name: constVars.pound,
    value: constVars.pound,
  },
  {
    name: constVars.ounce,
    value: constVars.ounce,
  },
];

class WeightUnits extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;

    return (
      <div>
        <h4>
          <FormattedMessage id="weightUnits" />
        </h4>
        <ReduxCoreSelect
          options={this.props.genderSelectionOptions}
          fieldIdentifier={this.props.fieldIdentifier || "selectWeightUnit"}
          updateAction={actions.cw.UPREDUXSELECTINPUT}
          selectValue={this.props.selectValue}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    genderSelectionOptions: (function() {
      var rows = [];
      for (var i = 0; i < selectOptions.length; i++) {
        // note: we add a key prop here to allow react to uniquely identify each
        // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
        rows.push(
          <MenuItem value={selectOptions[i].value} key={i}>
            {selectOptions[i].name}
          </MenuItem>
        );
      }
      return rows;
    })(),
    genderSelection:
      state.pwReducer[ownProps.fieldIdentifier || "selectWeightUnit"],
    selectValue: ownProps.selectValue,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(userProfileStyles)(WeightUnits));
