import { FormattedMessage } from "react-intl";
import React from 'react'
const selectOptions = [
  {
    name: <FormattedMessage id="Note" />,
    value: 2,
  },
  {
    name: <FormattedMessage id="procedure" />,
    value: 3,
  },
  {
    name: <FormattedMessage id="behaviour" />,
    value: 4,
  },
  {
    name: <FormattedMessage id="allergy" />,
    value: 5,
  },
  {
    name: <FormattedMessage id="observation" />,
    value: 6,
  },
  {
    name: <FormattedMessage id="dental" />,
    value: 7,
  },
  {
    name: <FormattedMessage id="Laboratory" />,
    value: 8,
  },
  {
    name: <FormattedMessage id="weightMeasurement" />,
    value: 9,
  },
  {
    name: <FormattedMessage id="heightMeasurement" />,
    value: 10,
  },
  {
    name: <FormattedMessage id="medication" />,
    value: 11,
  },
  {
    name: <FormattedMessage id="temperatureMeasurement" />,
    value: 12,
  },
  {
    name: <FormattedMessage id="pulseMeasurementNote" />,
    value: 13,
  },
  {
    name: <FormattedMessage id="respirationMeasurementNote" />,
    value: 14,
  },
  {
    name: <FormattedMessage id="physicalExam" />,
    value: 15,
  },
];
export default selectOptions;
