import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { paths } from "variables/projPaths";

const SearchResult = (props) => {
  const searchItemClickHandler = () => {
    props.onCloseSuggestionBox();
  };

  let patientList, contactList;
  if (props.result.func_status == 1) {
    if (props.result.animalList.length > 0) {
      patientList = (
        <div className="search-list">
          <h4>Patient</h4>
          <ul>
            {props.result.animalList.map((animal, key) => {
              return (
                <li key={key}>
                  <Link
                    to={paths.animalView + `/${animal.animalId}`}
                    onClick={searchItemClickHandler}
                  >
                    {animal.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }

    if (props.result.contactList.length > 0) {
      contactList = (
        <div className="search-list">
          <h4>Contacts</h4>
          <ul>
            {props.result.contactList.map((record, key) => {
              let listType;
              if (record.contactType === 2) {
                listType = "contact";
              } else if (record.contactType === 3) {
                listType = "customers";
              }
              return (
                <li key={key}>
                  <Link
                    to={`/app/${listType}/view/${record.contactId}`}
                    onClick={searchItemClickHandler}
                  >
                    {record.contactFirstName + " " + record.contactLastName}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
  }

  return (
    <div className="search-suggestion-box">
      {patientList}
      {contactList}
    </div>
  );
};

export default SearchResult;
