import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput.jsx";
import * as actions from "actions/projectActionTypes";
import ReduxCoreSelect from "components/CoreWeb/ReduxCoreSelect";
import { getActiveOrg } from "variables/coreWeb.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ContactImage from "components/CoreWeb/ContactImage";
import GridItem from "components/Grid/GridItem.jsx";
import ContactTitleSelect from "components/CoreWeb/ContactTitleSelect";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import AnimalSpeciesSelect from "components/ProjectWeb/AnimalSpeciesSelect.jsx";

const style = {
  ...userProfileStyles,
  ...sweetAlertStyle,
};

const CustomerBasicOptions = function(props) {
  const { classes, contactId, activeFileUrl } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.isCreate === true) {
      dispatch({ type: actions.cw.RESET_CONTACT_DETAILS, payload: {} });
    }
  }, []);
  return (
    <GridContainer
      className={`add-customer-section ${contactId ? "contactid-set" : ""}`}
    >
      <div className="self oni add-new-customer">
        <h3 className="myhead nosp st-t">
          {props.isCreate ? "Add New Customer" : "View Customer Details"}
        </h3>
        <p>
          {props.isCreate
            ? "Fill the following details"
            : "Following are the details"}
        </p>
      </div>

      <GridItem xs={12} md={12}>
        <GridContainer className="">
          {/*<GridItem xs={12} md={4}>*/}

          {/*<ReduxCustomInput */}
          {/*     mainTextLabel={<FormattedMessage id="businessName"></FormattedMessage>}*/}
          {/*     updateAction={actions.cw.UPREDUXCUSTOMINPUT}*/}
          {/*     fieldIdentifier={"contactDetails.orgName"}*/}
          {/*     multiLine={1}*/}
          {/*     disabled={this.props.disabled}*/}
          {/*   />*/}

          {/*</GridItem>*/}

          {contactId ? (
            <GridItem xs={12} md={4}>
              <div className="file-upload-div">
                <ContactImage
                  contactId={contactId}
                  style={{ float: "left" }}
                  orgId={getActiveOrg()}
                  imagePreviewUrl={activeFileUrl}
                  dispatch={props.dispatch}
                />
              </div>
            </GridItem>
          ) : (
            ""
          )}

          <GridItem xs={12} md={props.isCreate ? 12 : 8}>
            <GridContainer>
              {!props.isCreate && (
                <GridItem xs={12} md={6} className="input-box disable">
                  <label className="lbl-fld">Contact Id</label>
                  <ReduxCustomInput
                    //mainTextLabel={<FormattedMessage id="contactId" />}
                    // updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                    //fieldIdentifier={"contactDetails.contactId"}
                    multiLine={1}
                    disabled={true}
                    textValue={props.contactId}
                  />
                </GridItem>
              )}

              {!props.isCreate && (
                <GridItem xs={12} md={6} className="input-box">
                  <label className="lbl-fld">
                    {<FormattedMessage id="emailAddress" />}
                  </label>
                  <ReduxCustomInput
                    //mainTextLabel={<FormattedMessage id="emailAddress" />}
                    updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                    fieldIdentifier={"contactDetails.emailAddress"}
                    multiLine={1}
                    disabled={props.disabled}
                  />
                </GridItem>
              )}

              <GridItem xs={12} md={2} className="select-box">
                <label className="lbl-fld">Title</label>
                <ContactTitleSelect
                  fieldIdentifier={"contactDetails.contactTitle"}
                />
              </GridItem>
              <GridItem xs={12} md={5} className="input-box">
                <label className="lbl-fld">
                  {<FormattedMessage id="firstName" />}
                </label>
                <ReduxCustomInput
                  //mainTextLabel={<FormattedMessage id="firstName" />}
                  updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                  fieldIdentifier={"contactDetails.contactFirstName"}
                  multiLine={1}
                  disabled={props.disabled}
                />
              </GridItem>
              <GridItem xs={12} md={5} className="input-box">
                <label className="lbl-fld">
                  {<FormattedMessage id="lastName" />}
                </label>
                <ReduxCustomInput
                  //mainTextLabel={<FormattedMessage id="lastName" />}
                  updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                  fieldIdentifier={"contactDetails.contactLastName"}
                  multiLine={1}
                  disabled={props.disabled}
                />
              </GridItem>

              {props.isCreate && (
                <GridItem xs={12} md={6} className="input-box">
                  <label className="lbl-fld">
                    {<FormattedMessage id="emailAddress" />}
                  </label>
                  <ReduxCustomInput
                    //mainTextLabel={<FormattedMessage id="emailAddress" />}
                    updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                    fieldIdentifier={"contactDetails.emailAddress"}
                    multiLine={1}
                    disabled={props.disabled}
                  />
                </GridItem>
              )}

              {/*<GridItem xs={12} md={4}>*/}

              {/*</GridItem>*/}
              {/*<GridItem xs={12} md={4}>*/}

              {/*</GridItem>*/}
              {/*<GridItem xs={12} md={4}>*/}

              {/*</GridItem>*/}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

export default withStyles(style)(CustomerBasicOptions);
