import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink, Link } from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import ContactImage from "components/CoreWeb/ContactImage";
import { getActiveOrg } from "variables/coreWeb.jsx";
import { paths } from "variables/projPaths.jsx";
import CloseIcon from "@material-ui/icons/Close";
// core components
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";

import avatar from "assets/img/faces/avatar.jpg";

import {
  apiGetDashboard,
  apiGetSelfProfile,
  getToken,
} from "../../variables/newApi";

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, user, headerLinks, links } = this.props;
    return (
      <div className={className} ref="sidebarWrapper">
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}

function Sidebar(props) {
  /*constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openComponents: this.activeRoute("/components"),
      openForms: this.activeRoute("/forms"),
      openTables: this.activeRoute("/tables"),
      openMaps: this.activeRoute("/maps"),
      openPages: this.activeRoute("-page"),
      miniActive: true
    };
    this.activeRoute.bind(this);
  }*/
  const [miniActive, setMiniActive] = useState(true);
  const mainPanel = useRef("mainPanel");
  // verifies if routeName is the one active (in browser input)
  const activeRoute = function(routeName) {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  };
  const openCollapse = function(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  };

  const {
    classes,
    color,
    logo,
    image,
    logoText,
    routes,
    bgColor,
    rtlActive,
    active,
    handleToggle,
  } = props;
  const itemText =
    classes.itemText +
    " " +
    cx({
      [classes.itemTextMini]: props.miniActive && this.state.miniActive,
      [classes.itemTextMiniRTL]:
        rtlActive && props.miniActive && this.state.miniActive,
      [classes.itemTextRTL]: rtlActive,
    });
  const collapseItemText =
    classes.collapseItemText +
    " " +
    cx({
      [classes.collapseItemTextMini]: props.miniActive && this.state.miniActive,
      [classes.collapseItemTextMiniRTL]:
        rtlActive && props.miniActive && this.state.miniActive,
      [classes.collapseItemTextRTL]: rtlActive,
    });
  const userWrapperClass =
    classes.user +
    " " +
    cx({
      [classes.whiteAfter]: bgColor === "white",
    });
  const caret =
    classes.caret +
    " " +
    cx({
      [classes.caretRTL]: rtlActive,
    });
  const collapseItemMini =
    classes.collapseItemMini +
    " " +
    cx({
      [classes.collapseItemMiniRTL]: rtlActive,
    });
  const photo =
    classes.photo +
    " " +
    cx({
      [classes.photoRTL]: rtlActive,
    });

  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.redirect || prop.hideFromSideBar) {
          return null;
        }
        if (prop.collapse) {
          const navLinkClasses =
            classes.itemLink +
            " " +
            cx({
              [" " + classes.collapseActive]: activeRoute(prop.path),
            });
          const itemText =
            classes.itemText +
            " " +
            cx({
              [classes.itemTextMini]: props.miniActive && this.state.miniActive,
              [classes.itemTextMiniRTL]:
                rtlActive && props.miniActive && this.state.miniActive,
              [classes.itemTextRTL]: rtlActive,
            });
          const collapseItemText =
            classes.collapseItemText +
            " " +
            cx({
              [classes.collapseItemTextMini]:
                props.miniActive && this.state.miniActive,
              [classes.collapseItemTextMiniRTL]:
                rtlActive && props.miniActive && this.state.miniActive,
              [classes.collapseItemTextRTL]: rtlActive,
            });
          const itemIcon =
            classes.itemIcon +
            " " +
            cx({
              [classes.itemIconRTL]: rtlActive,
            });
          const caret =
            classes.caret +
            " " +
            cx({
              [classes.caretRTL]: rtlActive,
            });
          return (
            <ListItem key={key} className={classes.item}>
              <NavLink
                to={"#"}
                className={navLinkClasses}
                onClick={() => this.openCollapse(prop.state)}
              >
                <ListItemIcon className={itemIcon}>
                  {typeof prop.icon === "string" ? (
                    <Icon>
                      <Icon>{prop.icon}</Icon>
                    </Icon>
                  ) : (
                    <prop.icon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={prop.name}
                  secondary={
                    <b
                      className={
                        caret +
                        " " +
                        (this.state[prop.state] ? classes.caretActive : "")
                      }
                    />
                  }
                  disableTypography={true}
                  className={itemText}
                />
              </NavLink>
              <Collapse in={this.state[prop.state]} unmountOnExit>
                <List className={classes.list + " " + classes.collapseList}>
                  {prop.views.map((prop, key) => {
                    if (prop.redirect || prop.hideFromSideBar) {
                      return null;
                    }
                    const navLinkClasses =
                      classes.collapseItemLink +
                      " " +
                      cx({
                        [" " + classes[color]]: activeRoute(prop.path),
                      });
                    const collapseItemMini =
                      classes.collapseItemMini +
                      " " +
                      cx({
                        [classes.collapseItemMiniRTL]: rtlActive,
                      });
                    return (
                      <ListItem key={key} className={classes.collapseItem}>
                        <NavLink to={prop.path} className={navLinkClasses}>
                          <span className={collapseItemMini}>
                            {typeof prop.icon === "string" ? (
                              <Icon>{prop.icon}</Icon>
                            ) : (
                              <prop.icon />
                            )}
                          </span>

                          <ListItemText
                            primary={prop.name}
                            disableTypography={true}
                            className={collapseItemText}
                          />
                        </NavLink>
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </ListItem>
          );
        }
        const navLinkClasses =
          classes.itemLink +
          " " +
          cx({
            [" " + classes[color]]: activeRoute(prop.path),
          });
        const itemText =
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]: props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive,
          });
        const itemIcon =
          classes.itemIcon +
          " " +
          cx({
            [classes.itemIconRTL]: rtlActive,
          });
        return (
          <ListItem key={key} className={classes.item}>
            <NavLink to={prop.path} className={navLinkClasses}>
              <ListItemIcon className={itemIcon}>
                {typeof prop.icon === "string" ? (
                  <img src={prop.icon} />
                ) : (
                  <prop.icon />
                )}
              </ListItemIcon>
              <ListItemText
                primary={prop.name}
                disableTypography={true}
                className={itemText}
              />
            </NavLink>
          </ListItem>
        );
      })}
    </List>
  );

  const logoNormal =
    classes.logoNormal +
    " " +
    cx({
      [classes.logoNormalSidebarMini]:
        props.miniActive && this.state.miniActive,
      [classes.logoNormalSidebarMiniRTL]:
        rtlActive && props.miniActive && this.state.miniActive,
      [classes.logoNormalRTL]: rtlActive,
    });
  const logoMini =
    classes.logoMini +
    " " +
    cx({
      [classes.logoMiniRTL]: rtlActive,
    });
  const logoClasses =
    classes.logo +
    " " +
    cx({
      [classes.whiteAfter]: bgColor === "white",
    });
  var brand = (
    <div className={logoClasses + " logo-container"}>
      <Link to="/app/dashboard" className={logoMini + " site-logo"}>
        <img src={logo} alt="logo" className={classes.img} />
      </Link>
      <Link to="/app/dashboard" className={logoNormal + " logo-text"}>
        {logoText}
      </Link>
    </div>
  );
  const drawerPaper =
    classes.drawerPaper +
    " " +
    cx({
      [classes.drawerPaperMini]: props.miniActive && this.state.miniActive,
      [classes.drawerPaperRTL]: rtlActive,
    });
  const sidebarWrapper =
    classes.sidebarWrapper +
    " " +
    cx({
      [classes.drawerPaperMini]: props.miniActive && this.state.miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  const { varset, varf } = props;
  return (
    <div ref={mainPanel}>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: drawerPaper + " " + classes[bgColor + "Background"],
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}

          <SidebarWrapper className={sidebarWrapper} links={links} />
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden
        smDown
        implementation="css"
        className={active ? "side-bar-open" : "sidsebar-set"}
      >
        <div className="close" onClick={handleToggle}>
          <CloseIcon />
        </div>
        <Drawer
          onMouseOver={() => setMiniActive(false)}
          onMouseOut={() => setMiniActive(true)}
          anchor={rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: drawerPaper + " " + classes[bgColor + "Background"],
          }}
        >
          {brand}
          {/* {getToken() &&
          getToken().length > 0 &&
          props.location.pathname !== paths.orgSignIn &&
          props.location.pathname !== paths.createOrganisation ? (
            <ContactImage
              contactId={11}
              orgId={getActiveOrg()}
              imagePreviewUrl={"http://uploads.eleetz.com/1036.png"}
            />
          ) : (
            ""
          )} */}
          <SidebarWrapper className={sidebarWrapper} links={links} />
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

export default withStyles(sidebarStyle)(Sidebar);
