import React from "react";
import { FormattedMessage } from "react-intl";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import { connect } from "react-redux";
import ReduxCardCustomInput from "components/CoreWeb/ReduxCardCustomInput";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import AddressViewOptions from "./AddressViewOptions";
import { createAddressWithDispatch } from "variables/thunks";
import Button from "components/CustomButtons/Button.jsx";
import * as raisers from "actions/projectRaisers";
import { deleteContactAddressWithDispatch } from "../../variables/thunks";
import * as makeApiCall from "actions/makeApiCall";
import { bindActionCreators } from "redux";
import * as actions from "actions/projectActionTypes";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";

import { getActiveOrg } from "variables/coreWeb.jsx";
class AddressView extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        {this.props.match && this.props.match.params.contactId != undefined ? (
          <CoreApiReq
            endpointToCall={"ReadContactCompleteByContactID"}
            afterActionHandler={actions.cw.GOT_CONTACT_DETAILS}
            data={{
              contactId: this.props.match.params.contactId,
              orgId: getActiveOrg(),
            }}
          />
        ) : (
          ""
        )}
        <GridContainer justify="left">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <AddressViewOptions />
          </GridItem>
          <GridItem className="add-address-btn">
            <Button
              onClick={() => {
                this.props.dispatch(
                  createAddressWithDispatch(this.props.callApi, this.props)
                );
              }}
              color="success"
            >
              <FormattedMessage id="createAddress" />
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    addressDetails: state.pwReducer.addressDetails || [],
    contactDetails: state.pwReducer.contactDetails || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    dispatch: dispatch,
    raiseAction: (payload) => {
      dispatch(raisers.createActionGeneric(payload, actions.cw.SET_STATE_VAL));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(userProfileStyles)(AddressView));
