import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import StarRatingComponent from "react-star-rating-component";
import Visibility from "@material-ui/icons/Visibility";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import CardAvatar from "components/Card/CardAvatar.jsx";
import FormHelperText from "@material-ui/core/FormHelperText";

import Check from "@material-ui/icons/Check";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import { dataTable5, statusFromType } from "variables/general.jsx";
import { labelFromType } from "variables/project.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import { connect } from "react-redux";

import {
  getObjProp,
  defaultChangeReduxCoreSelect,
} from "variables/coreWeb.jsx";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  ...userProfileStyles,
};

class ReduxCoreSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      classes,
      formControlProps,
      labelText,
      id,
      labelProps,
      inputProps,
      error,
      white,
      inputRootCustomClasses,
      success,
      helpText,
      variant,
    } = this.props;
    const labelClasses = classNames({
      [" " + classes.labelRootError]: error,
      [" " + classes.labelRootSuccess]: success && !error,
    });

    console.log("Props", this.props);

    return (
      <FormControl
        fullWidth
        className={classes.selectFormControl}
        disabled={this.props.disabled}
        variant={variant}
      >
        {this.props.labelText !== undefined ? (
          <div style={{ position: "absolute", top: "0px" }}>
            <InputLabel
              style={{
                fontSize: "14px",
                fontWeight: 300,
                color: "rgba(0, 0, 0, 0.54)",
              }}
              className={classes.selectCustomCreate + " " + labelClasses}
              {...this.props.labelProps}
            >
              {this.props.labelText}
            </InputLabel>
          </div>
        ) : null}
        {this.props.placeholder && (
          <FormHelperText>{this.props.placeholder}</FormHelperText>
        )}
        <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          inputProps={{
            name: "simpleSelect",
            id: "simple-select",
            onChange: (event) =>
              this.props.changeEvent(
                this.props.isArrayVal
                  ? [event.target.value]
                  : event.target.value
              ),
            value: this.props.selectValue || this.props.customSelectedValue,
            disabled: this.props.disabled,
            "aria-label": "Without label",
            ...this.props.inputProps,
          }}
          disabled={this.props.disabled}
          style={{
            height: "40px",
            padding: "5px 10px",
          }}
        >
          {this.props.options}
        </Select>
      </FormControl>
    );
  }
}

ReduxCoreSelect.defaultProps = {};

const mapStateToProps = (state, ownProps) => {
  var valsToChoseFrom = getObjProp(state.pwReducer, ownProps.fieldIdentifier);
  var foundValue =
    ownProps.selectValue == 0
      ? 0
      : ownProps.selectValue || ownProps.isArrayVal
        ? valsToChoseFrom && valsToChoseFrom.length > 0
          ? valsToChoseFrom[0]
          : undefined
        : getObjProp(state.pwReducer, ownProps.fieldIdentifier);
  return {
    selectValue: foundValue,
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    changeEvent: (payload) => {
      defaultChangeReduxCoreSelect(dispatch, ownProps, payload);
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ReduxCoreSelect));
