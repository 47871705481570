import React, { useState, useEffect, useRef } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import StarRatingComponent from "react-star-rating-component";
import Visibility from "@material-ui/icons/Visibility";
import VpnKey from "@material-ui/icons/VpnKey";
import Clear from "@material-ui/icons/Clear";
import AddAlert from "@material-ui/icons/AddAlert";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import SweetAlert from "react-bootstrap-sweetalert";
import { dataTable4 } from "variables/general.jsx";
import { labelFromType } from "variables/project.jsx";
import { IntlProvider, FormattedMessage } from "react-intl";
import vieweye from "assets/img/view.svg";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { connect } from "react-redux";
import * as raisers from "actions/projectRaisers";
import { bindActionCreators } from "redux";
import * as makeApiCall from "actions/makeApiCall";

import { AUTH_CONFIG } from "Auth/auth0-variables";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { createNewResource } from "api/index";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import {
  changeUrl,
  viewButton,
  tableButton,
  getActiveOrg,
  deleteButton,
} from "variables/coreWeb.jsx";
import { paths } from "variables/projPaths.jsx";
import { useHistory } from "react-router-dom";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  ...userProfileStyles,
  ...sweetAlertStyle,
};

const deleteStaff = function(callMe, staffId) {
  var payLoad = {};
  payLoad.orgId = getActiveOrg();
  payLoad.staffId = staffId;

  callMe.req(
    localStorage.getItem("access_token"),
    "DeleteFunction",
    payLoad,
    actions.cw.API_ANIMAL_DELETE
  );
};

const decideButtons = function(prop, classes, propsData) {
  console.log(prop);
  let viewButtonValue = null;
  let orgId = getActiveOrg(function() {});
  if (orgId !== null && orgId !== undefined && orgId == prop.orgId) {
    viewButtonValue = viewButton(function() {
      changeUrl(paths.orgView + "/" + prop.orgId);
    }, classes);
  }
  var spreadMe = [];
  if (!viewButtonValue) {
    spreadMe.push(
      deleteButton(
        function(props) {
          propsData.raiseAction({
            fieldIdentifier: "selectedStaffId",
            value: prop.orgId,
          });
        },
        classes,
        "danger",
        DeleteOutlineIcon,
        [prop]
      )
    );
    spreadMe.push(
      tableButton(
        function(props) {
          window.localStorage.setItem("currentOrg", props[0].orgId);
          window.localStorage.setItem("currentOrgName", props[0].orgName);
          if (!props[1]) {
            changeUrl(paths.dashboard);
          }
        },
        classes,
        "success",
        VpnKey,
        [prop]
      )
    );
  } else {
    spreadMe.push(viewButtonValue);
  }
  return spreadMe;
};

const OrganisationList = function(props) {
  const { classes } = props;
  const [shouldShowDialog, setShouldShowDialog] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (!getActiveOrg(() => {}) || getActiveOrg(() => {}) <= 0) {
      //setShouldShowDialog(true);
    }
  }, []);

  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
    isLoading,
  } = useAuth0();

  const [orgList, setOrgList] = useState([]);

  const [token, setToken] = useState(null);

  const [infoMessage, setInfoMessage] = useState("");

  const getOrgsForUser = async () => {
    const data = {};
    try {
      const response = await createNewResource(
        token,
        "Read_All_User_Organisations",
        data
      );
      setOrgList(response.data.completeOrgList);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(
    () => {
      const fn = async () => {
        setToken(
          await getAccessTokenSilently({ audience: AUTH_CONFIG.audience })
        );
      };
      fn();
    },
    [!isLoading && isAuthenticated]
  );

  useEffect(
    () => {
      console.log("TOKEN", token);
      if (token != null) {
        getOrgsForUser();
      }
    },
    [!isLoading && isAuthenticated && token]
  );

  useEffect(
    () => {
      const msgDisplay = setTimeout(() => {
        console.log("ORGLIST", orgList.length);
        if (orgList.length === 0) {
          setInfoMessage(
            "You need to create at least one clinic to access other sections."
          );
        } else if (orgList.length > 0 && getActiveOrg() <= 0) {
          setInfoMessage(
            "Please sign in to one of the clinics by clicking on green key button."
          );
        } else {
          setInfoMessage("");
        }
      }, 1000);
      return () => clearTimeout(msgDisplay);

      console.log(VpnKey);
    },
    [orgList]
  );

  return (
    <GridContainer className="sl-pd-22 table-container">
      {shouldShowDialog ? (
        <SweetAlert
          style={{ display: "block", marginTop: "-200px" }}
          onConfirm={() => {
            setShouldShowDialog(false);
          }}
          confirmBtnCssClass={
            props.classes.button + " " + props.classes.success
          }
          confirmBtnText={<FormattedMessage id={"ok"} />}
        >
          {<FormattedMessage id={"noActiveOrg"} />}
        </SweetAlert>
      ) : (
        ""
      )}
      <GridItem xs={12} className="onr">
        {infoMessage && (
          <SnackbarContent
            message={infoMessage}
            color="info"
            id="info_alert_msg"
          />
        )}
        <Button
          className="createNewOrg-btn btn-theme"
          color="rose"
          onClick={function() {
            history.push(paths.createOrganisation);
          }}
        >
          <FormattedMessage id="createNewOrg" />
        </Button>
        <Card>
          <CardHeader>
            <h3 className="myhead nosp">
              <FormattedMessage id="yourOrganizations" />
            </h3>
            <p className="text-black">
              {" "}
              <FormattedMessage id="yourOrgPartOf" />
            </p>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={(orgList || []).map((prop, key) => {
                return {
                  id: prop.orgId,
                  name: prop.orgName,
                  actions: decideButtons(prop, classes, props),
                };
              })}
              filterable={false}
              columns={[
                {
                  Header: "Clinic Id",
                  accessor: "id",
                },
                {
                  Header: "Clinic Name",
                  accessor: "name",
                },

                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  Cell: (content) => (
                    <div className="text-left custome-button">
                      {content.value}
                    </div>
                  ),
                },
              ]}
              defaultPageSize={10}
              showPaginationTop={false}
              showPaginationBottom={true}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
      {props.selectedStaffId ? (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title={<FormattedMessage id="areYouSure" />}
          onConfirm={() => {
            props.raiseAction({
              fieldIdentifier: "selectedStaffId",
              value: undefined,
            }) || deleteStaff(props.callApi, props.selectedStaffId);
          }}
          onCancel={() => {
            props.raiseAction({
              fieldIdentifier: "selectedStaffId",
              value: undefined,
            });
          }}
          confirmBtnCssClass={
            props.classes.button + " " + props.classes.success
          }
          cancelBtnCssClass={props.classes.button + " " + props.classes.danger}
          confirmBtnText={<FormattedMessage id="deleteIt" />}
          cancelBtnText={<FormattedMessage id="cancel" />}
          showCancel
        >
          <FormattedMessage id="deletingClinicsPermanent" />
        </SweetAlert>
      ) : (
        ""
      )}
    </GridContainer>
  );
};

function mapStateToProps(state) {
  return {
    orgList: state.pwReducer.orgList,
    selectedStaffId: state.pwReducer.selectedStaffId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: dispatch,
    callApi: bindActionCreators(makeApiCall, dispatch),
    raiseAction: (payload) => {
      dispatch(raisers.createActionGeneric(payload, actions.cw.SET_STATE_VAL));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OrganisationList));
