import * as actions from "actions/projectActionTypes";
import axios from "axios";
import { APIURL } from "variables/project";
import genericHttpHandler from "actions/genericHttpHandler";
import genericRespHandler from "actions/genericRespHandler";

export function makeApiCallHandler(json, action) {
  return { type: action, payload: json };
}

export function req(
  apiKey,
  endpointUrl,
  data,
  afterActionHandler,
  afterActionThunk
) {
  console.log("req - makeApiCall.js");
  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };

  return (dispatch) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ` + apiKey;
    axios
      .post(APIURL + endpointUrl, data, config)
      .then((res) => {
        return res.data;
      })
      .then((json) => {
        genericRespHandler(json, dispatch);
        if (afterActionHandler) {
          dispatch(makeApiCallHandler(json, afterActionHandler));
        }
        if (afterActionThunk) {
          dispatch(afterActionThunk);
        }
      })
      .catch((error) => {
        if (error.response) {
          genericHttpHandler(error.response);
        }
      });
  };
}
