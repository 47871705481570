import React from "react";
import { FormattedMessage } from "react-intl";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CreateServiceStep1 from "./CreateServiceStep1.jsx";
import CreateServiceStep2 from "./CreateServiceStep2.jsx";
import CreateServiceStep4 from "./CreateServiceStep4.jsx";
import ComingSoonStep from "./ComingSoonStep.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
//icon import
import Option from "@material-ui/icons/MoreHoriz";
import Price from "@material-ui/icons/LocalOffer";
import LocationOn from "@material-ui/icons/LocationOn";
import PrePaymentRequiredSelect from "components/CoreWeb/PrePaymentRequiredSelect";
import final from "@material-ui/icons/VerifiedUser";
import MandatoryStaffSelect from "components/CoreWeb/MandatoryStaffSelect";
import OptionalStaffSelect from "components/CoreWeb/OptionalStaffSelect";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Email from "@material-ui/icons/Email";

import ShoppingCart from "@material-ui/icons/ShoppingCart";
import SelectIsActive from "components/CoreWeb/SelectIsActive";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// core components
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import * as makeApiCall from "actions/makeApiCall";
import Button from "components/CustomButtons/Button.jsx";
import * as actions from "actions/projectActionTypes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getActiveOrg } from "variables/coreWeb.jsx";
import { changeUrl, gridFix, viewButton } from "variables/coreWeb.jsx";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import ReactTable from "react-table";
import { updateService } from "variables/coreRequests.jsx";
import { createServices } from "../../variables/coreCreateRequests.jsx";
import { updateServiceWithDispatch } from "../../variables/thunks.jsx";
import { paths } from "variables/projPaths.jsx";
import ServicePictureUpload from "../CustomUpload/ServicePictureUpload.jsx";
import { uploadFile } from "../../variables/coreRequests.jsx";
import { getMostRecentFileFromList } from "variables/coreWeb.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import assignment from "./img/SupervisorAccount.svg";
import euroSymbol from "./img/Visibility.svg";
import deleicon from "assets/img/delete.svg";
import { Link } from "react-router-dom";
import vieweye from "assets/img/view.svg";
import { deleteBookingWithDispatch } from "../../variables/thunks";
import * as raisers from "actions/projectRaisers";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import AddAlert from "@material-ui/icons/AddAlert";

const style = {
  ...userProfileStyles,
  ...sweetAlertStyle,
  gridFix: { ...gridFix },
};

class ServiceView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileSelected: null,
    };
  }
  fillButtons = function(theProps, serviceFulfilmentConfigId, props) {
    return (
      <div>
        <Link className="cur">
          <button
            onClick={(prop) => {
              changeUrl(
                paths.pricingView +
                  "/" +
                  props.orgId +
                  "/" +
                  props.serviceId +
                  "/" +
                  props.serviceFulfilmentConfigId
              );
            }}
            className="sm selfview"
          >
            <img src={vieweye} />
          </button>
        </Link>
        <button
          color="danger"
          size="sm"
          onClick={() => {
            theProps.raiseAction({
              fieldIdentifier: "selectedBookingId",
              value: serviceFulfilmentConfigId,
            });
          }}
          className="sm danger cur"
        >
          <img src={deleicon} />
        </button>
      </div>
    );
  };

  tabContent = function(classes) {
    var resp = [];
    /*resp.push({
      tabButton:<FormattedMessage id="options"></FormattedMessage>,
      tabIcon: ShoppingCart,
      tabContent: (
        <div>
          {this.props.match &&
          this.props.match.params.serviceId != undefined ? (
            <div>
              {/*<h4>Service Image</h4>
              <Card>
                <CardBody>
                <ServicePictureUpload
                  imagePreviewUrl={this.props.imagePreviewUrl}
                  handleImageChange={file => {
                    this.setState({ fileSelected: file });
                  }}
            />
            {this.state.fileSelected ? (
              <GridContainer justify="center">
                <GridItem>
                <Button
                onClick={() => {
                  uploadFile(
                    [this.state.fileSelected],
                    [getActiveOrg(),this.props.serviceDetails.serviceId],
                    "UploadServiceImage"
                  );
                }}
                color="rose"
              >
                Upload
              </Button>
                </GridItem>
              </GridContainer>
              
            ) : (
              ""
            )}
                </CardBody>
            </Card>}
            </div>
          ) : (
            ""
          )}
          
        </div>
      )
    });*/
    if (this.props.match && this.props.match.params.serviceId != undefined) {
      resp.push({
        tabButton: <FormattedMessage id="pricing" />,
        tabIcon: assignment,

        tabContent: (
          <div className="">
            <GridContainer className={classes.gridFix + " table-container"}>
              <GridItem>
                <Button
                  onClick={() => {
                    this.props.history.push(
                      paths.pricingCreate +
                        "/" +
                        this.props.match.params.serviceId
                    );
                  }}
                  color="rose"
                  className="btn-theme"
                >
                  <FormattedMessage id="addNewPricing"> </FormattedMessage>
                </Button>
              </GridItem>
            </GridContainer>
            <h4>
              {" "}
              <FormattedMessage id="pricingOptions"> </FormattedMessage>
            </h4>
            <Card>
              <CardBody>
                <ReactTable
                  data={(
                    this.props.serviceDetails
                      .serviceFulfilmentConfigCompleteList || []
                  ).map((prop, key) => {
                    console.log("prop=============", prop);
                    return {
                      monetaryAmount: prop.monetaryAmount,
                      name: prop.name,
                      isActive: prop.isActive === 2 ? "Active" : "Inactive",
                      view: this.fillButtons(
                        this.props,
                        prop.serviceFulfilmentConfigId,
                        prop
                      ),
                    };
                  })}
                  filterable
                  columns={[
                    {
                      Header: <FormattedMessage id="pricingDescription" />,
                      accessor: "name",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: <FormattedMessage id="isActive" />,
                      accessor: "isActive",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Price",
                      accessor: "monetaryAmount",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: <FormattedMessage id="actions" />,
                      accessor: "view",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop={false}
                  showPaginationBottom={true}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </div>
        ),
      });
      resp.push({
        tabButton: "Location",
        tabIcon: euroSymbol,
        tabContent: (
          <Card>
            <CardBody>
              <ComingSoonStep />
            </CardBody>
          </Card>
        ),
      });
    }
    return resp;
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="sl-pd-22 service-view">
        {this.props.match && this.props.match.params.serviceId != undefined ? (
          <CoreApiReq
            endpointToCall={"Read_Service"}
            afterActionHandler={actions.cw.GOT_SERVICE_DETAILS}
            data={{
              orgId: getActiveOrg(),
              serviceId: this.props.match.params.serviceId,
            }}
          />
        ) : (
          ""
        )}
        <GridContainer className="border-none pdlr-20">
          <Card>
            <GridItem md={12}>
              <CardHeader className="in2 card-heading">
                <GridContainer>
                  <GridItem md={4}>
                    <div className="self">
                      <h3 className="myhead nosp set-o">
                        {this.props.match &&
                        this.props.match.params.serviceId ? (
                          <FormattedMessage id="serviceDetails" />
                        ) : (
                          "Add New Service"
                        )}
                      </h3>
                      <p>
                        {this.props.match && this.props.match.params.serviceId
                          ? "Following are the details"
                          : "Fill the following details"}
                      </p>
                    </div>
                  </GridItem>
                  {this.props.serviceDetails.orgId <= 0 ? (
                    <GridItem md={8}>
                      <SnackbarContent
                        message={
                          "This is a common service created to use for all clinics."
                        }
                        color="info"
                      />
                    </GridItem>
                  ) : (
                    ""
                  )}
                </GridContainer>
              </CardHeader>

              <CreateServiceStep1 orgId={this.props.serviceDetails.orgId} />
              {this.props.match &&
              this.props.match.params.serviceId != undefined ? (
                <GridContainer justify="left" className={classes.gridFix}>
                  <GridItem className="ml-5">
                    <Button
                      color="rose"
                      className="btn-theme"
                      onClick={() => {
                        this.props.dispatch(
                          updateServiceWithDispatch(
                            this.props.callApi,
                            this.props.serviceDetails
                          )
                        );
                      }}
                    >
                      <FormattedMessage id="update"> </FormattedMessage>
                    </Button>
                  </GridItem>
                </GridContainer>
              ) : (
                <GridContainer justify="left" className={classes.gridFix}>
                  <GridItem className="ml-5">
                    <Button
                      color="rose"
                      className="btn-theme"
                      onClick={() => {
                        createServices(
                          this.props.callApi,
                          this.props.serviceDetails
                        );
                      }}
                    >
                      <FormattedMessage id="createService"> </FormattedMessage>
                    </Button>
                  </GridItem>
                </GridContainer>
              )}
            </GridItem>
          </Card>
        </GridContainer>
        <NavPills color="rose" alignCenter tabs={this.tabContent(classes)} />
        {this.props.selectedBookingId ? (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-200px" }}
            title="Are you sure?"
            onConfirm={() => {
              this.props.raiseAction({
                fieldIdentifier: "selectedBookingId",
                value: undefined,
              }) ||
                this.props.dispatch(
                  deleteBookingWithDispatch(
                    this.props.callApi,
                    this.props.selectedBookingId
                  )
                );
            }}
            onCancel={() => {
              this.props.raiseAction({
                fieldIdentifier: "selectedBookingId",
                value: undefined,
              });
            }}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
          >
            Deleting a booking is permanent
            <div
              className="close-btn"
              onClick={() => {
                this.props.raiseAction({
                  fieldIdentifier: "selectedBookingId",
                  value: undefined,
                });
              }}
            >
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M17.1569 14.5253L28.4489 3.23295C29.1838 2.49842 29.1838 1.3108 28.4489 0.576282C27.7144 -0.15824 26.5267 -0.15824 25.7922 0.576282L14.4998 11.8686L3.20781 0.576282C2.47295 -0.15824 1.28567 -0.15824 0.55115 0.576282C-0.183717 1.3108 -0.183717 2.49842 0.55115 3.23295L11.8432 14.5253L0.55115 25.8177C-0.183717 26.5522 -0.183717 27.7398 0.55115 28.4743C0.917207 28.8407 1.39852 29.0248 1.87948 29.0248C2.36045 29.0248 2.84141 28.8407 3.20781 28.4743L14.4998 17.182L25.7922 28.4743C26.1586 28.8407 26.6396 29.0248 27.1206 29.0248C27.6015 29.0248 28.0825 28.8407 28.4489 28.4743C29.1838 27.7398 29.1838 26.5522 28.4489 25.8177L17.1569 14.5253Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="29" height="29" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </SweetAlert>
        ) : (
          ""
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  var activeFile = null;
  if (
    state.pwReducer.serviceDetails &&
    state.pwReducer.serviceDetails.fileList &&
    state.pwReducer.serviceDetails.fileList.length > 0
  ) {
    activeFile = getMostRecentFileFromList(
      state.pwReducer.serviceDetails.fileList
    );
  }
  return {
    serviceDetails: state.pwReducer.serviceDetails || {},
    selectedBookingId: state.pwReducer.selectedBookingId,
    activeFileUrl:
      activeFile != null
        ? "http://uploads.vetgeo.com/" +
          activeFile.fileId +
          "" +
          activeFile.fileExtension
        : null,
  };
  // return {
  //   serviceDetails: state.pwReducer.serviceDetails || {}
  // };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    dispatch: dispatch,
    raiseAction: (payload) => {
      dispatch(raisers.createActionGeneric(payload, actions.cw.SET_STATE_VAL));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ServiceView));
