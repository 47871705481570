import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import ContactViewOptions from "./ContactViewOptions";
import * as actions from "actions/projectActionTypes";
import { getActiveOrg } from "variables/coreWeb";
import * as makeApiCall from "actions/makeApiCall";
import Button from "components/CustomButtons/Button.jsx";
import { createCustomer } from "../../variables/coreCreateRequests";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { updateContact } from "../../variables/coreRequests";
import { stat } from "fs";
import { createMapPatientCustomer } from "../../variables/thunks";
import GenericMapList from "./GenericMapList";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useHistory } from "react-router-dom";

function LinkAnimals(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const redirect = function () {
    history.push("/customers/view/" + props.match.params.contactId);
  };

  const animals = useSelector((state) =>
    (state.pwReducer.animalList || []).map((prop, key) => {
      return {
        header1: prop.name,
        linkAction: () => {
          dispatch(
            createMapPatientCustomer(
              prop.animalId,
              props.match.params.contactId,
              getActiveOrg(),
              redirect
            )
          );
        },
        animalId: prop.animalId,
      };
    })
  );

  const animalIds = useSelector(
    (state) => state.pwReducer.contactDetails.animalList || []
  ).map((o) => o["animalId"]);

  return (
    <div>
      <CoreApiReq
        endpointToCall={"Read_All_Org_Animals_By_Org_ID"}
        afterActionHandler={actions.cw.GOT_ALL_ORG_ANIMALS_BY_ORG_ID}
        data={{
          orgId: getActiveOrg(),
        }}
      />
      {props.match.params.contactId != undefined ? (
        <CoreApiReq
          endpointToCall={"ReadContactCompleteByContactID"}
          afterActionHandler={actions.cw.GOT_CONTACT_DETAILS}
          data={{
            contactId: props.match.params.contactId,
            orgId: getActiveOrg(),
          }}
        />
      ) : (
        ""
      )}
      <GenericMapList
        hideHeader={true}
        header1={<FormattedMessage id={"AnimalName"} />}
        listData={animals.filter((x) => animalIds.indexOf(x.animalId) === -1)}
      />
    </div>
  );
}

export default withStyles(userProfileStyles)(LinkAnimals);
