import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import AddAlert from "@material-ui/icons/AddAlert";

import ReduxNotification from "components/CoreWeb/ReduxNotification";
import { connect } from "react-redux";
import * as actions from "actions/projectActionTypes";
import * as raisers from "actions/projectRaisers";
import { FormattedMessage } from "react-intl";

const style = {};

class ReduxNotificationHeaderGroup extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, toasts } = this.props;

    return (
      <div>
        {this.props.toastMessage ? (
          <ReduxNotification
            fieldIdentifier={this.props.showOnCreateSuccess}
            color={this.props.toastColor ? this.props.toastColor : "success"}
            message={this.props.toastMessage ? this.props.toastMessage : ""}
          />
        ) : (
          ""
        )}
        {toasts.length > 0
          ? toasts.map((prop, key) => {
              return (
                <div key={prop.id}>
                  <ReduxNotification
                    open={true}
                    color={prop.toastColor ? prop.toastColor : "success"}
                    message={
                      prop.fmi ? (
                        <FormattedMessage id={prop.fmi} />
                      ) : (
                        "Defaultmsg"
                      )
                    }
                  />
                </div>
              );
            })
          : ""}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showOnCreateSuccess: state.pwReducer.showOnCreateSuccess,
    toastMessage: state.pwReducer.toastMessage,
    toastColor: state.pwReducer.toastColor,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default withStyles(style)(
  connect(mapStateToProps, mapDispatchToProps)(ReduxNotificationHeaderGroup)
);
