import ReduxThunk from "redux-thunk";
import { updateAnimal, deleteAnimal } from "variables/coreRequests.jsx";
import {
  updateContact,
  updateService,
  updateProduct,
  updateResource,
  deletePatient,
  deleteContact,
  deleteAddress,
  deleteService,
  deleteProduct,
  deleteResource,
  deleteMedical,
  updateAddress,
  deleteBooking,
  updateMedical,
} from "./coreRequests";
import { createCustomerAddress } from "variables/coreCreateRequests";
import { changeUrl } from "variables/coreWeb.jsx";

import { paths } from "variables/projPaths";
import { createCustomer, createMedicalNote } from "./coreCreateRequests";
import * as actions from "actions/projectActionTypes";
import axios from "axios";
import { APIURL } from "variables/project";
import { DEFAULT_TOAST_TIMEOUT_MS } from "variables/coreWeb";
import genericHttpHandler from "actions/genericHttpHandler";
import genericRespHandler from "actions/genericRespHandler";
import { createPatient } from "variables/coreCreateRequests";

const createPhoneNumberWithDispatch = function(phoneDetails, cb) {
  return async function(dispatch, getState) {
    return dispatch(
      postApi(
        localStorage.getItem("access_token"),
        "CreateFunction",
        phoneDetails
      )
    ).then((data) => {
      if (data.func_status == 1) {
        dispatch({
          type: actions.cw.NOTI_SHOW,
          payload: {},
        });

        dispatch({
          type: actions.cw.UPREDUXCUSTOMINPUT,
          payload: {
            value: "",
            fieldIdentifier: "newPhoneNumber",
          },
        });

        setTimeout(() => {
          dispatch({
            type: actions.cw.NOTI_HIDE,
            payload: {},
          });
        }, DEFAULT_TOAST_TIMEOUT_MS);
        cb(data);
      } else {
        dispatch({
          type: actions.cw.NOTI_SHOW,
          payload: {
            msg: data.func_msg,
            color: "danger",
          },
        });
        setTimeout(() => {
          dispatch({
            type: actions.cw.NOTI_HIDE,
            payload: {},
          });
        }, DEFAULT_TOAST_TIMEOUT_MS);
      }
    });
  };
};

const createCustomerWithDispatch = function(contactDetails, cb) {
  return async function(dispatch, getState) {
    var createCustomerPayload = createCustomer(contactDetails);
    return dispatch(
      postApi(
        localStorage.getItem("access_token"),
        "Create_Org_Contact",
        createCustomerPayload
      )
    ).then((data) => {
      if (data.func_status == 1) {
        if (contactDetails.animalId) {
          dispatch(
            createMapPatientCustomer(
              contactDetails.animalId,
              data.newRecordID,
              createCustomerPayload.orgId,
              (dataFinal) => {
                cb({ ...dataFinal, animalId: contactDetails.animalId });
              }
            )
          );
        } else {
          dispatch({
            type: actions.cw.NOTI_SHOW,
            payload: {},
          });
          setTimeout(() => {
            dispatch({
              type: actions.cw.NOTI_HIDE,
              payload: {},
            });
          }, DEFAULT_TOAST_TIMEOUT_MS);
          cb(data);
        }
      } else {
        dispatch({
          type: actions.cw.NOTI_SHOW,
          payload: {
            msg: data.func_msg,
            color: "danger",
          },
        });
        setTimeout(() => {
          dispatch({
            type: actions.cw.NOTI_HIDE,
            payload: {},
          });
        }, DEFAULT_TOAST_TIMEOUT_MS);
      }
    });
  };
};

const deleteMapPatientCustomer = function(animalId, contactId, orgId, cb) {
  return async function(dispatch, getState) {
    dispatch(
      postApi(
        localStorage.getItem("access_token"),
        "Delete_Animal_Contact_Mapping",
        {
          animalId: animalId,
          orgId: orgId,
          contactId: contactId,
        }
      )
    )
      .then((data2) => {
        if (data2.func_status == 1) {
          setTimeout(() => {
            dispatch({
              type: actions.cw.NOTI_HIDE,
              payload: {},
            });

            cb(data2);
          }, DEFAULT_TOAST_TIMEOUT_MS);
        }
      })
      .then((data2) => {
        dispatch({
          type: actions.cw.NOTI_SHOW,
          payload: {
            msg: "Sucessfully unlinked",
            color: "success",
          },
        });
      });
  };
};

const createMapPatientCustomer = function(animalId, contactId, orgId, cb) {
  return async function(dispatch, getState) {
    dispatch(
      postApi(
        localStorage.getItem("access_token"),
        "Create_Animal_Contact_Mapping",
        {
          animalId: animalId,
          orgId: orgId,
          contactId: contactId,
        }
      )
    )
      .then((data2) => {
        if (data2.func_status == 1) {
          setTimeout(() => {
            dispatch({
              type: actions.cw.NOTI_HIDE,
              payload: {},
            });

            cb(data2);
          }, DEFAULT_TOAST_TIMEOUT_MS);
        }
      })
      .then((data2) => {
        dispatch({
          type: actions.cw.NOTI_SHOW,
          payload: {
            msg: "Sucessfully linked",
            color: "success",
          },
        });
      });
  };
};

const createOrgWithDispatch = function(payload, cb) {
  return async function(dispatch, getState) {
    dispatch(
      postApi(localStorage.getItem("access_token"), "CreateFunction", payload)
    )
      .then((data2) => {
        if (data2.func_status == 1) {
          setTimeout(() => {
            dispatch({
              type: actions.cw.NOTI_HIDE,
              payload: {},
            });

            cb(data2);
          }, DEFAULT_TOAST_TIMEOUT_MS);
        }
      })
      .then((data2) => {
        dispatch({
          type: actions.cw.NOTI_SHOW,
          payload: {
            msg: "Sucessfully created",
            color: "success",
          },
        });
      });
  };
};

const createStaffMember = function(payload, cb) {
  return async function(dispatch, getState) {
    dispatch(
      postApi(localStorage.getItem("access_token"), "Create_Member", payload)
    )
      .then((data2) => {
        if (data2.func_status == 1) {
          setTimeout(() => {
            dispatch({
              type: actions.cw.NOTI_HIDE,
              payload: {},
            });

            cb(data2);
          }, DEFAULT_TOAST_TIMEOUT_MS);
        }
      })
      .then((data2) => {
        dispatch({
          type: actions.cw.NOTI_SHOW,
          payload: {
            msg: "Sucessfully created",
            color: "success",
          },
        });
      });
  };
};

const createAnimalWithDispatch = function(animalDetails, cb) {
  return async function(dispatch, getState) {
    var createAnimalPayload = createPatient(animalDetails);
    console.log("-------AnimalDeetz:" + JSON.stringify(createAnimalPayload));
    console.log("CONTACTID_" + animalDetails.contactId);
    console.log("ORGID_" + animalDetails.orgId);
    return dispatch(
      postApi(
        localStorage.getItem("access_token"),
        "Create_Org_Animal",
        createAnimalPayload
      )
    ).then((data) => {
      if (data.func_status == 1) {
        if (animalDetails.contactId) {
          dispatch(
            createMapPatientCustomer(
              data.newRecordID,
              animalDetails.contactId,
              createAnimalPayload.orgId,
              () => {
                changeUrl(paths.customerView + "/" + animalDetails.contactId);
              }
            )
          );
        } else {
          dispatch({
            type: actions.cw.NOTI_SHOW,
            payload: {},
          });
          setTimeout(() => {
            dispatch({
              type: actions.cw.NOTI_HIDE,
              payload: {},
            });
            if (cb) {
              cb(data);
            }
          }, DEFAULT_TOAST_TIMEOUT_MS);
        }
      } else {
        dispatch({
          type: actions.cw.NOTI_SHOW,
          payload: {
            msg: data.func_msg,
            color: "danger",
          },
        });
        setTimeout(() => {
          dispatch({
            type: actions.cw.NOTI_HIDE,
            payload: {},
          });
        }, DEFAULT_TOAST_TIMEOUT_MS);
      }
    });
  };
};

/*  .then((data) => {
   data.fmi = "successful";
   dispatch({type: actions.cw.CREATED_EXCEPTION_SHOW, payload:data })
   setTimeout(() => {
     dispatch({type: actions.cw.CREATED_EXCEPTION_HIDE, payload:data })
   }, DEFAULT_TOAST_TIMEOUT_MS);
   
 })   
   await createPatient(callApi, animalDetails);
   await createPatient
   setTimeout(function() {
     dispatch({ type: actions.cw.NOTI_HIDE });
   }, 3000);
 };
};*/

const updateAnimalWithDispatch = function(callApi, animalDetails) {
  return async function(dispatch, getState) {
    console.log("-------AnimalDeetz:" + JSON.stringify(animalDetails));
    await updateAnimal(callApi, animalDetails);
    // await dispatch({type: actions.cw.NOTI_SHOW});
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_HIDE });
    }, 3000);
  };
};

const updateCustomerWithDispatch = function(callApi, contactDetails) {
  return async function(dispatch, getState) {
    console.log("-------CustomerDeetz:" + JSON.stringify(contactDetails));
    await updateContact(callApi, contactDetails);
    // await dispatch({type: actions.cw.NOTI_SHOW});
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_HIDE });
    }, 3000);
  };
};

const updateServiceWithDispatch = function(callApi, serviceDetails) {
  return async function(dispatch, getState) {
    console.log("-------ServiceDeetz:" + JSON.stringify(serviceDetails));
    await updateService(callApi, serviceDetails);
    // await dispatch({type: actions.cw.NOTI_SHOW});
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_HIDE });
    }, 3000);
  };
};

const createAddressWithDispatch = function(callApi, addressDetails) {
  return async function(dispatch, getState) {
    console.log("-------AddressDeetz:" + JSON.stringify(addressDetails));
    await createCustomerAddress(callApi, addressDetails);
    // await dispatch({type: actions.cw.NOTI_SHOW});
    setTimeout(function() {
      changeUrl(
        paths.customerView + "/" + addressDetails.contactDetails.contactId
      );
      //dispatch({ type: actions.cw.NOTI_HIDE });
    }, 3000);
  };
};

const updateAddressWithDispatch = function(callApi, addressDetails) {
  return async function(dispatch, getState) {
    await updateAddress(callApi, addressDetails);
    // await dispatch({type: actions.cw.NOTI_SHOW});
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_HIDE });
    }, 3000);
  };
};

const updateProductWithDispatch = function(callApi, productDetails) {
  return async function(dispatch, getState) {
    console.log("-------ProductDeetz:" + JSON.stringify(productDetails));
    await updateProduct(callApi, productDetails);
    // await dispatch({type: actions.cw.NOTI_SHOW});
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_HIDE });
    }, 3000);
  };
};

const updateResourceWithDispatch = function(callApi, resourceDetails) {
  return async function(dispatch, getState) {
    console.log("-------ResourceDeetz:" + JSON.stringify(resourceDetails));
    await updateResource(callApi, resourceDetails);
    // await dispatch({type: actions.cw.NOTI_SHOW});
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_HIDE });
    }, 3000);
  };
};

const deleteAnimalWithDispatch = function(callApi, selectedAnimalId) {
  return async function(dispatch, getState) {
    console.log("-------DeleteAnimalDeetz:" + JSON.stringify(selectedAnimalId));
    await deletePatient(callApi, selectedAnimalId);
    // await dispatch({ type: actions.cw.NOTI_SHOW });
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_HIDE });
    }, 3000);
  };
};

const deleteContactWithDispatch = function(callApi, selectedCustomerId) {
  return async function(dispatch, getState) {
    console.log(
      "-------DeleteContactDeetz:" + JSON.stringify(selectedCustomerId)
    );
    await deleteContact(callApi, selectedCustomerId);
    // await dispatch({ type: actions.cw.NOTI_SHOW });
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_HIDE });
    }, 3000);
  };
};

const deleteContactAddressWithDispatch = function(callApi, selectedAddressId) {
  return async function(dispatch, getState) {
    console.log(
      "-------DeleteContactAddressDeetz:" + JSON.stringify(selectedAddressId)
    );
    await deleteAddress(callApi, selectedAddressId);
    // await dispatch({ type: actions.cw.NOTI_SHOW });
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_HIDE });
    }, 3000);
  };
};

const deleteServiceWithDispatch = function(callApi, selectedServiceId) {
  return async function(dispatch, getState) {
    console.log(
      "-------DeleteServiceDeetz:" + JSON.stringify(selectedServiceId)
    );
    await deleteService(callApi, selectedServiceId);
    // await dispatch({ type: actions.cw.NOTI_SHOW });
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_HIDE });
    }, 3000);
  };
};

const deleteBookingWithDispatch = function(callApi, selectedBookingId) {
  return async function(dispatch, getState) {
    console.log(
      "-------DeleteBookingDeetz:" + JSON.stringify(selectedBookingId)
    );
    await deleteBooking(callApi, selectedBookingId);
    // await dispatch({ type: actions.cw.NOTI_SHOW });
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_HIDE });
    }, 3000);
  };
};

const deleteProductWithDispatch = function(callApi, selectedProductId) {
  return async function(dispatch, getState) {
    console.log(
      "-------DeleteProductDeetz:" + JSON.stringify(selectedProductId)
    );
    await deleteProduct(callApi, selectedProductId);
    // await dispatch({ type: actions.cw.NOTI_SHOW });
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_HIDE });
    }, 3000);
  };
};

const deleteResourceWithDispatch = function(callApi, selectedResourceId) {
  return async function(dispatch, getState) {
    console.log(
      "-------DeleteResourceDeetz:" + JSON.stringify(selectedResourceId)
    );
    await deleteResource(callApi, selectedResourceId);
    // await dispatch({ type: actions.cw.NOTI_SHOW });
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_HIDE });
    }, 3000);
  };
};

const deleteMetaDataWithDispatch = function(selectedMetaDataId, orgId, cb) {
  var payLoad = {};
  payLoad.functionName = "DeleteMetaData";
  var json = { orgId: orgId, metaDataId: selectedMetaDataId };
  payLoad.json = JSON.stringify(json);
  return async function(dispatch, getState) {
    return dispatch(
      postApi(localStorage.getItem("access_token"), "DeleteFunction", payLoad)
    ).then((data) => {
      if (data.func_status == 1) {
        dispatch({
          type: actions.cw.NOTI_SHOW,
          payload: {
            msg: "Deleted",
          },
        });
        setTimeout(() => {
          dispatch({
            type: actions.cw.NOTI_HIDE,
            payload: {},
          });
        }, DEFAULT_TOAST_TIMEOUT_MS);
        cb(data);
      } else {
        dispatch({
          type: actions.cw.NOTI_SHOW,
          payload: {
            msg: data.func_msg,
            color: "danger",
          },
        });
        setTimeout(() => {
          dispatch({
            type: actions.cw.NOTI_HIDE,
            payload: {},
          });
        }, DEFAULT_TOAST_TIMEOUT_MS);
      }
    });
  };
};

const createMedicalWithDispatch = function(callApi, medicalDetails, animalId) {
  console.log("Creating note record:2", medicalDetails);
  return async function(dispatch, getState) {
    console.log(
      "-------MedicalDeetz:" + JSON.stringify(medicalDetails, animalId)
    );
    await createMedicalNote(callApi, medicalDetails, animalId);
    //await dispatch({ type: actions.cw.NOTI_SHOW });
    setTimeout(function() {
      changeUrl(paths.animalView + "/" + animalId);
      dispatch({ type: actions.cw.NOTI_HIDE });
    }, 3000);
  };
};

const updateMedicalWithDispatch = function(
  callApi,
  medicalDetails,
  animalId,
  selectedMedicalId
) {
  return async function(dispatch, getState) {
    console.log(
      "-------MedicalDeetz:" +
        JSON.stringify(medicalDetails, animalId, selectedMedicalId)
    );
    await updateMedical(callApi, medicalDetails, animalId, selectedMedicalId);
    // await dispatch({type: actions.cw.NOTI_SHOW});
    setTimeout(function() {
      changeUrl(paths.animalView + "/" + animalId);
      dispatch({ type: actions.cw.NOTI_HIDE });
    }, 3000);
  };
};

const deleteMedicalWithDispatch = function(callApi, selectedMedicalId) {
  return async function(dispatch, getState) {
    console.log(
      "-------DeleteResourceDeetz:" + JSON.stringify(selectedMedicalId)
    );
    await deleteMedical(callApi, selectedMedicalId);
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_HIDE });
    }, 3000);
  };
};

const readContactCompleteWithDispatch = function(contactId, orgId, cb) {
  return async function(dispatch, getState) {
    return dispatch(
      postApi(
        localStorage.getItem("access_token"),
        "ReadContactCompleteByContactID",
        { contactId: contactId, orgId: orgId }
      )
    ).then((data) => {
      if (data.func_status == 1) {
        dispatch({
          type: actions.cw.GOT_CONTACT_DETAILS,
          payload: data,
        });

        cb(data);
      } else {
        dispatch({
          type: actions.cw.NOTI_SHOW,
          payload: {
            msg: "Read contact failed",
            color: "danger",
          },
        });
        setTimeout(() => {
          dispatch({
            type: actions.cw.NOTI_HIDE,
            payload: {},
          });
        }, DEFAULT_TOAST_TIMEOUT_MS);
      }
    });
  };
};

const makeApiCallHandler = function(json, action) {
  return { type: action, payload: json };
};
const postApi = function(apiKey, endpointUrl, dataToPost, afterActionHandler) {
  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  return (dispatch) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ` + apiKey;
    return axios.post(APIURL + endpointUrl, dataToPost, config).then((res) => {
      return res.data;
    });
  };
};
const getServiceFulfilmentConfig = function(orgId, serviceFulfilmentConfigId) {
  return function(dispatch, getState) {
    return dispatch(
      postApi(
        localStorage.getItem("access_token"),
        "Read_Org_ServiceFulfilmentConfig_Complete",
        { orgId: orgId, serviceFulfilmentConfigId: serviceFulfilmentConfigId }
      )
    ).then((data) => {
      dispatch({ type: actions.cw.GOT_SERVICEFULFILMENTCONFIG, payload: data });
    });
  };
};

const createException = function(exceptionDetails, cb) {
  return function(dispatch, getState) {
    return dispatch(
      postApi(
        localStorage.getItem("access_token"),
        "Create_Exception",
        exceptionDetails
      )
    ).then((data) => {
      data.fmi = "successful";
      dispatch({ type: actions.cw.CREATED_EXCEPTION_SHOW, payload: data });
      setTimeout(() => {
        dispatch({ type: actions.cw.CREATED_EXCEPTION_HIDE, payload: data });
        if (cb) {
          cb(data);
        }
      }, DEFAULT_TOAST_TIMEOUT_MS);
    });
  };
};

const createServiceOrder = function(serviceOrderDetails, cb) {
  return function(dispatch, getState) {
    return dispatch(
      postApi(
        localStorage.getItem("access_token"),
        "CreateFunction",
        serviceOrderDetails
      )
    ).then((data) => {
      data.fmi = "successful";
      dispatch({ type: actions.cw.CREATED_EXCEPTION_SHOW, payload: data });
      setTimeout(() => {
        dispatch({ type: actions.cw.CREATED_EXCEPTION_HIDE, payload: data });
        if (cb) {
          if (cb) {
            cb(data);
          }
        }
      }, DEFAULT_TOAST_TIMEOUT_MS);
    });
  };
};

const deleteException = function(exceptionDetails, cb) {
  return function(dispatch, getState) {
    return dispatch(
      postApi(
        localStorage.getItem("access_token"),
        "Delete_Exception",
        exceptionDetails
      )
    ).then((data) => {
      data.fmi = "successful";
      dispatch({ type: actions.cw.DELETED_EXCEPTION_SHOW, payload: data });
      setTimeout(() => {
        dispatch({ type: actions.cw.DELETED_EXCEPTION_HIDE, payload: data });
        if (cb) {
          cb(data);
        }
      }, DEFAULT_TOAST_TIMEOUT_MS);
    });
  };
};

const deleteAppointment = function(appointmentDetails, cb) {
  return function(dispatch, getState) {
    return dispatch(
      postApi(
        localStorage.getItem("access_token"),
        "Delete_Appointment",
        appointmentDetails
      )
    ).then((data) => {
      data.fmi = "successful";
      dispatch({ type: actions.cw.DELETED_EXCEPTION_SHOW, payload: data });
      setTimeout(() => {
        dispatch({ type: actions.cw.DELETED_EXCEPTION_HIDE, payload: data });
        if (cb) {
          cb(data);
        }
      }, DEFAULT_TOAST_TIMEOUT_MS);
    });
  };
};

const updateProfile = function(profileDetails) {
  return function(dispatch, getState) {
    return dispatch(
      postApi(
        localStorage.getItem("access_token"),
        "Update_User_Profile",
        profileDetails
      )
    ).then((data) => {
      if (data.func_status == 1) {
        dispatch({
          type: actions.cw.NOTI_SHOW,
          payload: {
            msg: "Successfully Updated",
            color: "success",
          },
        });
        setTimeout(() => {
          dispatch({
            type: actions.cw.NOTI_HIDE,
            payload: {},
          });
        }, DEFAULT_TOAST_TIMEOUT_MS);
      } else {
        dispatch({
          type: actions.cw.NOTI_SHOW,
          payload: {
            msg: "Not Updated",
            color: "danger",
          },
        });
        setTimeout(() => {
          dispatch({
            type: actions.cw.NOTI_HIDE,
            payload: {},
          });
        }, DEFAULT_TOAST_TIMEOUT_MS);
      }
      //dispatch({type: actions.cw.GOT_SERVICEFULFILMENTCONFIG, payload:data })
    });
  };
};

const setCalendarClickData = function(info) {
  return function(dispatch, getState) {
    return dispatch({
      type: actions.cw.SET_CALENDAR_CLICK_INFO,
      payload: info,
    });
  };
};

const setCalendarEventClickData = function(info) {
  return function(dispatch, getState) {
    return dispatch({
      type: actions.cw.SET_CALENDAR_EVENT_CLICK_INFO,
      payload: info,
    });
  };
};

const setNotificationMessage = (msg, color) => {
  return function(dispatch, getState) {
    dispatch({
      type: actions.cw.NOTI_SHOW,
      payload: {
        msg: msg,
        color: color,
      },
    });
    setTimeout(() => {
      dispatch({
        type: actions.cw.NOTI_HIDE,
        payload: {},
      });
    }, DEFAULT_TOAST_TIMEOUT_MS);
  };
};

const getSearchResult = function(orgId, textString) {
  return function(dispatch, getState) {
    return dispatch(
      postApi(localStorage.getItem("access_token"), "SearchSystem", {
        orgId: orgId,
        textString: textString,
      })
    ).then((data) => {
      dispatch({ type: actions.cw.GOT_SEARCH_RESULT, payload: data });
    });
  };
};

//######################### Project thunks
export {
  deleteMetaDataWithDispatch,
  createPhoneNumberWithDispatch,
  createMapPatientCustomer,
  deleteMapPatientCustomer,
  createCustomerWithDispatch,
  createAnimalWithDispatch,
  updateAnimalWithDispatch,
  updateCustomerWithDispatch,
  updateServiceWithDispatch,
  updateProductWithDispatch,
  updateResourceWithDispatch,
  deleteAnimalWithDispatch,
  deleteContactWithDispatch,
  deleteContactAddressWithDispatch,
  deleteServiceWithDispatch,
  deleteProductWithDispatch,
  deleteResourceWithDispatch,
  createAddressWithDispatch,
  createMedicalWithDispatch,
  updateAddressWithDispatch,
  updateMedicalWithDispatch,
  deleteMedicalWithDispatch,
  postApi,
  getServiceFulfilmentConfig,
  createException,
  deleteException,
  deleteAppointment,
  updateProfile,
  setCalendarClickData,
  createStaffMember,
  createOrgWithDispatch,
  setCalendarEventClickData,
  createServiceOrder,
  readContactCompleteWithDispatch,
  setNotificationMessage,
  deleteBookingWithDispatch,
  getSearchResult,
};
