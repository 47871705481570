import React from "react";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CreateResourceStep1 from "./WizardSteps/CreateResourceStep1.jsx";
import ComingSoonStep from "components/CoreWeb/ComingSoonStep.jsx";

class CreateResource extends React.Component {
  render() {
    return (
      <div>
        <CreateResourceStep1
          resourceId={
            this.props &&
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.resourceId
              ? this.props.match.params.resourceId
              : null
          }
        />
      </div>
    );
  }
}

export default CreateResource;
