import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { getActiveOrg } from "variables/coreWeb.jsx";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import { paths } from "variables/projPaths.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import * as raisers from "actions/projectRaisers";
import Button from "components/CustomButtons/Button.jsx";
import * as makeApiCall from "actions/makeApiCall";
import Visibility from "@material-ui/icons/Visibility";
import Clear from "@material-ui/icons/Clear";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { deleteServiceWithDispatch } from "../../variables/thunks";
import { FormattedMessage } from "react-intl";
import vieweye from "assets/img/view.svg";
import deleicon from "assets/img/delete.svg";
import { Link } from "react-router-dom";

const style = {
  ...userProfileStyles,
  ...sweetAlertStyle,
};

class OrgServices extends React.Component {
  render() {
    const { classes } = this.props;
    const fillButtons = function(theProps, seviceId) {
      return (
        <div>
          <Link to={paths.serviceView + "/" + seviceId} className="cur">
            <button className="sm selfview">
              <img src={vieweye} />
            </button>
          </Link>
          <button
            onClick={() => {
              theProps.raiseAction({
                fieldIdentifier: "selectedseviceId",
                value: seviceId,
              });
            }}
            className="sm danger"
          >
            <img src={deleicon} />
          </button>
          {/* <Button
            className={classes.actionButton}
            color="danger"
            size="sm"
            onClick={() => {
              theProps.raiseAction({
                fieldIdentifier: "selectedseviceId",
                value: seviceId,
              });
            }}
          >
            <Clear className={classes.icon} />
          </Button>
          <Link to={"/" + paths.serviceView + "/" + seviceId}>
            <Button className={classes.actionButton} color="info" size="sm">
              <Visibility className={classes.icon} />
            </Button>
          </Link> */}
        </div>
      );
    };

    return (
      <GridContainer justify={"center"} className="sl-pd-22 table-container">
        <CoreApiReq
          endpointToCall={"Read_All_Organisation_Services"}
          afterActionHandler={actions.cw.GOT_ALL_SERVICES_BY_ORG_ID}
          data={{
            orgId: getActiveOrg(),
          }}
        />
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Link to={paths.serviceCreate}>
            <Button className="btn-primary new-service btn-theme">
              + Create new service
            </Button>
          </Link>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader>
              <h3 className="myhead nosp">
                <FormattedMessage id="clinicServices" />
              </h3>
              <p>The services you offer your clients</p>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={this.props.serviceCompleteList.map((prop, key) => {
                  return {
                    id: key,
                    name: prop.name,
                    // position: labelFromType(prop[1]),
                    // status: orderStatusFromType(prop[2]),
                    // age: prop[3],
                    actions: fillButtons(this.props, prop.serviceId),
                    // workingFor: prop[2] != 1 ? '????' : "Coca Cola",
                    totalValue: "100$",
                    isPlatformSpecific: prop.isPlatformSpecific,
                    serviceId: prop.serviceId,
                    description: prop.description || "-",
                    bookingCount:
                      prop.serviceFulfilmentConfigCompleteList.length,
                  };
                })}
                filterable={false}
                columns={[
                  {
                    Header: "Service Id",
                    accessor: "serviceId",
                  },
                  {
                    Header: "Service Name",
                    accessor: "name",
                  },

                  {
                    Header: "Description",
                    accessor: "description",
                  },
                  {
                    Header: "Booking Configs",
                    accessor: "bookingCount",
                  },

                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
        {this.props.selectedseviceId ? (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-200px" }}
            title="Are you sure?"
            onConfirm={() => {
              this.props.raiseAction({
                fieldIdentifier: "selectedseviceId",
                value: undefined,
              }) ||
                this.props.dispatch(
                  deleteServiceWithDispatch(
                    this.props.callApi,
                    this.props.selectedseviceId
                  )
                );
            }}
            onCancel={() => {
              this.props.raiseAction({
                fieldIdentifier: "selectedseviceId",
                value: undefined,
              });
            }}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
          >
            Deleting a service is permanent
          </SweetAlert>
        ) : (
          ""
        )}
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    serviceCompleteList: state.pwReducer.serviceCompleteList || [],
    selectedseviceId: state.pwReducer.selectedseviceId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    dispatch: dispatch,
    raiseAction: (payload) => {
      dispatch(raisers.createActionGeneric(payload, actions.cw.SET_STATE_VAL));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(OrgServices));
