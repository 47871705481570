import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import StarRatingComponent from "react-star-rating-component";
import Visibility from "@material-ui/icons/Visibility";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import CardAvatar from "components/Card/CardAvatar.jsx";

import Check from "@material-ui/icons/Check";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import { dataTable5, statusFromType } from "variables/general.jsx";
import { labelFromType } from "variables/project.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import ReduxCoreSelect from "components/CoreWeb/ReduxCoreSelect";
import * as actions from "actions/projectActionTypes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";

const contactTitleSelectOptions = [
  {
    name: <FormattedMessage id="Mr" />,
    value: 2,
  },
  {
    name: <FormattedMessage id="Mrs" />,
    value: 3,
  },
  {
    name: <FormattedMessage id="Dr" />,
    value: 4,
  },
  {
    name: <FormattedMessage id="Ms" />,
    value: 5,
  },
];

class ContactTitleSelect extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;

    return (
      <div>
        <ReduxCoreSelect
          labelText={
            this.props.labelText || <FormattedMessage id="contactTitle" />
          }
          customSelectedValue={2}
          options={this.props.contactTitleSelectOptions}
          fieldIdentifier={this.props.fieldIdentifier || "contactTitle"}
          updateAction={actions.cw.UPREDUXSELECTINPUT}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    contactTitleSelectOptions: (function () {
      var rows = [];
      for (var i = 0; i < contactTitleSelectOptions.length; i++) {
        // note: we add a key prop here to allow react to uniquely identify each
        // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
        rows.push(
          <MenuItem value={contactTitleSelectOptions[i].value} key={i}>
            {contactTitleSelectOptions[i].name}
          </MenuItem>
        );
      }
      return rows;
    })(),
    genderSelection:
      state.pwReducer[ownProps.fieldIdentifier || "contactTitle"],
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(userProfileStyles)(ContactTitleSelect));
