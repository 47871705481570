import React from "react";
import { FormattedMessage } from "react-intl";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { paths } from "variables/projPaths.jsx";
// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import LocationOn from "@material-ui/icons/LocationOn";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import OrganisationType from "components/CoreWeb/OrganisationType";
import EmployeeCount from "components/CoreWeb/EmployeeCount";
import NumberRangeSelect from "components/CoreWeb/NumberRangeSelect";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput";
import MonetaryCurrency from "components/CoreWeb/MonetaryCurrency";
import * as makeApiCall from "actions/makeApiCall";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import * as actions from "actions/projectActionTypes";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import avatar from "assets/img/companylogo.png";
import { createOrgWithDispatch } from "variables/thunks";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const RegularMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: props.latitude, lng: props.longitude }}
      defaultOptions={{
        scrollwheel: false,
      }}
    >
      <Marker position={{ lat: props.latitude, lng: props.longitude }} />
    </GoogleMap>
  ))
);

const style = {};

const CompanyProfile = function(props) {
  const getTheViewProps = function(theProps) {
    var json = {};
    json.orgName = theProps.orgDetails.orgName;
    json.createdOn_UTC = theProps.orgDetails.createdOn_UTC;
    json.creatorId = theProps.creatorId;
    json.monetaryCurrency = theProps.monetaryCurrency;
    json.businessType = 2;
    json.isClaimed = 3;
    json.metaDataOptionList = [];
    json.metaDataOptionList.push({
      metadataidentifier: "employeeCount",
      metaData: theProps.orgDetails.employeeCount,
      type: 2,
    });
    json.metaDataOptionList.push({
      metadataidentifier: "organisationType",
      metaData: theProps.orgDetails.organisationType,
      type: 2,
    });
    json.metaDataOptionList.push({
      metadataidentifier: "yearEstablished",
      metaData: theProps.orgDetails.yearEstablished,
      type: 2,
    });
    json.metaDataOptionList.push({
      metadataidentifier: "orgNumber",
      metaData: theProps.orgDetails.orgNumber,
      type: 2,
    });
    json.metaDataOptionList.push({
      metadataidentifier: "yearsTrading",
      metaData: theProps.orgDetails.yearsTrading,
      type: 2,
    });
    json.metaDataOptionList.push({
      metadataidentifier: "companyProfileVision",
      metaData: theProps.orgDetails.companyProfileVision,
      type: 2,
    });
    return json;
  };

  const createOrganization = function(theProps) {
    var payLoad = {};
    payLoad.json = JSON.stringify(getTheViewProps(theProps));
    payLoad.functionName = "CreateOrganisation";
    return payLoad;
  };

  const updateOrganization = function(callMe, theProps) {
    var payLoad = {};
    var json = getTheViewProps(theProps);
    json.orgId = theProps.orgDetails.orgId;
    json.orgName = theProps.orgDetails.orgName;
    json.applicationGUID = theProps.orgDetails.applicationGUID;
    json.latitude = theProps.orgDetails.latitude;
    json.longitude = theProps.orgDetails.longitude;
    json.subscriptionDefinitionId =
      theProps.orgDetails.subscriptionDefinitionId;
    payLoad.json = JSON.stringify(json);
    payLoad.functionName = "UpdateOrganisation";

    window.localStorage.setItem("currentOrgName", theProps.orgDetails.orgName);

    callMe.req(
      localStorage.getItem("access_token"),
      "UpdateFunction",
      payLoad,
      actions.cw.API_RESP_UPDATE
    );
  };

  const { classes } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className="sl-pd-22 w-100">
      {props.match.params.id && (
        <CoreApiReq
          endpointToCall={"ReadOrganisationComplete"}
          afterActionHandler={actions.cw.GOT_ORGDETAILSLIMITED}
          data={{
            orgId: props.match.params.id,
          }}
        />
      )}
      <GridContainer justify="left" className="w-100">
        <GridItem>
          <Card>
            <CardHeader>
              <h3 className="myhead nosp">Clinic </h3>
              <p>Clinic Information</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {props.match.params.id && (
                  <GridItem xs={12} sm={12} md={4} lg={3} className="m-20">
                    <label className="lbl-fld">
                      <FormattedMessage id="organizationIdNumber" />
                    </label>
                    <ReduxCustomInput
                      // mainTextLabel={
                      //   <FormattedMessage id="organizationIdNumber" />
                      // }
                      //subTextLabel="(read only)"
                      updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                      fieldIdentifier={"orgDetails.orgId"}
                      multiLine={1}
                      disabled={true}
                    />
                  </GridItem>
                )}

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  className="m-20 input-box"
                >
                  <label className="lbl-fld">
                    <FormattedMessage id="organizationName" />
                  </label>
                  <ReduxCustomInput
                    //mainTextLabel={<FormattedMessage id="organizationName" />}
                    //subTextLabel="(required)"
                    updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                    fieldIdentifier={"orgDetails.orgName"}
                    textValue={props.orgDetails.orgName}
                    multiLine={1}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  className="m-20 select-box"
                >
                  <label className="lbl-fld">Organization Type</label>
                  <OrganisationType
                    inputProps={{
                      value: props.organisationType,
                    }}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  className="m-20 select-box"
                >
                  <label className="lbl-fld">Employee Count</label>
                  <EmployeeCount
                    inputProps={{
                      value: props.employeeCount,
                    }}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  className="m-20 select-box"
                >
                  <label className="lbl-fld">Year Established</label>
                  <NumberRangeSelect
                    //placeholder="Year Established"
                    start={1960}
                    stop={2020}
                    useValueNotIndex={true}
                    fieldIdentifier={"orgDetails.yearEstablished"}
                    inputProps={{
                      value: props.yearEstablished,
                    }}
                    descending={true}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  className="m-20 select-box "
                >
                  <label className="lbl-fld">Years Trading</label>
                  <NumberRangeSelect
                    //placeholder="Years Trading"
                    start={0}
                    stop={15}
                    lastOptionTextEnd={"+"}
                    useValueNotIndex={true}
                    fieldIdentifier={"orgDetails.yearsTrading"}
                    inputProps={{
                      value: props.yearsTrading,
                    }}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  className="m-20 select-box"
                >
                  <label className="lbl-fld">Currency</label>
                  <MonetaryCurrency
                    fieldIdentifier={"orgDetails.monetaryCurrency"}
                  />
                </GridItem>
                {/*<GridItem xs={12} sm={12} md={12} lg={10} className="m-20">
                  <ReduxCustomInput
                    subTextLabel="(required)"
                    updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                    textValue={props.companyProfileVision}
                    multiLine={5}
                    fieldIdentifier={"orgDetails.companyProfileVision"}
                  />
                  </GridItem>*/}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem>
          {props.match.params.id && (
            <Button
              color="rose"
              className={`${
                classes.updateProfileButton
              } create-Clinic btn-theme`}
              onClick={() => {
                updateOrganization(props.callApi, props);
              }}
            >
              <FormattedMessage id="updateDetails" />
            </Button>
          )}
          {!props.match.params.id && (
            <Button
              color="rose"
              className={`${
                classes.updateProfileButton
              } create-Clinic btn-theme`}
              onClick={() => {
                dispatch(
                  createOrgWithDispatch(createOrganization(props), (data2) => {
                    if (data2.func_status == 1) {
                      console.log("Create Org Data", data2);
                      if (data2.newRecordID != -1) {
                        window.localStorage.setItem(
                          "currentOrg",
                          data2.newRecordID
                        );

                        window.localStorage.setItem(
                          "currentOrgName",
                          props.orgDetails.orgName
                        );

                        props.callApi.req(
                          localStorage.getItem("access_token"),
                          "CreateFunction",
                          JSON.stringify(data2), // payload
                          actions.cw.API_RESP_CREATE
                        );
                      }
                      history.push("/dashboard");
                      window.location.reload();
                    } else {
                      // history.push(paths.orgSignIn);
                    }
                  })
                );
              }}
            >
              <FormattedMessage id="createOrganization" />
            </Button>
          )}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {/*<NavPills
              color="rose"
              alignCenter
              tabs={[
              
                {
                  tabButton: "Location",
                  tabIcon: LocationOn,
                  tabContent: (
                    <Card profile>
                      <CardHeader>
                        <h4><FormattedMessage id="clinicsLocation"></FormattedMessage></h4>
                      </CardHeader>

                      <RegularMap
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD9MFs8Q-HTKk8A5gbQmhWITQ2vxpL1LY4"
                        loadingElement={<div style={{ height: "100%" }} />}
                        containerElement={<div style={{ height: "800px" }} />}
                        mapElement={<div style={{ height: "100%" }} />}
                        latitude={this.props.orgDetails.latitude || 59.334591}
                        longitude={this.props.orgDetails.longitude || 18.06324}
                      />
                    </Card>
                  )
                }
              ]}
            />*/}
        </GridItem>
      </GridContainer>
    </div>
  );
};

CompanyProfile.propTypes = {};

CompanyProfile.defaultProps = {
  orgDetails: {},
};

function mapStateToProps(state) {
  console.log("ORG DETAILS", state.pwReducer.orgDetails.orgName);
  return {
    creatorId: state.pwReducer.selfProfile.userId,
    orgDetails: state.pwReducer.orgDetails,
    orgName: state.pwReducer.orgDetails.orgName,
    companyProfileVision: state.pwReducer.orgDetails.companyProfileVision,
    organisationType: parseInt(state.pwReducer.orgDetails.organisationType),
    employeeCount: parseInt(state.pwReducer.orgDetails.employeeCount),
    yearEstablished: parseInt(state.pwReducer.orgDetails.yearEstablished),
    yearsTrading: parseInt(state.pwReducer.orgDetails.yearsTrading),
    monetaryCurrency: parseInt(state.pwReducer.orgDetails.monetaryCurrency),
    orgNumber: state.pwReducer.orgDetails.orgNumber,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(CompanyProfile));
