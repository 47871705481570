import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Datetime from "react-datetime";
import { connect } from "react-redux";
import * as actions from "actions/projectActionTypes";
import * as raisers from "actions/projectRaisers";
import { getObjProp } from "variables/coreWeb.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import InputLabel from "@material-ui/core/InputLabel";

import FormHelperText from "@material-ui/core/FormHelperText";
import classNames from "classnames";
import CustomDateTime from "./CustomDateTime";
const styles = {
  ...userProfileStyles,
};

class ReduxCustomDateTime extends React.Component {
  render() {
    //console.log("Date Time Props",this.props);
    const { classes, formControlProps, error, success } = this.props;
    return <CustomDateTime {...this.props} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log(ownProps.fieldIdentifier);
  var myCurrentVal =
    ownProps.textValue || getObjProp(state.pwReducer, ownProps.fieldIdentifier);
  var respVal = moment(myCurrentVal);
  return {
    textValue: respVal,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    textChanged: (payload) => {
      if (
        ownProps.updateAction === actions.cw.UPREDUXCUSTOMINPUT ||
        !ownProps.updateAction
      ) {
        var newPayload = {};
        newPayload.value = payload;
        newPayload.fieldIdentifier = ownProps.fieldIdentifier;
        dispatch(
          raisers.createActionGeneric(newPayload, actions.cw.UPREDUXCUSTOMINPUT)
        );
      } else {
        dispatch(raisers.createActionGeneric(payload, ownProps.updateAction));
      }
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ReduxCustomDateTime));
