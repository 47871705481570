import * as actions from "actions/projectActionTypes";
import axios from "axios";
import { APIURL } from "variables/project";

export function createSuccessAction(json) {
  return { type: actions.cw.NOTI_SHOW, payload: json };
}

export default function genericRespHandler(json, dispatch) {
  if (json.func_status != 1) {
    // alert(json.func_status);
  } else {
    if (json.newRecordID) {
      dispatch(createSuccessAction(json));
    }
  }
}
