import React from "react";
import { FormattedMessage } from "react-intl";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CreateServiceStep1 from "./CreateServiceStep1.jsx";
import CreateServiceStep2 from "./CreateServiceStep2.jsx";
import CreateServiceStep4 from "./CreateServiceStep4.jsx";
import ComingSoonStep from "./ComingSoonStep.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
//icon import
import Option from "@material-ui/icons/MoreHoriz";
import Price from "@material-ui/icons/LocalOffer";
import LocationOn from "@material-ui/icons/LocationOn";
import PrePaymentRequiredSelect from "components/CoreWeb/PrePaymentRequiredSelect";
import final from "@material-ui/icons/VerifiedUser";
import MandatoryStaffSelect from "components/CoreWeb/MandatoryStaffSelect";
import OptionalStaffSelect from "components/CoreWeb/OptionalStaffSelect";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Email from "@material-ui/icons/Email";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// core components
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ReferralSources from "components/CoreWeb/ReferralSources";
import { getActiveOrg } from "variables/coreWeb.jsx";
import * as makeApiCall from "actions/makeApiCall";
import Button from "components/CustomButtons/Button.jsx";
import * as actions from "actions/projectActionTypes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PricingName from "./PricingName";
import SelectIsActive from "./SelectIsActive";
import { createServiceFulfilmentConfigComplete } from "variables/coreRequests.jsx";
import { generateResourceSfcMapRows } from "variables/coreWeb.jsx";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";

import MandatoryResourcesSelect from "components/CoreWeb/MandatoryResourcesSelect";
import OptionalResourcesSelect from "components/CoreWeb/OptionalResourcesSelect";
import ReactTable from "react-table";
import { Visibility } from "@material-ui/icons";
import supervisorAccount from "./img/SupervisorAccount.svg"
import visibility from "./img/Visibility.svg"

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

const getTabs = function (props) {
  var resp = [];
  const { classes } = props;
  resp.push({
    tabButton: <FormattedMessage id="manageStaff" />,
    tabIcon: supervisorAccount,
    tabContent: (
      <div className="sl-pd-22">
        <Card>
          <CardHeader>
            <h3 className="myhead nosp">
              <FormattedMessage id="staffMembers" />
            </h3>
            <FormattedMessage id="mandatoryStaff" /> -{" "}
            <small>
              <FormattedMessage id="mandatoryStaffService" />
            </small>
            <br />
            <FormattedMessage id="optionalstaff" /> -{" "}
            <small>
              <FormattedMessage id="optionalStaffService" />
            </small>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} lg={4}>
                <MandatoryStaffSelect
                  stop={props.mandatoryStaffRemainingCount}
                  disabled={props.disabled}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4} lg={4}>
                <OptionalStaffSelect
                  stop={props.optionalStaffRemainingCount}
                  disabled={props.disabled}
                />
              </GridItem>
            </GridContainer>
            <ReactTable
              data={props.staffTableData}
              filterable
              columns={[
                {
                  Header: <FormattedMessage id="requirementType" />,
                  accessor: "reqType",
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: <FormattedMessage id="staffMember" />,
                  accessor: "resourceSelect",
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: <FormattedMessage id="durationMinutes" />,
                  accessor: "durationSelect",
                  sortable: false,
                  filterable: false,
                },
                /*{
                  Header: <FormattedMessage id="salesPrice"></FormattedMessage>,
                  accessor: "salesPrice",
                  sortable: false,
                  filterable: false
                },*/
                {
                  Header: <FormattedMessage id="actions" />,
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                },
              ]}
              defaultPageSize={10}
              showPaginationTop={false}
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </div>
    ),
  });

  var obj = {
    tabButton: <FormattedMessage id="medicalRecord" />,
    tabIcon: visibility,
    tabContent: (
      <Card>
        <CardBody>
          <h4>
            <FormattedMessage id="resources" />
          </h4>
          <FormattedMessage id="mandatoryResources" /> -{" "}
          <small>
            <FormattedMessage id="mandatoryResourcesService" />
          </small>
          <br />
          <FormattedMessage id="optionalResources" /> -{" "}
          <small>
            <FormattedMessage id="optionalResourcesService" />
          </small>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <MandatoryResourcesSelect
                stop={props.mandatoryResourcesRemainingCount}
                disabled={props.disabled}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <OptionalResourcesSelect
                stop={props.optionalResourcesRemainingCount}
                disabled={props.disabled}
              />
            </GridItem>
          </GridContainer>
          <ReactTable
            data={props.resourceTableData}
            filterable
            columns={[
              {
                Header: <FormattedMessage id="requirementType" />,
                accessor: "reqType",
                sortable: false,
                filterable: false,
              },
              {
                Header: <FormattedMessage id="resourceName" />,
                accessor: "resourceSelect",
                sortable: false,
                filterable: false,
              },
              {
                Header: <FormattedMessage id="durationMinutes" />,
                accessor: "durationSelect",
                sortable: false,
                filterable: false,
              },
              /*{
      Header: <FormattedMessage id="salesPrice"></FormattedMessage>,
      accessor: "salesPrice",
      sortable: false,
      filterable: false
    },*/
              {
                Header: <FormattedMessage id="actions" />,
                accessor: "actions",
                sortable: false,
                filterable: false,
              },
            ]}
            defaultPageSize={10}
            showPaginationTop={false}
            showPaginationBottom={false}
            className="-striped -highlight"
          />
        </CardBody>
      </Card>
    ),
  };

  return resp;
};

class ServiceFulfilmentConfigResourceMappingView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullFilmentConfigs: [],
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <CoreApiReq
              endpointToCall={"Read_All_Organisation_Resources"}
              afterActionHandler={actions.cw.GOT_ALL_ORG_RESOURCES}
              data={{
                orgId: getActiveOrg(),
              }}
            />
            <CoreApiReq
              endpointToCall={"Read_All_Organisation_Users"}
              afterActionHandler={actions.cw.GOT_ALL_ORG_USERS}
              data={{
                orgId: getActiveOrg(),
              }}
            />

            <NavPills color="rose" alignCenter tabs={getTabs(this.props)} />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

function getAlreadySelected(stateLocation, prefixKey, maxCount) {
  var resp = [];
  for (var i = 0; i < maxCount; i++) {
    for (var l = 0; l < prefixKey.length; ++l) {
      var value = stateLocation[prefixKey[l] + "" + i.toString()];
      if (value) {
        resp.push(value);
      }
    }
  }
  return resp;
}

function resourceShortName(obj) {
  return obj ? obj.resourceShortName : "";
}

function resourceId(obj) {
  return obj ? obj.resourceId : -1;
}

function contactUserName(obj) {
  return obj && obj.contactList ? obj.contactList[0].contactFullName : "";
}

function mapStateToProps(state, ownProps) {
  var currentResourceList = state.pwReducer.resourceCompleteList || [];
  var currentStaffList = state.pwReducer.userList || [];
  var currentlySelectedResources = getAlreadySelected(
    state.pwReducer,
    ["mandatoryResConfigResourceSelect", "optionalResConfigResourceSelect"],
    state.pwReducer.mandatoryResources
  );
  var currentlySelectedStaff = getAlreadySelected(
    state.pwReducer,
    ["mandatoryStaffConfigResourceSelect", "optionalStaffConfigResourceSelect"],
    state.pwReducer.mandatoryStaff
  );

  return {
    resourceCompleteList: currentResourceList,
    mandatoryResources: state.pwReducer.mandatoryResources,
    optionalResources: state.pwReducer.optionalResources,
    mandatoryStaff: state.pwReducer.mandatoryStaff,
    optionalStaff: state.pwReducer.optionalStaff,
    resourceTableData: state.pwReducer.resourceCompleteListOptions
      ? generateResourceSfcMapRows(
          state.pwReducer.mandatoryResources,
          "mandatoryResConfig",
          currentResourceList,
          true,
          currentlySelectedResources,
          state.pwReducer,
          resourceShortName,
          resourceId,
          ownProps.disabled
        ).concat(
          generateResourceSfcMapRows(
            state.pwReducer.optionalResources,
            "optionalResConfig",
            currentResourceList,
            false,
            currentlySelectedResources,
            state.pwReducer,
            resourceShortName,
            resourceId,
            ownProps.disabled
          )
        )
      : [],
    staffTableData: generateResourceSfcMapRows(
      state.pwReducer.mandatoryStaff,
      "mandatoryStaffConfig",
      currentStaffList,
      true,
      currentlySelectedStaff,
      state.pwReducer,
      contactUserName,
      resourceId,
      ownProps.disabled
    ).concat(
      generateResourceSfcMapRows(
        state.pwReducer.optionalStaff,
        "optionalStaffConfig",
        currentStaffList,
        false,
        currentlySelectedStaff,
        state.pwReducer,
        contactUserName,
        resourceId,
        ownProps.disabled
      )
    ),
    mandatoryResourcesRemainingCount:
      currentResourceList.length - (state.pwReducer.optionalResources || 0),
    optionalResourcesRemainingCount:
      currentResourceList.length - (state.pwReducer.mandatoryResources || 0),
    mandatoryStaffRemainingCount:
      currentStaffList.length - (state.pwReducer.optionalStaff || 0),
    optionalStaffRemainingCount:
      currentStaffList.length - (state.pwReducer.mandatoryStaff || 0),
  };
}

function mapDispatchToProps() {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ServiceFulfilmentConfigResourceMappingView));
