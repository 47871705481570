import React from "react";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import ViewProduct from "components/CoreWeb/ViewProduct.jsx";
import CreateServiceStep2 from "components/CoreWeb/CreateServiceStep2.jsx";
import CreateServiceStep4 from "components/CoreWeb/CreateServiceStep4.jsx";
import ComingSoonStep from "components/CoreWeb/ComingSoonStep.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

class CreateProduct extends React.Component {
  render() {
    return (
      <div>
        <ViewProduct />
      </div>
    );
  }
}

export default CreateProduct;
