const cw = {
  dashboard: "/dashboard",
  orgList: "/app/profile/org-profiles",
  orgView: "/app/profile/organisation-profile",
  userProfile: "/app/profile/user-profile",
  serviceFulfilmentConfigView: "/app/campaign/editFulfilmentConfig",
  createOrganisation: "/app/profile/organisation-profile",
  customerView: "/app/customers/view",
  customerCreate: "/app/customers/createnew",
  customerCreateWithPatient: "/app/customers/createnewWithPatient",
  customerLinkPatient: "/app/customers/link/patient",
  customerInvoices: "/app/customers/currentInvoices",
  animalView: "/app/patients/view",
  //animals: "/app/patients",
  animalCreate: "/app/patients/createnew",
  animalCreateWithContact: "/app/patients/createnewWithContact",
  animalLinkCustomer: "/app/patients/link/customer",
  //resources: "/app/resources",
  resourceView: "/app/resources/view",
  resourceCreate: "/app/resources/createnew",
  productView: "/app/inventory/view",
  productCreate: "/app/inventory/createNew",
  productInventory: "/app/inventory",
  //services: "/app/services",
  serviceView: "/app/services/view",
  serviceCreate: "/app/services/createnewService",
  staffView: "/app/contact/view",
  customerList: "/app/customers/existing",
  animalList: "/app/patients/existing",
  serviceList: "/app/services/existingServices",
  productList: "/app/inventory/products",
  resourceList: "/app/settings/existing",
  memberList: "/app/personell/existing",
  memberCreate: "/app/personell/createnew",
  memberExport: "/app/personell/dataExport",
  pricingView: "/app/pricing/view",
  pricingCreate: "/app/pricing/create",
  medicalRecordEntry: "/app/patient/medicalRecordEntry",
  addressDetails: "/app/contact/addressDetails",
  addressDetailsView: "/app/contact/addressDetails/view",
  phoneNumberList: "/app/contact/phoneNumber",
  phoneNumberCreate: "/app/contact/phoneNumber/createnew",
  orgSignIn: "/app/profile/org-signin",
  scheduleAppointment: "/app/schedule",
  scheduleAppointmentCreate: "/app/schedule/createAppointment",
  scheduleAppointmentList: "/app/schedule/appointments",
  calendar: "/app/schedule/calendar",
  invoiceList: "/app/invoices",
};
const pw = {
  ...cw,
  campaignNew: "/campaign/wizard",
  campaignView: "/campaign/wizard",
  makeApplication: "/campaign/makeApplication",
};

var paths = { ...pw };
export { paths };
