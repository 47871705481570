import React from "react";
import { FormattedMessage } from "react-intl";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CreateServiceStep1 from "./CreateServiceStep1.jsx";
import CreateServiceStep2 from "./CreateServiceStep2.jsx";
import CreateServiceStep4 from "./CreateServiceStep4.jsx";
import ComingSoonStep from "./ComingSoonStep.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//icon import
import Option from "@material-ui/icons/MoreHoriz";
import Price from "@material-ui/icons/LocalOffer";
import LocationOn from "@material-ui/icons/LocationOn";
import PrePaymentRequiredSelect from "components/CoreWeb/PrePaymentRequiredSelect";
import final from "@material-ui/icons/VerifiedUser";
import MandatoryStaffSelect from "components/CoreWeb/MandatoryStaffSelect";
import OptionalStaffSelect from "components/CoreWeb/OptionalStaffSelect";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Email from "@material-ui/icons/Email";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// core components
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ReferralSources from "components/CoreWeb/ReferralSources";
import { getActiveOrg } from "variables/coreWeb.jsx";
import * as makeApiCall from "actions/makeApiCall";
import Button from "components/CustomButtons/Button.jsx";
import * as actions from "actions/projectActionTypes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PricingName from "./PricingName";
import SelectIsActive from "./SelectIsActive";
import {
  createServiceFulfilmentConfigComplete,
  updateServiceFulfilmentConfigComplete,
} from "variables/coreRequests.jsx";
import * as raisers from "actions/projectRaisers";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput.jsx";
import ServiceFulfilmentConfigResourceMappingView from "components/CoreWeb/ServiceFulfilmentConfigResourceMappingView";
import MandatoryResourcesSelect from "components/CoreWeb/MandatoryResourcesSelect";
import OptionalResourcesSelect from "components/CoreWeb/OptionalResourcesSelect";
import ReactTable from "react-table";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class PricingView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullFilmentConfigs: [],
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div className="sl-pd-22">
        {console.log("MATCH:" + JSON.stringify(this.props.match.params))}
        {console.log("IsCreate:" + JSON.stringify(this.props.isCreate))}
        {this.props.match.params.serviceFulfilmentConfigId ? (
          <CoreApiReq
            endpointToCall={"Read_Org_ServiceFulfilmentConfig_Complete"}
            afterActionHandler={actions.cw.GOT_SERVICEFULFILMENT_COMPLETE}
            data={{
              orgId: getActiveOrg(),
              serviceFulfilmentConfigId:
                this.props.match.params.serviceFulfilmentConfigId,
            }}
          />
        ) : (
          ""
        )}
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader>
                <h3 className="myhead nosp">
                  <FormattedMessage id="pricingDetails" />
                </h3>
              </CardHeader>
              <CardBody className="mke-sp">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6} lg={6} className="arr">
                    <PricingName
                      mainTextLabel={<FormattedMessage id="pricingName" />}
                      disabled={!this.props.isCreate}
                      fieldIdentifier={"serviceFulfilmentConfig.name"}
                      className="m-12"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={6} className="arr">
                    <SelectIsActive
                      fieldIdentifier={"serviceFulfilmentConfig.isActive"}
                      placeholder={
                        <FormattedMessage id="pricingActiveInactive" />
                      }
                      className="m-12"
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} lg={6} className="arr">
                    <PrePaymentRequiredSelect
                      placeholder={
                        <div className="m-12">
                          <FormattedMessage id="prepaymentRequired" />
                        </div>
                      }
                      disabled={!this.props.isCreate}
                      fieldIdentifier={
                        "serviceFulfilmentConfig.prePaymentRequired"
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} className="arr">
                    <ReduxCustomInput
                      mainTextLabel="Price"
                      subTextLabel="(required)"
                      textValue={this.props.firstName}
                      updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                      fieldIdentifier={"serviceFulfilmentConfig.monetaryAmount"}
                      className="m-12"
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <ServiceFulfilmentConfigResourceMappingView
              disabled={!this.props.isCreate}
            />
            <GridContainer justify="center">
              <GridItem>
                {!this.props.isCreate ? (
                  <div>
                    {/*<Button color="danger">Delete Pricing</Button>*/}
                    <Button
                      color="rose"
                      onClick={() => {
                        updateServiceFulfilmentConfigComplete(
                          this.props.callApi,
                          {
                            ...this.props.pwReducer,
                            orgId: getActiveOrg(),
                            serviceId: this.props.serviceId,
                            name: this.props.pwReducer.pricingName,
                          }
                        );
                      }}
                    >
                      <FormattedMessage id="updatePricing" />
                    </Button>
                  </div>
                ) : (
                  ""
                )}
                {this.props.isCreate ? (
                  <Button
                    color="rose"
                    onClick={() => {
                      createServiceFulfilmentConfigComplete(
                        this.props.callApi,
                        {
                          ...this.props.pwReducer,
                          orgId: getActiveOrg(),
                          serviceId: this.props.serviceId,
                          name: this.props.pwReducer.pricingName,
                        }
                      );
                    }}
                  >
                    <FormattedMessage id="createPricing" />
                  </Button>
                ) : (
                  ""
                )}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    serviceId: ownProps.match.params.serviceId,
    pwReducer: state.pwReducer,
    serviceFulfilmentConfigId: ownProps.match.params.serviceFulfilmentConfigId,
    isCreate: ownProps.match.params.serviceFulfilmentConfigId === undefined,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    raiseAction: (payload) => {
      dispatch(raisers.createActionGeneric(payload, actions.cw.SET_STATE_VAL));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(PricingView));
