import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { FormattedMessage } from "react-intl";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Event from "@material-ui/icons/Event";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Visibility from "@material-ui/icons/Visibility";
import EventBusy from "@material-ui/icons/EventBusy";
import { orderStatusFromType } from "variables/coreWeb.jsx";
import { dataTable5 } from "variables/general.jsx";
import { labelFromType } from "variables/project.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

class AppointmentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    const fillButtons = [
      { color: "info", icon: Visibility },
      { color: "danger", icon: Close },
      { color: "success", icon: EventBusy },
    ].map((prop, key) => {
      return (
        <Button color={prop.color} className={classes.actionButton} key={key}>
          <prop.icon className={classes.icon} />
        </Button>
      );
    });

    return (
      <GridContainer justify={"center"}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Event />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage id="appointmentList" />
                <small>
                  {" "}
                  <FormattedMessage id="existingAppointments" />{" "}
                </small>
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={dataTable5.dataRows.map((prop, key) => {
                  return {
                    id: key,
                    name: prop[0],
                    position: labelFromType(prop[1]),
                    status: orderStatusFromType(prop[2]),
                    age: prop[3],
                    actions: fillButtons,
                    workingFor: prop[2] != 1 ? "????" : "Coca Cola",
                    totalValue: "01-01-2019 09:00",
                    endData: "01-01-2019 09:30",
                    duration: "30 mins",
                    patient: prop[2] != 1 ? "????" : "Timmy",
                  };
                })}
                filterable
                columns={[
                  {
                    Header: <FormattedMessage id="Type" />,
                    accessor: "name",
                  },
                  {
                    Header: <FormattedMessage id="customerName" />,
                    accessor: "workingFor",
                  },
                  {
                    Header: <FormattedMessage id="patientName" />,
                    accessor: "patient",
                  },
                  {
                    Header: <FormattedMessage id="startDate" />,
                    accessor: "totalValue",
                  },
                  {
                    Header: <FormattedMessage id="endDate" />,
                    accessor: "endDate",
                  },
                  {
                    Header: <FormattedMessage id="duration" />,
                    accessor: "duration",
                  },
                  {
                    Header: <FormattedMessage id="Status" />,
                    accessor: "status",
                  },

                  {
                    Header: <FormattedMessage id="actions" />,
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(userProfileStyles)(AppointmentList);
