import React from "react";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput.jsx";
import * as actions from "actions/projectActionTypes";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { uploadFile } from "variables/coreRequests.jsx";
import ReferralSources from "components/CoreWeb/ReferralSources";
import AnimalPictureUpload from "components/CustomUpload/AnimalPictureUpload.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { FormattedMessage } from "react-intl";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class CreateAnimalStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animalName: "",
      primaryCarer: "",
      fileSelected: null,
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <AnimalPictureUpload
          imagePreviewUrl={this.props.imagePreviewUrl}
          handleImageChange={(file) => {
            this.setState({ fileSelected: file });
          }}
        />
        {this.state.fileSelected ? (
          <GridContainer justify="center">
            <GridItem>
              <Button
                onClick={() => {
                  uploadFile(
                    [this.state.fileSelected],
                    [this.props.orgId, this.props.animalId],
                    "UploadAnimalImage",
                    this.props.dispatch
                  );
                }}
                color="rose"
              >
                <FormattedMessage id="upload" />
              </Button>
            </GridItem>
          </GridContainer>
        ) : (
          ""
        )}

        {/*<h4>Marketing</h4>
        <Card>
          <CardBody>
            <h4>Customer Acquisition Source</h4>
            <ReferralSources />
          </CardBody>
        </Card>*/}
      </div>
    );
  }
}

export default withStyles(style)(CreateAnimalStep1);
