import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";
import { paths } from "variables/projPaths.jsx";
// core components
import HeaderLinks from "./HeaderLinks";
import Button from "components/CustomButtons/Button.jsx";
import headerStyle from "assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { APIURL } from "variables/project";
import { AUTH_CONFIG } from "Auth/auth0-variables";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getActiveOrg,
  getISODateTime,
  calcDurationDiff,
  resetActiveOrg,
} from "variables/coreWeb.jsx";
import { checkForActiveOrg } from "variables/coreWeb";
import { useHistory } from "react-router-dom";
import bell from "assets/img/svg/Notification.svg";
// import menu from "assets/img/svg/list.png";
import adminimg from "assets/img/placeholder.jpg";
import vieweye from "assets/img/view.svg";
import SearchBar from "components/SearchBar/SearchBar";

const Header = ({ ...props }) => {
  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
    isLoading,
  } = useAuth0();

  const [token, setToken] = useState(null);
  const location = useLocation();
  const [activeOrg, setActiveOrg] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const registrationType = useSelector(
    (state) => state.pwReducer.selfProfile.registrationType
  );

  useEffect(
    () => {
      const fn = async () => {
        var tokenVal = await getAccessTokenSilently({
          audience: AUTH_CONFIG.audience,
        });
        setToken(tokenVal);
        localStorage.setItem("access_token", tokenVal);
      };
      fn();
    },
    [!isLoading && isAuthenticated]
  );

  useEffect(
    () => {
      if (isAuthenticated && !isLoading && registrationType === 4) {
        checkForActiveOrg(location, history, setActiveOrg);
      }
    },
    [registrationType, location.pathname, isAuthenticated, !isLoading, token]
  );

  useEffect(
    () => {
      if (token != null && !isLoading) {
        fetch(APIURL + "Read_Self_Profile", {
          // content-type header should not be specified!
          method: "POST",
          body: JSON.stringify({}),
          headers: {
            Authorization: `Bearer ` + token,
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            dispatch({
              type: "GOT_SELFPROFILE",
              payload: response,
            });
          })
          .catch((error) => console.log(error));
      }
    },

    [isAuthenticated, !isLoading && token]
  );
  function makeBrand() {
    var name;
    props.routes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      }
      if (prop.path === props.location.pathname) {
        name = prop.name;
      }
      return null;
    });
    if (name) {
      return name;
    } else {
      return "VetGeo";
    }
  }
  const { classes, color, rtlActive, handleToggle } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    });
  const currentName = useSelector(
    (state) => state.pwReducer.selfProfile.contactFullName
  );
  const currentOrgName = useSelector((state) => state.pwReducer.currentOrgName);

  return (
    <AppBar className="headerown">
      <div className="self-header">
        <div className="mobile-menu" onClick={handleToggle}>
          <Menu />
        </div>
        {/* <h1 className="h3 mb-0" /> */}
        <SearchBar />
        <div className="area-icn">
          <span>
            <img src={adminimg} />
          </span>
          <Link to={paths.userProfile} className="profile-link">
            <div className="inf">
              <h4>{currentName}</h4>
              <p>{currentOrgName}</p>
            </div>
          </Link>
        </div>
        <div className="bell-icn">
          <img src={bell} />
        </div>
      </div>
    </AppBar>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleToggle: PropTypes.func,
};

export default withStyles(headerStyle)(Header);
