import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import ReduxCoreSelect from "components/CoreWeb/ReduxCoreSelect";
import * as actions from "actions/projectActionTypes";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import selectOptions from "./data/MedicalNoteOptions";
import { connect } from "react-redux";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  ...userProfileStyles,
};

class MedicalNoteType extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;

    return (
      <div>
        <h4>Medical Note Type</h4>
        <ReduxCoreSelect
          options={this.props.selectOptions}
          fieldIdentifier={this.props.fieldIdentifier || "selectNoteType"}
          updateAction={actions.cw.UPREDUXSELECTINPUT}
          selectValue={this.props.selectValue}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    selectOptions: (function() {
      var rows = [];
      for (var i = 0; i < selectOptions.length; i++) {
        // note: we add a key prop here to allow react to uniquely identify each
        // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
        rows.push(
          <MenuItem value={selectOptions[i].value} key={i}>
            {selectOptions[i].name}
          </MenuItem>
        );
      }
      return rows;
    })(),
    genderSelection:
      state.pwReducer[ownProps.fieldIdentifier || "selectNoteType"],
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(userProfileStyles)(MedicalNoteType));
