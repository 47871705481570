import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Pets from "@material-ui/icons/Pets";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Visibility from "@material-ui/icons/Visibility";
import Clear from "@material-ui/icons/Clear";

import CardAvatar from "components/Card/CardAvatar.jsx";

import { getActiveOrg } from "variables/coreWeb.jsx";
import { enumAnimalSpeciesToString } from "variables/coreWeb.jsx";
import * as makeApiCall from "actions/makeApiCall";
import image from "assets/img/default-dog.png";
import { paths } from "variables/projPaths.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import * as raisers from "actions/projectRaisers";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deletePatient } from "../../variables/coreRequests";
import { deleteAnimalWithDispatch } from "../../variables/thunks";
import { FormattedMessage } from "react-intl";
import AnimalList from "./AnimalList";

const style = {
  ...userProfileStyles,
  ...sweetAlertStyle,
};

class AnimalListView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <AnimalList hideLinkToContact={true} />;
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(AnimalListView));
