import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { connect } from "react-redux";

import {
  getObjProp,
  defaultChangeReduxCoreSelect,
} from "variables/coreWeb.jsx";

export function CheckboxesTags(props) {
  const [inputValue, setInputValue] = useState("");
  const defaultProps = {
    options: setCount,
    getOptionLabel: (option) => option.title,
  };

  const flatProps = {
    options: setCount.map((option) => option.title),
  };
  return (
    <Autocomplete
        {...defaultProps}
        id="checkboxes-tags-demo"
        className="Checkboxestags"
        debug
        options={setCount}
        onInputChange={(event, newInputValue) => {
            props.changeEvent(newInputValue)               
          }}
  
        // renderInput={(params) => <TextField {...params} label="Pulse Measurement" margin="normal" />}
        renderInput={(params) => (
            <TextField
              {...params}
              onChange={(event) => 
              props.changeEvent(
                props.isArrayVal
                  ? [event.target.value]
                  : event.target.value
              )}
            value='medicalRecord.pulseMeasurement'
            // disabled: props.disabled,
              label="Pulse Measurement"
            />
          )}
    />
  );
}

let count = Array.from({ length: 200 }, (_, idx) => `${++idx}`);
let setCount = count.map((res) => {
  return {
    title: res
  };
});


const mapStateToProps = (state, ownProps) => {
    var valsToChoseFrom = getObjProp(state.pwReducer, ownProps.fieldIdentifier);
    var foundValue =
      ownProps.selectValue == 0
        ? 0
        : ownProps.selectValue || ownProps.isArrayVal
        ? valsToChoseFrom && valsToChoseFrom.length > 0
          ? valsToChoseFrom[0]
          : undefined
        : getObjProp(state.pwReducer, ownProps.fieldIdentifier);
    return {
      selectValue: foundValue,
    };
  };
  
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      changeEvent: (payload) => {
        defaultChangeReduxCoreSelect(dispatch, {fieldIdentifier: "medicalRecord.pulseMeasurement"}, payload)
      }
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )((CheckboxesTags));
  