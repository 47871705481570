import React, { useState, useEffect } from "react";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { bindActionCreators } from "redux";
import CreateOrgClosedStep1 from "./WizardSteps/CreateOrgClosedStep1.jsx";
import ComingSoonStep from "../../components/CoreWeb/ComingSoonStep.jsx";
import PrimaryContactOptions from "components/CoreWeb/PrimaryContactOptions";
import { FormattedMessage } from "react-intl";
import {
  createCustomer,
  createPhoneNumber,
} from "variables/coreCreateRequests";
import { getActiveOrg } from "variables/coreWeb.jsx";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import * as makeApiCall from "actions/makeApiCall";
import { useHistory } from "react-router-dom";
import { createPhoneNumberWithDispatch } from "variables/thunks.jsx";
import { useSelector, useDispatch } from "react-redux";
import CreatePhoneNumberWizard from "./CreatePhoneNumberWizard.jsx";

const CreatePhoneNumber = function (props) {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12}>
        <CreatePhoneNumberWizard props={props} />
      </GridItem>
    </GridContainer>
  );
};

function mapStateToProps(state) {
  return {
    pwReducer: state.pwReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles({})(CreatePhoneNumberWizard));
