import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Visibility from "@material-ui/icons/Visibility";
import Clear from "@material-ui/icons/Clear";
import CardAvatar from "components/Card/CardAvatar.jsx";

import { getActiveOrg } from "variables/coreWeb.jsx";
import { paths } from "variables/projPaths.jsx";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import * as makeApiCall from "actions/makeApiCall";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import defaultImage from "assets/img/default-avatar.png";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import vieweye from "assets/img/view.svg";
import deleicon from "assets/img/delete.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import * as raisers from "actions/projectRaisers";
import { UPLOADSPATH } from "variables/project.jsx";
import { Link } from "react-router-dom";

const style = {
  ...userProfileStyles,
  ...sweetAlertStyle,
};

const customerImage = function(prop) {
  return (
    <CardAvatar cardAvatarProfileNormal className="bdr-img">
      <a href="#pablo" onClick={(e) => e.preventDefault()}>
        <img
          src={
            prop.cloudFileUrl ? UPLOADSPATH + prop.cloudFileUrl : defaultImage
          }
          alt="..."
          style={{
            height: "50px",
            width: "50px",
          }}
        />
      </a>
    </CardAvatar>
  );
};

// const deleteContact = function(callMe, contactId) {
//   var payLoad = {};
//   payLoad.contactId = contactId;
//   payLoad.orgId = getActiveOrg();

//   callMe.req(
//     localStorage.getItem("access_token"),
//     "Delete_Contact",
//     payLoad,
//     actions.cw.API_CONTACT_DELETE
//   );
// };

class CustomerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDelete: 0,
      isButtonClick: false,
      selectCustomerData: {},
    };
  }

  render() {
    const { classes } = this.props;
    const fillButtons = function(theProps, customerId) {
      return (
        <div>
          <Link to={paths.customerView + "/" + customerId} className="cur">
            <button className="sm selfview">
              <img src={vieweye} />
            </button>
          </Link>
          <button
            onClick={() => {
              theProps.raiseAction({
                fieldIdentifier: "selectedCustomerId",
                value: customerId,
              });
              theProps.raiseAction({
                fieldIdentifier: "linkedAnimalId",
                value: theProps.animalId,
              });
            }}
            className="sm danger cur"
          >
            <img src={deleicon} />
          </button>
          {/* <Button
            className={classes.actionButton}
            color="danger"
            size="sm"
            onClick={() => {
              theProps.raiseAction({
                fieldIdentifier: "selectedCustomerId",
                value: customerId,
              });
              theProps.raiseAction({
                fieldIdentifier: "linkedAnimalId",
                value: theProps.animalId,
              });
            }}
          >
            <Clear className={classes.icon} />
          </Button>
          <Link to={paths.customerView + "/" + customerId}>
            <Button className={classes.actionButton} color="info" size="sm">
              <Visibility className={classes.icon} />
            </Button>
          </Link> */}
        </div>
      );
    };
    return (
      <GridContainer justify={"center"} className="sl-pd-22 table-container">
        {!this.props.customers ? (
          <CoreApiReq
            endpointToCall={"Read_All_Organisation_Contacts"}
            afterActionHandler={actions.cw.GOT_ALL_ORG_CONTACTS_BY_ORG_ID}
            data={{
              orgId: getActiveOrg(),
            }}
          />
        ) : (
          ""
        )}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Link
            to={
              this.props.animalId
                ? "/app/customers/createnewWithPatient/" + this.props.animalId
                : "/app/customers/createnew"
            }
          >
            <Button className="btn-primary add-Customer btn-theme">
              + Add Customer
            </Button>
          </Link>

          {!this.props.hideLinkToCustomer ? (
            <Link to={"/app/customers/link/patient/" + this.props.animalId}>
              <Button color={"rose"} className="btn-theme">
                Link Existing Customer
              </Button>
            </Link>
          ) : (
            ""
          )}
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            {!this.props.hideHeader ? (
              <CardHeader className="in">
                <h3 className="myhead nosp">Your Customers </h3>
                <p>Existing customers</p>
              </CardHeader>
            ) : (
              ""
            )}
            <CardBody className="customers-table">
              <ReactTable
                data={this.props.contactList
                  .filter((x) => {
                    return x.contactType == 3;
                  })
                  .map((prop, key) => {
                    return {
                      id: key,
                      customerData: prop,
                      name: prop.contactFullName,
                      image: customerImage(prop),
                      email: prop.emailAddress,
                      actions: fillButtons(this.props, prop.contactId),
                      contactId: prop.contactId,
                    };
                  })}
                filterable={false}
                columns={[
                  {
                    Header: "Client Id",
                    accessor: "contactId",
                    headerClassName: "table-header-center",
                  },
                  {
                    Header: "Client Image",
                    accessor: "image",
                    headerClassName: "table-header-center",
                  },
                  {
                    Header: "Client Name",
                    accessor: "name",
                    headerClassName: "table-header-center",
                  },

                  {
                    Header: "Email Address",
                    accessor: "email",
                    headerClassName: "table-header-center",
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    headerClassName: "table-header-center",
                    Cell: (content) => (
                      <div className="text-left">{content.value}</div>
                    ),
                  },
                ]}
                // get selectedIndexIndex row data
                getTrProps={(state, rowInfo) => {
                  if (rowInfo && rowInfo.row) {
                    console.log("Row Info ", rowInfo);
                    return {
                      onClick: (e) => {
                        this.setState(
                          {
                            selectCustomerData:
                              rowInfo.original["customerData"],
                          },
                          () => {
                            // check if actions(delete/update) button clicked
                            if (this.state.isButtonClick) {
                              this.UpdateDeleteConfig(this.props.callApi);
                            }
                          }
                        );

                        //console.log();

                        // window.location.href =
                        //   paths.customerView + "/" + rowInfo.row.contactId;
                      },
                    };
                  } else {
                    return {};
                  }
                }}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    contactList: ownProps.customers || state.pwReducer.contactList || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    dispatch: dispatch,
    raiseAction: (payload) => {
      dispatch(raisers.createActionGeneric(payload, actions.cw.SET_STATE_VAL));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(CustomerList));
