import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput";
import SearchResult from "components/SearchResult/SearchResult";
import { getActiveOrg } from "variables/coreWeb.jsx";
import { getSearchResult } from "../../variables/thunks";

import withStyles from "@material-ui/core/styles/withStyles";
import headerStyle from "assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as makeApiCall from "actions/makeApiCall";
import * as actions from "actions/projectActionTypes";

const SearchBar = (props) => {
  const [isSearchSuggestionsOpen, setSearchSuggestionsOpen] = useState(false);

  const searchKeyWord = useSelector((state) => state.pwReducer.searchKeyWord);
  const searchResultData = useSelector((state) => state.pwReducer.searchResult);

  console.log("Search Result", searchResultData);

  const closeSuggestionBoxHandler = useCallback(() => {
    setSearchSuggestionsOpen(false);
  }, []);

  const getSearchSuggestions = useCallback(
    () => {
      if (searchKeyWord && searchKeyWord.trim() !== "") {
        props.dispatch(getSearchResult(getActiveOrg(), searchKeyWord));
        setSearchSuggestionsOpen(true);
      } else {
        setSearchSuggestionsOpen(false);
      }
    },
    [searchKeyWord]
  );

  useEffect(
    () => {
      getSearchSuggestions();
    },
    [searchKeyWord, getSearchSuggestions]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    console.log("Clicked outside target", event.target);
    console.log("Clicked outside", event.target.id);
    if (
      !event.target.classList.contains("search-suggestion-box") &&
      event.target.id !== "searchInputFld"
    ) {
      setSearchSuggestionsOpen(false);
    }

    if (event.target.id === "searchInputFld") {
      getSearchSuggestions();
    }
  };

  return (
    <div className="search-bar-container">
      <div className={"Create-input-box search-box"}>
        <ReduxCustomInput
          fieldIdentifier={"searchKeyWord"}
          updateAction={actions.cw.UPREDUXCUSTOMINPUT}
          placeholder="Search"
          textValue={searchKeyWord}
          id="searchInputFld"
          inputProps={{
            multiline: false,
            placeholder: "Search",
            autoComplete: "off",
            //placeholder: <FormattedMessage id="search" />,
          }}
        />
      </div>
      {isSearchSuggestionsOpen ? (
        <SearchResult
          result={searchResultData}
          onCloseSuggestionBox={closeSuggestionBoxHandler}
        />
      ) : (
        ""
      )}
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    dispatch: dispatch,
  };
}

export default connect(mapDispatchToProps)(withStyles(headerStyle)(SearchBar));
