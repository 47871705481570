import React from "react";

import defaultImage from "assets/img/default-avatar.png";

class ContactPictureUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: null,
      defaultImage:defaultImage,
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    if (this.props.handleImageChange) {
      this.props.handleImageChange(file);
    }
    reader.readAsDataURL(file);
  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  render() {
    return (
      <div className="picture-container text-left-k2">
        <div className="picture">
            {this.state.imagePreviewUrl ? 
                <img
                src={this.state.imagePreviewUrl}
                className="picture-src"
                alt="..."
              />
              :
                this.props.imagePreviewUrl ? 
                  <img
                  src={this.props.imagePreviewUrl}
                  className="picture-src"
                  alt="..."
                />
              :
                <img
                  src={this.state.defaultImage}
                  className="picture-src"
                  alt="..."
                />
            }
          <input type="file" onChange={(e) => this.handleImageChange(e)} />
        </div>
        <h6 className="description">Change Picture</h6>
        <div className="edit-icon">
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
            <path d="M21.2548 12.9148C20.7639 12.9148 20.3659 13.3128 20.3659 13.8037V22.6918C20.3659 23.1827 19.968 23.5806 19.4771 23.5806H3.47846C2.98756 23.5806 2.58963 23.1827 2.58963 22.6918V4.91547C2.58963 4.42458 2.98756 4.02664 3.47846 4.02664H14.1443C14.6352 4.02664 15.0331 3.6287 15.0331 3.13781C15.0331 2.64691 14.6352 2.24902 14.1443 2.24902H3.47846C2.00582 2.24902 0.812012 3.44283 0.812012 4.91547V22.6918C0.812012 24.1644 2.00582 25.3582 3.47846 25.3582H19.4772C20.9498 25.3582 22.1436 24.1644 22.1436 22.6918V13.8036C22.1436 13.3128 21.7457 12.9148 21.2548 12.9148Z" fill="white"/>
            <path d="M24.7028 1.4679C24.0646 0.829614 23.199 0.471101 22.2964 0.471205C21.3933 0.468602 20.5268 0.827739 19.8904 1.46847L8.18288 13.175C8.08575 13.2729 8.01248 13.3918 7.96868 13.5225L6.19106 18.8554C6.03592 19.3212 6.28772 19.8245 6.75346 19.9796C6.84382 20.0097 6.93845 20.025 7.03365 20.0251C7.12906 20.025 7.22389 20.0097 7.31451 19.9798L12.6474 18.2022C12.7784 18.1585 12.8974 18.0848 12.9949 17.9871L24.7024 6.27964C26.0312 4.951 26.0314 2.79669 24.7028 1.4679ZM23.4457 5.0237L11.891 16.5783L8.43885 17.7311L9.58808 14.2834L21.1471 2.72878C21.7826 2.09456 22.812 2.0956 23.4462 2.73107C23.7491 3.03464 23.9198 3.4456 23.9211 3.87447C23.9222 4.30563 23.751 4.71935 23.4457 5.0237Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0">
            <rect width="24.8872" height="24.8872" fill="white" transform="translate(0.812012 0.471191)"/>
            </clipPath>
            </defs>
          </svg>
        </div>

      </div>
    );
  }
}

export default ContactPictureUpload;
