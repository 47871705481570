import React, { useState, useEffect } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import { connect } from "react-redux";
import ReduxCardCustomInput from "components/CoreWeb/ReduxCardCustomInput";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ReduxCoreSelect from "components/CoreWeb/ReduxCoreSelect";
import ServiceSelect from "components/CoreWeb/ServiceSelect";
import CustomerSelect from "components/CoreWeb/CustomerSelect";
import ReduxCustomDateTime from "components/CoreWeb/ReduxCustomDateTime";
import CustomDateTime from "components/CoreWeb/CustomDateTime";
import AppointmentExceptionSelectType from "components/CoreWeb/AppointmentExceptionSelectType";
import { rows, getObjProp, APPOINTMENTTYPE } from "variables/coreWeb";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import { FormattedMessage } from "react-intl";
import { EXCEPTIONTYPE } from "../../variables/coreWeb";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import { getActiveOrg } from "variables/coreWeb.jsx";
import moment from "moment";
import {
  calendarExceptionColor,
  calendarAppointmentColor,
} from "variables/project";

const generateEvents = function(
  exStartTime,
  exEndTime,
  resourceCompleteList,
  selectedResourceIds,
  color,
  title
) {
  console.log("color: " + color);
  console.log("exstarttime" + exStartTime);
  console.log("exendtime" + exEndTime);
  var resp = (selectedResourceIds || []).map((prop, key) => {
    return {
      id: new Date().getTime(),
      title: title || "",
      start: new Date(exStartTime),
      end: new Date(exEndTime),
      resourceId: prop,
      color: color || "green",
    };
  });
  return resp;
};

const AppointmentExceptionCreateOptions = function(props) {
  const { calendarToUse } = props;
  const passedInEvents = props.events;
  const [updateTime, setUpdateTime] = useState(null);

  const [currentSfcs, setCurrentSfcs] = useState([]);
  const [serviceFulfilmentConfig, setServiceFulfilmentConfig] = useState(null);
  const [
    serviceFulfilmentConfigResourceMapList,
    setServiceFulfilmentConfigResourceMapList,
  ] = useState([]);
  const [sfcResIds, setSfcResIds] = useState(null);
  const [resourceCompleteList, setResourceCompleteList] = useState([]);
  const [resourceExViewList, setResourceExViewList] = useState([]);
  const [existingEvents, setExistingEvents] = useState([]);
  const [appointmentObj, setAppointmentObj] = useState(null);
  const [exceptionObj, setExceptionObj] = useState(null);
  const [appointmentEvents, setAppointmentEvents] = useState([]);
  const [previewEvents, setPreviewEvents] = useState([]);
  const [appointmentExceptionType, setAppointmentExceptionType] = useState(
    null
  );

  const pluck = (arr, key) => arr.map((o) => o[key]);

  useEffect(
    () => {
      setUpdateTime(new Date());
    },
    [calendarToUse.serviceSelect]
  );

  useEffect(
    () => {
      setUpdateTime(new Date());
    },
    [calendarToUse.serviceFulfilmentConfig]
  );

  useEffect(
    () => {
      setCurrentSfcs(
        calendarToUse &&
        calendarToUse.serviceSelect &&
        calendarToUse.serviceFulfilmentConfigCompleteList
          ? calendarToUse.serviceFulfilmentConfigCompleteList.filter(
              (x) => x.serviceId == calendarToUse.serviceSelect
            )
          : [] || []
      );
    },
    [
      calendarToUse,
      calendarToUse.serviceSelect,
      calendarToUse.serviceFulfilmentConfigCompleteList,
    ]
  );

  useEffect(
    () => {
      setServiceFulfilmentConfig(
        currentSfcs.find(
          (x) =>
            x.serviceFulfilmentConfigId ==
            calendarToUse.serviceFulfilmentConfigId
        )
      );
    },
    [currentSfcs]
  );

  useEffect(
    () => {
      setServiceFulfilmentConfigResourceMapList(
        serviceFulfilmentConfig
          ? serviceFulfilmentConfig.serviceFulfilmentConfigResourceMapList
          : []
      );
    },
    [serviceFulfilmentConfig]
  );

  useEffect(
    () => {
      setSfcResIds(
        calendarToUse.serviceSelect &&
        calendarToUse.appointmentExceptionType == APPOINTMENTTYPE &&
        serviceFulfilmentConfigResourceMapList
          ? (serviceFulfilmentConfigResourceMapList || []).map(
              (o) => o["resourceId"]
            )
          : calendarToUse.selectedExResourceIds
      );
    },
    [
      calendarToUse.serviceSelect,
      calendarToUse.appointmentExceptionType,
      serviceFulfilmentConfigResourceMapList,
    ]
  );

  useEffect(
    () => {
      setResourceCompleteList(
        calendarToUse ? calendarToUse.resourceCompleteList : []
      );
    },
    [calendarToUse, calendarToUse.resourceCompleteList]
  );

  useEffect(
    () => {
      setResourceExViewList(
        resourceCompleteList.filter((x) =>
          (sfcResIds || []).find((y) => y == x.resourceId)
        )
      );
    },
    [resourceCompleteList]
  );

  useEffect(
    () => {
      setExistingEvents(
        calendarToUse.appointmentExceptionType === EXCEPTIONTYPE
          ? getExceptionViewEvents(
              calendarToUse,
              calendarToUse.exceptionId
                ? passedInEvents.filter(
                    (x) =>
                      x.extendedProps.exceptionId != calendarToUse.exceptionId
                  )
                : passedInEvents
            )
          : getAppointmentViewEvents(
              calendarToUse,
              passedInEvents.filter(
                (x) =>
                  x.extendedProps.appointmentId != calendarToUse.appointmentId
              ),
              sfcResIds
            )
      );
    },
    [calendarToUse.appointmentExceptionType]
  );

  useEffect(
    () => {
      setAppointmentObj(
        calendarToUse.appointments.find(
          (x) => x.appointmentId == calendarToUse.appointmentId
        )
      );
    },
    [calendarToUse.appointments]
  );

  useEffect(
    () => {
      if (
        calendarToUse.appointmentExceptionType == APPOINTMENTTYPE &&
        appointmentObj?.appointmentId <= 0
      ) {
        var nyAppts = generateAppointmentEventsFromSFRMS(
          calendarToUse.exStartTime,
          serviceFulfilmentConfigResourceMapList,
          "green",
          appointmentObj ? appointmentObj.appointmentTitle : ""
        );
        console.log("@@@@@@@@@@@@@@@@@@@" + JSON.stringify(nyAppts));
        setPreviewEvents(nyAppts);
        props.previewEventsUpdated(nyAppts);
      }
    },
    [
      serviceFulfilmentConfigResourceMapList,
      appointmentObj,
      appointmentObj?.startDate,
      appointmentObj?.startTime,
      calendarToUse.appointmentExceptionType,
      calendarToUse.exStartTime,
      calendarToUse.exEndTime,
      calendarToUse.serviceSelect,
      calendarToUse.serviceFulfilmentConfigId,
      calendarToUse.contactId,
    ]
  );

  useEffect(
    () => {
      if (
        calendarToUse?.appointmentExceptionType == EXCEPTIONTYPE &&
        calendarToUse?.exceptionId <= 0
      ) {
        var nyExcepts = existingEvents.concat(
          calendarToUse.appointmentExceptionType == EXCEPTIONTYPE
            ? generateEvents(
                calendarToUse.exStartTime,
                calendarToUse.exEndTime,
                resourceCompleteList,
                calendarToUse.selectedExResourceIds,
                "green",
                "Exception"
              )
            : appointmentEvents
        );
        setPreviewEvents(nyExcepts);
        props.previewEventsUpdated(nyExcepts);
      }
    },
    [
      existingEvents,
      calendarToUse.appointmentExceptionType,
      resourceCompleteList,
      calendarToUse.selectedExResourceIds,
      calendarToUse.exStartTime,
      calendarToUse.exEndTime,
    ]
  );

  useEffect(
    () => {
      setAppointmentExceptionType(calendarToUse.appointmentExceptionType);
    },
    [calendarToUse.appointmentExceptionType]
  );

  useEffect(
    () => {
      console.log("SFC " + JSON.stringify(sfcResIds));
      console.log(
        "SRFRMS " + JSON.stringify(serviceFulfilmentConfigResourceMapList)
      );
    },
    [props.calendarToUse]
  );

  return (
    <div>
      {updateTime && calendarToUse.serviceSelect ? (
        <CoreApiReq
          endpointToCall={"Read_Service"}
          afterActionHandler={actions.cw.GOT_SERVICE_DETAILS}
          data={{
            orgId: getActiveOrg(),
            serviceId: calendarToUse.serviceSelect,
          }}
        />
      ) : (
        ""
      )}

      {calendarToUse ? (
        <Card className="cal-card">
          <CardBody>
            <GridContainer>
              {!props.hideType ? (
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <AppointmentExceptionSelectType
                    fieldIdentifier={
                      "calendar." +
                      props.calendarIdentifier +
                      ".appointmentExceptionType"
                    }
                  />
                </GridItem>
              ) : (
                " "
              )}
            </GridContainer>
            {appointmentExceptionType == APPOINTMENTTYPE ? (
              <div>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4} lg={4}>
                        {!props.startDate ? (
                          <ReduxCustomDateTime
                            placeholder={"Appointment Start Date"}
                            fieldIdentifier={
                              "calendar." +
                              props.calendarIdentifier +
                              ".exStartDate"
                            }
                            timeFormat={false}
                          />
                        ) : (
                          <CustomDateTime
                            placeholder={"Appointment Start Date"}
                            textValue={props.startDate}
                            textChanged={props.startDateChanged}
                            timeFormat={false}
                          />
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} lg={4}>
                        {!props.startTime ? (
                          <ReduxCustomDateTime
                            placeholder={"Appointment Start Time"}
                            fieldIdentifier={
                              "calendar." +
                              props.calendarIdentifier +
                              ".exStartTime"
                            }
                            dateFormat={false}
                          />
                        ) : (
                          <CustomDateTime
                            placeholder={"Appointment Start Time"}
                            dateFormat={false}
                            textValue={props.startTime}
                            textChanged={props.startTimeChanged}
                          />
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} lg={4}>
                        <ServiceSelect
                          fieldIdentifier={
                            "calendar." +
                            props.calendarIdentifier +
                            ".serviceSelect"
                          }
                          options={rows(
                            calendarToUse.serviceList,
                            "name",
                            "serviceId"
                          )}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} lg={4}>
                        <ReduxCoreSelect
                          placeholder={"Pricing Configuration"}
                          fieldIdentifier={
                            "calendar." +
                            props.calendarIdentifier +
                            ".serviceFulfilmentConfigId"
                          }
                          options={rows(
                            currentSfcs.filter((x) => x.isActive === 2) || [],
                            "name",
                            "serviceFulfilmentConfigId"
                          )}
                          variant="outlined"
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} lg={4}>
                        <CustomerSelect
                          options={rows(
                            props.contactList || [],
                            "contactFullName",
                            "contactId"
                          )}
                          fieldIdentifier={
                            "calendar." +
                            props.calendarIdentifier +
                            ".contactId"
                          }
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </div>
            ) : (
              ""
            )}
            {appointmentExceptionType == EXCEPTIONTYPE ? (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={4}>
                          <ReduxCustomDateTime
                            placeholder={"Exception Start Date"}
                            fieldIdentifier={
                              "calendar." +
                              props.calendarIdentifier +
                              ".exStartDate"
                            }
                            timeFormat={false}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={4}>
                          <ReduxCustomDateTime
                            placeholder={"Exception Start Time"}
                            fieldIdentifier={
                              "calendar." +
                              props.calendarIdentifier +
                              ".exStartTime"
                            }
                            dateFormat={false}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={4}>
                          <ReduxCustomDateTime
                            placeholder={"Exception End Date"}
                            fieldIdentifier={
                              "calendar." +
                              props.calendarIdentifier +
                              ".exEndDate"
                            }
                            timeFormat={false}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={4}>
                          <ReduxCustomDateTime
                            placeholder={"Exception End Time"}
                            fieldIdentifier={
                              "calendar." +
                              props.calendarIdentifier +
                              ".exEndTime"
                            }
                            dateFormat={false}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={4}>
                          <ReduxCoreSelect
                            placeholder={"Resource"}
                            fieldIdentifier={
                              "calendar." +
                              props.calendarIdentifier +
                              ".selectedExResourceIds"
                            }
                            options={rows(
                              resourceCompleteList,
                              "resourceShortName",
                              "resourceId"
                            )}
                            isArrayVal={true}
                            variant="outlined"
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4} />
                  </GridContainer>
                </GridItem>
              </GridContainer>
            ) : (
              ""
            )}
          </CardBody>
        </Card>
      ) : (
        ""
      )}
    </div>
  );
};

function getExceptionViewEvents(calendarObj, existingEvents) {
  var resp = (existingEvents || [])
    .filter((y) =>
      (y.resourceIds || []).find((k) =>
        (calendarObj.selectedExResourceIds || []).find((z) => z === k)
      )
    )
    .map(
      (x) =>
        generateEvents(
          x.start,
          x.end,
          [],
          calendarObj.selectedExResourceIds || [],
          x.extendedProps.exceptionId
            ? calendarExceptionColor
            : calendarAppointmentColor,
          x.extendedProps.exceptionId
            ? x.extendedProps.exceptionTitle
            : x.extendedProps.appointmentTitle
        )[0]
    );

  return resp;
}

function getAppointmentViewEvents(calendarObj, existingEvents, resIds) {
  var resp = (existingEvents || [])
    .filter((y) =>
      (y.resourceIds || []).find((k) => (resIds || []).find((z) => z === k))
    )
    .map(
      (x) =>
        generateEvents(
          x.start,
          x.end,
          [],
          resIds || [],
          x.extendedProps.exceptionId
            ? calendarExceptionColor
            : calendarAppointmentColor,
          x.extendedProps.exceptionId
            ? x.extendedProps.exceptionTitle
            : x.extendedProps.appointmentTitle
        )[0]
    );

  return resp;
}

function generateAppointmentEventsFromSFRMS(
  startDateTime,
  sfcRMaps,
  color,
  title
) {
  var resp = (sfcRMaps || []).map((prop, key) => {
    var endDate = moment(startDateTime).add(prop.durationMilliseconds, "ms");
    return {
      id: new Date().getTime(),
      title: title,
      start: moment(startDateTime).toDate(),
      end: endDate,
      resourceId: prop.resourceId,
      color: color || "green",
    };
  });
  console.log("åååååååååååååååååååå" + JSON.stringify(resp));
  return resp;
}

export default withStyles(userProfileStyles)(AppointmentExceptionCreateOptions);
