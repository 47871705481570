import React, { useState, useEffect } from "react";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { bindActionCreators } from "redux";
import CreateOrgClosedStep1 from "./WizardSteps/CreateOrgClosedStep1.jsx";
import ComingSoonStep from "../../components/CoreWeb/ComingSoonStep.jsx";
import PrimaryContactOptions from "components/CoreWeb/PrimaryContactOptions";
import { FormattedMessage } from "react-intl";
import {
  createCustomer,
  createPhoneNumber,
} from "variables/coreCreateRequests";
import { getActiveOrg } from "variables/coreWeb.jsx";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import * as makeApiCall from "actions/makeApiCall";
import { useHistory } from "react-router-dom";
import { createPhoneNumberWithDispatch } from "variables/thunks.jsx";
import { useSelector, useDispatch } from "react-redux";

const CreatePhoneNumberWizard = function (props) {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Wizard
      steps={[
        {
          stepName: "Options",
          stepComponent: () => {
            return (
              <GridContainer justify="center">
                <GridItem md={10}>
                  <PrimaryContactOptions
                    numTypeIdentifier={"newPhoneNumberType"}
                    numSelectIdentifier={"newPhoneNumber"}
                  />
                </GridItem>
              </GridContainer>
            );
          },
          stepId: "about",
        },
      ]}
      title={<FormattedMessage id={"createNewPhoneNumber"} />}
      subtitle=""
      cancelButton={true}
      cancelButtonText={<FormattedMessage id={"cancel"} />}
      cancelButtonClick={() => {
        if (props.cancelButtonClick) {
          props.cancelButtonClick();
        } else {
          history.goBack();
        }
      }}
      finishButtonClick={() => {
        dispatch(
          createPhoneNumberWithDispatch(
            {
              json: JSON.stringify({
                orgId: getActiveOrg(),
                contactId: props.match.params.contactId,
                metaData: "+" + props.pwReducer.newPhoneNumber,
                metaDataIdentifier: "phoneNum",
                type: props.pwReducer.newPhoneNumberType,
              }),
              functionName: "CreateContactPhoneNumber",
            },
            (resp) => {
              history.push("/customers/view/" + props.match.params.contactId);
            }
          )
        );
      }}
    />
  );
};

function mapStateToProps(state) {
  return {
    pwReducer: state.pwReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles({})(CreatePhoneNumberWizard));
