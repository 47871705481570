import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// core components
import PhoneNumSelect from "components/CoreWeb/PhoneNumSelect.jsx";
import PhoneNumSelectType from "components/CoreWeb/PhoneNumSelectType.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class PrimaryContactOptions extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <GridContainer justify="center">
          <GridItem className="input-box" xs={12} sm={12} md={12} lg={12}>
            <PhoneNumSelectType
              fieldIdentifier={this.props.numTypeIdentifier}
            />
          </GridItem>
          <GridItem className="select-box" xs={12} sm={12} md={12} lg={12}>
            <PhoneNumSelect fieldIdentifier={this.props.numSelectIdentifier} />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(style)(PrimaryContactOptions);
