import React from "react";
import history from "../history";
import Visibility from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";
import Forward from "@material-ui/icons/Forward";
import StarRatingComponent from "react-star-rating-component";
import MenuItem from "@material-ui/core/MenuItem";
import * as actions from "actions/projectActionTypes";
import * as raisers from "actions/projectRaisers";
import ReduxCoreSelect from "components/CoreWeb/ReduxCoreSelect";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput";
import { UPLOADSPATH, DEBUG } from "variables/project.jsx";
import { paths } from "variables/projPaths.jsx";
import moment from "moment";
import vieweye from "assets/img/view.svg";
import { FormattedMessage } from "react-intl";
import deleicon from "assets/img/delete.svg";

const getActiveOrg = function(callbackFunction) {
  var currentActiveOrg = window.localStorage.getItem("currentOrg");
  if (!currentActiveOrg && !callbackFunction) {
    console.log("no callback for getActiveOrg");
    // changeUrl(paths.orgSignIn);
  } else if (
    currentActiveOrg &&
    callbackFunction &&
    typeof callbackFunction === "function"
  ) {
    callbackFunction(currentActiveOrg);
  }
  return currentActiveOrg;
};

const checkForActiveOrg = function(locationParam, historyParam, setActiveOrg) {
  const org = getActiveOrg();

  if (org) {
    setActiveOrg(org);
  } else {
    if (
      locationParam.pathname != paths.orgList &&
      !locationParam.pathname.includes(paths.createOrganisation)
    ) {
      historyParam.push(paths.orgList);
    }
  }
};

const resetActiveOrg = function() {
  window.localStorage.removeItem("currentOrg");
};

const set = function(obj, path, value) {
  //console.log("---OBJ--- " + JSON.stringify(obj));
  //console.log("---Path---" + JSON.stringify(path));
  //console.log("---Value---" + JSON.stringify(value));
  if (obj && path) {
    var schema = obj; // a moving reference to internal objects within obj
    var pList = path.split(".");
    var len = pList.length;
    for (var i = 0; i < len - 1; i++) {
      var elem = pList[i];
      if (!schema[elem]) schema[elem] = {};
      schema = schema[elem];
    }
    schema[pList[len - 1]] = value;
  }
};
const getObjProp = function(obj, path) {
  //console.log("Getting " + path + " from : " + JSON.stringify(obj));
  if (obj && path) {
    var schema = obj; // a moving reference to internal objects within obj
    var pList = path.split(".");
    var len = pList.length;
    for (var i = 0; i < len - 1; i++) {
      var elem = pList[i];
      if (!schema[elem]) schema[elem] = {};
      schema = schema[elem];
    }
    var ret = schema[pList[len - 1]];
    //console.log("Found: " + JSON.stringify(ret))
    return ret;
  }
};

const stringMatch = function(item, valueToMatchAgainst) {
  return item === valueToMatchAgainst;
};

const handleChange = (name) => (event) => {
  alert(name + " " + event.target.value);
  /*this.setState({
    [name]: event.target.value,
  });*/
};

Array.prototype.remove = function(value) {
  return this.filter((f) => f != value);
};

const toggleGroupButtonSelect = function(name, propValue) {
  //alert("toggle");
  console.log(propValue);
  console.log(propValue.fillMe);
  //alert(console.log(name) + " " + console.log(propValue.value));
  if (!propValue.fillMe || !propValue.fillMe.includes(propValue.value)) {
    //propValue.fillMe.push(propValue.value);
    propValue.actionAdd(propValue);
  } else {
    //propValue.fillMe = propValue.fillMe.remove(propValue.value);
    propValue.actionRm(propValue);
  }
  //alert(propValue.fillMe.length);
};

const genericAddList = function(propertyListToCheck, valueToAdd) {
  var currentList = !propertyListToCheck ? [] : propertyListToCheck.slice();
  if (!currentList.includes(valueToAdd)) {
    currentList.push(valueToAdd);
    return currentList;
  }
  return currentList;
};

const genericRmList = function(propertyListToCheck, valueToRemove) {
  var currentList = !propertyListToCheck ? [] : propertyListToCheck.slice();
  if (currentList.includes(valueToRemove)) {
    currentList = currentList.remove(valueToRemove);
  }
  return currentList;
};

const genericHandleListItem = function(propertyListToCheck, valueToAddRemove) {
  var currentList = !propertyListToCheck ? [] : propertyListToCheck.slice();
  if (currentList.includes(valueToAddRemove)) {
    currentList = currentList.remove(valueToAddRemove);
  } else {
    currentList.push(valueToAddRemove);
  }
  return currentList;
};

const changeUrl = function(urlToNavTo) {
  history.push(urlToNavTo);
  window.location.reload();
};

const viewButton = function(clickFunc, classes) {
  return [{ color: "info", icon: Visibility }].map((prop, key) => {
    return (
      <button
        className="cur sm selfview"
        key={key}
        onClick={function() {
          clickFunc();
        }}
      >
        <img src={vieweye} />
      </button>
      /* <Button
        color={prop.color}
        className={classes.actionButton}
        key={key}
        onClick={function () {
          clickFunc();
        }}
      >
        <prop.icon className={classes.icon} />
      </Button> */
    );
  });
};

const forwardButton = function(clickFunc, classes) {
  return [{ color: "success", icon: Forward }].map((prop, key) => {
    return (
      <Button
        color={prop.color}
        className={classes.actionButton}
        key={key}
        onClick={function() {
          clickFunc();
        }}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });
};

const deleteButton = function(clickFunc, classes) {
  return [{ color: "danger", icon: Close }].map((prop, key) => {
    return (
      <Button
        color={prop.color}
        className={classes.actionButton}
        key={key}
        onClick={function() {
          clickFunc();
        }}
      >
        {/*            */}
        <img src={deleicon} />
      </Button>
    );
  });
};

const tableButton = function(clickFunc, classes, color, icon, callbackParams) {
  return [{ color: color, icon: icon }].map((prop, key) => {
    return (
      <Button
        color={prop.color}
        className={classes.actionButton}
        key={key}
        onClick={function() {
          clickFunc(callbackParams);
        }}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });
};

const setMetaDataOntoObject = function(metaDataList, propsToLookFor) {
  var resp = {};
  for (var i = 0; i < metaDataList.length; i++) {
    var currentItem = metaDataList[i];
    if (propsToLookFor.includes(currentItem.metaDataIdentifier)) {
      resp[currentItem.metaDataIdentifier] = isNaN(currentItem.metaData)
        ? currentItem.metaData
        : parseInt(currentItem.metaData);
    }
  }
  return resp;
};

const getAllMetaDataIntoArray = function(metaDataList, propsToLookFor) {
  var resp = [];
  for (var i = 0; i < metaDataList.length; i++) {
    var currentItem = metaDataList[i];
    if (propsToLookFor.includes(currentItem.metaDataIdentifier)) {
      resp.push(currentItem.metaData);
    } else {
      return [];
    }
  }
  return resp;
};

const getAllMetaDataIntoObject = function(metaDataList, propsToLookFor) {
  var resp = {};
  for (var i = 0; i < metaDataList.length; i++) {
    var currentItem = metaDataList[i];
    if (propsToLookFor.includes(currentItem.metaDataIdentifier)) {
      resp[currentItem.metaDataIdentifier] = currentItem.metaData;
    } else {
      return {};
    }
  }
  return resp;
};

const safeFindMetaDataString = function(metaDataList, valueToLookFor) {
  var resp = "";
  for (var i = 0; i < metaDataList.length; i++) {
    var currentItem = metaDataList[i];
    if (valueToLookFor === currentItem.metaDataIdentifier) {
      resp = currentItem.metaData;
    }
  }
  return resp;
};

const safeFindMetaDataNumbers = function(metaDataList, valueToLookFor) {
  var resp = [];
  for (var i = 0; i < metaDataList.length; i++) {
    var currentItem = metaDataList[i];
    if (valueToLookFor === currentItem.metaDataIdentifier) {
      resp = currentItem.metaData.split(",").map(Number);
    }
  }
  return resp;
};

const enumMedicalTypeToString = function(enumValue) {
  switch (enumValue) {
    case 2:
      return "Note";
    case 3:
      return "Procedure";
    case 4:
      return "Behaviour";
    case 5:
      return "Allergy";
    case 6:
      return "Observation";
    case 7:
      return "Dental";
    case 8:
      return "Laboratory";
    case 9:
      return "Weight Measurement";
    case 10:
      return "Height Measurement";
    case 11:
      return "Medication";
    case 12:
      return "Temperature Measurement";
    case 13:
      return "Pulse Measurement";
    case 14:
      return "Respiration Measurement";
  }
};
const enumAnimalSpeciesToString = function(enumValue) {
  switch (enumValue) {
    case 2:
      return "Avian";
    case 3:
      return "Bovine";
    case 4:
      return "Camelid";
    case 5:
      return "Canine";
    case 6:
      return "Caprine";
    case 7:
      return "Cavies";
    case 8:
      return "Cervidae";
    case 9:
      return "Equine";
    case 10:
      return "Feline";
    case 11:
      return "Lapine";
    case 12:
      return "Murine";
    case 13:
      return "Ovine";
  }
};

const convertToStars = function(starValue) {
  return (
    <StarRatingComponent value={starValue} color="success" size="sm">
      Active
    </StarRatingComponent>
  );
};

const activeStatusFromType = function(statusValue, callbackFunction) {
  if (statusValue == 1) {
    return (
      <Button
        color=""
        size="sm"
        onClick={() => (callbackFunction ? callbackFunction() : "")}
      >
        Unknown
      </Button>
    );
  }
  if (statusValue == 2) {
    return (
      <Button
        color="success"
        size="sm"
        onClick={() => (callbackFunction ? callbackFunction() : "")}
      >
        Active
      </Button>
    );
  }
  if (statusValue == 3) {
    return (
      <Button
        color="danger"
        size="sm"
        onClick={() => (callbackFunction ? callbackFunction() : "")}
      >
        InActive
      </Button>
    );
  }
};
const orderStatusFromType = function(statusValue) {
  if (statusValue == 2) {
    return (
      <Button color="success" size="sm">
        Pending
      </Button>
    );
  }
  if (statusValue == 3) {
    return (
      <Button color="" size="sm">
        Confirmed
      </Button>
    );
  }
  if (statusValue == 4) {
    return (
      <Button color="danger" size="sm">
        Rejected
      </Button>
    );
  }
  if (statusValue == 5) {
    return (
      <Button color="warning" size="sm">
        InProgress
      </Button>
    );
  }
  if (statusValue == 6) {
    return (
      <Button color="success" size="sm">
        Confirmed
      </Button>
    );
  }
  if (statusValue == 7) {
    return (
      <Button color="" size="sm">
        Invoiced
      </Button>
    );
  }
  if (statusValue == 8) {
    return (
      <Button color="danger" size="sm">
        Paid
      </Button>
    );
  }
  if (statusValue == 9) {
    return (
      <Button color="warning" size="sm">
        Closed
      </Button>
    );
  }
  if (statusValue == 10) {
    return (
      <Button color="warning" size="sm">
        Refunded
      </Button>
    );
  }
  if (statusValue == 11) {
    return (
      <Button color="warning" size="sm">
        Cancelled
      </Button>
    );
  }
  if (statusValue == 12) {
    return (
      <Button color="warning" size="sm">
        Awaiting Payment
      </Button>
    );
  }
};
var yesNoTextDescs = [{ text: "Yes" }, { text: "No" }];
function pluck(array, key) {
  return array.map((o) => o[key]);
}
function rows(objsToLoop, propText, propValue) {
  var resp = [];
  if (!objsToLoop) {
    return resp;
  }
  for (var i = 0; i < objsToLoop.length; i++) {
    // note: we add a key prop here to allow react to uniquely identify each
    // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
    var theValue =
      objsToLoop[i].value ||
      (propValue &&
        (typeof propValue === "function"
          ? propValue(objsToLoop[i])
          : objsToLoop[i][propValue]
            ? objsToLoop[i][propValue]
            : undefined)) ||
      i + 1;
    resp.push(
      <MenuItem value={theValue} key={i}>
        {(propText &&
          (typeof propText === "function"
            ? propText(objsToLoop[i])
            : objsToLoop[i][propText]
              ? objsToLoop[i][propText]
              : undefined)) ||
          objsToLoop[i].text}
      </MenuItem>
    );
  }
  return resp;
}
const REGISTRATION_VENDOR = 4;
const REGISTRATION_CLIENT = 3;

const gridFix = {
  width: "100% !important",
};

const defaultChangeReduxCoreSelect = function(dispatch, ownProps, payload) {
  var newPayload = {};
  newPayload.value = payload;
  newPayload.fieldIdentifier = ownProps.fieldIdentifier;
  if (
    ownProps.updateAction === actions.cw.UPREDUXSELECTINPUT ||
    ownProps.updateAction === undefined
  ) {
    console.log("---" + ownProps.updateAction);
    dispatch(
      raisers.createActionGeneric(newPayload, actions.cw.UPREDUXSELECTINPUT)
    );
  } else {
    dispatch(raisers.createActionGeneric(newPayload, ownProps.updateAction));
  }
};

const excludeSelectedExceptCurrent = function(
  completeList,
  currentlySelectedList,
  fieldIdentifier,
  state
) {
  var resp = completeList.filter(function(value) {
    return (
      !currentlySelectedList.includes(value.resourceId) ||
      state[fieldIdentifier] == value.resourceId
    );
  });
  if (currentlySelectedList.length == completeList.length) {
    resp.unshift({});
  }
  return resp;
};
const generateResourceSfcMapRows = function(
  rowCount,
  fieldIdentifierBase,
  resourceList,
  mandatory,
  currentlySelectedList,
  state,
  textDisplayName,
  valueName,
  isDisabled
) {
  var resp = [];
  for (var i = 0; i < rowCount; i++) {
    var fieldIdentifier = fieldIdentifierBase + "ResourceSelect" + i.toString();
    resp.push({
      name: i.toString(),
      resourceSelect: (
        <ReduxCoreSelect
          options={rows(
            excludeSelectedExceptCurrent(
              resourceList,
              currentlySelectedList,
              fieldIdentifier,
              state
            ),
            textDisplayName,
            valueName
          )}
          fieldIdentifier={fieldIdentifier}
          disabled={isDisabled}
        />
      ),
      durationSelect: (
        <ReduxCustomInput
          fieldIdentifier={fieldIdentifierBase + "Duration" + i.toString()}
          disabled={isDisabled}
        />
      ),
      salesPrice: (
        <ReduxCustomInput
          fieldIdentifier={fieldIdentifierBase + "SalesPrice" + i.toString()}
          disabled={isDisabled}
        />
      ),
      reqType: mandatory ? "Mandatory" : "Optional",
    });
  }
  return resp;
};
const generateFileUrl = function(fileId, fileExtension) {
  var returnMe = UPLOADSPATH.concat(fileId.toString(), fileExtension);
  return returnMe;
};
const getMostRecentFileFromList = function(fileList) {
  var activeFile = null;
  if (fileList && fileList.length > 0) {
    activeFile = fileList.sort((a, b) => a.fileId - b.fileId)[
      fileList.length - 1
    ];
  }
  return activeFile;
};
const nullRespVals = function(objToNull) {
  var activeFile = objToNull;
  delete activeFile.func_status;
  delete activeFile.func_msg;
  delete activeFile.df;
  delete activeFile.dl;
  return activeFile;
};

const coreWebStyles = {
  cardHeaderCenter: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "5em",
  },
};
const next = function(prop) {
  return function(arr) {
    var used = arr.reduce(function(o, v) {
      o[v[prop]] = true;
      return o;
    }, {});
    for (var i = 1; used[i]; i++);
    return i;
  };
};
const orderStatus = [
  { text: "Pending", value: 2 },
  { text: "Rejected", value: 4 },
  { text: "Requested Contact", value: 12 },
];
var groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

var getISODateTime = function(dateVal, timeVal) {
  return moment(dateVal)
    .hour(moment(timeVal).hour())
    .minute(moment(timeVal).minute());
};

var calcDurationDiff = function(start, end) {
  return start.diff(end);
};

var calcDurationDiffAgeString = function(start, end, options) {
  var optionStr = "";
  var diffDays = end.diff(start, "days");
  var age = moment.duration(end.diff(start));
  var years = age.years();
  var months = age.months();
  return (
    age.years() +
    " year(s) " +
    age.months() +
    " month(s) " +
    age.days() +
    " day(s)"
  );
  //.format(optionStr);//'Y[ year(s)] D[ day(s)] H[ hour(s)] m[ minute(s)] s[ second(s) ago.]');
};

var DEFAULT_TOAST_TIMEOUT_MS = 3000;

var APPOINTMENTTYPE = 1;
var EXCEPTIONTYPE = 2;

var phoneNumTypes = [
  { text: <FormattedMessage id="home" />, value: 8 },
  { text: <FormattedMessage id="work" />, value: 9 },
  { text: <FormattedMessage id="mobile" />, value: 10 },
  { text: <FormattedMessage id="mainline" />, value: 11 },
  { text: <FormattedMessage id="home Fax" />, value: 12 },
  { text: <FormattedMessage id="work Fax" />, value: 13 },
  { text: <FormattedMessage id="pager" />, value: 14 },
  { text: <FormattedMessage id="other" />, value: 15 },
];

export {
  // data for React Big Calendar in Calendar view
  getActiveOrg,
  checkForActiveOrg,
  resetActiveOrg,
  set,
  getObjProp,
  stringMatch,
  handleChange,
  toggleGroupButtonSelect,
  genericAddList,
  genericRmList,
  genericHandleListItem,
  changeUrl,
  viewButton,
  deleteButton,
  forwardButton,
  tableButton,
  setMetaDataOntoObject,
  getAllMetaDataIntoArray,
  getAllMetaDataIntoObject,
  safeFindMetaDataString,
  safeFindMetaDataNumbers,
  enumAnimalSpeciesToString,
  convertToStars,
  activeStatusFromType,
  orderStatusFromType,
  pluck,
  yesNoTextDescs,
  rows,
  REGISTRATION_VENDOR,
  REGISTRATION_CLIENT,
  gridFix,
  defaultChangeReduxCoreSelect,
  generateResourceSfcMapRows,
  generateFileUrl,
  getMostRecentFileFromList,
  enumMedicalTypeToString,
  nullRespVals,
  coreWebStyles,
  next,
  orderStatus,
  groupBy,
  getISODateTime,
  calcDurationDiff,
  calcDurationDiffAgeString,
  DEFAULT_TOAST_TIMEOUT_MS,
  APPOINTMENTTYPE,
  EXCEPTIONTYPE,
  phoneNumTypes,
};
