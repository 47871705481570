import initialState from "reducers/initialState";
import * as actions from "actions/projectActionTypes";
import { constVars } from "../variables/constVars";
import axios from "axios";
import { APIURL } from "variables/project";
import {
  set,
  stringMatch,
  genericAddList,
  genericRmList,
  genericHandleListItem,
  safeFindMetaDataString,
  safeFindMetaDataNumbers,
  changeUrl,
  setMetaDataOntoObject,
} from "variables/coreWeb.jsx";
import { paths } from "variables/projPaths.jsx";
import { fieldIdentifiers } from "variables/fieldIdentifiers.jsx";
import {
  APPOINTMENTTYPE,
  EXCEPTIONTYPE,
  nullRespVals,
} from "../variables/coreWeb";
import { apiGetSelfProfile } from "../variables/newApi";
import moment from "moment";

export default function handle(state = initialState, action) {
  console.log("INITIAL");
  console.log(initialState);
  let newState;
  var color, message;
  console.log("pwReducer: " + action.type);
  console.log(action);

  switch (action.type) {
    case actions.cw.API_RESP_CREATE:
      newState = { ...state };
      console.log("API_RESP_CREATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Successfully Created";
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        toastColor: color,
      };

    case actions.cw.API_RESP_UPDATE:
      newState = { ...state };
      console.log("API_RESP_UPDATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Successfully Updated";
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        toastColor: color,
      };

    case actions.cw.API_RESOURCE_CREATE:
      newState = { ...state };
      console.log("API_RESOURCE_CREATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Resource successfully Created";
        setTimeout(function() {
          changeUrl(paths.resourceList);
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        toastColor: color,
      };

    case actions.cw.API_SFC_CREATE:
      newState = { ...state };
      console.log("API_SFC_CREATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Successfully Created";
        setTimeout(function() {
          changeUrl(paths.serviceList);
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        toastColor: color,
      };

    case actions.cw.API_MEDICAL_CREATE:
      newState = { ...state };
      console.log("API_MEDICAL_CREATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Medical note successfully Created";
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        toastColor: color,
      };

    case actions.cw.API_SERVICE_CREATE:
      newState = { ...state };
      console.log("API_SERVICE_CREATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Service successfully Created";
        setTimeout(function() {
          changeUrl(paths.serviceList);
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        toastColor: color,
      };

    case actions.cw.API_ANIMAL_CREATE:
      newState = { ...state };
      console.log("API_ANIMAL_CREATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Patient successfully created";
        setTimeout(function() {
          changeUrl(paths.animalList);
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        toastColor: color,
      };

    case actions.cw.API_CUSTOMER_CREATE:
      newState = { ...state };
      console.log("API_CUSTOMER_CREATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Customer successfully created";
        setTimeout(function() {
          changeUrl(paths.customerList);
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        toastColor: color,
      };

    case actions.cw.API_PRODUCT_CREATE:
      newState = { ...state };
      console.log("API_PRODUCT_CREATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Product successfully created";
        setTimeout(function() {
          changeUrl(paths.productList);
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        toastColor: color,
      };

    case actions.cw.API_ADDRESS_CREATE:
      newState = { ...state };
      console.log("API_ADDRESS_CREATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Address successfully created";
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        toastColor: color,
      };
    case actions.cw.API_ADDRESS_UPDATE:
      newState = { ...state };
      console.log("API_ADDRESS_UPDATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Address successfully updated";
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        toastColor: color,
      };

    case actions.cw.API_MEMBER_CREATE:
      newState = { ...state };
      console.log("API_MEMBER_CREATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Staff successfully created";
        setTimeout(function() {
          changeUrl(paths.memberList);
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        toastColor: color,
      };

    case actions.cw.API_CONTACT_DELETE:
      newState = { ...state };
      console.log("API_CONTACT_DELETE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Customer successfully deleted";
        setTimeout(function() {
          changeUrl(paths.customerList);
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        showOnCreateSuccess: true,
        toastColor: color,
      };

    case actions.cw.API_CONTACT_ADDRESS_DELETE:
      newState = { ...state };
      console.log("API_CONTACT_ADDRESS_DELETE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Customer Address successfully deleted";
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        showOnCreateSuccess: true,
        toastColor: color,
      };

    case actions.cw.API_ANIMAL_DELETE:
      newState = { ...state };
      console.log("API_ANIMAL_DELETE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Patient successfully deleted";
        setTimeout(function() {
          changeUrl(paths.animalList);
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        showOnCreateSuccess: true,
        toastColor: color,
      };

    case actions.cw.API_META_DELETED:
      newState = { ...state };
      console.log("API_META_DELETED");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Successfully deleted";
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        showOnCreateSuccess: true,
        toastColor: color,
      };

    case actions.cw.API_SERVICE_DELETE:
      newState = { ...state };
      console.log("API_SERVICE_DELETE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Service successfully deleted";
        setTimeout(function() {
          changeUrl(paths.serviceList);
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        showOnCreateSuccess: true,
        toastColor: color,
      };

    case actions.cw.API_PRODUCT_DELETE:
      newState = { ...state };
      console.log("API_PRODUCT_DELETE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Product successfully deleted";
        setTimeout(function() {
          changeUrl(paths.productList);
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        showOnCreateSuccess: true,
        toastColor: color,
      };

    case actions.cw.API_RESOURCE_DELETE:
      newState = { ...state };
      console.log("API_RESOURCE_DELETE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Resource successfully deleted";
        setTimeout(function() {
          changeUrl(paths.resourceList);
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        showOnCreateSuccess: true,
        toastColor: color,
      };

    case actions.cw.API_MEDICAL_DELETE:
      newState = { ...state };
      console.log("API_MEDICAL_DELETE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Medical record successfully deleted";
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        showOnCreateSuccess: true,
        toastColor: color,
      };

    case actions.cw.API_MEDICAL_UPDATE:
      newState = { ...state };
      console.log("API_MEDICAL_UPDATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Medical note successfully updated";
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        showOnCreateSuccess: true,
        toastColor: color,
      };

    case actions.cw.GOT_ALL_ORG_RESOURCES:
      console.log("GOT_ALL_ORG_RESOURCES");
      var resourceCompleteListOptions = [];
      action.payload.resourceCompleteList.map((x) => {
        resourceCompleteListOptions.push({ text: x.resourceShortName });
      });
      return {
        ...state,
        resourceCompleteListOptions: resourceCompleteListOptions,
        resourceCompleteList: action.payload.resourceCompleteList,
      };
    case actions.cw.GOT_ORGDETAILS:
      console.log("GOT_ORGDETAILS");
      return { ...state, orgDetails: action.payload };
    case actions.cw.UPREDUXCUSTOMINPUT:
      console.log("UPREDUXCUSTOMINPUT");
      newState = { ...state };
      set(newState, action.payload.fieldIdentifier, action.payload.value);
      return newState;
    case actions.cw.UPREDUXSELECTINPUT:
      console.log("UPREDUXSELECTINPUT");
      newState = { ...state };
      set(newState, action.payload.fieldIdentifier, action.payload.value);
      return newState;
    case actions.cw.UPREDUXCOREICONINPUTSINGLE:
      console.log("UPREDUXCOREICONINPUTSINGLE");
      newState = { ...state };
      newState[action.payload.fieldIdentifier] = [action.payload.value];
      return newState;

    case actions.cw.UPREDUXCOREICONINPUT:
      console.log("UPREDUXCOREICONINPUT");
      newState = { ...state };
      newState[action.payload.fieldIdentifier] = genericHandleListItem(
        newState[action.payload.fieldIdentifier],
        action.payload.value
      );
      return newState;

    case actions.cw.UPREDUXGROUPBUTTONSELECTINPUT:
      console.log("UPREDUXGROUPBUTTONSELECTINPUT");
      newState = { ...state };
      newState[action.payload.fieldIdentifier] = genericHandleListItem(
        newState[action.payload.fieldIdentifier],
        action.payload.value
      );
      return newState;
    case actions.cw.UPREDUXCHIPSBUTTON:
      newState = { ...state };
      newState[action.payload.fieldIdentifier] = genericHandleListItem(
        newState[action.payload.fieldIdentifier],
        action.payload.value
      );
      return newState;
    case actions.cw.MANDSTAFFSELECTCHANGE:
      console.log("MANDSTAFFSELECTCHANGE");
      newState = { ...state };
      set(newState, action.payload.fieldIdentifier, action.payload.value);
      var mandatoryStaff = [];
      for (var i = 0; i < action.payload.value; i++) {
        mandatoryStaff.push({
          type: 1,
          staffMember: undefined,
          duration: undefined,
          salesPrice: undefined,
        });
      }
      newState.arrayOfMandatoryStaff = mandatoryStaff;
      return newState;

    case actions.cw.READ_SELFPROFILE:
      newState = { ...state };
      console.log("READ_SELFPROFILE Action");
      return { ...newState, selfProfile: action.payload };

    case actions.cw.GOT_ORGLIST:
      newState = { ...state };
      console.log("GOT_ORGLIST Action");
      return { ...newState, orgList: action.payload.completeOrgList };

    case actions.cw.GOT_SERVICEORDERS_SELF:
      newState = { ...state };
      console.log("GOT_SERVICEORDERS_SELF Action");
      return {
        ...newState,
        selfServiceOrderList: action.payload.serviceOrderList,
      };

    case actions.cw.GOT_SERVICE_DETAILS:
      newState = { ...state };
      console.log("GOT_SERVICE_DETAILS Action");
      return { ...newState, serviceDetails: nullRespVals(action.payload) };

    case actions.cw.GOT_PRODUCT_DETAILS:
      newState = { ...state };
      console.log("GOT_PRODUCT_DETAILS Action");
      return { ...newState, productDetails: action.payload };

    case actions.cw.GOT_ANIMAL_MEDICAL_RECORD:
      newState = { ...state };
      console.log("GOT_ANIMAL_MEDICAL_RECORD Action");
      return { ...newState, medicalNotes: action.payload };

    case actions.cw.GOT_SERVICEFULFILMENTS_SELF:
      newState = { ...state };
      console.log("GOT_SERVICEFULFILMENTS_SELF Action");
      return {
        ...newState,
        selfServiceFulfilmentConfigCompleteList:
          action.payload.serviceFulfilmentConfigCompleteList,
      };

    case actions.cw.GOT_ALLORGSERVICEORDERS:
      newState = { ...state };
      console.log("GOT_ALLORGSERVICEORDERS Action");
      return {
        ...newState,
        orgServiceOrderList: action.payload.serviceOrderList,
      };

    case actions.cw.GOT_AVAILABLESTARTTIMES:
      newState = { ...state };
      console.log("GOT_AVAILABLESTARTTIMES Action");
      return { ...newState, availableStartTimes: action.payload.searchResults };

    case actions.cw.GOT_SERVICEORDERS_ORG:
      newState = { ...state };
      console.log("GOT_SERVICEORDERS_ORG Action");
      return {
        ...newState,
        serviceOrderListOrg: action.payload.serviceOrderList,
      };

    case actions.cw.GOT_SERVICEFULFILMENTCONFIG:
      newState = { ...state };
      console.log("GOT_SERVICEFULFILMENTCONFIG Action");
      return { ...newState, serviceFulfilmentConfig: action.payload };
    case actions.cw.GOT_ORGDETAILSLIMITED:
      var orgDetails = {
        ...action.payload,
      };
      for (var i = 0; i < action.payload.metaDataList.length; i++) {
        var currentItem = action.payload.metaDataList[i];
        orgDetails[currentItem.metaDataIdentifier] = currentItem.metaData;
      }

      return { ...state, orgDetails: orgDetails };
    case actions.cw.GOT_ALL_ORG_ANIMALS_BY_ORG_ID:
      newState = { ...state };
      console.log("GOT_ALL_ORG_ANIMALS_BY_ORG_ID Action");
      return { ...newState, animalList: action.payload.animalList };

    case actions.cw.GOT_ALL_ORG_CONTACTS_BY_ORG_ID:
      newState = { ...state };
      console.log("GOT_ALL_ORG_CONTACTS_BY_ORG_ID Action");
      return { ...newState, contactList: action.payload.contactList };

    case actions.cw.GOT_ALL_ORG_PRODUCTS:
      newState = { ...state };
      console.log("GOT_ALL_ORG_PRODUCTS Action");
      return { ...newState, productList: action.payload.productList };

    case actions.cw.GOT_ALL_ORG_RESOURCES_BY_ORG_ID:
      newState = { ...state };
      console.log("GOT_ALL_ORG_RESOURCES_BY_ORG_ID Action");
      return {
        ...newState,
        resourceCompleteList: action.payload.resourceCompleteList,
      };

    case actions.cw.GOT_ALL_SERVICES_BY_ORG_ID:
      newState = { ...state };
      console.log("GOT_ALL_SERVICES_BY_ORG_ID Action");
      return {
        ...newState,
        serviceCompleteList: action.payload.serviceCompleteList,
      };

    case actions.cw.GOT_ALL_ORG_INVOICES_BY_ORG_ID:
      newState = { ...state };
      console.log("GOT_ALL_ORG_INVOICES_BY_ORG_ID Action");
      return {
        ...newState,
        listOfInvoices: action.payload.listOfInvoices,
      };

    case actions.cw.NOTI_SHOW:
      newState = { ...state };
      console.log("NOTI_SHOW Action");
      return {
        ...newState,
        showOnCreateSuccess: true,
        toastMessage: action.payload.msg || "Successfully Created",
        toastColor: action.payload.color || "success",
      };

    case actions.cw.NOTI_HIDE:
      newState = { ...state };
      console.log("NOTI_HIDE Action");
      return {
        ...newState,
        showOnCreateSuccess: undefined,
        toastMessage: undefined,
        toastColor: undefined,
      };
    case actions.cw.FILE_UPLOADED_STATUS:
      newState = { ...state };
      return {
        ...newState,
        showOnCreateSuccess: true,
        toastMessage: action.payload.msg,
        toastColor: action.payload.color,
      };
    case actions.cw.PAGE_RELOAD:
      newState = { ...state };
      console.log("PAGE_RELOAD Action");
      window.location.reload();
      return { ...newState };
    case actions.cw.GOT_ALL_ORG_USERS:
      newState = { ...state };
      var userListOptions = [];
      action.payload.userList.map((x) => {
        userListOptions.push({ text: x.contactList[0].contactFullName });
      });
      console.log("GOT_ALL_ORG_USERS Action");
      return {
        ...newState,
        userList: action.payload.userList,
        userListOptions: userListOptions,
      };

    case actions.cw.SET_STATE_VAL:
      newState = { ...state };
      newState[action.payload.fieldIdentifier] = action.payload.value;
      //   if (action.payload.btnIdentifier) {
      //     newState[action.payload.btnIdentifier] = action.payload.btnAction;
      //   }
      console.log("SET_STATE_VAL Action");
      return {
        ...newState,
      };

    case actions.cw.GOT_ANIMAL_DETAILS:
      newState = { ...state };
      console.log("GOT_ANIMAL_DETAILS Action");
      return { ...newState, animalDetails: action.payload };

    case actions.cw.GOT_RESOURCE_DETAILS:
      newState = { ...state };
      console.log("GOT_RESOURCE_DETAILS Action");
      return { ...newState, resourceDetails: action.payload };

    case actions.cw.GOT_CONTACT_DETAILS:
      newState = { ...state };
      console.log("GOT_CONTACT_DETAILS Action");
      var extractedPhoneNumbers = action.payload.metaDataList.filter(
        (x) => x.metaDataIdentifier === "phoneNum"
      );
      for (var i = 0; i < extractedPhoneNumbers.length; i++) {
        newState["phoneType" + i] = extractedPhoneNumbers[i].type;
        newState["phoneSelect" + i] = extractedPhoneNumbers[i].metaData;
      }
      return {
        ...newState,
        contactDetails: action.payload,
        contactPhoneNumbers: extractedPhoneNumbers,
      };

    case actions.cw.READ_DASHBOARD:
      newState = { ...state };
      console.log("READ_DASHBOARD Action");
      return {
        ...newState,
      };

    case actions.cw.GOT_SERVICEFULFILMENT_COMPLETE:
      newState = { ...state };
      console.log("GOT_SERVICEFULFILMENT_COMPLETE Action");
      var mandatoryStaff = action.payload.serviceFulfilmentConfigResourceMapList.filter(
        (x) =>
          x.serviceFulfilmentResourceConfigRelationship === 2 &&
          x.serviceFulfilmentResourceConfigRequiredOptional == 3
      );
      var optionalStaff = action.payload.serviceFulfilmentConfigResourceMapList.filter(
        (x) =>
          x.serviceFulfilmentResourceConfigRelationship === 2 &&
          x.serviceFulfilmentResourceConfigRequiredOptional == 2
      );
      var valsToAppend = {};
      for (var i = 0; i < mandatoryStaff.length; i++) {
        valsToAppend["mandatoryStaffConfigResourceSelect" + i] =
          mandatoryStaff[i].resourceId;
        valsToAppend["mandatoryStaffConfigDuration" + i] =
          mandatoryStaff[i].durationMilliseconds;
        valsToAppend["mandatoryStaffConfigSalesPrice" + i] =
          mandatoryStaff[i].monetaryAmount;
      }
      for (var i = 0; i < optionalStaff.length; i++) {
        valsToAppend["optionalStaffConfigResourceSelect" + i] =
          optionalStaff[i].resourceId;
        valsToAppend["optionalStaffConfigDuration" + i] =
          optionalStaff[i].durationMilliseconds;
        valsToAppend["optionalStaffConfigSalesPrice" + i] =
          optionalStaff[i].monetaryAmount;
      }
      return {
        ...newState,
        serviceFulfilmentConfig: action.payload,
        mandatoryStaff: mandatoryStaff.length,
        optionalStaff: optionalStaff.length,
        optionalResources: optionalStaff,
        mandatoryResources: mandatoryStaff,
        ...valsToAppend,
      };
    case actions.cw.API_CUSTOMER_UPDATE:
      newState = { ...state };
      console.log("API_CUSTOMER_UPDATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Customer successfully modified";
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        showOnCreateSuccess: true,
        toastColor: color,
      };

    case actions.cw.API_ANIMAL_UPDATE:
      newState = { ...state };
      console.log("API_ANIMAL_UPDATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Patient successfully modified";
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        showOnCreateSuccess: true,
        toastColor: color,
      };

    case actions.cw.API_SFC_UPDATE:
      newState = { ...state };
      console.log("API_SFC_UPDATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Pricing successfully modified";
        setTimeout(function() {
          changeUrl(paths.serviceList);
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        showOnCreateSuccess: true,
        toastColor: color,
      };

    case actions.cw.API_SERVICE_UPDATE:
      newState = { ...state };
      console.log("API_SERVICE_UPDATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Service successfully modified";
        setTimeout(function() {
          changeUrl(paths.serviceList);
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        showOnCreateSuccess: true,
        toastColor: color,
      };

    case actions.cw.API_PRODUCT_UPDATE:
      newState = { ...state };
      console.log("API_PRODUCT_UPDATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Product successfully modified";
        setTimeout(function() {
          changeUrl(paths.productList);
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }
      return {
        ...newState,
        toastMessage: message,
        showOnCreateSuccess: true,
        toastColor: color,
      };

    case actions.cw.API_RESOURCE_UPDATE:
      newState = { ...state };
      console.log("API_RESOURCE_UPDATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Resource successfully modified";
        setTimeout(function() {
          changeUrl(paths.resourceList);
        }, 2500);
      } else {
        color = "danger";
        message = action.payload.func_msg;
        setTimeout(function() {
          window.location.reload();
        }, 2500);
      }

      return {
        ...newState,
        toastMessage: message,
        showOnCreateSuccess: true,
        toastColor: color,
      };

    case actions.cw.GOT_CALENDAR_VIEW:
      newState = { ...state };

      newState.calendar[action.payload.fieldIdentifier] = {};
      var calendarToModify = newState.calendar[action.payload.fieldIdentifier];

      for (var i = 0; i < action.payload.resourceCompleteList.length; i++) {
        action.payload.resourceCompleteList[i].id =
          action.payload.resourceCompleteList[i].resourceId;
      }
      calendarToModify.resourceCompleteList =
        action.payload.resourceCompleteList;
      calendarToModify.serviceList = action.payload.serviceList;
      calendarToModify.serviceFulfilmentConfigCompleteList = [];
      calendarToModify.serviceList.forEach(function(element) {
        element.serviceFulfilmentConfigCompleteList.forEach(function(sfc) {
          calendarToModify.serviceFulfilmentConfigCompleteList.push(sfc);
        });
      });

      calendarToModify.exceptions = [];
      calendarToModify.appointments = [];
      for (var i = 0; i < action.payload.listOfResAppExFree.length; i++) {
        for (
          var l = 0;
          l <
          action.payload.listOfResAppExFree[i].listOfExceptionCompletes.length;
          l++
        ) {
          calendarToModify.exceptions.push(
            action.payload.listOfResAppExFree[i].listOfExceptionCompletes[l]
          );
        }

        for (
          var k = 0;
          k < action.payload.listOfResAppExFree[i].listOfAppointments.length;
          k++
        ) {
          calendarToModify.appointments.push(
            action.payload.listOfResAppExFree[i].listOfAppointments[k]
          );
        }
      }

      calendarToModify.appointmentExceptionType = 2;
      return newState;

    case actions.cw.SET_CALENDAR_CLICK_INFO:
      newState = { ...state };
      var calendarToModify = newState.calendar[action.payload.fieldIdentifier];
      calendarToModify.selectedExResourceIds = [
        parseInt(action.payload.resource.id),
      ];
      calendarToModify.selectedApResourceIds = [
        parseInt(action.payload.resource.id),
      ];
      calendarToModify.exStartDate = action.payload.startStr;
      calendarToModify.exStartTime = action.payload.startStr;
      calendarToModify.exEndDate = action.payload.endStr;
      calendarToModify.exEndTime = action.payload.endStr;
      calendarToModify.appointmentExceptionType = 2;
      calendarToModify.exceptionId = undefined;
      return {
        ...newState,
      };

    case actions.cw.SET_CALENDAR_EVENT_CLICK_INFO:
      newState = { ...state };
      console.log(action.payload);
      var calendarToModify = newState.calendar[action.payload.calendarId];
      calendarToModify.selectedExResourceIds =
        action.payload.event.extendedProps.resourceIdList;
      calendarToModify.exStartDate = action.payload.event.start;
      calendarToModify.exStartTime = action.payload.event.start;
      calendarToModify.exEndDate = action.payload.event.end;
      calendarToModify.exEndTime = action.payload.event.end;
      calendarToModify.exceptionId =
        action.payload.event.extendedProps.exceptionId;
      calendarToModify.appointmentExceptionType = action.payload.event
        .extendedProps.exceptionId
        ? EXCEPTIONTYPE
        : action.payload.event.extendedProps.appointmentId
          ? APPOINTMENTTYPE
          : null;
      calendarToModify.appointmentId =
        action.payload.event.extendedProps.appointmentId;
      if (
        action.payload.event.extendedProps.appointmentId &&
        action.payload.event.extendedProps.appointmentId > 0
      ) {
        calendarToModify.serviceSelect =
          action.payload.event.extendedProps.serviceFulfilmentConfigResourceMapList[0].serviceId;
        calendarToModify.serviceFulfilmentConfigId =
          action.payload.event.extendedProps.serviceFulfilmentConfigResourceMapList[0].serviceFulfilmentConfigId;
        calendarToModify.contactId =
          action.payload.event.extendedProps.contactId;
      }

      return {
        ...newState,
      };

    case actions.cw.CREATED_EXCEPTION_SHOW:
      newState = { ...state };
      var toastToShow = {
        id: action.payload.newRecordID,
        fmi: action.payload.fmi,
      };
      newState.toasts.push(toastToShow);
      return {
        ...newState,
      };

    case actions.cw.CREATED_EXCEPTION_HIDE:
      newState = { ...state };
      newState.toasts = [];
      return {
        ...newState,
      };

    case actions.cw.SET_VAR:
      newState = { ...state };
      set(newState, action.payload.fieldIdentifier, action.payload.value);
      return {
        ...newState,
      };

    case actions.cw.GOT_SELFPROFILE:
      console.log("GOT_SELFPROFILE Action");
      newState = { ...state };
      newState.selfProfile = action.payload;

      return {
        ...newState,
      };

    //ProjectWeb

    case actions.pw.GOT_ORGDETAILSSELLREPS:
      var orgDetails = {
        ...action.payload,
      };
      for (var i = 0; i < action.payload.metaDataList.length; i++) {
        var currentItem = action.payload.metaDataList[i];
        orgDetails[currentItem.metaDataIdentifier] = currentItem.metaData;
      }

      return { ...state, orgDetails: orgDetails };
    case actions.pw.SET_DEFAULT_CAMPAIGNSETTINGS:
      var nextState = {
        ...state,
        campaignSummary: "Boobs",
        campaignName: "Test",
        campaignLoc: [1, 2],
        productOrService: [1],
        campaignProdOrServName: "Putain",
        campaignProdOrServRef: "Pooop",
        campaignProdOrServDesc: "Looooooool",
      };
      return nextState;

    case actions.cw.RESET_CONTACT_DETAILS:
      const resetContact = {};
      resetContact.emailAddress = "";
      resetContact.contactFirstName = "";
      resetContact.contactLastName = "";

      var nextState = {
        ...state,
        contactDetails: resetContact,
      };
      return nextState;

    case actions.cw.RESET_ANIMAL_DETAILS:
      const animalDetails = {
        dateOfBirth: moment().toISOString(),
        timeOfBirth: moment().toISOString(),
        passportNumber: "-",
        microchipId: "-",
        insuranceReferenceNumber: "-",
        gender: 2,
        animalSpecies: 2,
        desexedStatus: 4,
        bloodGroup: 13,
        isActive: 2,
        name: "",
      };

      var nextState = {
        ...state,
        animalDetails: animalDetails,
      };
      return nextState;

    case actions.pw.GOT_CAMPAIGNSETTINGS:
      var nextState = {
        ...state,
        campaignSummary: "",
        campaignName: action.payload.name,
        campaignLoc: [1, 2],
        productOrService: [1],
        campaignProdOrServName: "Putain",
        campaignProdOrServRef: "Pooop",
        campaignProdOrServDesc: "Looooooool",
        campaignIsActive: action.payload.isActive,
        campaignOrg: action.payload.orgId,
        campaignType: [action.payload.serviceId],
        sellRepProfileYearsExp: [2],
        pwReducerContactsProvided: [1],
        pwReducerTeritory: [1],
        dealSize: [1],
        salesCycle: [1],
        repPay: [1],
        sellRepProfileCustTypes: [1],
        comissionPerc: 10,
        whyWork: "Dont work here",
        typeMatch: "We like motivated people",
        trainingProv: "None provided",
        campaignAgreeDisagree: 3,
      };
      return nextState;

    case actions.pw.GOT_CANDIDATE_PROFILE:
      var yearsExperience = action.payload.metaDataList
        .find(function(element) {
          return element.metaDataIdentifier == "yearsExperience";
        })
        .metaData.split(",")
        .map(Number);

      var howToSell = action.payload.metaDataList
        .find(function(element) {
          return element.metaDataIdentifier == "howToSell";
        })
        .metaData.split(",")
        .map(Number);

      var customerTypes = action.payload.metaDataList
        .find(function(element) {
          return element.metaDataIdentifier == "customerTypes";
        })
        .metaData.split(",")
        .map(Number);

      var yesNoOption = action.payload.metaDataList
        .find(function(element) {
          return element.metaDataIdentifier == "yesNoOption";
        })
        .metaData.split(",")
        .map(Number);

      var numberOfEmployees = action.payload.metaDataList
        .find(function(element) {
          return element.metaDataIdentifier == "numberOfEmployees";
        })
        .metaData.split(",")
        .map(Number);

      var employeeSeniority = action.payload.metaDataList
        .find(function(element) {
          return element.metaDataIdentifier == "employeeSeniority";
        })
        .metaData.split(",")
        .map(Number);

      var campaignLocationChoice = action.payload.metaDataList
        .find(function(element) {
          return element.metaDataIdentifier == "campaignLocationChoice";
        })
        .metaData.split(",")
        .map(Number);

      var considerationOptions = action.payload.metaDataList
        .find(function(element) {
          return element.metaDataIdentifier == "considerationOptions";
        })
        .metaData.split(",")
        .map(Number);

      var profileLookingFor = action.payload.metaDataList.find(function(
        element
      ) {
        return element.metaDataIdentifier == "profileLookingFor";
      }).metaData;

      var profileMilestones = action.payload.metaDataList.find(function(
        element
      ) {
        return element.metaDataIdentifier == "profileMilestones";
      }).metaData;

      var profileJoinFactors = action.payload.metaDataList.find(function(
        element
      ) {
        return element.metaDataIdentifier == "profileJoinFactors";
      }).metaData;

      var nextState = {
        ...state,
        profileName: action.payload.name,
        campaignType: action.payload.serviceId,
        yearsExperience: yearsExperience,
        howToSell: howToSell,
        customerTypes: customerTypes,
        yesNoOption: yesNoOption,
        numberOfEmployees: numberOfEmployees,
        employeeSeniority: employeeSeniority,
        campaignLocationChoice: campaignLocationChoice,
        considerationOptions: considerationOptions,
        profileLookingFor: profileLookingFor,
        profileMilestones: profileMilestones,
        profileJoinFactors: profileJoinFactors,
      };
      return nextState;

    case actions.cw.SET_MEDICAL_NOTE_RECORD:
      newState = { ...state };
      console.log("SET_MEDICAL_NOTE_RECORD Action", newState);

      let medicalRecord;
      if (
        newState.selectedMedicalNoteId &&
        newState.medicalNotes &&
        newState.medicalNotes != undefined
      ) {
        const medicalNotesArr = newState.medicalNotes.medicalNotes;
        for (const key in medicalNotesArr) {
          if (
            medicalNotesArr[key].medicalNoteId ===
            newState.selectedMedicalNoteId
          ) {
            // console.log("Processing...", medicalNotesArr[key].medicalNoteId);

            medicalRecord = {
              date: moment(medicalNotesArr[key].createdOn_UTC).toISOString(),
              time: moment(medicalNotesArr[key].createdOn_UTC).toISOString(),
              medicalNoteType: medicalNotesArr[key].medicalNoteType,
              weightMeasurement: medicalNotesArr[key].weightMeasurement
                ? medicalNotesArr[key].weightMeasurement
                : 20,
              weightUnits: medicalNotesArr[key].weightUnits
                ? medicalNotesArr[key].weightUnits
                : constVars.kilograms,
              heightMeasurement: medicalNotesArr[key].heightMeasurement
                ? medicalNotesArr[key].heightMeasurement
                : 1,
              heightUnits: medicalNotesArr[key].heightUnits
                ? medicalNotesArr[key].heightUnits
                : constVars.meters,
              temperatureMeasurement: medicalNotesArr[key]
                .temperatureMeasurement
                ? medicalNotesArr[key].temperatureMeasurement
                : 0,
              temperatureUnits: medicalNotesArr[key].temperatureUnits
                ? medicalNotesArr[key].temperatureUnits
                : constVars.celcius,
              pulseMeasurement: medicalNotesArr[key].pulseMeasurement
                ? medicalNotesArr[key].pulseMeasurement
                : 50,
              respirationMeasurement: medicalNotesArr[key]
                .respirationMeasurement
                ? medicalNotesArr[key].respirationMeasurement
                : 50,
              noteDescription: medicalNotesArr[key].noteDescription,
            };
            break;
          }
        }
      }

      return {
        ...newState,
        medicalRecord: { ...medicalRecord },
      };

    case actions.cw.GOT_SEARCH_RESULT:
      newState = { ...state };
      console.log("GOT_SEARCH_RESULT Action");
      return { ...newState, searchResult: action.payload };

    default:
      return state;
  }
}
