import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GenderSelect from "components/CoreWeb/GenderSelect";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import DeSexedStatus from "components/CoreWeb/DeSexedStatus";
import BloodGroup from "components/CoreWeb/BloodGroup";
import MonetaryCurrency from "components/CoreWeb/MonetaryCurrency";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";

import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput.jsx";
import { getActiveOrg } from "variables/coreWeb.jsx";
import * as makeApiCall from "actions/makeApiCall";
import Button from "components/CustomButtons/Button.jsx";
import * as actions from "actions/projectActionTypes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  ...customSelectStyle,
};

class CreateStaffMemberStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      desgin: false,
      code: false,
      develop: false,
    };
  }
  sendState() {
    return this.state;
  }
  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  isValidated() {
    return true;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className="sl-pd-22">
        {this.props.match &&
        this.props.match.params &&
        this.props.match.params.contactId != undefined ? (
          <CoreApiReq
            endpointToCall={"ReadContactCompleteByContactID"}
            afterActionHandler={actions.cw.GOT_SERVICEORDERS_SELF}
            data={{
              contactId: this.props.match.params.contactId,
              orgId: this.props.orgId,
            }}
          />
        ) : (
          ""
        )}
        {/*<h4>Staff Image</h4>
        <Card>
          <CardBody>
            <PictureUpload />
          </CardBody>
        </Card>*/}
        <Card>
          <CardHeader>
            <h3 className="myhead nosp set-o mt-0-k">Staff Details</h3>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} className="Create-input-box">
                <label className="lbl-fld">First name</label>
                <ReduxCustomInput
                  //mainTextLabel={"First name"}
                  updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                  fieldIdentifier={"staffFirstName"}
                  multiLine={1}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6} className="Create-input-box">
                <label className="lbl-fld">Last name</label>
                <ReduxCustomInput
                  //mainTextLabel={"Last name"}
                  updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                  fieldIdentifier={"staffLastName"}
                  multiLine={1}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6} className="Create-input-box">
                <label className="lbl-fld">Email address</label>
                <ReduxCustomInput
                  //mainTextLabel={"Email address"}
                  updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                  fieldIdentifier={"staffEmailAddress"}
                  multiLine={1}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    contactFirstName: state.pwReducer.staffFirstName,
    contactLastName: state.pwReducer.staffLastName,
    emailAddress: state.pwReducer.staffEmailAddress,
    orgId: getActiveOrg(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(CreateStaffMemberStep1));
