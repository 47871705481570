import { getActiveOrg } from "variables/coreWeb.jsx";
import * as actions from "actions/projectActionTypes";
import moment from "moment";

const createPatient = function (theProps) {
  var payLoad = {};
  payLoad.animalBreed = 2;
  payLoad.animalSpecies = theProps.animalSpecies;
  payLoad.bloodGroup = theProps.bloodGroup;
  payLoad.dateOfBirth = theProps.dateOfBirth;
  payLoad.deceasedDate = "2048-12-31T16:00:00.000Z";
  payLoad.deceasedStatus = 2;
  payLoad.desexedStatus = theProps.desexedStatus;
  payLoad.gender = theProps.gender;
  payLoad.insuranceReferenceNumber = theProps.insuranceReferenceNumber;
  payLoad.isActive = 2;
  payLoad.mainColour = "#FF0000";
  payLoad.microchipId = theProps.microchipId;
  payLoad.name = theProps.name;
  payLoad.orgId = getActiveOrg();
  payLoad.passportNumber = theProps.passportNumber;
  payLoad.secondaryColour = "#00ff00";
  return payLoad;
};

const createCustomer = function (theProps) {
  var payLoad = {};
  console.log(theProps);
  payLoad.contactAcquisition = 2;
  payLoad.contactFirstName = theProps.contactDetails.contactFirstName;
  payLoad.contactLastName = theProps.contactDetails.contactLastName;
  payLoad.contactTitle = 2;
  payLoad.contactType = 3;
  payLoad.emailAddress = theProps.contactDetails.emailAddress;
  payLoad.orgId = theProps.orgId;
  payLoad.orgName = theProps.contactDetails.orgName;
  payLoad.userId = theProps.userId;
  return payLoad;
};

const createResources = function (callMe, theProps) {
  var payLoad = {};
  console.log(theProps);
  payLoad.allowsOverlaps = 2;
  payLoad.depthUnitType = 2;
  payLoad.depthValue = 0;
  payLoad.heightUnitType = 2;
  payLoad.heightValue = 0;
  payLoad.latitude = 51.50722;
  payLoad.longitude = -0.1275;
  payLoad.maxAppointmentDuration = 7200000;
  payLoad.maxAppointmentFutureTimeInMs = 2419000000;
  payLoad.maxDailyUserSlots = 2;
  payLoad.maxExceptionDuration = 604800000;
  payLoad.orgId = getActiveOrg();
  payLoad.resourceName = theProps.resourceDetails.resourceName;
  payLoad.slotDuration = 10800000;
  payLoad.timeAllocationType = 4;
  payLoad.timeZoneIANA = "";
  payLoad.userId = 0;
  payLoad.widthUnitType = 2;
  payLoad.widthValue = 0;

  callMe.req(
    localStorage.getItem("access_token"),
    "Create_Resource",
    payLoad,
    actions.cw.API_RESOURCE_CREATE
  );
};

const createServices = function (callMe, theProps) {
  var payLoad = {};
  console.log(theProps);
  payLoad.description = theProps.description;
  payLoad.isPlatformSpecific = 3;
  payLoad.monetaryAmount = 0;
  payLoad.monetaryCurrency = 840;
  payLoad.name = theProps.name;
  payLoad.orgId = getActiveOrg();
  payLoad.taxRate = 1;

  callMe.req(
    localStorage.getItem("access_token"),
    "Create_Service",
    payLoad,
    actions.cw.API_SERVICE_CREATE
  );
};

const createPhoneNumber = function (callMe, theProps) {
  var payLoad = {};
  payLoad.functionName = "CreateContactPhoneNumber";
  console.log(theProps);
  var json = {};
  json.orgId = theProps.orgId;
  json.contactId = theProps.contactId;
  json.metaData = theProps.metaData;
  json.metaDataIdentifier = theProps.metaDataIdentifier;
  json.type = theProps.type;
  payLoad.json = JSON.stringify(json);
  return payLoad;
};

const createProduct = function (callMe, theProps) {
  var payLoad = {};
  console.log(theProps);
  payLoad.orgId = getActiveOrg();
  payLoad.productName = theProps.productName;
  payLoad.purchasePrice1 = {
    monetaryAmount: theProps.purchasePrice.monetaryAmount,
    monetaryCurrency: 840,
  };
  payLoad.salesPrice1 = {
    monetaryAmount: theProps.salesPrice.monetaryAmount,
    monetaryCurrency: 840,
  };
  payLoad.purchaseTaxRate = theProps.purchaseTaxRate;
  payLoad.salesTaxRate = theProps.salesTaxRate;
  payLoad.systemProductCode = "";
  payLoad.userProductCode = "";
  callMe.req(
    localStorage.getItem("access_token"),
    "Create_Product",
    payLoad,
    actions.cw.API_PRODUCT_CREATE
  );
};

const createStaff = function (theProps) {
  var payLoad = {};
  console.log(theProps);
  payLoad.contactFirstName = theProps.contactFirstName;
  payLoad.contactLastName = theProps.contactLastName;
  payLoad.emailAddress = theProps.emailAddress;
  payLoad.orgId = getActiveOrg();
  /*  callMe.req(
      localStorage.getItem("access_token"),
      "Create_Member",
      payLoad,
      actions.cw.API_MEMBER_CREATE
    );*/
  return payLoad;
};

const createCustomerAddress = function (callMe, theProps) {
  var payLoad = {};
  payLoad.address1 = theProps.addressDetails.address1;
  payLoad.address2 = theProps.addressDetails.address2;
  payLoad.addressType = 2;
  payLoad.attention = "";
  payLoad.city = theProps.addressDetails.city;
  payLoad.contactId = theProps.contactDetails.contactId;
  payLoad.country = 2;
  payLoad.creatorId = 3;
  payLoad.isActive = 3;
  payLoad.isBillingAddress = false;
  payLoad.orgId = getActiveOrg();
  payLoad.town = theProps.addressDetails.town;
  payLoad.userId = theProps.contactDetails.userId;
  payLoad.zipcode = theProps.addressDetails.zipcode;

  callMe.req(
    localStorage.getItem("access_token"),
    "Create_Address",
    payLoad,
    actions.cw.API_ADDRESS_CREATE
  );
};

const createMedicalNote = function (callMe, theProps, animalId) {
  var payLoad = {};
  console.log(theProps);
  var dateTime = moment(theProps.date)
    .hour(moment(theProps.time).hour())
    .minute(moment(theProps.time).minute());

  var noteDesc;
  if (theProps.medicalNoteType == 9) {
    noteDesc = theProps.weightMeasurement + " " + theProps.weightUnits;
  } else if (theProps.medicalNoteType == 10) {
    noteDesc = theProps.heightMeasurement + " " + theProps.heightUnits;
  } else if (theProps.medicalNoteType == 12) {
    noteDesc =
      theProps.temperatureMeasurement + " " + theProps.temperatureUnits;
  } else if (theProps.medicalNoteType == 13) {
    noteDesc = theProps.pulseMeasurement + " beats per minute";
  } else if (theProps.medicalNoteType == 14) {
    noteDesc = theProps.respirationMeasurement + " breaths per minute";
  } else {
    noteDesc = theProps.noteDescription;
  }

  payLoad.createdOn_UTC = dateTime.toISOString();
  payLoad.medicalNoteType = theProps.medicalNoteType;
  payLoad.medicalRecordId = parseInt(animalId);
  payLoad.noteDescription = noteDesc;
  payLoad.orgId = getActiveOrg();

  console.log(payLoad);
  callMe.req(
    localStorage.getItem("access_token"),
    "Create_MedicalNote",
    payLoad,
    actions.cw.API_MEDICAL_CREATE
  );
};

export {
  createPatient,
  createCustomer,
  createResources,
  createServices,
  createPhoneNumber,
  createProduct,
  createStaff,
  createCustomerAddress,
  createMedicalNote,
};
