import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import ReduxCoreSelect from "components/CoreWeb/ReduxCoreSelect";
import * as actions from "actions/projectActionTypes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getActiveOrg } from "variables/coreWeb.jsx";
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import LocationCity from "@material-ui/icons/LocationCity";
import * as makeApiCall from "actions/makeApiCall";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import AddressViewOptions from "./AddressViewOptions";
import Clear from "@material-ui/icons/Clear";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import * as raisers from "actions/projectRaisers";
import { deleteContactAddressWithDispatch } from "../../variables/thunks";
import { paths } from "../../variables/projPaths";
import deleicon from "assets/img/delete.svg";
import { Link } from "react-router-dom";
import vieweye from "assets/img/view.svg";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ReduxCardCustomInput from "components/CoreWeb/ReduxCardCustomInput";
import {
  createAddressWithDispatch,
  updateAddressWithDispatch,
} from "variables/thunks";

const style = {
  ...userProfileStyles,
  ...sweetAlertStyle,
};
class AddressList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldShowCreateDialog: false,
      shouldShowDeleteConfirmationDialog: false,
      shouldShowNewAddressDialogue: false,
      setValue: null,
    };
  }
  handleClose = function() {
    this.setState({ shouldShowCreateDialog: false });
  };

  fillButtons = function(theProps, addressId, abc) {
    return (
      <div>
        <Link className="cur">
          <button
            onClick={(prop) => {
              theProps.raiseAction({
                fieldIdentifier: "addressDetails",
                value: abc,
              });
              this.setState({ shouldShowNewAddressDialogue: true });
            }}
            className="sm selfview"
          >
            <img src={vieweye} />
          </button>
        </Link>
        <button
          color="danger"
          size="sm"
          onClick={() => {
            theProps.raiseAction({
              fieldIdentifier: "selectedAddressId",
              value: addressId,
            });
          }}
          className="sm danger cur"
        >
          <img src={deleicon} />
        </button>
      </div>
    );
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Button
          className="add-new-btn btn-theme"
          color="rose"
          // href={
          //   paths.addressDetails + "/" + this.props.contactDetails.contactId
          // }
          onClick={() => {
            this.setState({ shouldShowNewAddressDialogue: true });
          }}
        >
          Create New Address
        </Button>

        <Card className="table-container">
          {this.props.dontShowHeader ? (
            ""
          ) : (
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <LocationCity />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage id="addressList" />
                <small>
                  {" "}
                  - <FormattedMessage id="addressesConfigured" />
                </small>
              </h4>
            </CardHeader>
          )}
          <CardBody>
            {this.props.contactDetails.addressList ? (
              <ReactTable
                data={this.props.contactDetails.addressList.map((prop, key) => {
                  return {
                    id: key,
                    line1: prop.address1,
                    line2: prop.address2,
                    town: prop.town,
                    city: prop.city,
                    zipCode: prop.zipcode,
                    actions: this.fillButtons(this.props, prop.addressId, prop),
                  };
                })}
                filterable
                columns={[
                  {
                    Header: <FormattedMessage id="address1" />,
                    accessor: "line1",
                  },
                  {
                    Header: <FormattedMessage id="address2" />,
                    accessor: "line2",
                  },
                  {
                    Header: <FormattedMessage id="town" />,
                    accessor: "town",
                  },
                  {
                    Header: <FormattedMessage id="city" />,
                    accessor: "city",
                  },
                  {
                    Header: <FormattedMessage id="zipCode" />,
                    accessor: "zipCode",
                  },
                  {
                    Header: <FormattedMessage id="actions" />,
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                pageSize={10}
              />
            ) : (
              ""
            )}
          </CardBody>
        </Card>
        {this.state.shouldShowCreateDialog ? (
          <Dialog
            open={true}
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            keepMounted
            onClose={() => this.handleClose()}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <h4 className={classes.modalTitle} />
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
            >
              <AddressViewOptions />
            </DialogContent>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button
                onClick={() => {
                  this.setState({ shouldShowCreateDialog: false });
                }}
                color="simple"
              >
                <FormattedMessage id="close" />
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
        {this.props.selectedAddressId ? (
          <Dialog
            open={true}
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            keepMounted
            onClose={() => {
              this.handleClose();
            }}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <h4 className={classes.modalTitle}>
                <FormattedMessage id="areYouSure" />
              </h4>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
            >
              <p>
                <FormattedMessage id="deletingAdddressPermanent" />
              </p>
            </DialogContent>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button
                color="success"
                onClick={() => {
                  this.props.raiseAction({
                    fieldIdentifier: "selectedAddressId",
                    value: undefined,
                  });
                }}
                color="simple"
              >
                <FormattedMessage id="cancel" />
              </Button>
              <Button
                onClick={() => {
                  this.props.dispatch(
                    deleteContactAddressWithDispatch(
                      this.props.callApi,
                      this.props.selectedAddressId
                    )
                  );
                  this.props.raiseAction({
                    fieldIdentifier: "selectedAddressId",
                    value: undefined,
                  });
                }}
                color="danger"
              >
                <FormattedMessage id="deleteit" />
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
        {this.state.shouldShowNewAddressDialogue ? (
          <Dialog
            open={true}
            classes={{
              root: classes.center,
              paper: `${classes.modal} popup-address`,
            }}
            keepMounted
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle>
              {!this.props.addressList?.addressDetails?.addressId ? (
                <h4 className="add-popup-DialogTitle">Add Address</h4>
              ) : (
                <h4 className="add-popup-DialogTitle">Edit Address</h4>
              )}
            </DialogTitle>
            <DialogContent id="modal-slide-description">
              <GridContainer className="add-address-new">
                <GridItem
                  xs={12}
                  sm={6}
                  md={12}
                  className="no-card Create-input-box"
                >
                  <ReduxCardCustomInput
                    header={"Line 1"}
                    fieldIdentifier={"addressDetails.address1"}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={12}
                  className="no-card Create-input-box"
                >
                  <ReduxCardCustomInput
                    header={"Line 2"}
                    fieldIdentifier={"addressDetails.address2"}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={12}
                  className="no-card Create-input-box"
                >
                  <ReduxCardCustomInput
                    header={"Town"}
                    fieldIdentifier={"addressDetails.town"}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={12}
                  className="no-card Create-input-box"
                >
                  <ReduxCardCustomInput
                    header={"City"}
                    fieldIdentifier={"addressDetails.city"}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={12}
                  className="no-card Create-input-box"
                >
                  <ReduxCardCustomInput
                    header={"Zip/Postal Code"}
                    fieldIdentifier={"addressDetails.zipcode"}
                  />
                  {console.log("dasdsadaddadada", this.state.setValue)}
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions className="addnewaddress">
              <Button
                onClick={() => {
                  this.props.raiseAction({
                    fieldIdentifier: "addressDetails",
                    value: undefined,
                  });
                  this.setState({ shouldShowNewAddressDialogue: false });
                }}
                color="success"
                color="simple"
                className="Cancel-btn"
              >
                Cancel
              </Button>
              {console.log(this.props)}
              {!this.props.addressList?.addressDetails?.addressId ? (
                <Button
                  onClick={() => {
                    this.props.dispatch(
                      createAddressWithDispatch(this.props.callApi, this.props)
                    );
                  }}
                  color="success"
                  color="simple"
                >
                  Create New Address
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    this.props.dispatch(
                      updateAddressWithDispatch(this.props.callApi, this.props)
                    );
                  }}
                  color="success"
                  color="simple"
                >
                  Edit Address
                </Button>
              )}
            </DialogActions>
            <div
              onClick={() => {
                this.props.raiseAction({
                  fieldIdentifier: "addressDetails",
                  value: undefined,
                });
                this.setState({ shouldShowNewAddressDialogue: false });
              }}
              class="close-btn"
            >
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M17.1569 14.5253L28.4489 3.23295C29.1838 2.49842 29.1838 1.3108 28.4489 0.576282C27.7144 -0.15824 26.5267 -0.15824 25.7922 0.576282L14.4998 11.8686L3.20781 0.576282C2.47295 -0.15824 1.28567 -0.15824 0.55115 0.576282C-0.183717 1.3108 -0.183717 2.49842 0.55115 3.23295L11.8432 14.5253L0.55115 25.8177C-0.183717 26.5522 -0.183717 27.7398 0.55115 28.4743C0.917207 28.8407 1.39852 29.0248 1.87948 29.0248C2.36045 29.0248 2.84141 28.8407 3.20781 28.4743L14.4998 17.182L25.7922 28.4743C26.1586 28.8407 26.6396 29.0248 27.1206 29.0248C27.6015 29.0248 28.0825 28.8407 28.4489 28.4743C29.1838 27.7398 29.1838 26.5522 28.4489 25.8177L17.1569 14.5253Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="29" height="29" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </Dialog>
        ) : (
          ""
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    addressDetails: state.pwReducer.addressDetails || [],
    contactDetails: state.pwReducer.contactDetails || [],
    selectedAddressId: state.pwReducer.selectedAddressId,
    addressList: state.pwReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    dispatch: dispatch,
    raiseAction: (payload) => {
      dispatch(raisers.createActionGeneric(payload, actions.cw.SET_STATE_VAL));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(AddressList));
