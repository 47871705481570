import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Pets from "@material-ui/icons/Pets";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Visibility from "@material-ui/icons/Visibility";
import Clear from "@material-ui/icons/Clear";

import CardAvatar from "components/Card/CardAvatar.jsx";

import { getActiveOrg } from "variables/coreWeb.jsx";
import { enumAnimalSpeciesToString } from "variables/coreWeb.jsx";
import * as makeApiCall from "actions/makeApiCall";
import image from "assets/img/default-dog.png";
import { paths } from "variables/projPaths.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import * as raisers from "actions/projectRaisers";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deletePatient } from "../../variables/coreRequests";
import { deleteAnimalWithDispatch } from "../../variables/thunks";
import vieweye from "assets/img/view.svg";
import deleicon from "assets/img/delete.svg";
import { FormattedMessage } from "react-intl";
import { UPLOADSPATH } from "variables/project.jsx";

const style = {
  ...userProfileStyles,
  ...sweetAlertStyle,
};

const animalImage = function(prop) {
  return (
    <CardAvatar cardAvatarProfileNormal className="bdr-img">
      <a href="#pablo" onClick={(e) => e.preventDefault()}>
        <img
          src={prop.cloudFileUrl ? UPLOADSPATH + prop.cloudFileUrl : image}
          alt="..."
          style={{
            height: "50px",
            width: "50px",
          }}
        />
      </a>
    </CardAvatar>
  );
};

// const deletePatient = function(callMe, animalId) {
//   var payLoad = {};
//   payLoad.orgId = getActiveOrg();
//   payLoad.animalId = animalId;

//   callMe.req(
//     localStorage.getItem("access_token"),
//     "Delete_Animal_By_Animal_ID",
//     payLoad,
//     actions.cw.API_ANIMAL_DELETE
//   );
// };

function AnimalList(props) {
  const { classes } = props;
  const fillButtons = function(theProps, animalId) {
    return (
      <div className="">
        <Link to={paths.animalView + "/" + animalId} className="cur">
          <button className="sm selfview">
            <img src={vieweye} />
          </button>
        </Link>
        <button
          onClick={() => {
            theProps.raiseAction({
              fieldIdentifier: "selectedAnimalId",
              value: animalId,
            });
            theProps.raiseAction({
              fieldIdentifier: "linkedContactId",
              value: props.contactId,
            });
          }}
          className="sm danger cur"
        >
          <img src={deleicon} />
        </button>
        {/* <Button
          className={classes.actionButton}
          color="danger"
          size="sm"
          onClick={() => {
            theProps.raiseAction({
              fieldIdentifier: "selectedAnimalId",
              value: animalId,
            });
            theProps.raiseAction({
              fieldIdentifier: "linkedContactId",
              value: props.contactId,
            });
          }}
        >
          <Clear className={classes.icon} />
        </Button>
        <Button
          className={classes.actionButton}
          color="info"
          size="sm"
          href={paths.animalView + "/" + animalId}
        >
          <Visibility className={classes.icon} />
        </Button> */}
      </div>
    );
  };
  return (
    <GridContainer justify={"center"} className="sl-pd-22 table-container">
      {!props.animals ? (
        <CoreApiReq
          endpointToCall={"Read_All_Org_Animals_By_Org_ID"}
          afterActionHandler={actions.cw.GOT_ALL_ORG_ANIMALS_BY_ORG_ID}
          data={{
            orgId: getActiveOrg(),
          }}
        />
      ) : (
        ""
      )}
      <GridItem xs={12} sm={12} md={12} lg={12} className="customers-table-btn">
        <Link
          to={
            props.contactId
              ? "/app/patients/createnewWithContact/" + props.contactId
              : "/app/patients/createnew"
          }
        >
          <Button className="btn-primary btn-theme" color={"rose"}>
            + Add New Patient
          </Button>
        </Link>
        {!props.hideLinkToContact ? (
          <Link to={"/app/patients/link/customer/" + props.contactId}>
            <Button className="btn-theme" color={"rose"}>
              Link Existing Patient
            </Button>
          </Link>
        ) : (
          ""
        )}
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Card>
          {!props.hideHeader ? (
            <CardHeader>
              <h3 className="myhead nosp">
                <FormattedMessage id="yourPatients" />
              </h3>
              <p>
                <FormattedMessage id="patientsToTakeCareOf" />
              </p>
            </CardHeader>
          ) : (
            ""
          )}
          <CardBody>
            <ReactTable
              data={props.animalList.map((prop, key) => {
                return {
                  id: key,
                  name: prop.name,
                  animalData: prop,
                  type: enumAnimalSpeciesToString(prop.animalSpecies),
                  workingFor: prop[3],
                  actions: fillButtons(props, prop.animalId),
                  img: animalImage(prop),
                  animalId: prop.animalId,
                };
              })}
              filterable={false}
              columns={[
                {
                  Header: "Patient Id",
                  accessor: "animalId",
                  headerClassName: "table-header-center",
                },
                {
                  Header: <FormattedMessage id="patientImage" />,
                  accessor: "img",
                  headerClassName: "table-header-center",
                },
                {
                  Header: <FormattedMessage id="patientName" />,
                  accessor: "name",
                  headerClassName: "table-header-center",
                },
                {
                  Header: <FormattedMessage id="patientType" />,
                  accessor: "type",
                  headerClassName: "table-header-center",
                },
                {
                  Header: <FormattedMessage id="actions" />,
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  headerClassName: "table-header-center",
                  Cell: (content) => (
                    <div className="text-left">{content.value}</div>
                  ),
                },
              ]}
              defaultPageSize={10}
              showPaginationTop={false}
              showPaginationBottom={true}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    animalList: ownProps.animals || state.pwReducer.animalList || [],
    selectedAnimalId: state.pwReducer.selectedAnimalId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    dispatch: dispatch,
    raiseAction: (payload) => {
      dispatch(raisers.createActionGeneric(payload, actions.cw.SET_STATE_VAL));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(AnimalList));
