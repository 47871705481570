import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import moment from "moment";
import Button from "components/CustomButtons/Button.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppointmentExceptionCreateOptions from "components/CoreWeb/AppointmentExceptionCreateOptions";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import SidePanel from "components/SidePanel/SidePanel.jsx";
import CoreTitleCard from "components/CoreWeb/CoreTitleCard";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import dashboardRoutes from "routes/dashboard.jsx";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";
import { IntlProvider, FormattedMessage } from "react-intl";
import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/logo-white.svg";
import { connect } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import {
  createException,
  setCalendarClickData,
  deleteException,
  postApi,
  setCalendarEventClickData,
  createServiceOrder,
} from "variables/thunks";
import {
  deleteContactWithDispatch,
  deleteAnimalWithDispatch,
  deleteMapPatientCustomer,
  deleteMetaDataWithDispatch,
  readContactCompleteWithDispatch,
} from "variables/thunks";
import SweetAlert from "react-bootstrap-sweetalert";
import ReduxNotificationHeaderGroup from "components/CoreWeb/ReduxNotificationHeaderGroup";
import * as raisers from "actions/projectRaisers";
import * as actions from "actions/projectActionTypes";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { bindActionCreators } from "redux";
import * as makeApiCall from "actions/makeApiCall";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  getActiveOrg,
  getISODateTime,
  calcDurationDiff,
  resetActiveOrg,
} from "variables/coreWeb.jsx";
import { paths } from "variables/projPaths.jsx";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

const getSidePanel = function(routePath, props) {
  console.log("ROUTEPATH: " + JSON.stringify(routePath));
  switch (routePath) {
    case paths.calendar:
      return <div />;
    default:
      return (
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={11} md={10}>
              {/*<CoreTitleCard
                value={'-'}
                title={"Coming soon"}
                subtitle={"Coming soon"}
                color={"rose"}
                countUp={true}
             />*/}
            </GridItem>
            <GridItem xs={12} sm={11} md={10}>
              {/*<CoreTitleCard
                value={'-'}
                title={"Coming soon"}
                subtitle={"Coming soon"}
                color={"rose"}
                countUp={true}
            />*/}
            </GridItem>
          </GridContainer>
        </div>
      );
  }
};

const connectWithStore = function(store, WrappedComponent, ...args) {
  let ConnectedWrappedComponent = connect(...args)(
    withStyles(appStyle)(WrappedComponent)
  );
  return function(props) {
    return <ConnectedWrappedComponent {...props} store={store} />;
  };
};

var ps;

const Dashboard = function(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const registrationType = useSelector(
    (state) => state.pwReducer.selfProfile.registrationType
  );

  const [mobileView, setMobileView] = useState(false);
  const [miniActive, setMiniActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const refMainPanel = useRef();
  const [activeOrg, setActiveOrg] = useState(null);
  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
    isLoading,
  } = useAuth0();
  const { classes, ...rest } = props;
  const sidebarMinimize = () => {
    this.setMiniActive(!miniActive);
  };

  const getRoute = function() {
    return props.location.pathname !== "/maps/full-screen-maps";
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleDrawerToggle = () => {
    setMiniActive(!miniActive);
  };

  useEffect(() => {
    resizeFunction();
  });

  useEffect(
    () => {
      var filterRoutes = dashboardRoutes.filter((prop) => {
        return (
          !prop.registrationType ||
          prop.registrationType == registrationType ||
          registrationType === undefined
        );
      });

      for (var i = 0; i < filterRoutes.length; i++) {
        if (filterRoutes[i].views) {
          var returnViews = [];
          for (var l = 0; l < filterRoutes[i].views.length; l++) {
            var currentView = filterRoutes[i].views[l];
            if (
              !currentView.registrationType ||
              currentView.registrationType == registrationType ||
              registrationType === undefined
            ) {
              returnViews.push(currentView);
            } else {
              //its not valid for the user
            }
          }
          filterRoutes[i].views = returnViews;
        }
      }
      setFilteredRoutes(filterRoutes);
    },
    [registrationType]
  );

  const location = useLocation();
  // useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     const container = document.querySelector("#hello");
  //     ps = new PerfectScrollbar(container, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //     document.body.style.overflow = "hidden";
  //   }
  //   window.addEventListener("resize", resizeFunction);
  // });

  const resizeFunction = () => {
    console.log("InnerWidth" + window.innerWidth);
    if (window.innerWidth >= 960) {
      setMobileView(false);
    } else {
      setMobileView(true);
    }
  };

  const checkForActiveOrg = function() {
    const org = getActiveOrg();

    if (org) {
      setActiveOrg(org);
    } else {
      if (
        location.pathname != paths.orgSignIn &&
        !location.pathname.includes(paths.createOrganisation)
      ) {
        history.push(paths.orgSignIn);
      }
    }
  };

  /*constructor(props) {
    super(props);
    
    this.resizeFunction = this.resizeFunction.bind(this);
  }

  componentDidMount() {
    
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  
  
  
  */

  const switchRoutes = (
    <Switch>
      {filteredRoutes.map((prop, key) => {
        if (prop.redirect)
          return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
        if (prop.collapse)
          return prop.views.map((prop, key) => {
            return (
              <Route
                path={prop.path}
                component={withAuthenticationRequired(prop.component, {
                  onRedirecting: () => <div>Loading...</div>,
                })}
                key={key}
              />
            );
          });
        return (
          <Route
            path={prop.path}
            component={withAuthenticationRequired(prop.component, {
              onRedirecting: () => <div>Loading...</div>,
            })}
            key={key}
          />
        );
      })}
    </Switch>
  );
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={filteredRoutes}
        logoText={""}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={miniActive}
        color="blue"
        bgColor="black"
        miniActive={miniActive}
        active={open}
        handleToggle={handleToggle}
        {...rest}
      />

      {/*<div style={mobileView ? {'margin-left': '0px','margin-top': '80px',padding:'3%'} : {'margin-left': '260px', 'margin-top': '80px',padding:'1%', width: 'calc(100% - 260px)'}}  id={"hello"} ref={refMainPanel}  >*/}
      <div
        style={
          mobileView
            ? { "margin-left": "0px", padding: "3%" }
            : { "margin-left": "260px", padding: "0%" }
        }
        id={"hello"}
        ref={refMainPanel}
      >
        <Header
          sidebarMinimize={sidebarMinimize}
          miniActive={miniActive}
          routes={filteredRoutes}
          handleDrawerToggle={handleDrawerToggle}
          handleToggle={handleToggle}
          {...props}
        />
        <ReduxNotificationHeaderGroup
          key={props.toasts}
          toasts={props.toasts}
        />
        {props.selectedCustomerId && !props.linkedAnimalId ? (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-200px" }}
            title="Are you sure?"
            onConfirm={() => {
              props.raiseAction({
                fieldIdentifier: "selectedCustomerId",
                value: undefined,
              }) ||
                props.dispatch(
                  deleteContactWithDispatch(
                    props.callApi,
                    props.selectedCustomerId
                  )
                );
            }}
            onCancel={() => {
              props.raiseAction({
                fieldIdentifier: "selectedCustomerId",
                value: undefined,
              });
            }}
            confirmBtnCssClass={
              props.classes.button + " " + props.classes.success
            }
            cancelBtnCssClass={
              props.classes.button + " " + props.classes.danger
            }
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
          >
            Deleting a customer is permanent
            <div
              className="close-btn"
              onClick={() => {
                props.raiseAction({
                  fieldIdentifier: "selectedCustomerId",
                  value: undefined,
                });
              }}
            >
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M17.1569 14.5253L28.4489 3.23295C29.1838 2.49842 29.1838 1.3108 28.4489 0.576282C27.7144 -0.15824 26.5267 -0.15824 25.7922 0.576282L14.4998 11.8686L3.20781 0.576282C2.47295 -0.15824 1.28567 -0.15824 0.55115 0.576282C-0.183717 1.3108 -0.183717 2.49842 0.55115 3.23295L11.8432 14.5253L0.55115 25.8177C-0.183717 26.5522 -0.183717 27.7398 0.55115 28.4743C0.917207 28.8407 1.39852 29.0248 1.87948 29.0248C2.36045 29.0248 2.84141 28.8407 3.20781 28.4743L14.4998 17.182L25.7922 28.4743C26.1586 28.8407 26.6396 29.0248 27.1206 29.0248C27.6015 29.0248 28.0825 28.8407 28.4489 28.4743C29.1838 27.7398 29.1838 26.5522 28.4489 25.8177L17.1569 14.5253Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="29" height="29" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </SweetAlert>
        ) : (
          ""
        )}
        {props.selectedPhoneId && props.metaDataId ? (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-200px" }}
            title="Are you sure?"
            onConfirm={() => {
              (props.raiseAction({
                fieldIdentifier: "selectedPhoneId",
                value: undefined,
              }) &&
                props.raiseAction({
                  fieldIdentifier: "metaDataId",
                  value: undefined,
                })) ||
                props.dispatch(
                  deleteMetaDataWithDispatch(
                    props.metaDataId,
                    props.selectedPhoneId,
                    (resp) => {
                      dispatch(
                        readContactCompleteWithDispatch(
                          props.linkedContactId,
                          getActiveOrg(),
                          (resp) => {}
                        )
                      );
                    }
                  )
                );
            }}
            onCancel={() => {
              props.raiseAction({
                fieldIdentifier: "selectedPhoneId",
                value: undefined,
              });
              props.raiseAction({
                fieldIdentifier: "metaDataId",
                value: undefined,
              });
            }}
            confirmBtnCssClass={
              props.classes.button + " " + props.classes.success
            }
            cancelBtnCssClass={
              props.classes.button + " " + props.classes.danger
            }
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
          >
            Deleting a phone number is permanent
            <div
              className="close-btn"
              onClick={() => {
                props.raiseAction({
                  fieldIdentifier: "selectedPhoneId",
                  value: undefined,
                });
                props.raiseAction({
                  fieldIdentifier: "metaDataId",
                  value: undefined,
                });
              }}
            >
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M17.1569 14.5253L28.4489 3.23295C29.1838 2.49842 29.1838 1.3108 28.4489 0.576282C27.7144 -0.15824 26.5267 -0.15824 25.7922 0.576282L14.4998 11.8686L3.20781 0.576282C2.47295 -0.15824 1.28567 -0.15824 0.55115 0.576282C-0.183717 1.3108 -0.183717 2.49842 0.55115 3.23295L11.8432 14.5253L0.55115 25.8177C-0.183717 26.5522 -0.183717 27.7398 0.55115 28.4743C0.917207 28.8407 1.39852 29.0248 1.87948 29.0248C2.36045 29.0248 2.84141 28.8407 3.20781 28.4743L14.4998 17.182L25.7922 28.4743C26.1586 28.8407 26.6396 29.0248 27.1206 29.0248C27.6015 29.0248 28.0825 28.8407 28.4489 28.4743C29.1838 27.7398 29.1838 26.5522 28.4489 25.8177L17.1569 14.5253Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="29" height="29" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </SweetAlert>
        ) : (
          ""
        )}
        {props.selectedAnimalId && !props.linkedContactId ? (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-200px" }}
            title="Are you sure?"
            onConfirm={() => {
              props.raiseAction({
                fieldIdentifier: "selectedAnimalId",
                value: undefined,
              }) ||
                props.dispatch(
                  deleteAnimalWithDispatch(
                    props.callApi,
                    props.selectedAnimalId
                  )
                );
            }}
            onCancel={() => {
              props.raiseAction({
                fieldIdentifier: "selectedAnimalId",
                value: undefined,
              });
            }}
            confirmBtnCssClass={
              props.classes.button + " " + props.classes.success
            }
            cancelBtnCssClass={
              props.classes.button + " " + props.classes.danger
            }
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
          >
            Deleting a patient is permanent
            <div
              className="close-btn"
              onClick={() => {
                props.raiseAction({
                  fieldIdentifier: "selectedAnimalId",
                  value: undefined,
                });
              }}
            >
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M17.1569 14.5253L28.4489 3.23295C29.1838 2.49842 29.1838 1.3108 28.4489 0.576282C27.7144 -0.15824 26.5267 -0.15824 25.7922 0.576282L14.4998 11.8686L3.20781 0.576282C2.47295 -0.15824 1.28567 -0.15824 0.55115 0.576282C-0.183717 1.3108 -0.183717 2.49842 0.55115 3.23295L11.8432 14.5253L0.55115 25.8177C-0.183717 26.5522 -0.183717 27.7398 0.55115 28.4743C0.917207 28.8407 1.39852 29.0248 1.87948 29.0248C2.36045 29.0248 2.84141 28.8407 3.20781 28.4743L14.4998 17.182L25.7922 28.4743C26.1586 28.8407 26.6396 29.0248 27.1206 29.0248C27.6015 29.0248 28.0825 28.8407 28.4489 28.4743C29.1838 27.7398 29.1838 26.5522 28.4489 25.8177L17.1569 14.5253Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="29" height="29" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </SweetAlert>
        ) : (
          ""
        )}

        {(props.selectedAnimalId && props.linkedContactId) ||
        (props.selectedCustomerId && props.linkedAnimalId) ? (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-200px" }}
            title="Are you sure?"
            className="dssdasadsdasd"
            onConfirm={() => {
              dispatch(
                deleteMapPatientCustomer(
                  props.selectedAnimalId || props.linkedAnimalId,
                  props.selectedCustomerId || props.linkedContactId,
                  getActiveOrg(),
                  () => {
                    window.location.reload();
                  }
                )
              );
              props.raiseAction({
                fieldIdentifier: "selectedCustomerId",
                value: undefined,
              });
              props.raiseAction({
                fieldIdentifier: "selectedAnimalId",
                value: undefined,
              });
              props.raiseAction({
                fieldIdentifier: "linkedContactId",
                value: undefined,
              });
              props.raiseAction({
                fieldIdentifier: "linkedAnimalId",
                value: undefined,
              });
            }}
            onCancel={() => {
              props.raiseAction({
                fieldIdentifier: "selectedCustomerId",
                value: undefined,
              });
              props.raiseAction({
                fieldIdentifier: "selectedAnimalId",
                value: undefined,
              });
              props.raiseAction({
                fieldIdentifier: "linkedContactId",
                value: undefined,
              });
              props.raiseAction({
                fieldIdentifier: "linkedAnimalId",
                value: undefined,
              });
            }}
            confirmBtnCssClass={
              props.classes.button + " " + props.classes.success
            }
            cancelBtnCssClass={
              props.classes.button + " " + props.classes.danger
            }
            confirmBtnText="Yes, unlink them"
            cancelBtnText="Cancel"
            showCancel
          >
            Unlink a patient and contact
            <div
              className="close-btn"
              onClick={() => {
                props.raiseAction({
                  fieldIdentifier: "selectedCustomerId",
                  value: undefined,
                });
                props.raiseAction({
                  fieldIdentifier: "selectedAnimalId",
                  value: undefined,
                });
                props.raiseAction({
                  fieldIdentifier: "linkedContactId",
                  value: undefined,
                });
                props.raiseAction({
                  fieldIdentifier: "linkedAnimalId",
                  value: undefined,
                });
              }}
            >
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M17.1569 14.5253L28.4489 3.23295C29.1838 2.49842 29.1838 1.3108 28.4489 0.576282C27.7144 -0.15824 26.5267 -0.15824 25.7922 0.576282L14.4998 11.8686L3.20781 0.576282C2.47295 -0.15824 1.28567 -0.15824 0.55115 0.576282C-0.183717 1.3108 -0.183717 2.49842 0.55115 3.23295L11.8432 14.5253L0.55115 25.8177C-0.183717 26.5522 -0.183717 27.7398 0.55115 28.4743C0.917207 28.8407 1.39852 29.0248 1.87948 29.0248C2.36045 29.0248 2.84141 28.8407 3.20781 28.4743L14.4998 17.182L25.7922 28.4743C26.1586 28.8407 26.6396 29.0248 27.1206 29.0248C27.6015 29.0248 28.0825 28.8407 28.4489 28.4743C29.1838 27.7398 29.1838 26.5522 28.4489 25.8177L17.1569 14.5253Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="29" height="29" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </SweetAlert>
        ) : (
          ""
        )}

        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <GridContainer>
            <GridItem md={12} lg={12}>
              <div className={classes.content}>
                <div className={classes.container}>{switchRoutes}</div>
                {getSidePanel(location.pathname)}
              </div>
            </GridItem>
            {/*<GridItem md={4} lg={4}>  {getSidePanel(props.location.pathname, props)}</GridItem>	*/}
          </GridContainer>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer fluid /> : null}
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    selectedAnimalId: state.pwReducer.selectedAnimalId,
    selectedPhoneId: state.pwReducer.selectedPhoneId,
    metaDataId: state.pwReducer.metaDataId,
    selectedCustomerId: state.pwReducer.selectedCustomerId,
    linkedContactId: state.pwReducer.linkedContactId,
    linkedAnimalId: state.pwReducer.linkedAnimalId,

    userLang: state.pwReducer.selfProfile.languageKey || 1,
    toasts: state.pwReducer.toasts || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    raiseAction: (payload) => {
      dispatch(raisers.createActionGeneric(payload, actions.cw.SET_STATE_VAL));
    },
    dispatch: dispatch,
    callApi: bindActionCreators(makeApiCall, dispatch),
  };
}

const style = {
  ...userProfileStyles,
  ...sweetAlertStyle,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Dashboard));
