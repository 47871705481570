import React from "react";
import { FormattedMessage } from "react-intl";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import { connect } from "react-redux";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import ReduxCardCustomInput from "components/CoreWeb/ReduxCardCustomInput";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
class AddressViewOptions extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="sl-pd-22">
        <Card>
          <CardHeader>
            <div className="self oni">
              <h3 className="myhead nosp st-t">Add New Address</h3>
              <p>Fill the Following Details</p>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer className="add-address-new">
              <GridItem xs={12} sm={6} md={12} className="no-card Create-input-box">
                <ReduxCardCustomInput
                  header={"Line 1"}
                  fieldIdentifier={"addressDetails.line1"}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={12} className="no-card Create-input-box">
                <ReduxCardCustomInput
                  header={"Line 2"}
                  fieldIdentifier={"addressDetails.line2"}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} className="no-card Create-input-box">
                <ReduxCardCustomInput
                  header={"Town"}
                  fieldIdentifier={"addressDetails.town"}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} className="no-card Create-input-box">
                <ReduxCardCustomInput
                  header={"City"}
                  fieldIdentifier={"addressDetails.city"}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} className="no-card Create-input-box">
                <ReduxCardCustomInput
                  header={"Zip/Postal Code"}
                  fieldIdentifier={"addressDetails.zippost"}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(userProfileStyles)(AddressViewOptions));
