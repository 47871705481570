import * as cw from "actions/coreActionTypes";
const pw = {
  UP_SRPROFILE_NAMECHANGE: "UP_SRPROFILE_NAMECHANGE",
  UP_SRPROFILE_TYPECHANGE: "UP_SRPROFILE_TYPECHANGE",
  UP_SRPROFILE_ANSWCHANGE: "UP_SRPROFILE_ANSWCHANGE",
  UP_SRPROFILE_HOWTOSELLADD: "UP_SRPROFILE_HOWTOSELLADD",
  UP_SRPROFILE_HOWTOSELLRM: "UP_SRPROFILE_HOWTOSELLRM",
  UP_SRPCAMPAIGN_COMMISSIONPERC: "UP_SRPCAMPAIGN_COMMISSIONPERC",
};
export { pw, cw };
