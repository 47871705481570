import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput.jsx";
import * as actions from "actions/projectActionTypes";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  ...customSelectStyle,
};

class CreateServiceStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      desgin: false,
      code: false,
      develop: false,
    };
  }

  sendState() {
    return this.state;
  }
  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  isValidated() {
    return true;
  }

  render() {
    return (
      <div className="sl-pd-22 mt-b-50">
        <GridItem xs={12} md={12} className="m-20 input-box service-details">
          <label>Service Name</label>
          <ReduxCustomInput
            //mainTextLabel={"Service name"}
            //subTextLabel="(required)"
            inputProps={{ placeholder: "Enter Service Name" }}
            updateAction={actions.cw.UPREDUXCUSTOMINPUT}
            fieldIdentifier={"serviceDetails.name"}
            multiLine={1}
            className="input-box"
            disabled={this.props.orgId <= 0 ? true : false}
          />
        </GridItem>
        <GridItem xs={12} md={12} className="m-20 input-box service-details">
          <label>Service Description</label>
          <ReduxCustomInput
            //mainTextLabel={"Service description"}
            inputProps={{ placeholder: "Write about service" }}
            updateAction={actions.cw.UPREDUXCUSTOMINPUT}
            fieldIdentifier={"serviceDetails.description"}
            multiLine={5}
            disabled={this.props.orgId <= 0 ? true : false}
          />
        </GridItem>
      </div>
    );
  }
}

export default withStyles(style)(CreateServiceStep1);
