import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GenderSelect from "components/CoreWeb/GenderSelect";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import DeSexedStatus from "components/CoreWeb/DeSexedStatus";
import BloodGroup from "components/CoreWeb/BloodGroup";
import MonetaryCurrency from "components/CoreWeb/MonetaryCurrency";

import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput.jsx";
import { getActiveOrg } from "variables/coreWeb.jsx";
import * as makeApiCall from "actions/makeApiCall";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import Button from "components/CustomButtons/Button.jsx";
import * as actions from "actions/projectActionTypes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { updateProduct } from "../../variables/coreRequests";
import { createProduct } from "../../variables/coreCreateRequests";
import { updateProductWithDispatch } from "../../variables/thunks";
import { FormattedMessage } from "react-intl";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  ...customSelectStyle,
};

class ViewProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  sendState() {
    return this.state;
  }
  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  isValidated() {
    return true;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.props.match && this.props.match.params.productId != undefined ? (
          <CoreApiReq
            endpointToCall={"Read_Product"}
            afterActionHandler={actions.cw.GOT_PRODUCT_DETAILS}
            data={{
              orgId: getActiveOrg(),
              productId: this.props.match.params.productId,
            }}
          />
        ) : (
          ""
        )}

        {this.props.match && this.props.match.params.productId != undefined ? (
          <Card>
            <CardBody>
              <h4>
                <FormattedMessage id="productId" />
              </h4>

              <ReduxCustomInput
                updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                fieldIdentifier={"productDetails.productId"}
                multiLine={1}
                disabled={true}
              />
            </CardBody>
          </Card>
        ) : (
          ""
        )}
        {this.props.match && this.props.match.params.productId != undefined ? (
          <Card>
            <CardBody>
              <h4>
                <FormattedMessage id="systemId" />
              </h4>

              <ReduxCustomInput
                updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                fieldIdentifier={"productDetails.systemProductCode"}
                multiLine={1}
                disabled={true}
              />
            </CardBody>
          </Card>
        ) : (
          ""
        )}

        <Card>
          <CardBody>
            <h4>
              <FormattedMessage id="userProductReference" />
            </h4>
            <ReduxCustomInput
              updateAction={actions.cw.UPREDUXCUSTOMINPUT}
              fieldIdentifier={"productDetails.userProductCode"}
              multiLine={1}
            />
          </CardBody>
        </Card>

        {/* <Card>
          <CardBody>
           
            <ReduxCustomInput
              updateAction={actions.cw.UPREDUXCUSTOMINPUT}
              fieldIdentifier={"prodRef"}
              textValue={this.props.prodRef}
              multiLine={1}
            />
          </CardBody>
        </Card> */}
        <h4>
          <FormattedMessage id="productDetails" />
        </h4>
        <Card>
          <CardBody>
            <h4>
              <FormattedMessage id="productName" />
            </h4>
            <ReduxCustomInput
              updateAction={actions.cw.UPREDUXCUSTOMINPUT}
              fieldIdentifier={"productDetails.productName"}
              multiLine={1}
            />
          </CardBody>
        </Card>
        {/* <Card>
          <CardBody>
            <h4>Product Description</h4>      
            <ReduxCustomInput
              updateAction={actions.cw.UPREDUXCUSTOMINPUT}
              fieldIdentifier={"productDesc"}
              textValue={this.props.productDesc}
              multiLine={5}
            />
          </CardBody>
        </Card> */}
        <Card>
          <CardBody>
            <h4>
              <FormattedMessage id="productCurrency" />
            </h4>
            <MonetaryCurrency />
          </CardBody>
        </Card>
        <h4>
          <FormattedMessage id="purchasing" />
        </h4>

        <Card>
          <CardBody>
            <h4>
              <FormattedMessage id="purchaseCost" />
            </h4>
            <ReduxCustomInput
              updateAction={actions.cw.UPREDUXCUSTOMINPUT}
              fieldIdentifier={"productDetails.purchasePrice.monetaryAmount"}
              multiLine={1}
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h4>
              <FormattedMessage id="productDetails" />
            </h4>
            <ReduxCustomInput
              updateAction={actions.cw.UPREDUXCUSTOMINPUT}
              fieldIdentifier={"productDetails.purchaseTaxRate"}
              multiLine={1}
            />
          </CardBody>
        </Card>
        <h4>
          <FormattedMessage id="sales" />
        </h4>
        <Card>
          <CardBody>
            <h4>
              <FormattedMessage id="salesPrice" />
            </h4>
            <ReduxCustomInput
              updateAction={actions.cw.UPREDUXCUSTOMINPUT}
              fieldIdentifier={"productDetails.salesPrice.monetaryAmount"}
              multiLine={1}
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h4>
              <FormattedMessage id="salesTaxRate" />
            </h4>
            <ReduxCustomInput
              updateAction={actions.cw.UPREDUXCUSTOMINPUT}
              fieldIdentifier={"productDetails.salesTaxRate"}
              multiLine={1}
            />
          </CardBody>
        </Card>

        <GridContainer justify="center" style={{ marginTop: "5%" }}>
          <GridItem>
            {this.props.match && this.props.match.params.productId ? (
              <Button
                color="rose"
                onClick={() => {
                  this.props.dispatch(
                    updateProductWithDispatch(
                      this.props.callApi,
                      this.props.productDetails
                    )
                  );
                }}
              >
                <FormattedMessage id="update" />
              </Button>
            ) : (
              <Button
                color="rose"
                onClick={() => {
                  createProduct(this.props.callApi, this.props.productDetails);
                }}
              >
                <FormattedMessage id="createProduct" />
              </Button>
            )}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    productDetails: state.pwReducer.productDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    dispatch: dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ViewProduct));
