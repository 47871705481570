import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as makeApiCall from "actions/makeApiCall";
import * as actions from "actions/projectActionTypes";
import Button from "components/CustomButtons/Button.jsx";
import ReduxCustomDateTime from "components/CoreWeb/ReduxCustomDateTime";

import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput.jsx";
import MedicalNoteType from "../../components/CoreWeb/MedicalNoteType";
import {
  createMedicalWithDispatch,
  updateMedicalWithDispatch,
} from "../../variables/thunks";
import WeightUnits from "../../components/CoreWeb/WeightUnits";
import HeightUnits from "../../components/CoreWeb/HeightUnits";
import TemperatureUnits from "../../components/CoreWeb/TemperatureUnits";
import NumberRangeSelect from "../../components/CoreWeb/NumberRangeSelect";
import CheckboxesTags from "./CustomSelection";
import { useDropzone } from "react-dropzone";

const styles = {};

const MedicalRecordNote = function(props) {
  const { classes } = props;

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    console.log("Accepted", acceptedFiles);
  }, []);

  const maxSize = 1048576;

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    rejectedFiles,
    isDragReject,
    isFileTooLarge,
  } = useDropzone({
    onDrop: onDrop,
    accept: "application/pdf, application/doc, application/docx",
    minSize: 0,
    maxSize: maxSize, // 5 MB
  });

  let acceptedFileItems;
  if (acceptedFiles && acceptedFiles !== undefined) {
    acceptedFileItems = acceptedFiles.map((file) => {
      return (
        <li key={file.path}>
          {file.path} - {file.size} bytes
        </li>
      );
    });
  }

  console.log("Accepted Files", acceptedFiles);

  const files =
    acceptedFileItems && acceptedFileItems.length !== 0 ? (
      <div>
        <h4>Accepted Files: </h4> <ul>{acceptedFileItems}</ul>
      </div>
    ) : (
      ""
    );

  console.log("Rejected Files", rejectedFiles);

  let fileRejectionList;
  if (rejectedFiles && rejectedFiles !== undefined) {
    fileRejectionList = rejectedFiles.map((file) => {
      console.log(file);
      return (
        <li key={file.path}>
          {file.path} - {file.size} bytes
          {/* <ul>
            {errors.map((e) => (
              <li key={e.code}>{e.message}</li>
            ))}
          </ul> */}
        </li>
      );
    });
  }

  const rejectedErrors =
    fileRejectionList && fileRejectionList.length !== 0 ? (
      <div>
        <h4>Upload Rejected - Invalid Files: </h4> <ul>{fileRejectionList}</ul>
      </div>
    ) : (
      ""
    );

  //console.log("Creating Note Record:1 ", props.medicalRecord);

  return (
    <div>
      <h4>
        <FormattedMessage id="newMedicalNote" />
        <small>
          {" "}
          - <FormattedMessage id="CreatePatientRecord" />
        </small>
      </h4>
      <GridContainer justify={"center"}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <Card>
                <CardBody>
                  <h4>
                    <FormattedMessage id="date" />
                  </h4>
                  <ReduxCustomDateTime
                    dateFormat={true}
                    timeFormat={false}
                    fieldIdentifier={"medicalRecord.date"}
                  />
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={6} lg={6}>
              <Card>
                <CardBody>
                  <h4>
                    <FormattedMessage id="time" />
                  </h4>
                  <ReduxCustomDateTime
                    dateFormat={false}
                    timeFormat={true}
                    fieldIdentifier={"medicalRecord.time"}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>

          <Card>
            <CardBody>
              <MedicalNoteType
                fieldIdentifier={"medicalRecord.medicalNoteType"}
              />
            </CardBody>
          </Card>

          {/* Weight Measurement */}
          <GridContainer>
            {props.medicalRecord && props.medicalNoteType == 9 ? (
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <Card>
                  <CardBody>
                    <h4>
                      <FormattedMessage id="weightMeasurement" />
                    </h4>
                    <ReduxCustomInput
                      updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                      fieldIdentifier={"medicalRecord.weightMeasurement"}
                      multiLine={1}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            ) : (
              " "
            )}

            {props.medicalRecord && props.medicalNoteType == 9 ? (
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <Card>
                  <CardBody>
                    <WeightUnits
                      fieldIdentifier={"medicalRecord.weightUnits"}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            ) : (
              " "
            )}
          </GridContainer>

          {/* Height Measurement */}
          <GridContainer>
            {props.medicalRecord && props.medicalNoteType == 10 ? (
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <Card>
                  <CardBody>
                    <h4>
                      <FormattedMessage id="heightMeasurement" />
                    </h4>
                    <ReduxCustomInput
                      updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                      fieldIdentifier={"medicalRecord.heightMeasurement"}
                      multiLine={1}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            ) : (
              " "
            )}

            {props.medicalRecord && props.medicalNoteType == 10 ? (
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <Card>
                  <CardBody>
                    <HeightUnits
                      fieldIdentifier={"medicalRecord.heightUnits"}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            ) : (
              " "
            )}
          </GridContainer>

          {/* Temperature Measurement */}
          <GridContainer>
            {props.medicalRecord && props.medicalNoteType == 12 ? (
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <Card>
                  <CardBody>
                    <h4>
                      <FormattedMessage id="temperatureMeasurement" />
                    </h4>
                    <ReduxCustomInput
                      updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                      fieldIdentifier={"medicalRecord.temperatureMeasurement"}
                      multiLine={1}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            ) : (
              " "
            )}

            {props.medicalRecord && props.medicalNoteType == 12 ? (
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <Card>
                  <CardBody>
                    <TemperatureUnits
                      fieldIdentifier={"medicalRecord.temperatureUnits"}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            ) : (
              " "
            )}
          </GridContainer>

          {/* Pulse Measurement */}

          {props.medicalRecord && props.medicalNoteType == 13 ? (
            <Card>
              <CardBody>
                <h4>
                  <FormattedMessage id="pulseMeasurement" />
                </h4>
                {/* <NumberRangeSelect
                    placeholder={<FormattedMessage id="pulseMeasurementNote" />}
                    start={0}
                    stop={200}
                    useValueNotIndex={true}
                    fieldIdentifier={"medicalRecord.pulseMeasurement"}
                  />   */}
                <CheckboxesTags />
              </CardBody>
            </Card>
          ) : (
            " "
          )}

          {/* Respiration Measurement */}

          {props.medicalRecord && props.medicalNoteType == 14 ? (
            <Card>
              <CardBody>
                <h4>
                  <FormattedMessage id="respirationMeasurement" />
                </h4>
                <NumberRangeSelect
                  placeholder={
                    <FormattedMessage id="respirationMeasurementNote" />
                  }
                  start={0}
                  stop={200}
                  useValueNotIndex={true}
                  fieldIdentifier={"medicalRecord.respirationMeasurement"}
                />
              </CardBody>
            </Card>
          ) : (
            " "
          )}

          {props.medicalRecord &&
          props.medicalNoteType != 9 &&
          props.medicalNoteType != 10 &&
          props.medicalNoteType != 12 &&
          props.medicalNoteType != 13 &&
          props.medicalNoteType != 14 ? (
            <Card>
              <CardBody>
                <h4>
                  <FormattedMessage id="medicalNoteDetails" />
                </h4>
                <ReduxCustomInput
                  updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                  fieldIdentifier={"medicalRecord.noteDescription"}
                  multiLine={3}
                />
              </CardBody>
            </Card>
          ) : (
            " "
          )}

          <Card>
            <CardBody>
              <div {...getRootProps()}>
                <h4>
                  <FormattedMessage id="medicalNoteDocument" />
                </h4>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
                <em>(Only *.pdf and *.doc files will be accepted)</em>
                {/* {isDragReject ? <p>File is invalid, sorry!</p> : ""} */}
                {isFileTooLarge && <p>File is too large.</p>}
              </div>

              {files}
              {rejectedErrors}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem>
          <Button
            color="rose"
            className={classes.updateProfileButton}
            onClick={() => {
              if (props.selectedMedicalNoteId) {
                props.dispatch(
                  updateMedicalWithDispatch(
                    props.callApi,
                    props.medicalRecord,
                    props.animalId,
                    props.selectedMedicalNoteId
                  )
                );
              } else {
                props.dispatch(
                  createMedicalWithDispatch(
                    props.callApi,
                    props.medicalRecord,
                    props.animalId
                  )
                );
              }
            }}
          >
            {props.selectedMedicalNoteId ? (
              <FormattedMessage id="updateEntry" />
            ) : (
              <FormattedMessage id="createNewEntry" />
            )}
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const medicalRecord = state.pwReducer.medicalRecord;
  console.log("Medical Record ID", state.pwReducer.selectedMedicalNoteId);
  console.log("Medical Record", medicalRecord);
  return {
    medicalRecord: medicalRecord,
    medicalNoteType: medicalRecord.medicalNoteType,
    selectedMedicalNoteId: state.pwReducer.selectedMedicalNoteId,
    animalId:
      ownProps && ownProps.match
        ? ownProps.match.params.animalId
        : window.location.pathname.split("/view/")[1],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    dispatch: dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MedicalRecordNote));
