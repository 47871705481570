import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Datetime from "react-datetime";
import { connect } from "react-redux";
import * as actions from "actions/projectActionTypes";
import * as raisers from "actions/projectRaisers";
import { getObjProp } from "variables/coreWeb.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import InputLabel from "@material-ui/core/InputLabel";

import FormHelperText from "@material-ui/core/FormHelperText";
import classNames from "classnames";
const styles = {
  ...userProfileStyles,
};

class CustomDateTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes, formControlProps, error, success } = this.props;
    const labelClasses = classNames({
      [" " + classes.labelRootError]: error,
      [" " + classes.labelRootSuccess]: success && !error,
    });
    return (
      <FormControl fullWidth disabled={this.props.disabled}>
        {this.props.labelText !== undefined ? (
          <InputLabel
            className={classes.labelRoot + " " + labelClasses}
            htmlFor={this.props.id}
            {...this.props.labelProps}
          >
            {this.props.labelText}
          </InputLabel>
        ) : null}
        {this.props.placeholder && (
          <FormHelperText>{this.props.placeholder}</FormHelperText>
        )}
        <Datetime
          timeFormat={this.props.timeFormat}
          initialValue={new Date()}
          //timeFormat={false}
          inputProps={
            this.props.inputProps || {
              placeholder: this.props.placeholder || "",
            }
          }
          onChange={(value) => this.props.textChanged(value)}
          value={this.props.textValue}
          dateFormat={this.props.dateFormat}
        />
      </FormControl>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    textValue: ownProps.textValue,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    textChanged: (payload) => {
      if (ownProps.textChanged) ownProps.textChanged(payload);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CustomDateTime));
