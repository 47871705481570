import React from "react";
import { FormattedMessage } from "react-intl";

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Visibility from "@material-ui/icons/Visibility";
import { paths } from "variables/projPaths.jsx";
import {
  changeUrl,
  viewButton,
  deleteButton,
  orderStatusFromType,
} from "variables/coreWeb.jsx";
import { labelFromType } from "variables/project.jsx";
import { getActiveOrg } from "variables/coreWeb.jsx";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import { connect } from "react-redux";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import * as makeApiCall from "actions/makeApiCall";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import * as raisers from "actions/projectRaisers";
import Clear from "@material-ui/icons/Clear";
import vieweye from "assets/img/view.svg";
import deleicon from "assets/img/delete.svg";
const style = {
  ...userProfileStyles,
  ...sweetAlertStyle,
};

const deleteStaff = function(callMe, staffId) {
  var payLoad = {};
  payLoad.userId = staffId;
  payLoad.orgId = getActiveOrg();

  callMe.req(
    localStorage.getItem("access_token"),
    "Delete_Member",
    payLoad,
    actions.cw.API_ANIMAL_DELETE
  );
  // alert("coming soon");
};

const OrgStaffList = function(props) {
  const { classes } = props;
  const fillButtons = function(theProps, staffId) {
    return (
      <div className="">
        <Link to={paths.staffView + "/" + staffId} className="cur">
          <button className="sm selfview">
            <img src={vieweye} />
          </button>
        </Link>
        <button
          onClick={() => {
            theProps.raiseAction({
              fieldIdentifier: "selectedStaffId",
              value: staffId,
            });
          }}
          className="sm danger"
        >
          <img src={deleicon} />
        </button>
        {/* <Link to={paths.staffView + "/" + staffId}>
          <Button className={classes.actionButton} color="info" size="sm">
            <Visibility className={classes.icon} />
          </Button>
        </Link>

        <Button
          className={classes.actionButton}
          color="danger"
          size="sm"
          onClick={() => {
            theProps.raiseAction({
              fieldIdentifier: "selectedStaffId",
              value: staffId,
            });
          }}
        >
          <Clear className={classes.icon} />
        </Button> */}
      </div>
    );
  };

  return (
    <GridContainer justify={"center"} className="sl-pd-22 table-container">
      <CoreApiReq
        endpointToCall={"Read_All_Organisation_Users"}
        afterActionHandler={actions.cw.GOT_ALL_ORG_USERS}
        data={{
          orgId: getActiveOrg(),
        }}
      />
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Link to={paths.memberCreate}>
          <Button className="btn-primary staff-member btn-theme">
            + Create Staff Member
          </Button>
        </Link>
      </GridItem>

      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Card>
          <CardHeader>
            <h3 className="myhead nosp">
              <FormattedMessage id="staffList" />
            </h3>
            <p>
              {" "}
              <FormattedMessage id="manageYourStaff" />
            </p>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={props.userList.map((prop, key) => {
                return {
                  id: key,
                  name: prop.contactList[0].contactFullName,
                  position: labelFromType(prop[1]),
                  status: orderStatusFromType(prop[2]),
                  age: prop[3],
                  actions: fillButtons(props, prop.contactList[0].contactId),
                  workingFor: prop[2] != 1 ? "Name" : "Jeffy",
                  totalValue: "100$",
                  role: "Vet",
                  futureApp: 10,
                  vacationDays: 3,
                  contactId: prop.contactList[0].contactId,
                };
              })}
              filterable={false}
              columns={[
                {
                  Header: "Staff Id",
                  accessor: "contactId",
                },
                {
                  Header: <FormattedMessage id="staffName" />,
                  accessor: "name",
                },

                {
                  Header: <FormattedMessage id="role" />,
                  accessor: "role",
                },

                {
                  Header: <FormattedMessage id="actions" />,
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                },
              ]}
              defaultPageSize={10}
              showPaginationTop={false}
              showPaginationBottom={true}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
      {props.selectedStaffId ? (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px" }}
          title={<FormattedMessage id="areYouSure" />}
          onConfirm={() => {
            props.raiseAction({
              fieldIdentifier: "selectedStaffId",
              value: undefined,
            }) || deleteStaff(props.callApi, props.userId);
          }}
          onCancel={() => {
            props.raiseAction({
              fieldIdentifier: "selectedStaffId",
              value: undefined,
            });
          }}
          confirmBtnCssClass={
            props.classes.button + " " + props.classes.success
          }
          cancelBtnCssClass={props.classes.button + " " + props.classes.danger}
          confirmBtnText={<FormattedMessage id="deleteIt" />}
          cancelBtnText={<FormattedMessage id="cancel" />}
          showCancel
        >
          <FormattedMessage id="deletingStaffPermanent" />
        </SweetAlert>
      ) : (
        ""
      )}
    </GridContainer>
  );
};

function mapStateToProps(state) {
  return {
    userList: state.pwReducer.userList || [],
    selectedStaffId: state.pwReducer.selectedStaffId,
    userId: state.pwReducer.selfProfile.userId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    raiseAction: (payload) => {
      dispatch(raisers.createActionGeneric(payload, actions.cw.SET_STATE_VAL));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(OrgStaffList));
