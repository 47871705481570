import moment from "moment";
import { constVars } from "../variables/constVars";

export default {
  selfProfile: {},
  orgDetails: {
    organisationType: 1,
    employeeCount: 1,
    yearEstablished: 2020,
    yearsTrading: 0,
    monetaryCurrency: 752,
  },
  selfServiceOrderList: [],
  orgList: [],
  serviceFulfilmentConfig: {
    isActive: 2,
    prePaymentRequired: 2,
  },
  mandatoryResources: 0,
  optionalResources: 0,
  mandatoryStaff: 0,
  optionalStaff: 0,
  animalDetails: {
    dateOfBirth: moment().toISOString(),
    timeOfBirth: moment().toISOString(),
    passportNumber: "-",
    microchipId: "-",
    insuranceReferenceNumber: "-",
    gender: 2,
    animalSpecies: 2,
    desexedStatus: 4,
    bloodGroup: 13,
    isActive: 2,
  },
  phoneNumberType: 8,
  medicalRecord: {
    date: moment().toISOString(),
    time: moment().toISOString(),
    medicalNoteType: 2,
    weightMeasurement: 20,
    weightUnits: constVars.kilograms,
    heightMeasurement: 1,
    heightUnits: constVars.meters,
    temperatureMeasurement: 0,
    temperatureUnits: constVars.celcius,
    pulseMeasurement: 50,
    respirationMeasurement: 50,
  },
  calendar: {
    calendar1: {
      exStartDate: moment().toISOString(),
      exStartTime: moment().toISOString(),
      exEndDate: moment().toISOString(),
      exEndTime: moment()
        .add(1, "hours")
        .toISOString(),
      appointmentExceptionType: 2,
      resourceCompleteList: [],
      exceptions: [],
      appointments: [],
    },
  },
  contactDetails: {
    contactTitle: 2,
  },
  toasts: [],
  selectedGender: 2,
  newPhoneNumberType: 8,
  phoneNumberId: "",
  showOnCreateSuccess: true,
  currentOrgName: window.localStorage.getItem("currentOrgName"),
  searchKeyWord: "",
  searchResult: {},
};
