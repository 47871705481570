import React, { useEffect } from "react";
import PropTypes from "prop-types";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import YouTube from "react-youtube";
import FeedItem from "../FeedItem";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "actions/projectActionTypes";
import * as raisers from "actions/projectRaisers";

import * as makeApiCall from "actions/makeApiCall";

const feedStyle = {};
const CoreApiReq = (props) => {
  const { classes } = props;
  const access_token = localStorage.getItem("access_token");

  useEffect(
    () => {
      // HERE WE ARE TRIGGERING THE ACTION
      console.log(props.endpointToCall);
      console.log("REQUEST TOKEN", access_token);
      // if (access_token) {
      props.makeApiCall.req(
        access_token,
        props.endpointToCall,
        props.data,
        props.afterActionHandler,
        props.afterActionThunk
      );
      // }
    },
    [access_token]
  );

  return <div />;
};

CoreApiReq.propTypes = {
  endpointToCall: PropTypes.string,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    makeApiCall: bindActionCreators(makeApiCall, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(feedStyle)(CoreApiReq));
