import axios from "axios";
import { APIURL } from "./project";
import { constVars } from "./constVars";
import * as actions from "../actions/projectActionTypes";

axios.defaults.baseURL = `${APIURL}`;

export const getToken = () => {
  const ACCESSS_TOKEN = localStorage.getItem("access_token");
  return ACCESSS_TOKEN;
};

export const defaultPostRequest = (
  endpointUrl,
  data,
  payLoadAction,
  dispatch
) => {
  axios
    .post(endpointUrl, data || {}, {
      headers: {
        [constVars.Authorization]: `${constVars.Bearer} ${getToken()}`,
      },
    })
    .then((resp) => {
      dispatch({
        type: payLoadAction,
        payload: { ...resp.data },
      });
    });
};

export const apiGetSelfProfile = (dispatch, getState) => {
  return (dispatch, getState) => {
    defaultPostRequest(
      `${APIURL}Read_Self_Profile`,
      {},
      actions.cw.READ_SELFPROFILE,
      dispatch
    );
  };
};

export const apiGetDashboard = (dispatch, getState) => {
  return (dispatch, getState) => {
    defaultPostRequest(
      `${APIURL}ReadDashboard`,
      {},
      actions.cw.READ_DASHBOARD,
      dispatch
    );
  };
};
