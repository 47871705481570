import React from "react";
import { FormattedMessage } from "react-intl";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import CreateStaffMemberStep1 from "./WizardSteps/CreateStaffMemberStep1.jsx";
import ComingSoonStep from "components/CoreWeb/ComingSoonStep.jsx";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput.jsx";
import { getActiveOrg } from "variables/coreWeb.jsx";
import * as makeApiCall from "actions/makeApiCall";
import Button from "components/CustomButtons/Button.jsx";
import * as actions from "actions/projectActionTypes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import { createStaff } from "../../variables/coreCreateRequests.jsx";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useHistory } from "react-router-dom";
import { createStaffMember } from "variables/thunks";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  ...customSelectStyle,
};

const CreateStaffMember = function (props) {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div>
      <CreateStaffMemberStep1 />
      <GridContainer justify="center">
        <GridItem>
          <Button
            color="rose"
            onClick={() => {
              dispatch(
                createStaffMember(createStaff(props), () => {
                  history.push("/personell/existing");
                })
              );
            }}
          >
            <FormattedMessage id="createStaffMember" />
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    contactFirstName: state.pwReducer.staffFirstName,
    contactLastName: state.pwReducer.staffLastName,
    emailAddress: state.pwReducer.staffEmailAddress,
    orgId: getActiveOrg(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(CreateStaffMember));
