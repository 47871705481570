import { createSelectorCreator, defaultMemoize } from "reselect";
import createCachedSelector from "re-reselect";
import {
  calendarExceptionColor,
  calendarAppointmentColor,
} from "variables/project";

const getCalendar = (state, calendarName) =>
  state.pwReducer.calendar[calendarName] || {};
const getCalEx = (state, calendarName) =>
  getCalendar(state, calendarName).exceptions;
const getCalAp = (state, calendarName) =>
  getCalendar(state, calendarName).appointments;

export const exToEvents = function (rawExceptions) {
  console.log(rawExceptions);

  var resp = [];
  if (rawExceptions && rawExceptions.length > 0) {
    rawExceptions.map((exception) => {
      exception.timeScaleList.map((tso) => {
        resp.push({
          title: exception.exceptionTitle,
          start: tso.start,
          end: tso.end,
          color: calendarExceptionColor,
          resourceIds: exception.resourceIdList,
          id: tso.tsoId,
          extendedProps: { ...exception, ...tso },
        });
      });
    });
  }

  return resp;
};

export const apToEvents = function (rawExceptions) {
  console.log(rawExceptions);

  var resp = [];
  if (rawExceptions && rawExceptions.length > 0) {
    rawExceptions.map((exception) => {
      var idx = 0;
      exception.timeScaleList.map((tso) => {
        if (!resp.find((x) => x.id == tso.tsoId)) {
          resp.push({
            title: exception.appointmentTitle,
            start: tso.start,
            end: tso.end,
            color: calendarAppointmentColor,
            resourceIds: [
              exception.serviceFulfilmentConfigResourceMapList[idx].resourceId,
            ],
            id: tso.tsoId,
            extendedProps: { ...exception, ...tso },
          });
        }

        idx++;
      });
    });
  }

  return resp;
};

export const getCalendarExceptionEvents = createCachedSelector(
  [getCalEx],
  (items) => exToEvents(items)
)((_state_, calendarName) => calendarName);

export const getCalendarAppointmentEvents = createCachedSelector(
  [getCalAp],
  (items) => apToEvents(items)
)((_state_, calendarName) => calendarName);
