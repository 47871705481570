import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import StarRatingComponent from "react-star-rating-component";
import Visibility from "@material-ui/icons/Visibility";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import CardAvatar from "components/Card/CardAvatar.jsx";

import Check from "@material-ui/icons/Check";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ReduxCoreSelect from "components/CoreWeb/ReduxCoreSelect";
import { dataTable5, statusFromType } from "variables/general.jsx";
import { labelFromType } from "variables/project.jsx";
import * as actions from "actions/projectActionTypes";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  ...userProfileStyles,
};

const agreeDisagreeOpts = [
  { name: "Unknown", value: 1 },
  { name: "AED", value: 784 },
  { name: "AFN", value: 971 },
  { name: "AlbanianLek", value: +"008" },
  { name: "AMD", value: +"051" },
  { name: "ANG", value: 532 },
  { name: "AOA", value: 973 },
  { name: "ARS", value: +"032" },
  { name: "AUD", value: +"036" },
  { name: "AWG", value: 533 },
  { name: "AZN", value: 944 },
  { name: "BAM", value: 977 },
  { name: "BBD", value: +"052" },
  { name: "BDT", value: +"050" },
  { name: "BGN", value: 975 },
  { name: "BHD", value: +"048" },
  { name: "BIF", value: 108 },
  { name: "BMD", value: +"060" },
  { name: "BND", value: +"096" },
  { name: "BOB", value: +"068" },
  { name: "BOV", value: 984 },
  { name: "BRL", value: 986 },
  { name: "BSD", value: +"044" },
  { name: "BTN", value: +"064" },
  { name: "BWP", value: +"072" },
  { name: "BYN", value: 933 },
  { name: "BYR", value: 974 },
  { name: "BZD", value: +"084" },
  { name: "CAD", value: 124 },
  { name: "CDF", value: 976 },
  { name: "CHE", value: 947 },
  { name: "CHF", value: 756 },
  { name: "CHW", value: 948 },
  { name: "CLF", value: 990 },
  { name: "CLP", value: 152 },
  { name: "CNY", value: 156 },
  { name: "COP", value: 170 },
  { name: "COU", value: 970 },
  { name: "CRC", value: 188 },
  { name: "CUC", value: 931 },
  { name: "CUP", value: 192 },
  { name: "CVE", value: 132 },
  { name: "CZK", value: 203 },
  { name: "DJF", value: 262 },
  { name: "DKK", value: 208 },
  { name: "DOP", value: 214 },
  { name: "DZD", value: +"012" },
  { name: "EGP", value: 818 },
  { name: "ERN", value: 232 },
  { name: "ETB", value: 230 },
  { name: "EUR", value: 978 },
  { name: "FJD", value: 242 },
  { name: "FKP", value: 238 },
  { name: "GBP", value: 826 },
  { name: "GEL", value: 981 },
  { name: "GHS", value: 936 },
  { name: "GIP", value: 292 },
  { name: "GMD", value: 270 },
  { name: "GNF", value: 324 },
  { name: "GTQ", value: 320 },
  { name: "GYD", value: 328 },
  { name: "HKD", value: 344 },
  { name: "HNL", value: 340 },
  { name: "HRK", value: 191 },
  { name: "HTG", value: 332 },
  { name: "HUF", value: 348 },
  { name: "IDR", value: 360 },
  { name: "KMF", value: 174 },
  { name: "KPW", value: 408 },
  { name: "KRW", value: 410 },
  { name: "KWD", value: 414 },
  { name: "KYD", value: 136 },
  { name: "KZT", value: 398 },
  { name: "LAK", value: 418 },
  { name: "LBP", value: 422 },
  { name: "LKR", value: 144 },
  { name: "LRD", value: 430 },
  { name: "LSL", value: 426 },
  { name: "LYD", value: 434 },
  { name: "MAD", value: 504 },
  { name: "MDL", value: 498 },
  { name: "MGA", value: 969 },
  { name: "MKD", value: 807 },
  { name: "MMK", value: 104 },
  { name: "MNT", value: 496 },
  { name: "MOP", value: 446 },
  { name: "MRO", value: 478 },
  { name: "MUR", value: 480 },
  { name: "MVR", value: 462 },
  { name: "MWK", value: 454 },
  { name: "MXN", value: 484 },
  { name: "MXV", value: 979 },
  { name: "MYR", value: 458 },
  { name: "MZN", value: 943 },
  { name: "NAD", value: 516 },
  { name: "NGN", value: 566 },
  { name: "NIO", value: 558 },
  { name: "NOK", value: 578 },
  { name: "NPR", value: 524 },
  { name: "NZD", value: 554 },
  { name: "OMR", value: 512 },
  { name: "PAB", value: 590 },
  { name: "PEN", value: 604 },
  { name: "PGK", value: 598 },
  { name: "PHP", value: 608 },
  { name: "PKR", value: 586 },
  { name: "PLN", value: 985 },
  { name: "PYG", value: 600 },
  { name: "QAR", value: 634 },
  { name: "RON", value: 946 },
  { name: "RSD", value: 941 },
  { name: "RUB", value: 643 },
  { name: "RWF", value: 646 },
  { name: "SAR", value: 682 },
  { name: "SBD", value: +"090" },
  { name: "SCR", value: 690 },
  { name: "SDG", value: 938 },
  { name: "SEK", value: 752 },
  { name: "SGD", value: 702 },
  { name: "SHP", value: 654 },
  { name: "SLL", value: 694 },
  { name: "SOS", value: 706 },
  { name: "SRD", value: 968 },
  { name: "SSP", value: 728 },
  { name: "STD", value: 678 },
  { name: "SVC", value: 222 },
  { name: "SYP", value: 760 },
  { name: "SZL", value: 748 },
  { name: "THB", value: 764 },
  { name: "TJS", value: 972 },
  { name: "TMT", value: 934 },
  { name: "TND", value: 788 },
  { name: "TOP", value: 776 },
  { name: "TRY", value: 949 },
  { name: "TTD", value: 780 },
  { name: "TWD", value: 901 },
  { name: "TZS", value: 834 },
  { name: "UAH", value: 980 },
  { name: "UGX", value: 800 },
  { name: "USD", value: 840 },
  { name: "USN", value: 997 },
  { name: "UYI", value: 940 },
  { name: "UYU", value: 858 },
  { name: "UZS", value: 860 },
  { name: "VEF", value: 937 },
  { name: "VND", value: 704 },
  { name: "VUV", value: 548 },
  { name: "WST", value: 882 },
  { name: "XAF", value: 950 },
  { name: "XAG", value: 961 },
  { name: "XAU", value: 959 },
  { name: "XBA", value: 955 },
  { name: "XBB", value: 956 },
  { name: "XBC", value: 957 },
  { name: "XBD", value: 958 },
  { name: "XCD", value: 951 },
  { name: "XDR", value: 960 },
  { name: "XOF", value: 952 },
  { name: "XPD", value: 964 },
  { name: "XPF", value: 953 },
  { name: "XPT", value: 962 },
  { name: "XSU", value: 994 },
  { name: "XTS", value: 963 },
  { name: "XUA", value: 965 },
  { name: "XXX", value: 999 },
  { name: "YER", value: 886 },
  { name: "ZAR", value: 710 },
  { name: "ZMW", value: 967 },
  { name: "ZWL", value: 932 },
  // {
  //   name: "SEK",
  //   value: 752
  // },
  // {
  //   name: "GBP",
  //   value: 826
  // }
];

class MonetaryCurrency extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;

    return (
      <ReduxCoreSelect
        //placeholder="Currency"
        options={this.props.agreeDisagreOptions}
        fieldIdentifier={this.props.fieldIdentifier || "currency"}
        updateAction={actions.cw.UPREDUXSELECTINPUT}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    agreeDisagreOptions: (function() {
      var rows = [];
      for (var i = 0; i < agreeDisagreeOpts.length; i++) {
        // note: we add a key prop here to allow react to uniquely identify each
        // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
        rows.push(
          <MenuItem value={agreeDisagreeOpts[i].value} key={i}>
            {agreeDisagreeOpts[i].name}
          </MenuItem>
        );
      }
      return rows;
    })(),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(userProfileStyles)(MonetaryCurrency));
