import React, { useState, useEffect } from "react";
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
// import InfoOutline from "@material-ui/icons/InfoOutline";da

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import sellrepDashboardStyle from "assets/jss/material-dashboard-pro-react/views/sellrepDashboardStyle";
import { useSelector, useDispatch } from "react-redux";
import { paths } from "variables/projPaths.jsx";
import { getActiveOrg } from "variables/coreWeb";
import { useHistory } from "react-router-dom";
import { apiGetDashboard, apiGetSelfProfile, getToken } from "variables/newApi";
import CardBody from "components/Card/CardBody";
import pawIcon from "assets/img/big/paw.svg";
import userIcon from "assets/img/big/user.svg";
import usersIcon from "assets/img/big/user-group.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { checkForActiveOrg } from "variables/coreWeb";
import { useLocation } from "react-router-dom";
import Charts from "views/Charts/Charts";
import "react-circular-progressbar/dist/styles.css";

function Dashboard(props) {
  const dispatch = useDispatch();
  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
    isLoading,
  } = useAuth0();

  const { classes } = props;
  const history = useHistory();
  const location = useLocation();
  const [activeOrg, setActiveOrg] = useState(null);
  const registrationType = useSelector(
    (state) => state.pwReducer.selfProfile.registrationType
  );
  const appointmentsBarValue = 0.0;

  useEffect(
    () => {
      if (activeOrg != null) {
        if (activeOrg.length > 0) {
          dispatch(apiGetDashboard());
        } else {
          history.push(paths.orgSignIn);
        }
      }
    },
    [!isLoading && isAuthenticated, activeOrg]
  );

  useEffect(
    () => {
      if (isAuthenticated && !isLoading && registrationType === 4) {
        checkForActiveOrg(location, history, setActiveOrg);
      }
    },
    [registrationType, isAuthenticated, !isLoading]
  );

  return (
    <div className="sl-pd-2">
      <GridContainer xs={12} sm={12} md={12} lg={12} className="fullwidth">
        <GridItem
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className="ds-total-appointments"
        >
          <Card>
            <CardHeader>
              <h3 className="myhead2">Total Appointments</h3>
            
            </CardHeader>
            <CardBody className="setimg">
              {/* <img src={loaderIcon} /> */}

              <div className="appointment-bar">
                <CircularProgressbar
                  value={appointmentsBarValue}
                  maxValue={1}
                  text={`${appointmentsBarValue * 100}%`}
                  strokeWidth="5"
                />
              </div>
              <p className="set-sm">
               More features coming soon
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={8} lg={8} className="ds-statistics">
          <GridContainer xs={12} sm={12} md={12} lg={12}>
            <GridItem xs={12} sm={8} md={4} lg={4} className="ds-stat-box">
              <Card className="nomar box-card">
                <CardBody className="pink box-body">
                  <div className="rr box-img">
                    <img src={userIcon} />
                  </div>
                  <div className="valuef">
                    <h4 className={classes.cardTitle}>Total Clients</h4>
                    <h3 className="no-pad">Coming Soon</h3>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={8} md={4} lg={4} className="ds-stat-box">
              <Card className="nomar box-card">
                <CardBody className="blue box-body">
                  <div className="rr box-img">
                    <img src={usersIcon} />
                  </div>
                  <div className="valuef">
                    <h4 className={classes.cardTitle}>Total Staff</h4>
                    <h3 className="no-pad">Coming Soon</h3>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={8} md={4} lg={4} className="pr-0 ds-stat-box">
              <Card className="nomar box-card">
                <CardBody className="orange box-body">
                  <div className="rr box-img">
                    <img src={pawIcon} />
                  </div>
                  <div className="valuef">
                    <h4 className={classes.cardTitle}>Total Patients</h4>
                    <h3 className="no-pad">Coming Soon</h3>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="pr-0 ds-welcome-text"
            >
              <Card className="nomar2 ds-welcome-card">
                <CardHeader>
                  <h3 className="myhead welcome-page">
                    Welcome to VetGeo Version 3.0
                  </h3>
                </CardHeader>
                <CardBody>
                  <p className="set">
                    This system has been in existance since 2017 and it has
                    lacked bit of commitment during 2020 due to me now taking on
                    bigger responsibilities, During late 2021 you will see a
                    significant improvement in the platform
                  </p>
                  <br />
                  <p className="set">
                    Thank you so much for the continued support and I am hoping
                    we can really push things forward
                  </p>
                  <br />
                  <p className="nospace set">Regards</p>
                  <p className="nospace set">VetGeo Team</p>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem>{/* <Charts /> */}</GridItem>
        {/*<GridItem xs={12} sm={12} md={12} lg={12}>
          <Card className="nomar3">
            <CardBody className="full">
              <img src={chart} />
            </CardBody>
          </Card>
</GridItem>*/}
      </GridContainer>
    </div>
  );
}

export default withStyles(sellrepDashboardStyle)(Dashboard);
