const constVars = {
  kilograms: "kg",
  grams: "g",
  pound: "lb",
  ounce: "oz",
  meters: "m",
  centimeter: "cm",
  inches: "inches",
  fahrenheit: "fahrenheit",
  celcius: "celcius",
  Authorization: "Authorization",
  Bearer: "Bearer",
};

export { constVars };
