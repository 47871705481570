import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import ContactViewOptions from "./ContactViewOptions";
import * as actions from "actions/projectActionTypes";
import { getActiveOrg } from "variables/coreWeb";
import * as makeApiCall from "actions/makeApiCall";
import Button from "components/CustomButtons/Button.jsx";
import { createCustomer } from "../../variables/coreCreateRequests";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { updateContact } from "../../variables/coreRequests";
import { stat } from "fs";
import { createMapPatientCustomer } from "../../variables/thunks";
import GenericMapList from "./GenericMapList";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useHistory } from "react-router-dom";

function LinkCustomers(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const redirect = function () {
    history.push("/patients/view/" + props.match.params.animalId);
  };

  const contacts = useSelector((state) =>
    (state.pwReducer.contactList || []).map((prop, key) => {
      return {
        header1: prop.contactFullName,
        linkAction: () => {
          dispatch(
            createMapPatientCustomer(
              props.match.params.animalId,
              prop.contactId,
              getActiveOrg(),
              redirect
            )
          );
        },
        contactId: prop.contactId,
        contactType: prop.contactType,
      };
    })
  );

  const contactIds = useSelector(
    (state) => state.pwReducer.animalDetails.contactList || []
  ).map((o) => o["contactId"]);

  return (
    <div>
      <CoreApiReq
        endpointToCall={"Read_All_Organisation_Contacts"}
        afterActionHandler={actions.cw.GOT_ALL_ORG_CONTACTS_BY_ORG_ID}
        data={{
          orgId: getActiveOrg(),
        }}
      />
      {props.match && props.match.params.animalId ? (
        <CoreApiReq
          endpointToCall={"Read_AnimalComplete_By_Animal_ID"}
          afterActionHandler={actions.cw.GOT_ANIMAL_DETAILS}
          data={{
            orgId: getActiveOrg(),
            animalId: props.match.params.animalId,
          }}
        />
      ) : (
        ""
      )}
      <GenericMapList
        hideHeader={true}
        header1={<FormattedMessage id={"Customer Name"} />}
        listData={contacts.filter(
          (x) => contactIds.indexOf(x.contactId) === -1 && x.contactType === 3
        )}
      />
    </div>
  );
}

export default withStyles(userProfileStyles)(LinkCustomers);
