import React, { useEffect, useState } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { useSelector, useDispatch } from "react-redux";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import ContactViewOptions from "./ContactViewOptions";
import * as actions from "actions/projectActionTypes";
import { getActiveOrg } from "variables/coreWeb";
import * as makeApiCall from "actions/makeApiCall";
import Button from "components/CustomButtons/Button.jsx";
import { createCustomer } from "../../variables/coreCreateRequests";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { updateContact } from "../../variables/coreRequests";
import { stat } from "fs";
import { readContactCompleteWithDispatch } from "../../variables/thunks";

const ContactView = function(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    //ReadContactCompleteByContactID
    if (props.match.params.contactId) {
      console.log("Calling contact complete read");
      dispatch(
        readContactCompleteWithDispatch(
          props.match.params.contactId,
          getActiveOrg(),
          (resp) => {}
        )
      );
    }
  }, []);

  return (
    <div className="sl-pd-22">
      <ContactViewOptions
        animalId={props.match.params.animalId}
        contactId={props.match.params.contactId}
        disabled={props.disabled}
        orgId={getActiveOrg()}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    contactDetails: state.pwReducer.contactDetails,
    orgId: getActiveOrg(),
    userId: state.pwReducer.selfProfile.userId,
    disabled:
      state.pwReducer.contactDetails &&
      state.pwReducer.contactDetails.contactType == 2
        ? true
        : false,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    dispatch: dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(userProfileStyles)(ContactView));
