import React, { useState, useEffect, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import moment from "moment";
import PassportNumber from "components/CoreWeb/PassportNumber.jsx";
import CreateAnimalStep1 from "components/CoreWeb/CreateAnimalStep1.jsx";
import CreateAnimalStep2 from "components/CoreWeb/CreateAnimalStep2.jsx";
import CreateAnimalStep3 from "components/CoreWeb/CreateAnimalStep3.jsx";
import MedicalRecordNote from "views/Forms/MedicalRecordNote.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Info from "@material-ui/icons/Info";
import Pets from "@material-ui/icons/Pets";
import LocationOn from "@material-ui/icons/LocationOn";
import Gavel from "@material-ui/icons/Gavel";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as makeApiCall from "actions/makeApiCall";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import { getActiveOrg, calcDurationDiffAgeString } from "variables/coreWeb.jsx";
import {
  generateFileUrl,
  getMostRecentFileFromList,
} from "variables/coreWeb.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { updateAnimal, deleteAnimal } from "variables/coreRequests.jsx";
import {
  updateAnimalWithDispatch,
  createAnimalWithDispatch,
} from "variables/thunks.jsx";
import Clear from "@material-ui/icons/Clear";
import ReactTable from "react-table";
import { enumMedicalTypeToString } from "variables/coreWeb.jsx";
import ReduxCustomDateTime from "components/CoreWeb/ReduxCustomDateTime";
import GenderSelect from "components/CoreWeb/GenderSelect";
import DeSexedStatus from "components/CoreWeb/DeSexedStatus";
import CoreDateTime from "components/CoreWeb/CoreDateTime";
import BloodGroup from "components/CoreWeb/BloodGroup";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput.jsx";
import AnimalSpeciesSelect from "components/ProjectWeb/AnimalSpeciesSelect.jsx";
// @material-ui/icons
import ComingSoonStep from "components/CoreWeb/ComingSoonStep.jsx";
import { paths } from "variables/projPaths.jsx";
import * as raisers from "actions/projectRaisers";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { deleteMedicalWithDispatch } from "../../variables/thunks";
import { UPLOADSPATH } from "variables/project.jsx";
import { rows, APPOINTMENTTYPE, EXCEPTIONTYPE } from "variables/coreWeb.jsx";
import ReduxCoreSelect from "components/CoreWeb/ReduxCoreSelect";
import MicrochipNumber from "components/CoreWeb/MicrochipNumber";
import AnimalBreedSelect from "components/ProjectWeb/AnimalBreedSelect.jsx";
import InsuranceRefNumber from "components/CoreWeb/InsuranceRefNumber";
import SelectIsActive from "components/CoreWeb/SelectIsActive";
import {
  LocalHospital,
  People,
  Assignment,
  EuroSymbol,
  Print,
  Share,
  PlusOne,
  Add,
  Label,
} from "@material-ui/icons";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Build from "@material-ui/icons/Build";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Edit from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import CustomerList from "components/CoreWeb/CustomerList.jsx";
import SelectIsDeceased from "../../components/CoreWeb/SelectIsDeceased";
import { useHistory } from "react-router-dom";
import localHospital from "./img/localHospital.svg";
import eurosymbol from "./img/eurosymbol.svg";
import assignment from "./img/Assignment.svg";
import peaple from "./img/peaple.svg";
const style = {
  ...userProfileStyles,
  ...sweetAlertStyle,
};

const fillButtons = function(theProps, medicalNoteId, handleRecordAction) {
  const { classes } = theProps;
  return (
    <div className="Patients-button">
      <Button
        className={classes.actionButton}
        color="danger"
        size="sm"
        onClick={() => {
          theProps.raiseAction({
            fieldIdentifier: "selectedMedicalNoteId",
            value: medicalNoteId,
          });

          handleRecordAction("delete");
        }}
      >
        <Clear className={classes.icon} />
      </Button>
      <Button
        className={classes.actionButton}
        color="info"
        size="sm"
        onClick={() => {
          theProps.raiseAction({
            fieldIdentifier: "selectedMedicalNoteId",
            value: medicalNoteId,
          });

          handleRecordAction("edit");
        }}
      >
        <Edit className={classes.icon} />
      </Button>
    </div>
  );
};

const CreateAnimal = function(props) {
  const history = useHistory();
  const { classes } = props;
  const [open, setOpen] = React.useState(false);
  const [medicalRecordAction, setMedicalRecordAction] = useState(null);
  const isCreate = !props.match.params.animalId;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isCreate === true) {
      dispatch({ type: actions.cw.RESET_ANIMAL_DETAILS, payload: {} });
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRecordAction = (recordAction = null) => {
    setMedicalRecordAction(recordAction);
    dispatch({
      type: actions.cw.SET_MEDICAL_NOTE_RECORD,
      payload: {},
    });
  };
  // const  isCreate  = props.match.params.animalId ? false : true;

  const getTabs = function(
    props,
    animalId,
    fileUrl,
    state,
    handleRecordAction
  ) {
    var resp = [];
    const { classes } = props;
    if (animalId) {
      resp.push({
        tabButton: <FormattedMessage id="medicalRecord" />,
        tabIcon: localHospital,
        tabContent: (
          // Get animal medical note
          <div>
            {props.match && props.match.params.animalId ? (
              <CoreApiReq
                endpointToCall={"Read_MedicalRecord_By_Org_And_Animal_ID"}
                afterActionHandler={actions.cw.GOT_ANIMAL_MEDICAL_RECORD}
                data={{
                  animalId: props.match.params.animalId,
                  orgId: getActiveOrg(),
                }}
              />
            ) : (
              ""
            )}

            <Button
              className="add-new-entry btn-theme"
              color="rose"
              // href={
              //   paths.medicalRecordEntry +
              //   "/" +
              //props.match.params.animalId
              // }
              onClick={handleClickOpen}
            >
              <FormattedMessage id="addNewEntry" />
            </Button>

            <GridContainer justify={"center"} className="table-container">
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <CardBody>
                    {props.medicalNotes && props.medicalNotes != undefined ? (
                      <ReactTable
                        data={props.medicalNotes.medicalNotes.map(
                          (prop, key) => {
                            return {
                              id: key,
                              date: moment(prop.createdOn_UTC).format(
                                "dd DD, MM YYYY, h:mm:ss a"
                              ),
                              note: enumMedicalTypeToString(
                                prop.medicalNoteType
                              ),
                              noteDescription: prop.noteDescription,
                              actions: fillButtons(
                                props,
                                prop.medicalNoteId,
                                state,
                                handleRecordAction
                              ),
                            };
                          }
                        )}
                        filterable
                        columns={[
                          {
                            Header: <FormattedMessage id="date" />,
                            accessor: "date",
                          },
                          {
                            Header: <FormattedMessage id="noteType" />,
                            accessor: "note",
                          },
                          {
                            Header: <FormattedMessage id="noteDescriptionn" />,
                            accessor: "noteDescription",
                          },

                          {
                            Header: <FormattedMessage id="actions" />,
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationTop={false}
                        showPaginationBottom={true}
                        className="-striped -highlight"
                      />
                    ) : (
                      ""
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

            {props.selectedMedicalNoteId && medicalRecordAction === "delete" ? (
              <Dialog
                open={true}
                classes={{
                  root: classes.center,
                  paper: classes.modal,
                }}
                keepMounted
                onClose={() => props.handleClose()}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
              >
                <DialogTitle
                  id="classic-modal-slide-title"
                  disableTypography
                  className={classes.modalHeader}
                >
                  <h4 className={`${classes.modalTitle} deleting-title`}>
                    Are you sure?
                  </h4>
                </DialogTitle>
                <DialogContent
                  id="modal-slide-description"
                  className={classes.modalBody}
                >
                  <p> Deleting a Medical record is permanent</p>
                </DialogContent>
                <DialogActions
                  style={{ display: "flex", justifyContent: "center" }}
                  className={
                    classes.modalFooter + " " + classes.modalFooterCenter
                  }
                >
                  <Button
                    className="cancel-btn"
                    color="success"
                    onClick={() => {
                      props.raiseAction({
                        fieldIdentifier: "selectedMedicalNoteId",
                        value: undefined,
                      });
                    }}
                    color="simple"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="cancel-btn-delete"
                    onClick={() => {
                      props.dispatch(
                        deleteMedicalWithDispatch(
                          props.callApi,
                          props.selectedMedicalNoteId
                        )
                      );

                      props.raiseAction({
                        fieldIdentifier: "selectedMedicalNoteId",
                        value: undefined,
                      });
                    }}
                    color="danger"
                  >
                    Yes, delete it.
                  </Button>
                </DialogActions>
              </Dialog>
            ) : (
              ""
            )}
          </div>
        ),
      });
    }
    if (animalId) {
      resp.push({
        tabButton: <FormattedMessage id="clients" />,
        tabIcon: peaple,
        tabContent: (
          // Get animal medical note
          <div>
            <CustomerList
              customers={props.animalDetails.contactList}
              animalId={animalId}
              hideHeader={true}
            />
          </div>
        ),
      });
    }

    if (animalId) {
      resp.push({
        tabButton: <FormattedMessage id="appointments" />,
        tabIcon: assignment,
        tabContent: (
          <div>
            {/*<Button
            color="rose"
            href={
              paths.medicalRecordEntry +
              "/" +
              props.match.params.animalId
            }
          >
            <FormattedMessage id="addAppointment" />
          </Button>*/}
            <Card>
              <CardBody>
                <h4>
                  <FormattedMessage id="comingSoon" />
                </h4>
              </CardBody>
            </Card>
          </div>
        ),
      });
    }
    if (animalId) {
      resp.push({
        tabButton: <FormattedMessage id="billing" />,
        tabIcon: eurosymbol,
        tabContent: (
          <div>
            {/*<Button
            color="rose"
            href={
              paths.medicalRecordEntry +
              "/" +
              props.match.params.animalId
            }
          >
            <FormattedMessage id="addInvoice" />
          </Button>*/}
            <Card>
              <CardBody>
                <h4>
                  <FormattedMessage id="comingSoon" />
                </h4>
              </CardBody>
            </Card>
          </div>
        ),
      });
    }

    return resp;
  };
  return (
    <div className="sl-pd-22">
      <GridContainer justify="center">
        {props.match && props.match.params.animalId ? (
          <CoreApiReq
            endpointToCall={"Read_AnimalComplete_By_Animal_ID"}
            afterActionHandler={actions.cw.GOT_ANIMAL_DETAILS}
            data={{
              orgId: getActiveOrg(),
              animalId: props.match.params.animalId,
            }}
          />
        ) : (
          ""
        )}

        <GridItem xs={12} sm={12} md={12}>
          <Card
            className={`create-Patient ${
              props.match.params.animalId ? "patients-boxes" : ""
            }`}
          >
            <CardHeader className="in">
              <h3 className="myhead nosp">
                {isCreate === true ? "Add New Patient" : "View Patient Details"}
              </h3>
              <p>
                {isCreate
                  ? "Fill the following details"
                  : "Following are the details"}
              </p>
            </CardHeader>
            <CardBody className="create-Patient-card-body">
              <GridContainer>
                {!isCreate ? (
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer className="">
                      {props.match.params.animalId ? (
                        <GridItem
                          xs={12}
                          sm={12}
                          md={4}
                          className="patients-boxes-upload"
                        >
                          <CreateAnimalStep1
                            dispatch={props.dispatch}
                            orgId={getActiveOrg()}
                            animalId={props.match.params.animalId}
                            imagePreviewUrl={props.activeFileUrl}
                          />
                        </GridItem>
                      ) : (
                        ""
                      )}
                      {/* <GridItem xs={12} md={6}>
                        <GridItem xs={12} md={6} className="Create-input-box">
                          <label className="lbl-fld">Animal Id</label>
                          <ReduxCustomInput
                            //mainTextLabel={<FormattedMessage id="animalId" />}
                            updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                            fieldIdentifier={"animalDetails.animalId"}
                            textValue={props.animalName}
                            multiLine={1}
                            disabled={true}
                          />
                        </GridItem>
                      </GridItem> */}

                      <GridItem xs={12} md={8}>
                        <GridContainer className="">
                          <GridItem xs={12} md={6} className="Create-input-box">
                            <label className="lbl-fld">
                              {<FormattedMessage id="patientName" />}
                            </label>
                            <ReduxCustomInput
                              //mainTextLabel={<FormattedMessage id="patientName" />}
                              updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                              fieldIdentifier={"animalDetails.name"}
                              textValue={props.animalName}
                              multiLine={1}
                            />
                          </GridItem>

                          <GridItem
                            xs={12}
                            md={6}
                            className="Create-select-box"
                          >
                            <label className="lbl-fld">
                              {<FormattedMessage id="species" />}
                            </label>
                            <AnimalSpeciesSelect />
                          </GridItem>

                          <GridItem
                            xs={12}
                            md={6}
                            lg={6}
                            className="Create-select-box dateofbirth"
                          >
                            <label className="lbl-fld">
                              <FormattedMessage id="dateOfBirth" />
                            </label>
                            <ReduxCustomDateTime
                              fieldIdentifier={"animalDetails.dateOfBirth"}
                              //placeholder={<FormattedMessage id="dateOfBirth" />}
                              timeFormat={false}
                            />
                          </GridItem>

                          <GridItem
                            xs={12}
                            md={6}
                            className="Create-select-box inactive-deceased"
                          >
                            <div>
                              <label className="lbl-fld">
                                <FormattedMessage id="activeOrInactive" />
                              </label>
                              <SelectIsActive
                                fieldIdentifier={"animalDetails.isActive"}
                              />
                            </div>
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                      {/* <div >
                      <AnimalSpeciesSelect />
                    </div> */}
                    </GridContainer>
                  </GridItem>
                ) : (
                  ""
                )}

                {isCreate ? (
                  <Fragment>
                    <GridItem xs={12} md={4} className="Create-input-box">
                      <label className="lbl-fld">
                        {<FormattedMessage id="patientName" />}
                      </label>
                      <ReduxCustomInput
                        //mainTextLabel={<FormattedMessage id="patientName" />}
                        updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                        fieldIdentifier={"animalDetails.name"}
                        textValue={props.animalName}
                        multiLine={1}
                      />
                    </GridItem>

                    <GridItem xs={12} md={4} className="Create-select-box">
                      <label className="lbl-fld">
                        {<FormattedMessage id="species" />}
                      </label>
                      <AnimalSpeciesSelect />
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={4}
                      className="Create-select-box inactive-deceased"
                    >
                      <div>
                        <label className="lbl-fld">
                          <FormattedMessage id="activeOrInactive" />
                        </label>
                        <SelectIsActive
                          fieldIdentifier={"animalDetails.isActive"}
                        />
                      </div>
                    </GridItem>

                    <GridItem
                      xs={12}
                      md={6}
                      lg={4}
                      className="Create-select-box dateofbirth"
                    >
                      <label className="lbl-fld">
                        <FormattedMessage id="dateOfBirth" />
                      </label>
                      <ReduxCustomDateTime
                        fieldIdentifier={"animalDetails.dateOfBirth"}
                        //placeholder={<FormattedMessage id="dateOfBirth" />}
                        timeFormat={false}
                      />
                    </GridItem>
                  </Fragment>
                ) : (
                  ""
                )}

                <GridItem xs={12} md={4} className="Create-select-box aggeset">
                  <label className="lbl-fld">
                    <FormattedMessage id="ageString" />
                  </label>
                  <CustomInput
                    // labelText={
                    //   <span>
                    //     <FormattedMessage id="ageString" />
                    //   </span>
                    // }
                    inputProps={{ value: props.ageString, disabled: true }}
                    formControlProps={{ fullWidth: true }}
                  />
                </GridItem>

                {/* <GridItem xs={12} md={3} className="m1-20">
                  <SelectIsDeceased fieldIdentifier={"animalDetails.deceasedStatus"} />
                </GridItem> */}

                <GridItem xs={12} md={4} className="Create-input-box">
                  <label className="lbl-fld">
                    <FormattedMessage id="deceasedDate" />
                  </label>
                  {props.animalDetails.deceasedStatus === 3 ? (
                    <ReduxCustomDateTime
                      fieldIdentifier={"animalDetails.deceasedDate"}
                      //placeholder={<FormattedMessage id="deceasedDate" />}
                    />
                  ) : (
                    <ReduxCustomInput
                      textValue={"-"}
                      //mainTextLabel={<FormattedMessage id="deceasedDate" />}
                    />
                  )}
                </GridItem>

                {/* {!isCreate ? (
                  <GridItem
                    xs={12}
                    md={4}
                    className="Create-input-box primaryCarer"
                  >
                    <ReduxCustomInput
                      mainTextLabel={<FormattedMessage id="primaryCarer" />}
                      updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                      fieldIdentifier={"animalDetails.primaryCarer"}
                      textValue={props.animalName}
                      multiLine={1}
                      disabled={true}
                    />
                  </GridItem>
                ) : (
                  ""
                )} */}

                <GridItem
                  xs={12}
                  md={4}
                  className="Create-input-box microchipId"
                >
                  <label className="lbl-fld">
                    <FormattedMessage id="microchipNumber" />
                  </label>
                  <MicrochipNumber
                    fieldIdentifier={"animalDetails.microchipId"}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={4}
                  className="Create-input-box insuranceReferenceNumber"
                >
                  <label className="lbl-fld">
                    <FormattedMessage id="insuranceRefNumber" />
                  </label>
                  <InsuranceRefNumber
                    fieldIdentifier={"animalDetails.insuranceReferenceNumber"}
                  />
                </GridItem>

                {/*<GridItem xs={12} md={4}>
                <AnimalBreedSelect></AnimalBreedSelect>
                </GridItem>*/}

                <GridItem
                  xs={12}
                  md={4}
                  className="Create-input-box passportNumber"
                >
                  <label className="lbl-fld">
                    <FormattedMessage id="passportNumber" />
                  </label>
                  <PassportNumber
                    fieldIdentifier={"animalDetails.passportNumber"}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={4}
                  className="Create-select-box gender-set"
                >
                  <label className="lbl-fld">
                    <FormattedMessage id="gender" />
                  </label>
                  <GenderSelect
                    fieldIdentifier={"animalDetails.gender"}
                    //mainTextLabel={<FormattedMessage id="gender" />}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  md={4}
                  className="Create-select-box gender-set bloodgroup"
                >
                  <label className="lbl-fld">
                    <FormattedMessage id="bloodGroup" />
                  </label>
                  <BloodGroup
                    //mainTextLabel={<FormattedMessage id="bloodGroup" />}
                    fieldIdentifier={"animalDetails.bloodGroup"}
                  />
                </GridItem>
                {/*<GridItem xs={12} md={4}></GridItem>*/}
                {/*<GridItem xs={12} md={4}></GridItem>*/}
              </GridContainer>
              {!isCreate ? (
                <div className="st-s upadat-btn">
                  <GridContainer justify="left">
                    <GridItem>
                      <Button
                        className="btn-theme"
                        color="rose"
                        onClick={() => {
                          props.dispatch(
                            updateAnimalWithDispatch(
                              props.callApi,
                              props.animalDetails
                            )
                          );
                        }}
                      >
                        <FormattedMessage id="update" />
                      </Button>
                      {/* <Button
                            color="danger"
                            onClick={() => {
                              deleteAnimal(props.callApi, props.animalDetails);
                            }}
                          >
                            Delete
                          </Button> */}
                    </GridItem>
                  </GridContainer>
                </div>
              ) : (
                <GridContainer justify="left">
                  <GridItem className="pl-2 createPatient-btn">
                    <Button
                      color="rose"
                      onClick={() => {
                        props.dispatch(
                          createAnimalWithDispatch(
                            {
                              ...props.animalDetails,
                              contactId: props.match.params.contactId,
                            },
                            (resp) => {
                              history.push(
                                "/patients/view/" + resp.newRecordID
                              );
                            }
                          )
                        );
                        dispatch({
                          type: actions.cw.RESET_ANIMAL_DETAILS,
                          payload: {},
                        });
                      }}
                    >
                      <FormattedMessage id="createPatient" />
                    </Button>
                  </GridItem>
                </GridContainer>
              )}
            </CardBody>
          </Card>
          <NavPills
            color="rose"
            alignCenter
            tabs={getTabs(
              props,
              props.match && props.match.params.animalId
                ? props.match.params.animalId
                : undefined,
              props.activeFileUrl,
              handleRecordAction
            )}
          />
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Add new entry</DialogTitle>
            <DialogContent>
              <MedicalRecordNote />
            </DialogContent>
          </Dialog>

          {props.selectedMedicalNoteId && medicalRecordAction === "edit" ? (
            <Dialog
              open={true}
              onClose={() => {
                props.raiseAction({
                  fieldIdentifier: "selectedMedicalNoteId",
                  value: undefined,
                });

                handleRecordAction();
              }}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                {props.selectedMedicalNoteId ? "Update Entry" : "Add new entry"}
              </DialogTitle>
              <DialogContent>
                <MedicalRecordNote />
              </DialogContent>
            </Dialog>
          ) : (
            ""
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
};
function mapStateToProps(state) {
  var activeFile = null;
  if (
    state.pwReducer.animalDetails &&
    state.pwReducer.animalDetails &&
    state.pwReducer.animalDetails.fileList &&
    state.pwReducer.animalDetails.fileList.length > 0
  ) {
    activeFile = getMostRecentFileFromList(
      state.pwReducer.animalDetails.fileList
    );
  }
  var age = null;
  if (state.pwReducer && state.pwReducer.animalDetails) {
    age = moment(state.pwReducer.animalDetails.dateOfBirth);
  }
  return {
    ageString: age ? calcDurationDiffAgeString(age, new moment()) : "",
    animalDetails: state.pwReducer.animalDetails,
    medicalNotes: state.pwReducer.medicalNotes,
    selectedMedicalNoteId: state.pwReducer.selectedMedicalNoteId,
    medicalRecordAction: state.pwReducer.medicalRecordAction,
    activeFileUrl:
      activeFile != null
        ? UPLOADSPATH + activeFile.fileId + "" + activeFile.fileExtension
        : null,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    dispatch: dispatch,
    raiseAction: (payload) => {
      dispatch(raisers.createActionGeneric(payload, actions.cw.SET_STATE_VAL));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(CreateAnimal));
