import React from "react";
import { FormattedMessage } from "react-intl";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import LocationOn from "@material-ui/icons/LocationOn";
import ReduxCoreSelect from "components/CoreWeb/ReduxCoreSelect";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import LanguageSelect from "components/CoreWeb/LanguageSelect";
import * as actions from "actions/projectActionTypes";
import Button from "components/CustomButtons/Button.jsx";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput.jsx";
import { updateProfile } from "variables/thunks";
import { connect } from "react-redux";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <div className="sp-2">
        <div className="sl-pd-22">
          <GridContainer justify="center">
            <Card>
              <GridItem xs={12} sm={12} md={12}>
                <CardHeader className="in2 mb-50">
                  <div className="self">
                    <h3 className="myhead nosp st-t">
                      <FormattedMessage id="yourDetails" />
                    </h3>
                    <p>
                      {" "}
                      <FormattedMessage id="presentYourself" />
                    </p>
                  </div>
                </CardHeader>
                <div className="sl-pd-22">
                  <CardBody>
                    <GridContainer className="input-box-profile">
                      <GridItem xs={12} sm={12} md={4} className="input-box">
                        <label className="lbl-fld">
                          <FormattedMessage id="emailaddress" /> (not
                          modifiable)
                        </label>
                        <ReduxCustomInput
                          //mainTextLabel={<FormattedMessage id="emailaddress" />}
                          //subTextLabel="(not modifiable)"
                          textValue={this.props.emailAddress}
                          updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                          fieldIdentifier={
                            this.props.emailFieldIdentifier || "emailAddress"
                          }
                          disabled={true}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} className="input-box">
                        <label className="lbl-fld">
                          <FormattedMessage id="firstName" />
                        </label>
                        <ReduxCustomInput
                          //mainTextLabel={<FormattedMessage id="firstName" />}
                          //subTextLabel="(required)"
                          updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                          fieldIdentifier={
                            this.props.firstNameFieldIdentifier ||
                            "selfProfile.contactFirstName"
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} className="input-box">
                        <label className="lbl-fld">
                          <FormattedMessage id="lastName" />
                        </label>
                        <ReduxCustomInput
                          //   mainTextLabel={<FormattedMessage id="lastName" />}
                          //   subTextLabel="(required)"
                          updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                          fieldIdentifier={
                            this.props.lastNameFieldIdentifier ||
                            "selfProfile.contactLastName"
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} className=" select-box">
                        <label className="lbl-fld">System Language</label>
                        <LanguageSelect> </LanguageSelect>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </div>
              </GridItem>
              <GridItem>
                <div className="sl-pd-223 update-button">
                  <Button
                    color="rose"
                    className="btn-theme"
                    onClick={() =>
                      this.props.dispatch(
                        updateProfile({
                          emailAddress: this.props.emailAddress,
                          contactFirstName: this.props.contactFirstName,
                          contactLastName: this.props.contactLastName,
                          userId: this.props.userId,
                          timeZoneIANA: this.props.selfProfile.timeZoneIANA,
                          userState: this.props.selfProfile.userState,
                          resourceId: this.props.selfProfile.resourceId,
                          languageKey: this.props.selfProfile.languageKey,
                          registrationType: this.props.selfProfile
                            .registrationType,
                        })
                      )
                    }
                  >
                    <FormattedMessage id="update" />
                  </Button>
                </div>
              </GridItem>
            </Card>
            {false ? (
              <GridItem xs={12} sm={12} md={12}>
                <NavPills
                  color="rose"
                  alignCenter
                  tabs={[
                    {
                      tabButton: "Settings",
                      tabIcon: LocationOn,
                      tabContent: <div />,
                    },
                  ]}
                />
              </GridItem>
            ) : (
              ""
            )}
          </GridContainer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userId: state.pwReducer.selfProfile.userId,
    contactFirstName: state.pwReducer.selfProfile.contactFirstName,
    contactLastName: state.pwReducer.selfProfile.contactLastName,
    emailAddress: state.pwReducer.selfProfile.emailAddress,
    selfProfile: state.pwReducer.selfProfile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(userProfileStyles)(UserProfile));
