import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Dashboard from "views/Dashboard/Dashboard.jsx";
import Charts from "views/Charts/Charts.jsx";
import Calendar from "views/Calendar/Calendar.jsx";
import UserProfile from "views/Pages/UserProfile.jsx";

import pagesRoutes from "./pages.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
// import ContentPaste from "@material-ui/icons/ContentPaste";
import EuroSymbol from "@material-ui/icons/EuroSymbol";
import AccountBox from "@material-ui/icons/AccountBox";
import Equalizer from "@material-ui/icons/Equalizer";
import Pets from "@material-ui/icons/Pets";
import Face from "@material-ui/icons/Face";
import Event from "@material-ui/icons/Event";
import Settings from "@material-ui/icons/Settings";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Ballot from "@material-ui/icons/Ballot";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import Category from "@material-ui/icons/Category";
import AnimalListView from "components/CoreWeb/AnimalListView.jsx";
import CustomerListView from "components/CoreWeb/CustomerListView.jsx";
import InvoiceList from "components/CoreWeb/InvoiceList.jsx";
import AppointmentList from "components/CoreWeb/AppointmentList.jsx";
import OrgServices from "components/CoreWeb/OrgServices.jsx";
import OrgProducts from "components/CoreWeb/OrgProducts.jsx";
import OrgInventoryList from "components/CoreWeb/OrgInventoryList.jsx";
import OrgResourceList from "components/CoreWeb/OrgResourceList.jsx";
import OrgStaffList from "components/CoreWeb/OrgStaffList.jsx";
import OrgStaffExport from "components/CoreWeb/OrgStaffExport.jsx";
import CreateAnimal from "views/Forms/CreateAnimal.jsx";
import OrganisationList from "components/CoreWeb/OrganisationList.jsx";
import CreateAppointment from "views/Forms/CreateAppointment.jsx";
import CreatePhoneNumber from "views/Forms/CreatePhoneNumber.jsx";
import CreateInvoice from "views/Forms/CreateInvoice.jsx";
import CreateService from "views/Forms/CreateService.jsx";
import CreateProduct from "../views/Forms/CreateProduct.jsx";
import CreateResource from "../views/Forms/CreateResource.jsx";
import CreateStaffMember from "../views/Forms/CreateStaffMember.jsx";
import CompanyProfile from "../views/Pages/CompanyProfile.jsx";
import CreateInventory from "../views/Forms/CreateInventory.jsx";
import ContactView from "components/CoreWeb/ContactView.jsx";
import ServiceView from "components/CoreWeb/ServiceView.jsx";
import ViewProduct from "components/CoreWeb/ViewProduct.jsx";
import PricingView from "components/CoreWeb/PricingView.jsx";
import AddressView from "components/CoreWeb/AddressView.jsx";
import { paths } from "variables/projPaths.jsx";
import MedicalRecordNote from "views/Forms/MedicalRecordNote.jsx";
import ComingSoonStep from "../components/CoreWeb/ComingSoonStep.jsx";
import Logout from "components/CoreWeb/Containers/Logout.jsx";
import LoginCallback from "../components/CoreWeb/LoginCallback.jsx";
import LinkAnimals from "../components/CoreWeb/LinkAnimals.jsx";
import { Devices, Extension } from "@material-ui/icons";
import LinkCustomers from "../components/CoreWeb/LinkCustomers.jsx";

var dashRoutes = [
  {
    path: "/app/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
  },
  {
    //path: "/customers/existing",
    path: paths.customerList,
    name: <FormattedMessage id="existingCustomers" />,
    component: CustomerListView,
    icon: Face,
  },
  {
    //path: "/patients/existing",
    path: paths.animalList,
    name: <FormattedMessage id="existingPatients" />,
    mini: "",
    component: AnimalListView,
    icon: Pets,
  },
  {
    path: paths.calendar,
    name: <FormattedMessage id="clinicCalendar" />,
    mini: "",
    component: Calendar,
    icon: Event,
  },
  {
    path: paths.serviceList,
    name: <FormattedMessage id="existingServices" />,
    mini: "",
    component: OrgServices,
    icon: ShoppingCart,
  },
  /* {
    path: "/inventory/products",
    name: <FormattedMessage id="products"></FormattedMessage>,
    mini: "",
    component: OrgProducts,
    icon: Ballot,
  },
  {
    path: "/settings/existing",
    name: <FormattedMessage id="manageResources"></FormattedMessage>,
    mini: "",
    component: OrgResourceList,
    icon: Category,
  },
  {
    path: "/settings/existing",
    name: <FormattedMessage id="manageResourcesEquipment"></FormattedMessage>,
    mini: "",
    component: OrgResourceList,
    icon: Devices,
  },*/
  {
    path: paths.memberList,
    name: <FormattedMessage id="manageStaff" />,
    mini: "",
    component: OrgStaffList,
    icon: SupervisorAccount,
  },
  {
    path: paths.memberExport,
    name: "Staff Data Export",
    mini: "",
    component: OrgStaffExport,
    hideFromSideBar: true,
  },
  {
    path: paths.orgList,
    name: <FormattedMessage id="signIntoOrg" />,
    mini: "",
    component: OrganisationList,
    icon: Settings,
  },
  {
    path: paths.userProfile,
    name: <FormattedMessage id="yourProfile" />,
    mini: "",
    component: UserProfile,
    icon: AccountBox,
  },
  /*
  {
    collapse: true,
    path: "/metrics",
    name: "Metrics",
    state: "openMetrics",
    icon: Equalizer,
    views: [
      {
        path: "/metrics/your-metrics",
        name: "Your Clinic Data",
        mini: "",
        component: Charts
      }
    ]
  },*/
  {
    collapse: true,
    path: paths.customerList,
    name: <FormattedMessage id="customers" />,
    state: "openCust",
    icon: Face,
    hideFromSideBar: true,
    views: [
      {
        path: paths.customerCreate,
        name: <FormattedMessage id="createNewCustomer" />,
        mini: "",
        component: ContactView,
        hideFromSideBar: true,
      },
      {
        path: paths.customerCreateWithPatient + "/:animalId",
        name: <FormattedMessage id="createNewCustomer" />,
        mini: "",
        component: ContactView,
        hideFromSideBar: true,
      },

      {
        path: paths.customerView + "/:contactId",
        name: <FormattedMessage id="viewCustomer" />,
        mini: "",
        component: ContactView,
        hideFromSideBar: true,
      },
      {
        path: paths.customerLinkPatient + "/:animalId",
        name: <FormattedMessage id="viewCustomer" />,
        mini: "",
        component: LinkCustomers,
        hideFromSideBar: true,
      },
      {
        path: paths.addressDetails + "/:contactId",
        name: <FormattedMessage id="viewAddress" />,
        mini: "",
        component: AddressView,
        hideFromSideBar: true,
      },
      {
        path: paths.phoneNumberCreate + "/:contactId",
        name: <FormattedMessage id="createNewPhoneNumber" />,
        mini: "",
        component: CreatePhoneNumber,
        hideFromSideBar: true,
      },
      {
        path: paths.phoneNumberList + "/:phoneNumberId",
        name: <FormattedMessage id="createNewPhoneNumber" />,
        mini: "",
        component: CreatePhoneNumber,
        hideFromSideBar: true,
      },
    ],
  },
  {
    collapse: true,
    path: "/app/patients",
    name: <FormattedMessage id="patients" />,
    state: "openPati",
    icon: Pets,
    hideFromSideBar: true,
    views: [
      {
        path: paths.animalCreate,
        name: <FormattedMessage id="createNew" />,
        mini: "",
        component: CreateAnimal,
        hideFromSideBar: true,
      },
      {
        path: paths.animalCreateWithContact + "/:contactId",
        name: <FormattedMessage id="createNew" />,
        mini: "",
        component: CreateAnimal,
        hideFromSideBar: true,
      },
      {
        path: paths.animalView + "/:animalId",
        name: <FormattedMessage id="viewPatient" />,
        mini: "",
        component: CreateAnimal,
        hideFromSideBar: true,
      },
      {
        path: paths.medicalRecordEntry + "/:animalId",
        name: <FormattedMessage id="medicalRecordEntry" />,
        mini: "",
        component: MedicalRecordNote,
        hideFromSideBar: true,
      },
      {
        path: paths.animalLinkCustomer + "/:contactId",
        name: <FormattedMessage id="medicalRecordEntry" />,
        mini: "",
        component: LinkAnimals,
        hideFromSideBar: true,
      },
    ],
  },

  {
    collapse: true,
    path: paths.invoiceList,
    name: <FormattedMessage id="invoices" />,
    state: "openInvo",
    icon: EuroSymbol,
    hideFromSideBar: true,
    views: [
      /*{
        path: "/customers/newInvoice",
        name: <FormattedMessage id="createNewInvoice"></FormattedMessage>,
        mini: "",
        component: ComingSoonStep
      },*/
      {
        path: paths.customerInvoices,
        name: <FormattedMessage id="existingInvoices" />,
        mini: "",
        component: ComingSoonStep,
      },
    ],
  },

  {
    collapse: true,
    path: paths.schedule,
    name: "Schedule",
    state: "openApp",
    icon: Event,
    hideFromSideBar: true,
    views: [
      {
        path: paths.scheduleAppointmentCreate,
        name: "Create New Appointment",
        mini: "",
        component: CreateAppointment,
        hideFromSideBar: true,
      },
      {
        path: paths.scheduleAppointmentList,
        name: <FormattedMessage id="appointments" />,
        mini: "",
        component: AppointmentList,
        hideFromSideBar: true,
      },
    ],
  },

  {
    collapse: true,
    path: "/app/services",
    name: <FormattedMessage id="services" />,
    state: "openServices",
    icon: ShoppingCart,
    hideFromSideBar: true,
    views: [
      {
        path: paths.serviceCreate,
        name: <FormattedMessage id="createNewService" />,
        mini: "",
        component: CreateService,
        hideFromSideBar: true,
      },
      {
        path: paths.serviceView + "/:serviceId",
        name: <FormattedMessage id="viewService" />,
        mini: "",
        component: ServiceView,
        hideFromSideBar: true,
      },
      {
        path: paths.pricingCreate + "/:serviceId",
        name: <FormattedMessage id="addPricing" />,
        mini: "",
        component: PricingView,
        hideFromSideBar: true,
      },
      {
        path:
          paths.pricingView + "/:orgId/:serviceId/:serviceFulfilmentConfigId",
        name: <FormattedMessage id="viewPricing" />,
        mini: "",
        component: PricingView,
        hideFromSideBar: true,
      },
    ],
  },

  {
    collapse: true,
    path: paths.productInventory,
    name: <FormattedMessage id="inventory" />,
    state: "openInventory",
    icon: Ballot,
    hideFromSideBar: true,
    views: [
      {
        path: paths.productCreate,
        name: <FormattedMessage id="addNewProduct" />,
        mini: "",
        component: CreateProduct,
        hideFromSideBar: true,
      },
      {
        path: paths.productView + "/:productId",
        name: <FormattedMessage id="viewProduct" />,
        mini: "",
        component: ViewProduct,
        hideFromSideBar: true,
      },
      {
        path: paths.productInventory + "/addInventory",
        name: <FormattedMessage id="addInventory" />,
        mini: "",
        component: ComingSoonStep,
        hideFromSideBar: true,
      },
      {
        path: paths.productInventory + "/manageStock",
        name: <FormattedMessage id="viewStockLevels" />,
        mini: "",
        component: ComingSoonStep,
        hideFromSideBar: true,
      },
    ],
  },
  {
    collapse: true,
    path: "/app/resources",
    name: <FormattedMessage id="resources" />,
    state: "openResources",
    icon: Category,
    hideFromSideBar: true,
    views: [
      {
        path: paths.resourceCreate,
        name: <FormattedMessage id="addResource" />,
        mini: "",
        component: CreateResource,
        hideFromSideBar: true,
      },

      {
        path: paths.resourceList,
        name: <FormattedMessage id="manageResourcesEquipment" />,
        mini: "",
        component: OrgResourceList,
      },
      {
        path: paths.resourceView + "/:resourceId",
        name: <FormattedMessage id="viewResource" />,
        mini: "",
        component: CreateResource,
        hideFromSideBar: true,
      },
    ],
  },

  {
    collapse: true,
    path: "/app/personell",
    name: <FormattedMessage id="personell" />,
    state: "openPersonell",
    icon: SupervisorAccount,
    hideFromSideBar: true,
    views: [
      {
        path: paths.memberCreate,
        name: <FormattedMessage id="addStaffMember" />,
        mini: "",
        component: CreateStaffMember,
        hideFromSideBar: true,
      },

      {
        path: paths.staffView + "/:contactId",
        name: <FormattedMessage id="viewStaff" />,
        mini: "",
        component: ContactView,
        hideFromSideBar: true,
      },
    ],
  },
  /*{
    collapse: true,
    path: "/unavailable",
    name: "Unavailable Periods",
    state: "openUnav",
    icon: EventBusy,
    views: [
      
      {
        path: "/unavailable/createAbsence",
        name: "New Staff Absence",
        mini: "",
        component: CreateStaffAbsence
      },
      {
        path: "/unavailable/absences",
        name: " Absences",
        mini: "",
        component: AbsenceList
      }, 
      {
        path: "/unavailable/createException",
        name: "New Resource Exclusion",
        mini: "",
        component: CreateResourceExclusion
      },
      {
        path: "/unavailable/resExceptions",
        name: "Resource Exlusions",
        mini: "",
        component: ResourceExceptionList
      },   
      {
        path: "/unavailable/createClosure",
        name: "New Closure Period",
        mini: "",
        component: CreateOrgClosure
      },
      {
        path: "/unavailable/closures",
        name: "Clinic Closures",
        mini: "",
        component: OrgClosures
      },        
    ]
  },*/
  /*{
    collapse: true,
    path: "/insurance",
    name: "Insurance",
    state: "openInsurances",
    icon: ListAlt,
    views: [
      {
        path: "/insurance/clinic",
        name: "Clinic Insurance",
        mini: "",
        component: UserProfile
      },    
      {
        path: "/insurance/customer",
        name: "Customers Insurance",
        mini: "",
        component: UserProfile
      },      
    ]
  },*/
  {
    collapse: true,
    path: "/app/settings",
    name: <FormattedMessage id="settings" />,
    state: "openSettings",
    icon: Settings,
    hideFromSideBar: true,
    views: [
      {
        path: paths.orgView + "/:id",
        name: <FormattedMessage id="clinicView" />,
        mini: "",
        component: CompanyProfile,
        hideFromSideBar: true,
      },
      {
        path: paths.orgView,
        name: <FormattedMessage id="createClinic" />,
        mini: "",
        component: CompanyProfile,
        hideFromSideBar: true,
      },

      /*{
        path: "/settings/insurance",
        name: "Clinic Insurance",
        mini: "",
        component: CompanyProfile
      }*/
    ],
  },
  {
    collapse: true,
    path: "/app/profile",
    name: <FormattedMessage id="profile" />,
    state: "openProfile",
    icon: AccountBox,
    hideFromSideBar: true,
    views: [
      /*{
        path: "/profile/user-feedback",
        name: "Your Feedback",
        mini: "",
        component: YourFeedback
      },
     */
    ],
  },
  {
    path: "/app/logout",
    name: "Logout",
    icon: DashboardIcon,
    component: Logout,
  },
  {
    path: "/loginCallback",
    name: "LoginCallBack",
    component: LoginCallback,
    hideFromSideBar: true,
  },
];

export default dashRoutes;
