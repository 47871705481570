import React from "react";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CreateAppointmentStep1 from "./WizardSteps/CreateAppointmentStep1.jsx";
import CreateCustomerStep2 from "./WizardSteps/CreateCustomerStep2.jsx";

class CreateAppointment extends React.Component {
  render() {
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={8}>
          <Wizard
            validate
            steps={[
              {
                stepName: "Options",
                stepComponent: CreateAppointmentStep1,
                stepId: "about",
              },
            ]}
            title="Create New Appointment"
            subtitle="We will now setup a new appointment"
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default CreateAppointment;
