import React, { useEffect } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import StarRatingComponent from "react-star-rating-component";
import Visibility from "@material-ui/icons/Visibility";
import VpnKey from "@material-ui/icons/VpnKey";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import { useSelector, useDispatch } from "react-redux";
import { dataTable4 } from "variables/general.jsx";
import { labelFromType } from "variables/project.jsx";
import { IntlProvider, FormattedMessage } from "react-intl";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { readContactCompleteWithDispatch } from "../../variables/thunks";
import { connect } from "react-redux";
import * as raisers from "actions/projectRaisers";
import { bindActionCreators } from "redux";
import * as makeApiCall from "actions/makeApiCall";
import deleicon from "assets/img/delete.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Wizard from "components/Wizard/Wizard.jsx";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import {
  changeUrl,
  viewButton,
  tableButton,
  deleteButton,
  getActiveOrg,
  phoneNumTypes,
} from "variables/coreWeb.jsx";
import { paths } from "variables/projPaths.jsx";
import { Link } from "react-router-dom";
import { deleteMetaDataWithDispatch } from "../../variables/thunks";
import { useHistory } from "react-router-dom";
import PrimaryContactOptions from "./PrimaryContactOptions";
import { createPhoneNumberWithDispatch } from "variables/thunks";
import vieweye from "assets/img/view.svg";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const PhoneNumberList = function(props, prop) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { classes } = props;
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const fillButtons = function(theProps, phoneId, metaDataId, abc) {
    return (
      <div className="">
        <button
          className="sm selfview"
          onClick={(prop) => {
            handleClickOpen();
            theProps.raiseAction({
              fieldIdentifier: "newPhoneNumberType",
              value: abc.type,
            });
            theProps.raiseAction({
              fieldIdentifier: "newPhoneNumber",
              value: abc.metaData,
            });
          }}
        >
          <img src={vieweye} />
        </button>
        <button
          onClick={() => {
            theProps.raiseAction({
              fieldIdentifier: "selectedPhoneId",
              value: phoneId,
            });
            theProps.raiseAction({
              fieldIdentifier: "linkedContactId",
              value: props.contactId,
            });
            theProps.raiseAction({
              fieldIdentifier: "metaDataId",
              value: metaDataId,
            });
          }}
          className="sm danger cur"
        >
          <img src={deleicon} />
        </button>
      </div>
    );
  };

  return (
    <GridContainer className="table-container">
      <GridItem xs={12}>
        <div className="create-phone-align">
          <Button
            className="create-phone-btn btn-theme"
            color={"rose"}
            onClick={handleClickOpen}
          >
            <FormattedMessage id="createNewPhoneNumber" />
          </Button>
        </div>
        <Card>
          {props.dontShowHeader ? (
            ""
          ) : (
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage id="yourOrganizations" />

                <small>
                  {" "}
                  - <FormattedMessage id="yourOrgPartOf" />
                </small>
              </h4>
            </CardHeader>
          )}
          <CardBody>
            <ReactTable
              data={(props.phoneNumbers || []).map((prop, key) => {
                return {
                  id: key,
                  phoneNum: prop.metaData,
                  type: phoneNumTypes.find((x) => x.value == prop.type).text,
                  actions: fillButtons(
                    props,
                    prop.orgId,
                    prop.metaDataId,
                    prop
                  ),
                  dispatch: props.dispatch,
                  orgId: prop.orgId,
                  metaDataId: prop.metaDataId,
                };
              })}
              filterable
              columns={[
                {
                  Header: "Phone Number",
                  accessor: "phoneNum",
                },
                {
                  Header: "Number Type",
                  accessor: "type",
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                },
              ]}
              defaultPageSize={10}
              showPaginationTop={false}
              showPaginationBottom={true}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
      {open ? (
        <Dialog
          open={true}
          classes={{
            root: classes.center,
            paper: `${classes.modal} add-phone-popup`,
          }}
          onClose={handleClose}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogContent id="modal-slide-description">
            <GridItem className="Create-phone-set-one" md={12}>
              <Wizard
                steps={[
                  {
                    stepName: "Options",
                    stepComponent: () => {
                      return (
                        <GridContainer justify="center">
                          <GridItem className="Create-phone-set" md={12}>
                            <PrimaryContactOptions
                              className="option-boxes"
                              numTypeIdentifier={"newPhoneNumberType"}
                              numSelectIdentifier={"newPhoneNumber"}
                            />
                          </GridItem>
                        </GridContainer>
                      );
                    },
                    stepId: "about",
                  },
                ]}
                title={<FormattedMessage id={"createNewPhoneNumber"} />}
                subtitle=""
                cancelButton={true}
                cancelButtonText={<FormattedMessage id={"cancel"} />}
                cancelButtonClick={() => {
                  handleClose();
                  if (props.cancelButtonClick) {
                    props.cancelButtonClick();
                  } else {
                    history.goBack();
                  }
                }}
                finishButtonClick={() => {
                  dispatch(
                    createPhoneNumberWithDispatch(
                      {
                        json: JSON.stringify({
                          orgId: getActiveOrg(),
                          contactId: props.contactId,
                          metaData: "+" + props.pwReducer.newPhoneNumber,
                          metaDataIdentifier: "phoneNum",
                          type: props.pwReducer.newPhoneNumberType,
                        }),
                        functionName: "CreateContactPhoneNumber",
                      },
                      (resp) => {
                        window.location.reload();
                      }
                    )
                  );
                }}
              />
              <div onClick={handleClose} class="close-btn">
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M17.1569 14.5253L28.4489 3.23295C29.1838 2.49842 29.1838 1.3108 28.4489 0.576282C27.7144 -0.15824 26.5267 -0.15824 25.7922 0.576282L14.4998 11.8686L3.20781 0.576282C2.47295 -0.15824 1.28567 -0.15824 0.55115 0.576282C-0.183717 1.3108 -0.183717 2.49842 0.55115 3.23295L11.8432 14.5253L0.55115 25.8177C-0.183717 26.5522 -0.183717 27.7398 0.55115 28.4743C0.917207 28.8407 1.39852 29.0248 1.87948 29.0248C2.36045 29.0248 2.84141 28.8407 3.20781 28.4743L14.4998 17.182L25.7922 28.4743C26.1586 28.8407 26.6396 29.0248 27.1206 29.0248C27.6015 29.0248 28.0825 28.8407 28.4489 28.4743C29.1838 27.7398 29.1838 26.5522 28.4489 25.8177L17.1569 14.5253Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="29" height="29" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </GridItem>
          </DialogContent>
          {/* <DialogActions
                style={{display:'flex', justifyContent:'center'}}
                className={
                  classes.modalFooter + " " + classes.modalFooterCenter
                }
              >
                <Button
                  className="cancel-btn"
                  color="success" 
                  color="simple"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                className="cancel-btn-delete"
                  color="danger"
                >
                  Yes, delete it.
                </Button>
              </DialogActions> */}
        </Dialog>
      ) : (
        ""
      )}
    </GridContainer>
  );
};

function mapStateToProps(state) {
  return {
    orgList: state.pwReducer.orgList,
    selectedPhoneId: state.pwReducer.selectedPhoneId,
    shouldShowPhoneDialogue: state.pwReducer.shouldShowPhoneDialogue,
    metaDataId: state.pwReducer.metaDataId,
    pwReducer: state.pwReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    dispatch: dispatch,
    raiseAction: (payload) => {
      dispatch(raisers.createActionGeneric(payload, actions.cw.SET_STATE_VAL));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(userProfileStyles)(PhoneNumberList));
