import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { getActiveOrg } from "variables/coreWeb.jsx";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import * as actions from "actions/projectActionTypes";
import * as makeApiCall from "actions/makeApiCall";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GridContainer from "components/Grid/GridContainer.jsx";
import { useDispatch } from "react-redux";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {
  LocalHospital,
  People,
  Print,
  Share,
  PlusOne,
  Add,
} from "@material-ui/icons";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Build from "@material-ui/icons/Build";
import { Link, useHistory } from "react-router-dom";
// core components
import NavPills from "components/NavPills/NavPills.jsx";

//icon import
import Contact_mail from "@material-ui/icons/ContactMail";
import Phone_social from "@material-ui/icons/PermDeviceInformation";
import LocationOn from "@material-ui/icons/LocationOn";
import final from "@material-ui/icons/VerifiedUser";
import CreateCustomerStep1 from "views/Forms/WizardSteps/CreateCustomerStep1";
import CreateCustomerStep2 from "views/Forms/WizardSteps/CreateCustomerStep2";
import CreateCustomerStep3 from "views/Forms/WizardSteps/CreateCustomerStep3";
import CreateCustomerStep4 from "views/Forms/WizardSteps/CreateCustomerStep4";
import PrimaryContactOptions from "components/CoreWeb/PrimaryContactOptions";
import SecondaryContactOptions from "components/CoreWeb/SecondaryContactOptions";
import SocialPrimaryOptions from "components/CoreWeb/SocialPrimaryOptions";
import SocialSeondaryOptions from "components/CoreWeb/SocialSeondaryOptions";
import PhoneNumberList from "components/CoreWeb/PhoneNumberList";
import ContactBasicOptions from "components/CoreWeb/ContactBasicOptions";
import ContactImage from "components/CoreWeb/ContactImage";
import Button from "components/CustomButtons/Button.jsx";
import AddressList from "components/CoreWeb/AddressList";
import LocationCity from "@material-ui/icons/LocationCity";
import Pets from "@material-ui/icons/Pets";
import Assignment from "@material-ui/icons/Assignment";
import { getMostRecentFileFromList } from "variables/coreWeb.jsx";
import { UPLOADSPATH } from "variables/project.jsx";
import {
  createCustomer,
  createPhoneNumber,
} from "../../variables/coreCreateRequests";
import { updateContact, deleteMetaData } from "../../variables/coreRequests";
import { stat } from "fs";
import {
  updateCustomerWithDispatch,
  createCustomerWithDispatch,
} from "../../variables/thunks";
import { EuroSymbol, Phone } from "@material-ui/icons";
import AnimalList from "components/CoreWeb/AnimalList.jsx";
import CreatePhoneNumberWizard from "../../views/Forms/CreatePhoneNumberWizard";
import pet from "./img/Pets.svg";
import phone from "./img/Phone.svg";
import locationCity from "./img/LocationCity.svg";
import assignment from "./img/Assignment.svg";
import euroSymbol from "./img/EuroSymbol.svg";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

const ContactViewOptions = function(props) {
  const theProps = props;
  const history = useHistory();
  const [isCustomerView, setCustomerView] = useState(true);
  const dispatch = useDispatch();

  const getContactTabs = function(theProps) {
    var resp = [];
    if (
      theProps.contactId != undefined &&
      theProps.contactDetails.contactType == 3
    ) {
      resp.push({
        tabButton: <FormattedMessage id="patients" />,
        tabIcon: pet,
        tabContent: (
          <div>
            <AnimalList
              contactId={theProps.contactId}
              animals={props.contactDetails.animalList}
              hideHeader={true}
            />
          </div>
        ),
      });
    }
    if (theProps.contactId != undefined) {
      resp.push({
        tabButton: <FormattedMessage id="phoneNumbers" />,
        tabIcon: phone,
        tabContent: (
          <div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                {props.contactId != undefined ? (
                  <div>
                    {props.contactPhoneNumbers.length > 0 ? (
                      <h4>
                        <FormattedMessage id="phoneNumbers" />
                      </h4>
                    ) : (
                      ""
                    )}
                    <PhoneNumberList
                      contactId={props.contactId}
                      phoneNumbers={props.contactPhoneNumbers}
                      dontShowHeader={true}
                    />
                  </div>
                ) : (
                  ""
                )}
              </GridItem>
            </GridContainer>
          </div>
        ),
      });
    }
    if (theProps.contactId != undefined) {
      resp.push({
        tabButton: <FormattedMessage id="addresses" />,
        tabIcon: locationCity,
        tabContent: (
          <div>
            <AddressList
              contactId={theProps.contactId}
              contactDetails={theProps.contactDetails}
              dontShowHeader={true}
            />
          </div>
        ),
      });
    }
    if (theProps.contactId != undefined && theProps.contactType == 2) {
      resp.push({
        tabButton: <FormattedMessage id="patients" />,
        tabIcon: pet,
        tabContent: (
          <div>
            <Card>
              <CardBody>
                <h4>
                  <FormattedMessage id="comingSoon" />
                </h4>
              </CardBody>
            </Card>
          </div>
        ),
      });
    }
    if (theProps.contactId != undefined) {
      resp.push({
        tabButton: <FormattedMessage id="appointments" />,
        tabIcon: assignment,
        tabContent: (
          <div>
            <Link>
              <Button className="btn-primary create-appointment btn-theme">
                Create Appointment
              </Button>
            </Link>
            <Card>
              <CardBody>
                <h4>
                  <FormattedMessage id="comingSoon" />
                </h4>
              </CardBody>
            </Card>
          </div>
        ),
      });
    }
    if (
      theProps.contactId != undefined &&
      theProps.contactDetails.contactType == 3
    ) {
      resp.push({
        tabButton: <FormattedMessage id="billing" />,
        tabIcon: euroSymbol,
        tabContent: (
          <div>
            <Link>
              <Button className="btn-primary create-billling btn-theme">
                Create Billling
              </Button>
            </Link>
            <Card>
              <CardBody>
                <h4>
                  <FormattedMessage id="comingSoon" />
                </h4>
              </CardBody>
            </Card>
          </div>
        ),
      });
    }
    return resp;
  };

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <div>
            <Card>
              <CardBody>
                <GridContainer justify={"left"}>
                  {/* {theProps.contactId ? (
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <br />
                      <ContactImage
                        contactId={theProps.contactId}
                        style={{ float: "left" }}
                        orgId={getActiveOrg()}
                        imagePreviewUrl={theProps.activeFileUrl}
                        dispatch={props.dispatch}
                      />
                    </GridItem>
                  ) : (
                    ""
                  )} */}
                </GridContainer>
                <ContactBasicOptions
                  isCreate={theProps.contactId == undefined}
                  contactId={theProps.contactId}
                  activeFileUrl={theProps.activeFileUrl}
                  dispatch={props.dispatch}
                />
                <GridContainer justify={"left"}>
                  <GridItem md={12} className="upadate-btn-customers">
                    <br />
                    {theProps.contactId != undefined ? (
                      <Button
                        className="upadate-btn-customer btn-theme"
                        color="rose"
                        onClick={() => {
                          theProps.dispatch(
                            updateCustomerWithDispatch(
                              theProps.callApi,
                              theProps.contactDetails
                            )
                          );
                        }}
                      >
                        <FormattedMessage id="update" />
                      </Button>
                    ) : (
                      <div className="st-s bottom-set">
                        <Button
                          color="rose"
                          className="btn-theme"
                          onClick={() => {
                            theProps.dispatch(
                              createCustomerWithDispatch(
                                {
                                  ...theProps,
                                  //animaId: props.animaId,
                                  userId: 0,
                                },
                                (resp) => {
                                  history.push(
                                    "/patients/createnewWithContact/" +
                                      resp.newRecordID
                                  );
                                }
                              )
                            );
                          }}
                        >
                          <FormattedMessage id="createCustomer" />
                        </Button>
                      </div>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <NavPills color="rose" alignCenter tabs={getContactTabs(props)} />
        </GridItem>
      </GridContainer>
    </div>
  );
};

function mapStateToProps(state) {
  var activeFile = null;
  if (
    state.pwReducer.contactDetails &&
    state.pwReducer.contactDetails.fileList &&
    state.pwReducer.contactDetails.fileList.length > 0
  ) {
    activeFile = getMostRecentFileFromList(
      state.pwReducer.contactDetails.fileList
    );
  }
  return {
    contactDetails: state.pwReducer.contactDetails,
    activeFileUrl:
      activeFile != null
        ? UPLOADSPATH + activeFile.fileId + "" + activeFile.fileExtension
        : null,
    pwReducer: state.pwReducer,
    contactPhoneNumbers: state.pwReducer.contactPhoneNumbers || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    dispatch: dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ContactViewOptions));
