import axios from "axios";

import { APIURL } from "variables/project";

export const createNewResource = (token, url, data) => {
  return axios.post(`${APIURL}${url}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
