import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Datetime from "react-datetime";
import { connect } from "react-redux";
import * as actions from "actions/projectActionTypes";
import * as raisers from "actions/projectRaisers";
import { getObjProp } from "variables/coreWeb.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const style = {};

class ReduxCustomPhoneInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <FormControl fullWidth>
          <PhoneInput
            inputProps={{
              key: "PhoneInput_" + this.props.fieldIdentifier,
              autoFocus: true,
            }}
            country={'us'}
            placeholder={this.props.placeholder || "Enter phone number"}
            value={this.props.textValue}
            onChange={(value, data) =>
              !data || !data.dialCode
                ? (this.props.textChanged(null) || true) &&
                  alert("select country code from the drop down first")
                : this.props.textChanged(value)
            }
            copyNumbersOnly={false}
            enableSearch={true}
          />
        </FormControl>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  var myCurrentVal =
    ownProps.textValue || getObjProp(state.pwReducer, ownProps.fieldIdentifier);
  return {
    textValue: myCurrentVal,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    textChanged: (payload) => {
      if (
        ownProps.updateAction === actions.cw.UPREDUXCUSTOMINPUT ||
        !ownProps.updateAction
      ) {
        var newPayload = {};
        newPayload.value = payload;
        newPayload.fieldIdentifier = ownProps.fieldIdentifier;
        dispatch(
          raisers.createActionGeneric(newPayload, actions.cw.UPREDUXCUSTOMINPUT)
        );
      } else {
        dispatch(raisers.createActionGeneric(payload, ownProps.updateAction));
      }
    },
  };
};

export default withStyles(extendedFormsStyle)(
  connect(mapStateToProps, mapDispatchToProps)(ReduxCustomPhoneInput)
);
