import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GenderSelect from "components/CoreWeb/GenderSelect";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import DeSexedStatus from "components/CoreWeb/DeSexedStatus";
import BloodGroup from "components/CoreWeb/BloodGroup";
import { FormattedMessage } from "react-intl";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  ...customSelectStyle,
};

class ComingSoonStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      desgin: false,
      code: false,
      develop: false,
    };
  }
  sendState() {
    return this.state;
  }
  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  isValidated() {
    return true;
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>
              <FormattedMessage id="comingSoon" />
            </h4>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(style)(ComingSoonStep);
